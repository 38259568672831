import React, {useState} from "react";
import ScanTable from "./ScanTable";
import {Trans} from "react-i18next";
import QPacksGrid from "../CampaignPage/Standard/QPacksGrid";
import ModalQuestionsPack from "../CampaignPage/Standard/ModalQuestionsPack";
import ModalAddNewPulseStep3 from "../PulsePage/ModalAddNewPulseStep3";
import ModalAddNewScanChooseDateToSend from "./ModalAddNewScanChooseDateToSend";
import {toast} from "react-toastify";

interface MyProps {

}

interface MyState {
    selectedQPack: any,
    selectedScan: any,

    modalChooseQuestionsShow: boolean,
    modalChooseDateShow: boolean,
    modalQuestionsFromSection: string,

    selectedQuestionIds:any,

    refreshTable:number
}

class ScanPage extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
        selectedQPack: {},
        selectedScan: {},
        modalQuestionsFromSection: "",
        selectedQuestionIds: [],
        modalChooseQuestionsShow: false,
        modalChooseDateShow: false,
        refreshTable: 0
    };

    this.onScanDeleted = this.onScanDeleted.bind(this);
    this.onClickPack = this.onClickPack.bind(this);
    this.onQuestionPackScheduled = this.onQuestionPackScheduled.bind(this);
    this.onScanCreated = this.onScanCreated.bind(this);
    this.onEditScan = this.onEditScan.bind(this);


  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
      let closeQuestionsPackModal = () => this.setState({ modalChooseQuestionsShow: false });
      let closeChooseDateModal = () => this.setState({ modalChooseDateShow: false });

      const refreshTable = this.state.refreshTable;

    return (
      <div className="container mt-5 p-5" id="resultsContainer">
          <h4>
              <Trans>Create a new scan from a pack</Trans>
          </h4>
          <div style={{padding:"2rem 0"}} >
              <QPacksGrid
                  horizontal={true}
                  rows={1}
                  packType={1}
                  onClickPack={this.onClickPack}
              />
          </div>

        <h3><Trans>Scans</Trans></h3>
        <br/>
        <ScanTable refresh={refreshTable} onScanDeleted={this.onScanDeleted} onEditScan={this.onEditScan} />

            {/*fromSection*/}
            {/*  campaign - campaign generated questions*/}
            {/*  add_scan - questions from selectedQPack*/}
            {/*  edit_scan - questions from selectedIdScan*/}
          <ModalQuestionsPack
              fromSection = {this.state.modalQuestionsFromSection}
              selectedQPack= {this.state.selectedQPack}
              selectedScan = {this.state.selectedScan}
              show={this.state.modalChooseQuestionsShow}
              onHide={closeQuestionsPackModal}
              onQuestionPackScheduled={this.onQuestionPackScheduled}
              size='lg'
          />

          <ModalAddNewScanChooseDateToSend
              show={this.state.modalChooseDateShow}
              selectedScan={this.state.selectedScan}
              selectedQPack={this.state.selectedQPack}
              selectedQuestionIds={this.state.selectedQuestionIds}
              onHide={closeChooseDateModal}
              onScanCreated = {this.onScanCreated}
          />

      </div>
    );
  }


    onScanCreated() {
      let refreshTable = this.state.refreshTable;

        this.setState({ modalChooseQuestionsShow:false, modalChooseDateShow: false, refreshTable:++refreshTable });
    }

    onScanDeleted() {}

    onEditScan(oneScan:any) {
        this.setState({ modalChooseQuestionsShow:true, selectedScan: oneScan, modalQuestionsFromSection:"edit_scan" });
    }


    onClickPack(selectedQPack) {
        this.setState({ selectedQPack: selectedQPack, modalChooseQuestionsShow: true, modalQuestionsFromSection:"add_scan", selectedScan: {} });
    }

    onQuestionPackScheduled(selectedQuestions) {
        let selectedQuestionIds = [];
        for(let i=0;i<selectedQuestions.length;i++){
            if(selectedQuestions[i]['IdQuestion']){
                selectedQuestionIds.push(selectedQuestions[i]['IdQuestion']);
            }
        }
        this.setState({ modalChooseQuestionsShow:false, modalChooseDateShow: true , selectedQuestionIds: selectedQuestionIds });
    }


}

export { ScanPage };
