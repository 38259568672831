import React from 'react';

import {Button} from 'react-bootstrap'

import { serverService } from '../../_services/server.service';
import i18n from "i18next";
import ModalPulseQuestions from "./ModalPulseQuestions";

import {Trans} from "react-i18next";

import { CommentsViewModal } from './CommentsViewModal';

const $ = require('jquery');
$.DataTable = require('datatables.net');

interface MyProps {
    pulse: any,
    onCancel(): void
}

interface MyState {
    comments: any,
    addNewQuestion: boolean
}
 
class PulseAnswerTable extends React.Component<MyProps,MyState> {
    constructor(props) {
        super(props);

        this.state = {
            addNewQuestion: false,
            comments: null
        };

        this.reloadTableData = this.reloadTableData.bind(this);
        this.addClickEvent = this.addClickEvent.bind(this);
        this.doFetchAllPulses = this.doFetchAllPulses.bind(this);
    }

    componentDidMount() {
        let columns = [
          {
              title: i18n.t("trans_33_question"),
              width: 350,
              data: 'QuestionText',
          },
          {
              title: i18n.t("trans_33_percentage_agree_of"),
              width: 160,
              data: 'NumOfYes',
          },
          {
              title: i18n.t("trans_33_pulse_questions_table_numofanswers"),
              width: 160,
              data: 'NumOfAnswers',
          },
          {
              title: i18n.t("trans_33_pulse_questions_table_comments"),
              width: 220,
              data: 'IdPulse',
              orderable: false
          },
        ]

        const table = $(this.refs.main).DataTable({
            dom: "<'data-table-wrapper'" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'><'col-sm-12 col-md-7'p>>" +
                    ">",
            // dom: 'Bfrtip',
            data: this.props.pulse.questions,
            columns,
            ordering: true,
            paging:true,
            buttons: [
                { extend: 'excel', text: i18n.t("trans_33_export_excel") }
            ],
            // select: false
            // select: {
            //     // style: 'single',
            //     //blurable: true
            // },

            columnDefs: [
                {
                  render: function ( data, type, row ) {
                      const percentage = row.NumOfYes || 0
                      return `${percentage} %`
                  },
                  targets: 1,
                },
                {
                    render: function ( data, type, row ) {
                        if(data != null){
                          const isDisabled = !row.AcceptsComments;
                          const btnText = isDisabled
                            ? i18n.t("trans_33_comments_disabled")
                            : i18n.t("trans_33_view_comments");

                          const btnClsx = isDisabled
                            ? "'btn btn-secondary disabled w-100 btn-table-actions btn-table-actions-disabled'"
                            : "'btn btn-primary w-100 btn-table-actions'"

                            return "<button class=" + btnClsx + ""
                                + " data-id=" + row.IdPulseQuestion + ""
                                + ">" + btnText + "</button>";

                        } else {
                            return "";
                        }
                    },
                    targets: 3,
                },
            ]
        });

        this.addClickEvent(table)
    }

    componentWillUnmount(){
      $('.data-table-wrapper')
          .find('.pulsesAnswersTable')
          .DataTable()
          .destroy(true);
    }

    render() {
        const { pulse } = this.props;

        const { id, name } = pulse

        return (
          <div>
            <button className="btn btn-primary py-2 px-4" onClick={this.props.onCancel}><i className="fas fa-arrow-left"></i> Back to Pulse</button>
            <div className="row mb-4 mt-5 align-items-center">
                <div className="col-md-7">
                  <div className='row align-items-center '>
                    <h3 className='col-md-4 m-0 font-weight-bold pulse-answer-title'>{name}</h3>
                    <div className='col-md-8 d-flex flex-nowrap align-items-center w-100 input-group relative border border-secondary rounded'>
                      <i className="pl-2 fas fa-search"></i>
                      <input
                        className='w-100 p-2 shadow-none border-0'
                        style={{ outline: 0 }}
                        type="text"
                        placeholder={i18n.t("trans_33_search")}
                        onChange={e => {
                          const table = $(this.refs.main)
                            .dataTable();

                          table.fnFilter( e.target.value );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-5 d-flex justify-content-end">
                  <Button
                    className='p-2 mr-3 btn-outline-primary-updated'
                    variant="outline-primary"
                    size="lg"
                    onClick={() => {
                      $(this.refs.main)
                        .DataTable().button().trigger();
                    }}
                  >
                    <Trans>trans_33_export_excel</Trans>
                  </Button>
                  <Button
                      className='px-4'
                      id="addBtn"
                      variant="primary"
                      size="lg"
                      onClick={() => {
                        this.setState(({ addNewQuestion: true }))
                      }}
                  >
                      <Trans>trans_33_add_pulse_question</Trans>
                  </Button>
                </div>
            </div>
            
            <div className="row">
              <div className="col-md-12" id="usersTableContainer">
                <table style={{ width: '100%' }} ref="main" className="table table-bordered rounded table-data-sort pulsesAnswersTable" />

                {/* <ModalPulseQuestions
                    size="lg"
                    show
                    selectedIdPulse={id}
                    selectedPulseNumOfAnswers={questions.length}
                    onHide={modalPulseQuestionsClose}
                    onNavigateToStep3={this.onNavigateToStep3}
                /> */}

                <ModalPulseQuestions
                    size="lg"
                    show={this.state.addNewQuestion}
                    selectedIdPulse={id} 
                    // selectedPulseNumOfAnswers={0}
                    selectedPulseNumOfAnswers={0}
                    isDuringAddingPulse={false}
                    onHide={() => {
                      this.doFetchAllPulses()
                      this.setState(({ addNewQuestion: false }))
                    }}
                    onNavigateToStep3={() => {}}
                />

                
              <CommentsViewModal
                comments={this.state.comments}
                show={Boolean(this.state.comments)}
                onHide={() => { this.setState({ comments: '' }) }}
              />

              </div>
            </div>
          </div>
        );
    }


    doFetchAllPulses() {
      serverService.getPulseQuestions(this.props.pulse.id).then(
        questionsRes => {
          this.reloadTableData(questionsRes.data)
        }
      );
    }

    reloadTableData(items) { 
        const table = $('.data-table-wrapper')
            .find('.pulsesAnswersTable')
            .DataTable();
        table.clear();
        table.rows.add(items);
        table.draw();

        this.addClickEvent(table)
    }

    addClickEvent (table) {
      table.on( 'click', 'button', (e, dt, type, cell, originalEvent) => {
          e.stopPropagation();

          const id = e.target.getAttribute("data-id");

          const comment = this.props.pulse.questions.find(({ IdPulseQuestion }) => +id === IdPulseQuestion)

          if(!comment.AcceptsComments) {
            return;
          }

          const comments = (comment.Comments || '').split("<br/>").filter(i => i !== '').map(answer => ({ answer: answer.replace("•", ""), comments: '', agree: '' }))

          this.setState({ comments: {
            Added: comment.Added,
            comments,
            QuestionText: comment.QuestionText,
            Name: this.props.pulse.name,
            NumOfAnswers: comment.NumOfAnswers,
            NumOfYes: comment.NumOfYes
          } })

          return;
      });
    }


}


export default PulseAnswerTable;
