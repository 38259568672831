import React from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { serverService } from '../../_services/server.service';

interface MyProps {
  onLanguageSelected(IdLanguage: number): any;
  selectedIdLanguage?: number;
  languages?: any;
  defaultText?:string;
}

interface MyState {
  languages: any;
}

class LanguageSelect extends React.Component<MyProps, MyState> {
  private userObj = null;

  constructor(props) {
    super(props);

    this.state = {
      languages: [],
    };
  }

  componentDidMount() {
    let userStr = localStorage.getItem('user');
    let IdCompany = 0;
    if (userStr) {
      this.userObj = JSON.parse(userStr);
      IdCompany = this.userObj.IdCompany;
    }

    if (this.props.languages != null) {
      this.setState({ languages: this.props.languages });
    } else {
      this.fetchLanguages();
    }
  }

  render() {
    let { languages } = this.state;

    let disabled = false;
    if(this.userObj != null && this.userObj.IdCompany === 1){
      // disabled = true;
    }
    let optionItems = [];
    let defaultValue = 0;

    if (languages && languages.length > 0) {
      optionItems = languages.map(language => {
        let selected = false;
        if (this.props.selectedIdLanguage > 0) {
          if (this.props.selectedIdLanguage == language.IdLanguage) {
            selected = true;
            defaultValue = language.IdLanguage;
          }
        }
        return (
          <option
            key={language.IdLanguage}
            value={language.IdLanguage}
            selected={selected}
          >
            {language.Name}
          </option>
        );
      });
    }


    if(this.props.defaultText){
      optionItems.unshift(<option key='0' value='0'>{this.props.defaultText}</option>);
    }


    return (
      <div className='language-select-container'>
        <select onChange={this.onLanguageSelected} disabled={disabled} >{optionItems}</select>
      </div>
    );
  }

  fetchLanguages() {

    serverService.getLanguages().then(
      result => {
        if (result.status === '1') {
          this.setState({
            languages: result.data,
          });
        } else {
          toast.warn('Error while fetching languages');
        }
      },
      error => {
        toast.warn('Did not get languages');
      }
    );
  }

  onLanguageSelected = event => {
    // this.setState({ selectedValueId: event.target.value });
    this.props.onLanguageSelected(event.target.value);
  };
}

export default LanguageSelect;
