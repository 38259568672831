import React from 'react';

import { serverService } from '../../../_services/server.service';

import './CampaignEnterprisePage.css'

interface MyProps {

}

interface MyState {
}

class CampaignEnterprisePage extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
        head.appendChild(script);
    }

    render() {
        return (
           <div className="row">
               <div className="col-md-12">
                   <div className="calendly-inline-widget" data-url="https://calendly.com/voxcollector/60min" />
               </div>
           </div>
        );
    }

}

export default CampaignEnterprisePage;