import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";
import {Typeahead} from 'react-bootstrap-typeahead';

import { serverService } from '../../../_services/server.service';

import LanguageSelect from "../../_other/LanguageSelect";

import i18n from "i18next";


interface MyProps {
    allSkills:any,
    selectedQuestion:any,
    onQuestionAdded():any,
    show:boolean,
    onHide():any,
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields: any,
    selectedQuestion: any,
    translationQuestionsMap: any,

    allTagsObj:any,
    allTags: any,
    questionTags: any
}

    class AdminModalAddNewQuestion extends React.Component<MyProps,MyState> {


    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',
            formFields:{},
            selectedQuestion: {},
            translationQuestionsMap:{},

            allTagsObj:[],
            allTags:[],
            questionTags:[]
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSkillChange = this.handleSkillChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onExited = this.onExited.bind(this);
        this.onEntered = this.onEntered.bind(this);
        this.onLanguageSelected = this.onLanguageSelected.bind(this);

        this.handleOnChangeSelectTags = this.handleOnChangeSelectTags.bind(this);


    }

    componentDidMount(){

    }

    onExited(){
        let formFields = this.state.formFields;
        formFields.QuestionText = "";
        formFields.Skills = {};
        formFields.IdLanguage = 1;

        this.setState({
            submitted: false,
            loading: false,
            error: '',
            formFields:formFields,
            selectedQuestion: {},

            allTagsObj:[],
            allTags:[],
            questionTags:[]
        });
    }

    onEntered(){
        serverService.getAllTranslations(this.props.selectedQuestion.IdTranslationGlobal).then(result => {
            if(result.status > 0){
                let translationQuestions = result.data;
                let translationQuestionsMap = {};
                for(let i=0;i<translationQuestions.length;i++){
                    translationQuestionsMap[translationQuestions[i].IdLanguage] = translationQuestions[i];
                }
                this.setState({translationQuestionsMap:translationQuestionsMap});
            }
        });


        serverService.getQPickerTags().then(result => {
            if(result.status > 0){
               let tagsArr = [];
               for(let i=0;i<result.data.length;i++){
                   tagsArr.push(result.data[i]['Name']);
               }

               this.setState({allTags: tagsArr,allTagsObj: result.data});
            }
        });


        serverService.getQuestionTags(this.props.selectedQuestion.IdQuestion).then(result => {
            if(result.status > 0){
                let tagsArr = [];
                for(let i=0;i<result.data.length;i++){
                    tagsArr.push(result.data[i]['Name']);
                }

                this.setState({'questionTags': tagsArr});
            }
        });



    }

    onLanguageSelected(IdLanguage){

        let {formFields,translationQuestionsMap} = this.state;

        let QuestionText = "";

        if(IdLanguage == 1){
            QuestionText = this.props.selectedQuestion.QuestionText;
        } else if(translationQuestionsMap[IdLanguage] !== undefined){
            QuestionText = translationQuestionsMap[IdLanguage].Text;
        }

        formFields.QuestionText = QuestionText;
        formFields.IdLanguage = IdLanguage;
        this.setState({formFields:formFields});
    }

    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }

    handleSkillChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields.Skills[name] = value;
        this.setState({ formFields: formFields });
    }


    handleOnChangeSelectTags(selectedTags:any) {
        let selectedTagsJoin = selectedTags.join();

        this.setState({questionTags: selectedTags});
    }



    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});

        const { formFields,translationQuestionsMap,selectedQuestion } = this.state;
        const QuestionText = formFields.QuestionText;
        const Skills = formFields.Skills;
        const IdLanguage = formFields.IdLanguage;


        // stop here if form is invalid
        if (!(QuestionText)) {
            toast.error("Missing fields");
            return;
        }

        let userStr = localStorage.getItem('user');
        let IdUser = 0;
        if(userStr) {
            let userObj = JSON.parse(userStr);
            IdUser = userObj.IdUser;
        }

        let mainInsertOrUpdateQuestionPromise = Promise.resolve({status: 0});


        let selectedQuestionTags = this.state.questionTags;
        let allTagsObj = this.state.allTagsObj;


        let questionTagsObj = [];
        for(let i=0;i<selectedQuestionTags.length;i++){
            for(let j=0;j<allTagsObj.length;j++){
                if(selectedQuestionTags[i] === allTagsObj[j]['Name']){
                    questionTagsObj.push(allTagsObj[j]);
                }
            }
        }

        // console.log(questionTagsObj);
        // return;

        if(IdLanguage > 1){
            let translation;
            let insertOrUpdatePromise = Promise.resolve({status: 0});
            if(translationQuestionsMap[IdLanguage] === undefined){
                translation = {IdTranslationQuestion: 0, IdLanguage: IdLanguage.toString()
                    , IdTranslationGlobal: selectedQuestion.IdTranslationGlobal, Type: "1"};
                translation.Text = QuestionText;
                insertOrUpdatePromise =  serverService.insertTranslation(translation);
            } else {
                translation = translationQuestionsMap[IdLanguage];
                translation.Text = QuestionText;
                insertOrUpdatePromise =  serverService.updateTranslation(translation);
            }


            mainInsertOrUpdateQuestionPromise = insertOrUpdatePromise.then(result => {
                if(result.status == 1){
                    return serverService.insertOrUpdateSkillsForQuestion(Skills,selectedQuestion.IdQuestion);
                }  else {
                    toast.error("Add question translation failed");
                }
            }).then(result => {
                return serverService.insertOrUpdateTagsForQuestion(questionTagsObj,selectedQuestion.IdQuestion);
            });
        } else {
            let question = {'IdQuestion': 0,'IdUser':IdUser.toString(), 'QuestionText':QuestionText, 'Type' : '1' };
            if(this.state.selectedQuestion && typeof(this.state.selectedQuestion.IdQuestion) !== "undefined"){
                question.IdQuestion = this.state.selectedQuestion.IdQuestion;

                mainInsertOrUpdateQuestionPromise = serverService.updateQuestion(question).then(result => {
                            if(result.status == 1){
                                return serverService.insertOrUpdateSkillsForQuestion(Skills,question.IdQuestion);
                            } else {
                                toast.error("Add question failed");
                            }
                    }).then(result => {
                        return serverService.insertOrUpdateTagsForQuestion(questionTagsObj,question.IdQuestion);
                    }, error => {
                        toast.warn("Did not update question");
                    });
            } else {
                mainInsertOrUpdateQuestionPromise = serverService.insertQuestion(question).then(result => {
                        question['IdQuestion'] = result.data.IdQuestion;
                        if(result.status == 1){
                            return serverService.insertOrUpdateSkillsForQuestion(Skills,result.data.IdQuestion);
                        } else {
                            toast.error("Add question failed");
                        }
                }).then(result => {
                    return serverService.insertOrUpdateTagsForQuestion(questionTagsObj,question.IdQuestion);
                }, error => {
                    toast.warn("Did not insert question");
                });
            }
        }


        mainInsertOrUpdateQuestionPromise.then(result => {
            console.log(result);
            if(result !== undefined && result.status == 1){
                let messageStr = "Question and skills edited";
                toast.success(messageStr);
                this.props.onQuestionAdded();
            }
        });

    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.selectedQuestion !== prevState.selectedQuestion){
            this.setState({selectedQuestion:this.props.selectedQuestion});

            let formFields = this.state.formFields;
            formFields.QuestionText = "";
            formFields.Skills = {};

            if(this.state.selectedQuestion && typeof(this.state.selectedQuestion.IdQuestion) !== "undefined"){
                formFields.QuestionText = this.props.selectedQuestion.QuestionText;
            }

            let questionSkills = this.state.selectedQuestion['Skills'];
            this.props.allSkills.map((oneSkill,index) => {
                if (questionSkills !== undefined) {
                    for (let i = 0; i < questionSkills.length; i++) {
                        if (questionSkills[i].IdSkill == oneSkill.IdSkill) {
                            formFields.Skills[oneSkill.IdSkill] = questionSkills[i].Weight;
                            break;
                        }
                    }
                }
            });

            this.setState({formFields:formFields});
        }
    }


    render() {

        let editMode = false;
        if(this.state.selectedQuestion && typeof(this.state.selectedQuestion.IdQuestion) !== "undefined"){
            editMode = true;
        }

        let titleStr = "Add Question";
        if(editMode){
            titleStr = "Edit Question";
        }

        let confirmButtonStr = "Add Question";
        if(editMode){
            confirmButtonStr = "Save";
        }

        let listSkills = this.props.allSkills.map((oneSkill,index) => {

            let value = "";
            if(this.state.formFields.Skills[oneSkill.IdSkill] != undefined){
                value = this.state.formFields.Skills[oneSkill.IdSkill];
            }

            return <div className="row mt-2">
                <div className="col-md-6">{oneSkill.Name}</div>
                <div className="col-md-2">
                    <input type="text" value={value} style={{'width':'70px'}}
                           name={oneSkill.IdSkill}
                           onChange={this.handleSkillChange}
                /></div>
            </div>;
        });


        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                onExited  = {this.onExited}
                onEntered = {this.onEntered}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        {titleStr}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSubmit}>

                        <Form.Group controlId="formBasicName">
                            <Form.Label>
                                <b>*Question text</b> - {this.props.selectedQuestion.IdTranslationGlobal}
                                {editMode &&
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;<LanguageSelect onLanguageSelected={this.onLanguageSelected} /></span>
                                }
                            </Form.Label>
                            <br/><br/>
                            <Form.Control size="sm" type="text" placeholder="Type-in the question"
                                          name="QuestionText"
                                          value={this.state.formFields.QuestionText}
                                          onChange={this.handleChange}
                            />

                            <br/>

                            <div>
                                <b>Tags</b><br/>
                                <Typeahead
                                    multiple={true}
                                    id="selectTagsAct"
                                    options={this.state.allTags}
                                    selected={this.state.questionTags}
                                    onChange={this.handleOnChangeSelectTags}
                                    placeholder={i18n.t("trans_33_select_tags")}
                                />
                            </div>


                            <br/>
                            <div className="skills_container">
                                <b>Skills</b><br/>
                                {listSkills}
                            </div>
                        </Form.Group>

                        <br/>

                        <Button variant="primary" type="submit">
                            {confirmButtonStr}
                        </Button>
                    </Form>
                </Modal.Body>
                <br/>
            </Modal>
        );
    }

}

export default AdminModalAddNewQuestion;