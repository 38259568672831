/**
 * Created by Alex on 17-Oct-19.
 */
import React from 'react';

import { serverService } from '../../_services/server.service';

import {Typeahead} from 'react-bootstrap-typeahead';
import {toast} from "react-toastify";
import i18n from "i18next";

interface MyProps {
    selectedQuestion:string,
    handleQuestionChange(questionText:string):any,
}

interface MyState {
    questions:any,
    selectedQuestion:string,
}

class QuestionsAutocomplete extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            questions: [],
            selectedQuestion:'',
        };

        this.handleOnInputChange = this.handleOnInputChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.fetchQuestions = this.fetchQuestions.bind(this);

    }

    componentDidMount(){
        this.fetchQuestions();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selectedQuestion !== prevState.selectedQuestion) {
            this.setState({selectedQuestion: this.props.selectedQuestion});
        }
    }

    render() {
        let selectedQuestion = this.state.selectedQuestion;
        if(selectedQuestion == null){
            selectedQuestion = "";
        }
        return (
            <Typeahead
                allowNew
                bsSize="small"
                selected={[{'QuestionText':selectedQuestion}]}
                id="questionsAct"
                options={this.state.questions}
                labelKey="QuestionText"
                onInputChange={this.handleOnInputChange}
                onChange={this.handleOnChange}
                placeholder={i18n.t("trans_33_questions_acp_search_for_question")}
                newSelectionPrefix={i18n.t("trans_33_questions_acp_add_question")}
            />
        );
    }

    handleOnInputChange(questionString:string) {
        this.props.handleQuestionChange(questionString);
    }

    handleOnChange(questionsObject:any) {
        if(questionsObject != undefined && questionsObject[0] != undefined && questionsObject[0].QuestionText != undefined ){
            this.props.handleQuestionChange(questionsObject[0].QuestionText);
        }
    }

    fetchQuestions() {
        serverService.getQuestionsForCompany()
            .then(
                result => {
                    if(result.status === "1"){
                        this.setState({
                            questions:result.data,
                        });
                    }
                },
                error => {
                    toast.warn("Did not get questions");
                }
            );
    }

    doesQuestionAlreadyExists(questionText:string){
        const questions = this.state.questions;
        if(questions){
            for(let i=0;i<questions.length;i++){
                if(questions[i].QuestionText.toLowerCase() == questionText.toLowerCase()){
                    return true;
                }
            }
        }
        return false;
    }
}




export default QuestionsAutocomplete;