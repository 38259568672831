import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./AdvicesPage.css";
import {Trans} from "react-i18next";
const moment = require("moment");

interface MyProps {
  pdfResources: any;
  rowsPdf: number;
  displayDate: boolean;
  advice?: boolean;
  colNo?:number;
}

interface MyState {
  loading: boolean;
  pdfPage: number;
}

class PdfsGrid extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      pdfPage: 0,
    };

    this.pdfPrevClick = this.pdfPrevClick.bind(this);
    this.pdfNextClick = this.pdfNextClick.bind(this);
  }

  componentDidMount() {
    this.setState({});
  }

  render() {
    const { pdfResources, rowsPdf, advice ,colNo} = this.props;
    let colVal = colNo ? colNo : 6;
    const { pdfPage } = this.state;
    let pdfItems = [];
    for (let i = 0; i < rowsPdf; i++) {
      let colItems = [];
      for (let j = 0; j < colVal; j++) {
        let resourceIndex = pdfPage * (rowsPdf * 3) + i * 3 + j;

        if (pdfResources == undefined) {
          break;
        }

        let oneResource = pdfResources[resourceIndex];
        if (oneResource === undefined) {
          break;
        }

        let dateEquivalent = oneResource.Skill;
        if (this.props.displayDate) {
          dateEquivalent = oneResource.Added;
        }

        // colItems.push(
        //     <div className="col-md-auto">
        //         <a target="_blank" className="guide-links" href={oneResource.Link}>
        //             <div className="row top-buffer">
        //                 <div className="col-md-4">
        //                     <div className="pdf-placeholder text-center">
        //                         <b>PDF</b>
        //                     </div>
        //                 </div>
        //                 <div className="col-md-8">
        //                     <span className="guide-item-title">{oneResource.Name}</span>
        //                     <br/>
        //                     <span className="guide-item-date">{dateEquivalent}</span>
        //                 </div>
        //             </div>
        //         </a>
        //     </div>
        // );

        colItems.push(
          <div
            className={`mt-3 w-100  ${advice ? "col-md-6" : "col-md-12"}`}
            key={"col_items_" + i + "_" + j}
          >
            <a target="_blank" className="guide-links" href={oneResource.Link}>
              <div className="row w-100 align-items-center top-buffer">
                <div className="col-md-3 col-3 pr-0 pr-md-2">
                  <div className="pdf-placeholder text-center">
                    <b>PDF</b>
                  </div>
                </div>
                <div className="col-8">
                  <div className="ml-3">
                    <span className="guide-item-title">{oneResource.Name}</span>
                    <br />
                    <span className="guide-item-date ml-3">
                      {dateEquivalent}
                    </span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        );
      }

      pdfItems.push(
        <div className="row w-100" key={"pdf_items_" + i}>
          {colItems}
        </div>
      );
    }

    return (
      <div className="container-fluid  settings-container">
        <div className="row w-100">{pdfItems}</div>
        <div className="pdf-pagination">
          {pdfPage > 0 && (
            <span className="pdf-btn-previous" onClick={this.pdfPrevClick}>
              <b>
                <Trans>trans_33_previous</Trans>
              </b>
            </span>
          )}

            {pdfPage < pdfResources.length / (rowsPdf * 3) - 1 && (
            <span className="pdf-btn-next" onClick={this.pdfNextClick}>
              &nbsp;&nbsp;&nbsp;
              <b>
                <Trans>trans_33_next</Trans>
              </b>
            </span>
          )}
        </div>
      </div>
    );
  }

  pdfPrevClick() {
    let pdfPage = this.state.pdfPage;
    this.setState({ pdfPage: pdfPage - 1 });
  }

  pdfNextClick() {
    let pdfPage = this.state.pdfPage;
    this.setState({ pdfPage: pdfPage + 1 });
  }
}

export default PdfsGrid;
