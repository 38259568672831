import React from "react";
import { withRouter } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import "./MyNavbar.css";
import ModalChooseLicense from "../SettingsPage/ModalChooseLicense";
import { toast } from "react-toastify";
import { serverService } from "../../_services/server.service";

import PubSub from "../../_helpers/PubSub.js";
import i18n from "i18next";
import {Trans} from "react-i18next";

interface MyProps {
  user: any;
}

interface MyState {
  modalChooseLicense: boolean;
  company: any;
  license: any;
  paymentMethod: any;
}

class MyNavbar extends React.Component<MyProps & RouteComponentProps, MyState> {
  private userObj;

  constructor(props) {
    super(props);

    this.state = {
      modalChooseLicense: false,
      company: {},
      license: {},
      paymentMethod: {},
    };

    this.onLogout = this.onLogout.bind(this);

    this.doOpenModalChooseLicense = this.doOpenModalChooseLicense.bind(this);
    this.doCloseModalChooseLicense = this.doCloseModalChooseLicense.bind(this);
    this.onLicenseUpdated = this.onLicenseUpdated.bind(this);
  }

  componentWillMount() {
    this.userObj = JSON.parse(localStorage.getItem("user"));
  }

  componentDidMount() {
    if (this.userObj) {
      this.doFetchCompany().then((result1) => {
        this.doFetchLicense().then((result1) => {});
      });
    }
    PubSub.subscribe("updateLicense", (product) => {
      toast.success("License updated");
      this.doFetchLicense();
      this.doCloseModalChooseLicense();
    });
  }

  componentWillUnmount() {
    PubSub.unsubscribe("updateLicense");
  }

  render() {
    let LicenseType = 0;
    if (this.userObj && this.userObj.LicenseType) {
      LicenseType = this.userObj.LicenseType;
    }

    let topHeaderLabel = "";
    if (this.userObj) {
      topHeaderLabel = "Connected as " + this.userObj.Username;
    }

    let licenseMenuLabel = i18n.t("trans_33_license_no_license");
    if (LicenseType == 1) {
      licenseMenuLabel = i18n.t("trans_33_license_self_starter");
    } else if (LicenseType == 2) {
      licenseMenuLabel = i18n.t("trans_33_license_standard");
    } else if (LicenseType == 3) {
      licenseMenuLabel = i18n.t("trans_33_license_enterprise");
    }

    return (
      <div className="nav-container-new">
        <Navbar bg="" variant="dark" expand="lg" id="topNavbar">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {this.userObj && this.userObj.AuthToken && (
              <Nav className="mr-auto hamburger-icon">&nbsp;&nbsp;</Nav>
            )}

            {this.userObj && this.userObj.AuthToken && (
              <Nav className="mr-right">
                <NavDropdown title={topHeaderLabel} id="dropdown-new">
                  <NavDropdown.Item onClick={this.doOpenModalChooseLicense}>
                    {licenseMenuLabel}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/settings">
                    <Trans>
                      trans_33_navbar_my_settings
                    </Trans>
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={this.onLogout}>
                    <Trans>
                      trans_33_logout
                    </Trans>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            )}
          </Navbar.Collapse>
        </Navbar>

        {/*<ModalChooseLicense*/}
          {/*show={this.state.modalChooseLicense}*/}
          {/*onHide={this.doCloseModalChooseLicense}*/}
          {/*company={this.state.company}*/}
          {/*license={this.state.license}*/}
          {/*onLicenseUpdated={this.onLicenseUpdated}*/}
        {/*/>*/}
      </div>
    );
  }

  doFetchCompany() {
    let IdCompany = 0;
    if (this.userObj) {
      IdCompany = this.userObj.IdCompany;
    }

    return serverService.getCompany(IdCompany).then(
      (result) => {
        let company = result.data[0];
        if (result.status === "1") {
          this.setState({
            company: company,
          });

          // serverService.paymentsGetPaymentMethods().then(
          //   (result) => {
          //     if (result.status !== "1") {
          //       toast.error(result.message);
          //     } else {
          //       if (result.data.length > 0) {
          //         this.setState({ paymentMethod: result.data[0] });
          //       }
          //     }
          //   },
          //   (error) => {
          //     toast.warn("Did not get payment methods");
          //   }
          // );
        } else {
          toast.error(result.message);
        }
      },
      (error) => {
        toast.warn("Did not get company");
      }
    );
  }

  doFetchLicense() {
    return serverService.getCurrentLicense()
        .then(
            result => {

              if(result.status === "1"){

                let userStr = localStorage.getItem('user');
                if(userStr) {
                  let userObj = JSON.parse(userStr);

                  if(result.data.length > 0){
                    let currentLicense = result.data[0];
                    userObj['LicenseType'] = currentLicense['Type'];
                    userObj['VirtualVoxNum'] = currentLicense['VirtualVoxNum'];
                    userObj['PhysicalVoxNum'] = currentLicense['PhysicalVoxNum'];

                    localStorage.setItem('user',JSON.stringify(userObj));
                  }
                }

              } else {
                toast.error("Issues while communicating with the server while fetching the license");
              }

            }, error => {
                toast.warn("Did not get current license");
            }
        );
  }

  onLicenseUpdated(isItADowngrade) {
    toast.success(i18n.t("trans_33_msg_license_updated"));
    this.doCloseModalChooseLicense();
    this.doFetchLicense();
  }

  doOpenModalChooseLicense() {
    this.setState({ modalChooseLicense: true });
  }

  doCloseModalChooseLicense() {
    this.setState({ modalChooseLicense: false });
  }

  onLogout() {
    localStorage.removeItem("user");
    this.props.history.push("/login");
  }
}

export default withRouter(MyNavbar);
