import React from "react";
import moment from "moment";

import { toast } from "react-toastify";

import AdminResultsTable from "./AdminResultsTable";

interface MyProps {}

interface MyState {}

class AdminResultsPage extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <div className="container mt-5 p-5" id="resultsContainer">
        <AdminResultsTable />
      </div>
    );
  }
}

export { AdminResultsPage };
