/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import 'react-day-picker/lib/style.css';



import {Link} from "react-router-dom";
import {Trans} from "react-i18next";
import i18n from "i18next";

interface MyProps {
    onConfirm(): void,
    onCancel(): void,
    licenseForFeature:string,
    show:boolean,
    onHide(): any,
}

interface MyState {
}




class ModalUpgradeLicense extends React.Component<MyProps,MyState> {

    private userObj;

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount(){
        const userStr = localStorage.getItem('user');
        if(userStr) {
            this.userObj = JSON.parse(userStr);
        }
    }

    render() {
        let {licenseForFeature} = this.props;
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        <Trans>trans_33_upgrade_needed</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <br/>
                    <p>
                        <Trans i18nKey="trans_33_features_available_with_license">
                            text<strong>{{licenseForFeature}}</strong>text
                        </Trans>
                    </p>
                    <br/>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.props.onCancel}>
                            <Trans>trans_33_no_i_dont_want_to_upgrade</Trans>
                        </Button>

                        {this.userObj && this.userObj.AuthToken && (this.userObj.IdCompany != 69 && this.userObj.IdCompany != 71) &&
                            <Link to="/settings">
                                <Button variant="primary" onClick={this.props.onConfirm}>
                                    <Trans>trans_33_see_upgrade_options</Trans>
                                </Button>
                            </Link>
                        }

                    </Modal.Footer>
                </Modal.Body>
            </Modal>
        );
    }

}

export default ModalUpgradeLicense;