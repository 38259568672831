import React, {useEffect} from 'react';
import { toast } from "react-toastify";

import ModalConfirmAction from '../_other/ModalConfirmAction';

import {Button,ButtonToolbar,Form} from 'react-bootstrap'

import { serverService } from '../../_services/server.service';
import i18n from "i18next";
import {Trans} from "react-i18next";
import ModalCopyLink from "../MyVoxPage/ModalCopyLink";

import { config } from '../../_helpers/C';


import moment from 'moment'

const $ = require('jquery');
$.DataTable = require('datatables.net');

require( 'datatables.net-bs4' );
require( 'datatables.net-select-bs4' );

const columns = [
    {
        title: "Name",
        width: 200,
        data: 'Name'
    },
    {
        title: "Date",
        width: 200,
        data: 'DateToSend'
    },
    {
        title: "Vox",
        width: 200,
        data: 'VoxName'
    },
    {
        title: "",
        width: 200,
        data: 'IdScan',
        orderable: false
    },
    {
        title: "",
        width: 200,
        data: 'IdScan',
        orderable: false
    },
];


interface MyProps {
    onScanDeleted():any,
    onEditScan(oneScan:any):any,
    refresh: number,
}

interface MyState {
    scans: any,
    submitted: boolean,
    modalConfirmDeleteShow: boolean,
    modalCopyLinkShow:boolean,
    selectedLink: string,
    selectedScan: any,
    selectedIdScan:number,
}

class ScanTable extends React.Component<MyProps,MyState> {
    constructor(props) {
        super(props);

        this.state = {
            scans:[],
            submitted:false,
            modalConfirmDeleteShow:false,
            modalCopyLinkShow:false,
            selectedLink: "",
            selectedScan:{},
            selectedIdScan:0,
        };


        this.onScanAdded = this.onScanAdded.bind(this);
        this.onScanEdited = this.onScanEdited.bind(this);

        this.reloadTableData = this.reloadTableData.bind(this);
        this.showLinkToScan = this.showLinkToScan.bind(this);

    }



    componentDidMount() {

        let table = $(this.refs.main).DataTable({
            dom: "<'data-table-wrapper'" +
                    "<'row'<'col-md-6'<'toolbar'>><'col-md-6'>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'><'col-sm-12 col-md-7'p>>" +
                    ">",
            data: this.state.scans,
            columns,
            ordering: true,
            order: [[1, 'desc']],
            paging:true,
            select: {
                style: 'single',
                //blurable: true
            },

            columnDefs: [
                {
                    render: function ( data, type, row ) {
                        if (type === "display" || type === "filter") {
                            if(data != null){
                                return moment(data).format('D MMM YYYY HH:mm:ss');
                            } else {
                                return "";
                            }
                        }
                       return data;
                    },
                    targets: 1,
                },

                {
                    render: function ( data, type, row ) {
                        if(data != null){
                            let buttonText = i18n.t("trans_33_manage_scan");

                            return "<button class='btn btn-primary w-100 btn-table-actions'" +
                                " type='manage_questions'" +
                                " IdScan=" + row.IdScan + ""
                                + " IdVox=" + row.IdVox + ""
                                + ">" + buttonText
                                + "</button>";

                        } else {
                            return "";
                        }
                    },
                    targets: 3,
                },

                {
                    render: function ( data, type, row ) {
                        if(data != null){
                            let buttonText = i18n.t("View link");

                            return "<button class='btn btn-outline-primary btn-table-actions'" +
                                " type='link'" +
                                " IdScan=" + row.IdScan + ""
                                + " IdVox=" + row.IdVox + ""
                                + " Token=" + row.Token + ""
                                + ">" + buttonText
                                + "<i class='fas fa-link ml-2'></i>"
                                + "</button>";

                        } else {
                            return "";
                        }
                    },
                    targets: 4,
                },
            ]
        });

        $("#deleteBtn").hide();
        $("#editBtn").hide();

        table.on('select', function ( e, dt, type, indexes ) {
            $("#deleteBtn").show();
            // $("#editBtn").show();
        });

        table.on('deselect', function ( e, dt, type, indexes ) {
            $("#deleteBtn").hide();
            $("#editBtn").hide();
        });


        this.doFetchAllScans();
    }

    componentWillUnmount(){
        $('.data-table-wrapper')
            .find('.scansTable')
            .DataTable()
            .destroy(true);
    }

    componentDidUpdate(oldProps) {
        if (this.props.refresh !== oldProps.refresh) {
            this.doFetchAllScans()
        }
    }

    render() {

        let modalConfirmDeleteOpen = (itemToEdit) => {
            const table = $('.data-table-wrapper')
                .find('.scansTable')
                .DataTable();
            let data = table.rows( { selected: true }).data();

            this.setState({ modalConfirmDeleteShow: true });
        };
        let modalConfirmDeleteClose = () => this.setState({ modalConfirmDeleteShow: false });

        let deleteSelectedItemWithConfirmation = () => {
            modalConfirmDeleteOpen(null);
        };

        let closeCopyLinkModal = () => this.setState({ modalCopyLinkShow: false });



        let editSelectedItem = () => {
            const table = $('.data-table-wrapper')
                .find('.scansTable')
                .DataTable();
            let data = table.rows( { selected: true }).data();
        };


        let deleteSelectedItem = () => {
            const table = $('.data-table-wrapper')
                .find('.scansTable')
                .DataTable();

            let data = table.rows( { selected: true }).data();

            let IdScan = data[0].IdScan;

            serverService.deleteScan(IdScan)
                .then(
                    result => {
                        if(result.status == 1){
                            toast.success("Scan deleted");
                            $("#deleteBtn").hide();
                            this.props.onScanDeleted();
                            this.doFetchAllScans();
                        } else {
                            toast.error("Delete failed");
                        }

                        this.setState({ modalConfirmDeleteShow: false });
                    },
                    error => {
                        toast.warn("Did not delete scan");
                    }
                );
        };


        return (
            <div className="row">
                <div  className="col-md-12" id="usersTableContainer">

                    <table style={{ width: '100%' }} ref="main" className="table table-bordered scansTable" />

                    <div className="float-right">

                        <ButtonToolbar>

                            <Button
                                id="editBtn"
                                variant="light"
                                onClick={editSelectedItem}
                            >
                                <Trans>trans_33_Edit</Trans>
                            </Button>
                            &nbsp;
                            <Button
                                id="deleteBtn"
                                variant="light"
                                onClick={deleteSelectedItemWithConfirmation}
                            >
                                <Trans>trans_33_Delete</Trans>
                            </Button>


                        </ButtonToolbar>

                    </div>


                    <ModalConfirmAction
                        show={this.state.modalConfirmDeleteShow}
                        actionTitle={i18n.t("trans_33_confirm_delete")}
                        actionBody={i18n.t("trans_33_scan_delete_are_you_sure")}
                        noBtnText={i18n.t("trans_33_no_go_back")}
                        yesBtnText={i18n.t("trans_33_yes_confirm")}
                        onConfirmNo={modalConfirmDeleteClose}
                        onHide={modalConfirmDeleteClose}
                        onConfirmYes={deleteSelectedItem}
                    />


                    <ModalCopyLink
                        show={this.state.modalCopyLinkShow}
                        selectedLink={this.state.selectedLink}
                        title={"Copy link"}
                        onHide={closeCopyLinkModal}
                    />

                </div>

            </div>);
    }


    onScanAdded(scan){
    }

    onScanEdited(item){
    }


    doFetchAllScans() {
        serverService.getScans().then(
            (result) => {
                if(result.data){
                    this.setState({scans: result.data },() => {
                        this.reloadTableData(this.state.scans);
                    });
                }
            },
            (error) => {
                toast.warn("Did not get scans");
            }
        );
    }

    showLinkToScan(IdScan, ScanToken){

        const url = config.FRONTEND_DOMAIN_NAME + "/onescan"
            + "?IdScan=" + IdScan
            + "&h=" + ScanToken;

        this.setState({modalCopyLinkShow:true, selectedLink:url,});
    }

    reloadTableData(items) {
        const table = $('.data-table-wrapper')
            .find('.scansTable')
            .DataTable();
        table.clear();
        table.rows.add(items);
        table.draw();


        table.on( 'click', 'button', (e, dt, type, cell, originalEvent) => {
            e.stopPropagation();

            let data = e.target.attributes;

            if(data.type != undefined && data.type.value === "link"){
                this.showLinkToScan(data.IdScan.value, data.Token.value)
            } if(data.type != undefined && data.type.value === "manage_questions"){
                let selectedScan = {}
                let selectedIdScan = data.IdScan.value

                for(let i=0;i<this.state.scans.length;i++){
                    if(this.state.scans[i].IdScan == selectedIdScan){
                        selectedScan = this.state.scans[i];
                        break;
                    }
                }


               this.props.onEditScan(selectedScan)
            } else {

            }

        });

        $("#deleteBtn").hide();
        $("#editBtn").hide();
    }


}


export default ScanTable;
