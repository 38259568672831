import React from 'react';
import { toast } from "react-toastify";

import ModalAddNewQuestion from './ModalAddNewQuestion';
import ModalConfirmAction from '../../_other/ModalConfirmAction';
import ModalImportQuestions from './ModalImportQuestions';

import {Button,ButtonToolbar,Form} from 'react-bootstrap'

import { serverService } from '../../../_services/server.service';

import './QuestionsTable.css'
import i18n from "i18next";
import {Trans} from "react-i18next";

import moment from 'moment'

const $ = require('jquery');
$.DataTable = require('datatables.net');

require( 'datatables.net-bs4' );
require( 'datatables.net-select-bs4' );
require('../../datatables.selected.css');


interface MyProps {
    questions:any,
    onQuestionAdded(extra:string):any,
    onQuestionDeleted():any,
    onQuestionsImported():any,
}

interface MyState {
    submitted: boolean,
    loading:boolean,
    modalImportShow: boolean,
    modalAddNewShow:boolean,
    modalConfirmDeleteShow: boolean,
    selectedQuestion: any,
    formFields:any,
}

class QuestionsTable extends React.Component<MyProps,MyState> {
    constructor(props) {
        super(props);

        this.state = {
            submitted:false,
            loading:false,
            modalImportShow: false,
            modalAddNewShow:false,
            modalConfirmDeleteShow:false,
            formFields:{},
            selectedQuestion:{}
        };

        this.onQuestionAdded = this.onQuestionAdded.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.onQuestionsImported = this.onQuestionsImported.bind(this);

    }
    componentDidMount() {
        let table = $(this.refs.main).DataTable({
            dom: "<'data-table-wrapper'" +
                    "<'row'f<'col-md-6'<'toolbar'>><'col-md-6'>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'><'col-sm-12 col-md-7'p>>" +
                    ">",
            data: this.props.questions,
            columns : [
                {
                    title: i18n.t("trans_33_Type"),
                    width: 40,
                    data: 'Type'
                },
                {
                    title: i18n.t("trans_33_Question"),
                    width: 300,
                    data: 'QuestionText'
                },
                {
                    title: i18n.t("trans_33_Planned"),
                    width: 80,
                    data: 'ScheduledCnt'
                },
                {
                    title: i18n.t("trans_33_Added"),
                    width: 80,
                    data: 'Added'
                }
            ],
            ordering: true,
            paging:true,
            select: {
                style: 'single',
                //blurable: true
            },
            language: {
                search: i18n.t("trans_33_search"),
                emptyTable:  i18n.t("trans_33_table_no_records"),
                paginate: {
                    first: i18n.t("trans_33_first"),
                    previous: i18n.t("trans_33_previous"),
                    next: i18n.t("trans_33_next"),
                    last: i18n.t("trans_33_last")
                }
            },
            columnDefs: [
                { width: 40, targets: 0 },
                { width: 300, targets: 1 },
                { width: 80, targets: 2 },

                {
                    render: function ( data, type, row ) {
                        if(data === 1){
                            return "<img src='/assets/images/logo_vox32.png' style='float:right;'/>";
                        } else {
                            return "";
                        }
                    },
                    targets: 0,
                },

                {
                    render: function ( data, type, row ) {
                        if(data === 0 || row.schedules === undefined){
                            return i18n.t("trans_33_not_planned");
                        } else {
                            let tooltipText = "";
                            for(let i=0;i<row.schedules.length;i++){
                                tooltipText = tooltipText + row.schedules[i].Name + " on "
                                        + moment(row.schedules[i].Date).format("MM-DD-YYYY") + "\n";
                            }

                            return "<span data-toggle='tooltip' data-title='"+tooltipText+"'>" +
                                i18n.t("trans_33_Planned") + " <img src='/assets/images/icon_info.png'/>" +
                                "</span>";
                        }
                    },
                    targets: 2,
                },
                {
                    render: function ( data, type, row ) {
                        if(data != null){
                            return moment(data).format('D MMM YYYY HH:mm:ss');
                        } else {
                            return "";
                        }
                    },
                    targets: 3,
                }
            ]
        });

        $("#deleteQuestionBtn").hide();
        $("#editQuestionBtn").hide();

        table.on( 'select', function ( e, dt, type, indexes ) {
            $("#deleteQuestionBtn").show();
            $("#editQuestionBtn").show();
    });

        table.on( 'deselect', function ( e, dt, type, indexes ) {
            $("#deleteQuestionBtn").hide();
            $("#editQuestionBtn").hide();
        });

    }

    componentWillUnmount(){
        $('.data-table-wrapper')
            .find('table')
            .DataTable()
            .destroy(true);
    }

    componentDidUpdate(oldProps) {
        if(this.props.questions !== oldProps.questions){
            reloadTableData(this.props.questions);
        }
    }

    render() {
        let {loading} = this.state;

        let modalAddNewOpen = (itemToEdit) => this.setState({ modalAddNewShow: true, selectedQuestion:itemToEdit });
        let closeAddNewModal = () => this.setState({ modalAddNewShow: false });

        let modalConfirmDeleteOpen = (itemToEdit) => {
            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();
            let data = table.rows( { selected: true }).data();

            if(data[0].Type === 1){
                toast.warn(i18n.t("trans_33_msg_cannot_delete_vox_questions"));
            } else if(data[0].ScheduledCnt > 0){
                toast.warn(i18n.t("trans_33_msg_cannot_delete_scheduled_question"));
            } else {
                this.setState({ modalConfirmDeleteShow: true });
            }
        };
        let modalConfirmDeleteClose = () => this.setState({ modalConfirmDeleteShow: false });

        let modalImportOpen = (itemToEdit) => this.setState({ modalImportShow: true });
        let closeImportModal = () => this.setState({ modalImportShow: false });

        let deleteSelectedItemWithConfirmation = () => {
            modalConfirmDeleteOpen(null);
        };


        let editSelectedItem = () => {
            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();
            let data = table.rows( { selected: true }).data();

            if(data[0].Type === 1){
                toast.warn(i18n.t("trans_33_msg_cannot_edit_vox_questions"));
            } else if(data[0].ScheduledCnt > 0){
                toast.warn(i18n.t("trans_33_msg_cannot_edit_scheduled_questions"));
            } else {
                modalAddNewOpen(data[0]);
            }

        };


        let deleteSelectedItem = () => {
            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();

            let data = table.rows( { selected: true }).data();

            let IdQuestion = data[0].IdQuestion;

            serverService.deleteQuestion(IdQuestion)
                .then(
                    result => {
                        if(result.status === "1"){
                            toast.success(i18n.t("trans_33_msg_question_deleted"));
                            $("#deleteQuestionBtn").hide();
                            this.props.onQuestionDeleted();
                        } else {
                            toast.error(i18n.t("trans_33_msg_delete_failed"));
                        }

                        this.setState({ modalConfirmDeleteShow: false });
                    },
                    error => {
                        toast.warn("Did not delete question");
                    }
                );
        };


        return (
            <div className="row">
                <div  className="col-md-12">
                    <Form onSubmit={this.handleSubmit} id="add_question_form">
                        <Form.Group controlId="formBasicName">
                            <Form.Label>*<Trans>trans_33_modal_add_question_question_text</Trans></Form.Label>
                            <Form.Control as="textarea" rows={2}
                                          placeholder="Type-in the question"
                                          name={"QuestionText"}
                                          value={this.state.formFields.QuestionText}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        {loading &&
                        <img className="float-right" alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        }

                        <Button variant="primary" type="submit" className="float-right" disabled={loading}>
                            <Trans>
                                trans_33_add_question
                            </Trans>
                        </Button>

                    </Form>
                </div>

                <div  className="col-md-12" id="questionTableContainer">


                    <div className="float-right">
                        <br/>
                        <ButtonToolbar>
                            <Button
                                id="importBtn"
                                variant="light"
                                onClick={modalImportOpen}
                            >
                                <Trans>
                                    trans_33_Import
                                </Trans>
                            </Button>
                        </ButtonToolbar>
                    </div>

                    <table ref="main" className="table table-bordered questionsTable" />


                    <div className="float-right">

                        <ButtonToolbar>

                            <Button
                                id="editQuestionBtn"
                                variant="light"
                                onClick={editSelectedItem}
                            >
                                <Trans>
                                    trans_33_Edit
                                </Trans>
                            </Button>
                            &nbsp;
                            <Button
                                id="deleteQuestionBtn"
                                variant="light"
                                onClick={deleteSelectedItemWithConfirmation}
                            >
                                <Trans>
                                   trans_33_Delete
                                </Trans>
                            </Button>

                            &nbsp;

                        </ButtonToolbar>


                    </div>


                    <ModalAddNewQuestion
                        show={this.state.modalAddNewShow}
                        selectedQuestion={this.state.selectedQuestion}
                        onHide={closeAddNewModal}
                        onQuestionAdded={this.onQuestionAdded}
                    />

                    <ModalConfirmAction
                        show={this.state.modalConfirmDeleteShow}
                        actionTitle={i18n.t("trans_33_confirm_delete")}
                        actionBody={i18n.t("trans_33_msg_confirm_delete_question")}
                        noBtnText={i18n.t("trans_33_no_go_back")}
                        yesBtnText={i18n.t("trans_33_yes_confirm")}
                        onConfirmNo={modalConfirmDeleteClose}
                        onHide={modalConfirmDeleteClose}
                        onConfirmYes={deleteSelectedItem}
                    />

                    <ModalImportQuestions
                        show={this.state.modalImportShow}
                        onHide={closeImportModal}
                        onQuestionsImported={this.onQuestionsImported}
                    />
                </div>


            </div>);
    }

    onQuestionAdded(item){
        this.props.onQuestionAdded(item);
        this.setState({modalAddNewShow: false});
    }

    onQuestionsImported(item){
        this.props.onQuestionsImported();
        //this.setState({modalImportShow: false});
    }


    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});

        const { formFields } = this.state;
        console.log(formFields)

        const QuestionText = formFields.QuestionText;

        // stop here if form is invalid
        if (!(QuestionText)) {
            toast.error(i18n.t("trans_33_missing_fields"));
            return;
        }

        let userStr = localStorage.getItem('user');
        let IdUser = 0;
        if(userStr) {
            let userObj = JSON.parse(userStr);
            IdUser = userObj.IdUser;
        }


        this.setState({submitted: true, loading: true});

        let question = {'IdQuestion': '0','IdUser':IdUser.toString(), 'QuestionText':QuestionText };
        serverService.insertQuestion(question)
            .then(
                result => {
                    this.setState({submitted: false, loading: false});
                    if(result.status === "1"){
                        let messageStr = i18n.t("trans_33_msg_question_added");
                        toast.success(messageStr);
                        this.props.onQuestionAdded(result.extra);

                        let formFields = this.state.formFields;
                        formFields.QuestionText = "";
                        this.setState({ formFields: formFields });
                    } else {
                        toast.error(i18n.t("trans_33_msg_question_add_failed"));
                    }
                },
                error => {
                    toast.warn("Did not insert question");
                    this.setState({ loading: false });
                }
            );
    }
}

function reloadTableData(items) {
    const table = $('.data-table-wrapper')
        .find('table')
        .DataTable();
    table.clear();
    table.rows.add(items);
    table.draw();

    $("#deleteQuestionBtn").hide();
    $("#editQuestionBtn").hide();
}

export default QuestionsTable;
