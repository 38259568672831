import React from 'react';

import { serverService } from '../../_services/server.service';

import './ResultsEnterprise.css'
import {toast} from "react-toastify";


interface MyProps {
    selectedTabName:string,
}

interface MyState {
    QuestionText: string,
}

class ResultsSilvesquareHorizontalPage extends React.Component<MyProps,MyState> {
    constructor(props) {
        super(props);

        this.state = {
            QuestionText: "",
        };
    }

    componentDidMount() {
        // const head = document.querySelector('head');
        // const script = document.createElement('script');
        // script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
        // head.appendChild(script);

        const head = document.querySelector('head');
        const meta = document.createElement('meta');
        meta.setAttribute('http-equiv', 'refresh');
        meta.setAttribute('content', '1800');
        head.appendChild(meta);


        serverService.getYesterdayResultsForVoxToken("dASTxBPDXuVDonY")
            .then(
                result => {
                    let QuestionText = "No question";
                    if(result.data.length > 0){
                        QuestionText = result.data[0].QuestionText;
                    }
                    if(result.status == 1){
                        this.setState({
                            QuestionText:QuestionText,
                        });
                    }
                },
                error => {
                    toast.warn("Did not get results");
                }
            );

    }

    render() {

        let {QuestionText} = this.state;

        return (

            <div className="container">

                <div className="row">
                    <div className="col-md-12 mt-2">
                        <img src="/assets/images/voxcollector_line.png" width="150px" />
                    </div>
                </div>


                <div className="row mb-4">
                    <div className="col-md-12 d-flex justify-content-center">
                        <h1>Yesterday's votes</h1>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-12 d-flex justify-content-center">
                        <h2 style={{fontSize:"3rem", fontWeight:"bold", textAlign:"center"}}>{QuestionText}</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 d-flex justify-content-center">
                        <iframe
                            src="https://metabase.qrsafe.eu/public/dashboard/0e940381-f274-44c3-a684-eaa3c2e986ed"
                            frameBorder="0"
                            width="1024"
                            height="650"
                            allowTransparency
                        />
                    </div>
                </div>
            </div>
        );
    }


}

export default ResultsSilvesquareHorizontalPage;