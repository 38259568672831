/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../_services/server.service';
import i18n from "i18next";
import {Trans} from "react-i18next";


interface MyProps {
    show:boolean,
    onHide():any,
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields:any,
}

class ModalForgotPassword extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',

            formFields:{},
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
    }

    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});

        const { formFields } = this.state;
        const Email = formFields.Email;

        if (!(Email)) {
            toast.error(i18n.t("trans_33_msg_missing_fields"));
            return;
        }

        this.setState({submitted: true, loading: true});



        if (!Email || Email.length == 0) {
            toast.warn(i18n.t("trans_33_login_page_msg_type_in_username"));
        } else {
            this.setState({ loading: true });
            serverService.forgotPassword(Email).then(
                (response) => {
                    if (response.status === 0) {
                        toast.warn(response.message);
                    } else {
                        toast.success(i18n.t("trans_33_login_page_msg_link_sent"));
                    }

                    this.setState({ loading: false});

                    this.props.onHide();
                },
                (error) => {
                    toast.error("Server error");
                    this.setState({ loading: false });
                }
            );
        }



    }


    render() {
        let {loading} = this.state;

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        <Trans>
                            trans_33_forgot_password
                        </Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSubmit}>

                        <Form.Group controlId="formBasicName">
                            <Form.Label>
                                *<Trans>trans_33_email</Trans>
                            </Form.Label>
                            <Form.Control size="sm" type="text" placeholder={i18n.t("trans_33_email")}
                                          name="Email"
                                          value={this.state.formFields.Email}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        <br/>

                        <Button variant="primary" type="submit" disabled={loading}>
                            <Trans>
                                trans_33_send_reset_link_to_email
                            </Trans>
                        </Button>
                        {loading &&
                        <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        }
                    </Form>
                </Modal.Body>
                <br/>
            </Modal>
        );
    }

}

export default ModalForgotPassword;