/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { toast } from "react-toastify";

import { Button, ButtonToolbar } from 'react-bootstrap'

import i18n from "i18next";
import {Trans} from "react-i18next";
import {serverService} from "../../_services/server.service";


interface MyProps {
    pulseQuestions:any,
    onPulseQuestionDeleted:any,
    selectedPulseNumOfAnswers:number,
}

interface MyState {
    submitted: boolean,
    loading:boolean,
    selectedIdPulseQuestion: number,
}

class PulseQuestionsTable extends React.Component<MyProps,MyState> {
    constructor(props) {
        super(props);

        this.state = {
            submitted:false,
            loading:false,
            selectedIdPulseQuestion: 0
        };

        this.deleteSelectedItem = this.deleteSelectedItem.bind(this)
    }

    deleteSelectedItem(id) {
      serverService.deletePulseQuestion(id)
        .then(
            result => {
                if(result.status === '1'){
                    toast.success("Question deleted");
                    this.props.onPulseQuestionDeleted();
                } else {
                    toast.error("Delete failed");
                }
            },
            error => {
                toast.warn("Did not delete user");
            }
        );
    };

    render() {
      const { pulseQuestions } = this.props

      if(!pulseQuestions.length) {
        return (
          <div className='text-center pt-4'>
            <h3><Trans>no_pulse_q_added</Trans></h3>
          </div>
        )
      }

      return (
        <div className="row">
          <div className='col-md-12'>
            {
              pulseQuestions.map(({ QuestionText, IdPulse, IdPulseQuestion }) => (
                <div className='d-flex' key={`${IdPulse}--${IdPulseQuestion}`}>
                  <div className='w-100'>
                    {QuestionText}
                  </div>
                  <button onClick={() => this.deleteSelectedItem(IdPulseQuestion)} className='pulse-question-close'>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </button>
                </div>
              ))
            }
          </div>
        </div>
      );
    }

}

export default PulseQuestionsTable;
