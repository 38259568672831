import React from 'react';
import { toast } from "react-toastify";

import { Form,Button,ButtonToolbar } from "react-bootstrap";

import { serverService } from '../../../_services/server.service';
import i18n from "i18next";

const $ = require('jquery');
$.DataTable = require('datatables.net');

require( 'datatables.net-bs4' );
require( 'datatables.net-select-bs4' );
require( 'datatables.net-buttons-bs4' )();
require( 'datatables.net-buttons/js/buttons.html5.js' )();


interface MyWindow extends Window {
    JSZip: any;
}
declare let window: MyWindow;

window.JSZip = require('jszip');


const columns = [
    {
        title: 'Question',
        width: 300,
        data: 'QuestionText'
    },
    {
        title: 'Bench Yes[%]',
        width: 60,
        data: 'BenchmarkYesPercentage'
    },
    {
        title: 'Bench Votes',
        width: 60,
        data: 'BenchmarkNumberOfAnswers'
    },
    {
        title: 'Company Yes[%]',
        width: 60,
        data: 'CompanyYesPercentage'
    },
    {
        title: 'Company Votes',
        width: 60,
        data: 'CompanyNumberOfAnswers'
    },
    {
        title: 'Main variable',
        width: 60,
        data: 'MainVariable'
    },
    {
        title: 'Second variable',
        width: 60,
        data: 'SecondVariable'
    },
    {
        title: 'Assessment',
        width: 60,
        data: 'Assessment'
    },
    {
        title: 'Nudge',
        width: 60,
        data: 'Nudge'
    },
    {
        title: 'Tags',
        width: 80,
        data: 'Tags'
    }

];


interface MyProps {
}

interface MyState {
    loading: boolean,
    results: any,
}

class AdminResultsTable extends React.Component<MyProps,MyState> {
    constructor(props) {
        super(props);

        this.state = {
            loading:false,
            results: [],
        };

    }
    componentDidMount() {

        let table = $(this.refs.main).DataTable({
            //dom: '<"data-table-wrapper"<"toolbar"><"bottom"f>rtip>',
            dom: "<'data-table-wrapper'" +
                    "<'row'<'col-md-6'<'toolbar'>><'col-md-6'f>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'B><'col-sm-12 col-md-7'p>>" +
                    ">",
            data: this.state.results,
            columns,
            ordering: true,
            paging:true,
            buttons: [
                { extend: 'excel', text: i18n.t("trans_33_export_excel") }
            ],
            "order": [[ 0, "desc" ]],

            scrollX: true,

            columnDefs: [
                { width: 500, targets: 0 },
                { width: 60, targets: 1 },
                { width: 60, targets: 2 },
                { width: 60, targets: 3 },
                { width: 60, targets: 4 },
            ]
        });


        this.setState({
            loading:false,
            results: [],
        });

        this.doFetchAll();

    }

    componentWillUnmount(){
        $('.data-table-wrapper')
            .find('table')
            .DataTable()
            .destroy(true);
    }

    componentDidUpdate(oldProps) {
        if(this.state.results !== oldProps.results){
            reloadTableData(this.state.results);
        }
    }

    render() {

        return (
            <div>
                <table ref="main" className="table table-bordered" />
            </div>
        );
    }


    doFetchAll(){

        let userStr = localStorage.getItem('user');
        let IdCompany = 0;
        if(userStr) {
            let userObj = JSON.parse(userStr);
            IdCompany = userObj.IdCompany;
        }

        this.setState({loading: true});
        serverService.getAdminResultsForCompany(IdCompany)
            .then(
                resultsRes => {
                    if(resultsRes && resultsRes.data != undefined){

                        let results = resultsRes.data.results;
                        let skills = resultsRes.data.skills;
                        let question_skills = {};
                        for(let i=0;i<skills.length;i++){
                            if(question_skills[skills[i].IdQuestion] === undefined){
                                question_skills[skills[i].IdQuestion] = [];
                            }
                            question_skills[skills[i].IdQuestion].push(skills[i]);
                        }

                        for(let i=0;i<results.length;i++){
                            let oneQuestionSkills = question_skills[results[i].IdQuestion];
                            let MainVariable = "";
                            let SecondVariable = "";

                            let Assessment = "";
                            let Nudge = "";

                            let mainVariableWeight = 0;
                            let absMainVariableWeight = 0;

                            let secondVariableWeight = 0;
                            let absSecondVariableWeight = 0;
                            if(oneQuestionSkills !== undefined){
                                for(let j=0;j<oneQuestionSkills.length;j++){

                                    if(oneQuestionSkills[j].Type === 0){
                                        if(Math.abs(oneQuestionSkills[j].Weight) > absMainVariableWeight){
                                            absSecondVariableWeight = absMainVariableWeight;
                                            secondVariableWeight = mainVariableWeight;

                                            mainVariableWeight = oneQuestionSkills[j].Weight;
                                            absMainVariableWeight = Math.abs(oneQuestionSkills[j].Weight);

                                            SecondVariable = MainVariable;
                                            MainVariable = oneQuestionSkills[j].Name;
                                        } else {
                                            if(Math.abs(oneQuestionSkills[j].Weight) > absSecondVariableWeight){
                                                secondVariableWeight = oneQuestionSkills[j].Weight;
                                                absSecondVariableWeight = Math.abs(oneQuestionSkills[j].Weight);

                                                SecondVariable = oneQuestionSkills[j].Name;
                                            }
                                        }
                                    } else if(oneQuestionSkills[j].Type === 1){
                                        Assessment = oneQuestionSkills[j].Weight;
                                    } else if(oneQuestionSkills[j].Type === 2){
                                        Nudge = oneQuestionSkills[j].Weight;
                                    }
                                }
                            }
                            results[i]['MainVariable'] = MainVariable;
                            results[i]['SecondVariable'] = SecondVariable;
                            results[i]['Skills'] = oneQuestionSkills;
                            results[i]['Assessment'] = Assessment;
                            results[i]['Nudge'] = Nudge;

                        }

                        this.setState({results: results, loading:false });

                    } else {
                        this.setState({loading:false})
                    }
                },
                error => {
                    toast.warn("Did not get results for company");
                    this.setState({ loading: false });
                }
            );
    }

}



function reloadTableData(results) {
    const table = $('.data-table-wrapper')
        .find('table')
        .DataTable();
    table.clear();
    table.rows.add(results);
    table.draw();

    let toolbar =  $("#toolbar");

    $("div.toolbar").html(toolbar);
}


export default AdminResultsTable;
