import React from 'react';
import { toast } from "react-toastify";

import './AdminLogsTable.css'
import i18n from "i18next";

import moment from 'moment'

const $ = require('jquery');
$.DataTable = require('datatables.net');

require( 'datatables.net-bs4' );
require( 'datatables.net-select-bs4' );
require('../../datatables.selected.css');

const columns = [
    {
        title: 'Added',
        width: 70,
        data: 'Added'
    },
    {
        title: 'Type',
        width: 100,
        data: 'Type'
    },
    {
        title: 'Importance',
        width: 100,
        data: 'ImportanceLevel'
    },
    {
        title: 'Text',
        width: 130,
        data: 'Text'
    },
];


interface MyProps {
    logs:any,
}

interface MyState {
}

class AdminLogsTable extends React.Component<MyProps,MyState> {
    constructor(props) {
        super(props);

        this.state = {
        };

    }
    componentDidMount() {
        let table = $(this.refs.main).DataTable({
            dom: "<'data-table-wrapper'" +
                    "<'row'<'col-md-6'<'toolbar'>><'col-md-6'f>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'><'col-sm-12 col-md-7'p>>" +
                    ">",
            data: this.props.logs,
            columns,
            ordering: true,
            paging:true,
            scrollX: false,
            // select: {
            //     style: 'single'
            // },
            order: [[ 0, "desc" ]]
            ,language: {
                search: i18n.t("trans_33_search"),
                emptyTable:  i18n.t("trans_33_table_no_records"),
                paginate: {
                    first: i18n.t("trans_33_first"),
                    previous: i18n.t("trans_33_previous"),
                    next: i18n.t("trans_33_next"),
                    last: i18n.t("trans_33_last")
                }
            }
            ,columnDefs: [
                {
                    render: function ( data, type, row ) {
                        if(data != null && data != "0000-00-00"){
                            return moment(data).format('YYYY-MM-DD HH:mm:ss');
                        } else {
                            return "";
                        }
                    },
                    targets: 0,
                }
            ],
        });

    }

    componentWillUnmount(){
        $('.data-table-wrapper')
            .find('table')
            .DataTable()
            .destroy(true);
    }

    componentDidUpdate(oldProps) {
        if(this.props.logs !== oldProps.logs){
            reloadTableData(this.props.logs);
        }
    }

    render() {
        return (
            <div className="row">
                <div  className="col-md-12" id="logsTableContainer">
                    <table ref="main" className="table table-bordered logsTable" />
                </div>
            </div>);
    }

}

function reloadTableData(items) {
    const table = $('.data-table-wrapper')
        .find('table')
        .DataTable();
    table.clear();
    table.rows.add(items);
    table.draw();

    $("#deleteBtn").hide();
    $("#editBtn").hide();
}

export default AdminLogsTable;
