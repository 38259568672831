import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

import { Trans } from 'react-i18next';
import VoxSelect from '../_other/VoxSelect';

interface MyProps {
  onHide(): any;
  handleSubmit(ev: any): any;
  onEntered(): any;
  handleChange(ev: any): any;
  handleCanUsersModerate(ev: any): any;
  show: boolean;
  size?: any;
  submitted: boolean;
  loading: boolean;
  error?: string;
  success: string;
  formFields: any;
  selectedIdVox: number;
  inputQuestions: any;
  voxes: any;
  onVoxSelected
}

class RequestQuestionsModal extends React.Component<MyProps> {
  render() {
    const {
      handleSubmit,
      formFields,
      handleChange,
      show,
      onHide,
      onEntered,
      error,
      success,
      voxes,
      selectedIdVox,
      onVoxSelected,
      handleCanUsersModerate,
      loading,
    } = this.props;

    return (
      <Modal
      show={show}
      onHide={onHide}
      onEntered={onEntered}
      size='lg'
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
        <Modal.Header className='modal-header-update p-4' closeButton translate="yes">
            <Modal.Title as="h4" id="example-custom-modal-styling-title">
              <Trans>trans_33_request_questions</Trans>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-4 pb-0'>

          <p style={{ color: 'red' }}>{error}</p>

          <p style={{ color: 'green' }}>{success}</p>

          <Form onSubmit={handleSubmit}>
            <Form.Group controlId='formCompanyName'>
              <Form.Label className='font-weight-bold'>
                *<Trans>Question text</Trans>
              </Form.Label>
              <Form.Control
                size='sm'
                as='textarea'
                name='QuestionText'
                className='p-3'
                rows={3}
                value={formFields.QuestionText}
                onChange={handleChange}
              />
            </Form.Group>


            <div className="d-flex">
              <div className="mr-4">
                <span style={{ display: 'inline-block', }}>
                  <VoxSelect
                      selectedIdVox={selectedIdVox}
                      onVoxSelected={onVoxSelected}
                      voxes={voxes}
                      className='add-new-pulse-select'
                  />
                </span>
              </div>
              &nbsp;&nbsp;
              <div className="col-md6 my-auto">
                <Form.Check
                    type="checkbox"
                    checked={formFields.CanUsersModerate || false}
                    onChange={handleCanUsersModerate}
                    id="CanUsersModerate"
                    label={<p className='ml-3'><Trans>trans_33_allow_users_to_moderate_questions</Trans></p>}
                />
              </div>
            </div>


            <div style={{ textAlign: 'right',padding: "0 10px 0 10px" }}>
              <Button
                variant='primary'
                type='submit'
                className='px-4 py-2 py btn-primary-updated no-shrink'
                size="lg"
                disabled={loading}
              >
                <Trans>trans_33_Send</Trans>
              </Button>
              {loading && (
                <img
                  alt='load ing'
                  src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
                />
              )}
            </div>
          </Form>
        </Modal.Body>
        <br/>
    </Modal>
    );
  }

}

export { RequestQuestionsModal };
