import React from "react";

import { toast } from "react-toastify";
import { Row, Col, Button } from "react-bootstrap";
import ModalPaymentInfo from "./ModalPaymentInfo";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { serverService } from "../../_services/server.service";
import ModalCompanyDetails from "../_modals/ModalCompanyDetails";
import ModalChooseLicense from "./ModalChooseLicense";

import PubSub from "../../_helpers/PubSub.js";
import VoxesPaymentsTable from "./VoxesPaymentsTable";
import LicenseLogsTable from "./LicenseLogsTable";
import PdfsGrid from "../AdvicesPage/PdfsGrid";
import { config } from "../../_helpers/C";
import i18n from "i18next";
import {Trans} from "react-i18next";

const moment = require("moment");

interface MyProps {}

interface MyState {
  company: any;
  license: any;
  voxes: any;
  invoices: any;
  licenseLogs: any;
  paymentMethod: any;
  modalPaymentInfo: boolean;
  modalCompanyDetails: boolean;
  modalChooseLicense: boolean;

  subscriptions: any;
  subscriptionsSchedules: any;

  licensePrice: number;

  billingCycleFrom: any;
  billingCycleTo: any;

  numberOfAnswersForVirtualVoxesAvailable: number;
  totalNumberOfAnswersForVirtualVoxesForTheCurrentBillingCycle: number;

  totalNumberOfAutoPilotQuestionsForTheCurrentBillingCycle: number;


  licensePendingUpdates: any;
}

class SettingsPage extends React.Component<MyProps, MyState> {
  private userObj;

  constructor(props) {
    super(props);

    this.state = {
      company: {},
      license: {},
      voxes: [],
      invoices: [],
      licenseLogs: [],
      paymentMethod: {},
      modalPaymentInfo: false,
      modalCompanyDetails: false,
      modalChooseLicense: false,

      subscriptions: [],
      subscriptionsSchedules: [],

      licensePrice: -1,

      billingCycleFrom: null,
      billingCycleTo: null,

      numberOfAnswersForVirtualVoxesAvailable: 0,
      totalNumberOfAnswersForVirtualVoxesForTheCurrentBillingCycle: 0,

      totalNumberOfAutoPilotQuestionsForTheCurrentBillingCycle:0,

      licensePendingUpdates:[]
    };

    this.doOpenModalPaymentInfo = this.doOpenModalPaymentInfo.bind(this);
    this.doCloseModalPaymentInfo = this.doCloseModalPaymentInfo.bind(this);

    this.doOpenModalCompanyDetails = this.doOpenModalCompanyDetails.bind(this);
    this.doCloseModalCompanyDetails = this.doCloseModalCompanyDetails.bind(
      this
    );

    this.doOpenModalChooseLicense = this.doOpenModalChooseLicense.bind(this);
    this.doCloseModalChooseLicense = this.doCloseModalChooseLicense.bind(this);

    this.onCompanyDetailsUpdated = this.onCompanyDetailsUpdated.bind(this);
    this.onLicenseUpdated = this.onLicenseUpdated.bind(this);

    this.onChangeCampaignStatus = this.onChangeCampaignStatus.bind(this);

    this.onVoxDeleted = this.onVoxDeleted.bind(this);

    this.doResetPassword = this.doResetPassword.bind(this);

  }

  componentDidMount() {
    const userStr = localStorage.getItem("user");
    if (userStr) {
      this.userObj = JSON.parse(userStr);
    }

    let fetchAllVoxesPromise = this.doFetchAllVoxes();
    let fetchCompanyPromise = this.doFetchCompany();
    let fetchCurrentLicense = this.doFetchCurrentLicense();
    let fetchCurrentLicenseLogs = this.doFetchCurrentLicenseLogs();


    //let fetchSubscriptionsPromise = this.doFetchSubscriptions();
    //let fetchSubscriptionsSchedulesPromise = this.doFetchSubscriptionsSchedules();
    let fetchInvoicesPromise = this.doFetchInvoices();

    Promise.all([
      fetchAllVoxesPromise,
      fetchCompanyPromise,
      fetchCurrentLicense,
      fetchCurrentLicenseLogs,
      fetchInvoicesPromise,
    ]);


    this.doFetchCurrentLicensePendingUpdates();

    PubSub.subscribe("updateLicense", (product) => {
      this.doFetchSubscriptions();
      this.doCloseModalChooseLicense();
    });
  }

  componentWillUnmount() {
    PubSub.unsubscribe("updateLicense");
  }

  render() {
    let { license, licensePrice,licensePendingUpdates } = this.state;
    let membershipType = "No membership";
    let membershipStartDate = moment("0000-00-00");
    let membershipStopDate = moment("0000-00-00");
    let membershipStartDateStr = "";
    let membershipStopDateStr = "";
    let isCampaignStarted = true;
    let licensePriceStr = "N/A";
    if (license && licensePrice !== undefined) {
      licensePriceStr = licensePrice + " EUR";
    }

    if (license) {
      if (license.Type == 1) {
        membershipType = i18n.t("trans_33_license_self_starter");
      } else if (license.Type == 2) {
        membershipType = i18n.t("trans_33_license_standard");
      } else if (license.Type == 3) {
        membershipType = i18n.t("trans_33_license_enterprise");
      }

      if (license.Type == 1 || license.Type == 2 || license.Type == 3) {
        membershipStartDate = moment(license.StartDate);
        membershipStopDate = moment(license.StopDate);

        membershipStartDateStr = membershipStartDate.format("DD.MM.YYYY");
        membershipStopDateStr = membershipStopDate.format("DD.MM.YYYY");

        isCampaignStarted =
          membershipStartDate.isValid() && !membershipStopDate.isValid();
      }
    }

    return (
      <div className="container p-5 mt-5" id="settingsContainer">
        <div className="row">
          <div className="col-md-5">
            <div>
              <b><Trans>trans_33_settings_payment_info</Trans></b>
              <br />
              {!this.state.paymentMethod.card && <span>No payment info</span>}

              {this.state.paymentMethod.card && (
                <div>
                  <br />
                  <span><Trans>trans_33_Card</Trans> {this.state.paymentMethod.card}</span>
                  <br />
                  <span>**** **** **** {this.state.paymentMethod.last4}</span>
                  <br />
                  <span>
                    Exp: {this.state.paymentMethod.exp_month}/
                    {this.state.paymentMethod.exp_year}
                  </span>
                </div>
              )}

              <br />
              <Button onClick={this.doOpenModalPaymentInfo}>Change</Button>
            </div>
          </div>
          <div className="col-md-7">
            <div>
              <b><Trans>trans_33_settings_billing_info</Trans></b>
              <br />

              <div>
                <div className="row">
                  <div className="col-md-3"><Trans>trans_33_Company</Trans>: </div>
                  <div className="col-md-3">{this.state.company.Name}</div>
                </div>

                <div className="row">
                  <div className="col-md-3"><Trans>trans_33_Responsable</Trans>: </div>
                  <div className="col-md-3">
                    {this.state.company.ResponsableFirstName +
                      " " +
                      this.state.company.ResponsableName}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3"><Trans>trans_33_Street</Trans>: </div>
                  <div className="col-md-3">{this.state.company.AddressStreet} {this.state.company.AddressStreetNumber}</div>
                </div>

                <div className="row">
                  <div className="col-md-3"><Trans>trans_33_postal_code</Trans>: </div>
                  <div className="col-md-3">{this.state.company.AddressPostalCode}</div>
                </div>

                <div className="row">
                  <div className="col-md-3"><Trans>trans_33_vat_number</Trans>: </div>
                  <div className="col-md-3">{
                    this.state.company.VATNumber==0?i18n.t("trans_33_no_vat"):this.state.company.VATNumber
                  }</div>
                </div>

                <div className="row">
                  <div className="col-md-3"><Trans>trans_33_phone_number</Trans>: </div>
                  <div className="col-md-3">
                    {this.state.company.PhoneNumber}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3"><Trans>trans_33_email</Trans>: </div>
                  <div className="col-md-3">{this.state.company.Email}</div>
                </div>
              </div>

              <Button onClick={this.doOpenModalCompanyDetails}><Trans>trans_33_Change</Trans></Button>
            </div>
          </div>
        </div>

        <br />
        <br />
        <div className="row">
          <div className="col-md-5">
            {this.state.invoices && (
              <div>
                <b><Trans>trans_33_Invoices</Trans></b>
                <br />
                <PdfsGrid 
                  pdfResources={this.state.invoices}
                  rowsPdf={1}
                  displayDate={true} 
                />
              </div>
            )}
          </div>
          <div className="col-md-7">
            {license && (
              <div>
                <b><Trans>trans_33_settings_membership_status</Trans></b>
                <br />
                {membershipStartDate !== undefined &&
                  membershipStartDate.isValid() && (
                    <div>
                      <Trans>trans_33_settings_campaign_status</Trans> &nbsp;&nbsp;
                      {isCampaignStarted && (
                        <BootstrapSwitchButton
                          checked={true}
                          onlabel={i18n.t("trans_33_ON")}
                          offlabel={i18n.t("trans_33_OFF")}
                          onstyle="success"
                          offstyle="danger"
                          onChange={this.onChangeCampaignStatus}
                        />
                      )}
                      {!isCampaignStarted && (
                        <BootstrapSwitchButton
                          checked={false}
                          onlabel={i18n.t("trans_33_ON")}
                          offlabel={i18n.t("trans_33_OFF")}
                          onstyle="success"
                          offstyle="danger"
                          onChange={this.onChangeCampaignStatus}
                        />
                      )}
                      <br />
                    </div>
                  )}
                <br />
                <p>
                  <Trans>trans_33_settings_membership_type</Trans>: <b>{membershipType}</b>, <Trans>trans_33_settings_monthly_cost</Trans>:{" "}
                  <b>{licensePriceStr}</b>
                  <br />
                  <Trans>trans_33_settings_virtual_voxes</Trans>: <b>{license["VirtualVoxNum"]}</b>,
                  <Trans>trans_33_settings_physical_voxes</Trans>: <b>{license["PhysicalVoxNum"]}</b>
                </p>

            {license["PendingActivationVoxNum"] !== undefined && license["PendingActivationVoxNum"] > 0 &&
            <p>
              <Trans>trans_33_settings_physical_voxes_pending</Trans>:{" "}
              <b>{license["PendingActivationVoxNum"]}</b>
            </p>
            }

                {membershipStartDate !== undefined &&
                  membershipStartDate.isValid() && (
                    <p>
                      <Trans>trans_33_settings_membership_started</Trans>: {membershipStartDateStr}
                      {membershipStopDate !== undefined &&
                        membershipStopDate.isValid() && (
                          <span>
                            ; <Trans>trans_33_settings_membership_ended</Trans>: {membershipStopDateStr}
                          </span>
                        )}
                      <br />
                    </p>
                  )}

                {this.state.billingCycleFrom !== null &&
                  this.state.billingCycleFrom.isValid() &&
                  this.state.billingCycleTo !== null &&
                  this.state.billingCycleTo.isValid() && (
                    <div>
                      <p>
                        <Trans>trans_33_settings_current_billing_cycle</Trans>:{" "}
                        {this.state.billingCycleFrom.format("DD.MM.YYYY")} -{" "}
                        {this.state.billingCycleTo.format("DD.MM.YYYY")}
                        <br />
                      </p>

                      {this.state.numberOfAnswersForVirtualVoxesAvailable > 0 && (
                        <p>
                          <Trans>trans_33_settings_num_of_answers</Trans>:{" "}
                          <b>
                            {
                              this.state
                                .totalNumberOfAnswersForVirtualVoxesForTheCurrentBillingCycle
                            }
                          </b>{" "}
                          <Trans>trans_33_out_of</Trans>{" "}
                          {this.state.numberOfAnswersForVirtualVoxesAvailable}
                          <br />
                        </p>
                      )}


                      {this.state.totalNumberOfAutoPilotQuestionsForTheCurrentBillingCycle > 0 && (
                          <p>
                            <Trans>trans_33_settings_num_of_auto_pilot_questions</Trans>:{" "}
                            <strong>
                              {
                                this.state.totalNumberOfAutoPilotQuestionsForTheCurrentBillingCycle
                              }
                            </strong>
                            {license.Type === 2 &&
                                <div>
                                  <Trans>trans_33_extra_cost</Trans>: <strong>{config.PRICE_AUTO_PILOT_DAILY * this.state.totalNumberOfAutoPilotQuestionsForTheCurrentBillingCycle} EUR</strong>
                                </div>
                            }

                            <br />
                          </p>
                      )}

                    </div>
                  )}


              {licensePendingUpdates.length > 0 &&
                <p>
                  <Trans>trans_33_settings_peding_updates</Trans><br/>
                  <Trans>trans_33_settings_license_type</Trans>: <b>{licensePendingUpdates[0]['Type']==1?'Self-starter':licensePendingUpdates[0]['Type']==2?'Standard':'Enterprise'}</b><br/>
                  <Trans>trans_33_settings_virtual_voxes</Trans>: <b>{licensePendingUpdates[0]['VirtualVoxNum']}</b><br/>
                  <Trans>trans_33_settings_physical_voxes</Trans>: <b>{licensePendingUpdates[0]['PhysicalVoxNum']}</b>
                </p>
              }

                <Button onClick={this.doOpenModalChooseLicense}><Trans>trans_33_Upgrade</Trans></Button>
              </div>
            )}
          </div>
        </div>


        <Button onClick={this.doResetPassword}><Trans>Reset password</Trans></Button>

        <br />
        <br />

        <VoxesPaymentsTable
          voxes={this.state.voxes}
          onVoxDeleted={this.onVoxDeleted}
        />

        <LicenseLogsTable licenseLogs={this.state.licenseLogs} />

        <ModalPaymentInfo
          show={this.state.modalPaymentInfo}
          onHide={this.doCloseModalPaymentInfo}
        />

        <ModalCompanyDetails
          show={this.state.modalCompanyDetails}
          onHide={this.doCloseModalCompanyDetails}
          company={this.state.company}
          title={i18n.t("trans_33_settings_modal_company_details_title")}
          onCompanyDetailsUpdated={this.onCompanyDetailsUpdated}
          backdrop={"true"}
          closeButton={true}
        />

        <ModalChooseLicense
          show={this.state.modalChooseLicense}
          onHide={this.doCloseModalChooseLicense}
          company={this.state.company}
          license={this.state.license}
          onLicenseUpdated={this.onLicenseUpdated}
          voxes={this.state.voxes}
        />
      </div>
    );
  }

  doOpenModalPaymentInfo() {
    this.setState({ modalPaymentInfo: true });
  }

  doCloseModalPaymentInfo() {
    this.setState({ modalPaymentInfo: false });
  }

  doOpenModalCompanyDetails() {
    this.setState({ modalCompanyDetails: true });
  }

  doCloseModalCompanyDetails() {
    this.setState({ modalCompanyDetails: false });
  }

  doOpenModalChooseLicense() {
    this.setState({ modalChooseLicense: true });
  }

  doCloseModalChooseLicense() {
    this.setState({ modalChooseLicense: false });
  }

  doResetPassword() {
    serverService.forgotPassword(this.userObj['Username']).then(
        (response) => {
          if (response.status === 0) {
            toast.warn(response.message);
          } else {
            toast.success(i18n.t("trans_33_login_page_msg_link_sent"));
          }
        },
        (error) => {
          toast.error("Server error");
        }
    );
  }

  doFetchCurrentLicense() {
    return serverService.getCurrentLicense().then((result) => {
      let license = result.data[0];

      if(!license){
        return;
      }

      this.userObj['LicenseType'] = license['Type'];
      this.userObj['VirtualVoxNum'] = license['VirtualVoxNum'];
      this.userObj['PhysicalVoxNum'] = license['PhysicalVoxNum'];
      localStorage.setItem('user',JSON.stringify(this.userObj));

      let licensePrice = 0;
      if (license["Type"] == 1) {
        licensePrice = config.PRICE_LICENSES[1];
      } else if (license["Type"] == 2) {
        licensePrice =  config.PRICE_LICENSES[2];
      } else if (license["Type"] == 3) {
        licensePrice = config.PRICE_LICENSES[3];
      }

      let totalNumberOfVoxes = license["VirtualVoxNum"] + license["PhysicalVoxNum"];
      if(totalNumberOfVoxes > 0){
        licensePrice += config.PRICE_EXTRA_VOX_MONTHLY * (totalNumberOfVoxes - 1);
      }

      let nowMoment = moment();

      let membershipStartDate = moment(license["StartDate"]);

      let billingCycleFrom = membershipStartDate.clone();
      billingCycleFrom.set("month", nowMoment.month());
      billingCycleFrom.set("year", nowMoment.year());



      let billingCycleTo = membershipStartDate.clone();
      billingCycleTo.set("month", nowMoment.month());
      billingCycleTo.set("year", nowMoment.year());
      billingCycleTo = billingCycleTo.add(1, "M");


      if (nowMoment.date() < billingCycleFrom.date()) {
        billingCycleFrom = billingCycleFrom.add(-1, "M");
        billingCycleTo = billingCycleTo.add(-1, "M");
      }


      let numberOfAnswersForVirtualVoxesAvailable =
        license["VirtualVoxNum"] * config.NUMBER_OF_EXTRA_ANSWER_FOR_VIRTUAL_VOXES;

      this.setState({
        license: license,
        licensePrice: licensePrice,
        billingCycleFrom: billingCycleFrom,
        billingCycleTo: billingCycleTo,
        numberOfAnswersForVirtualVoxesAvailable: numberOfAnswersForVirtualVoxesAvailable,
      });

      serverService
        .getNumberOfAnswersForVirtualVoxesForBillingCycle(
          billingCycleFrom.format("YYYY-MM-DD"),
          billingCycleTo.format("YYYY-MM-DD")
        )
        .then(
          (result) => {
            this.setState({
              totalNumberOfAnswersForVirtualVoxesForTheCurrentBillingCycle:
                result.data,
            });
          },
          (error) => {
            console.log(error);
          }
        );



      serverService
          .getNumberOfAutoPilotQuestionsForBillingCycle(
              billingCycleFrom.format("YYYY-MM-DD"),
              billingCycleTo.format("YYYY-MM-DD")
          )
          .then(
              (result) => {
                this.setState({
                  totalNumberOfAutoPilotQuestionsForTheCurrentBillingCycle:
                  result.data,
                });
              },
              (error) => {
                console.log(error);
              }
          );
    });
  }

  doFetchCurrentLicenseLogs() {
    return serverService.getCurrentLicenseLogs().then((result) => {
      let licenseLogs = result.data;

      this.setState({ licenseLogs: licenseLogs });
    });
  }


  doFetchCurrentLicensePendingUpdates() {
    return serverService.getCurrentLicensePendingUpdate().then((result) => {
      let licensePendingUpdates = result.data;

      this.setState({ licensePendingUpdates: licensePendingUpdates });
    });
  }


  doFetchCompany() {
    let IdCompany = this.userObj.IdCompany;

    return serverService.getCompany(IdCompany).then(
      (result) => {
        let company = result.data[0];
        this.setState({ company: company });
        if (result.status === "1") {
          serverService.paymentsGetPaymentMethods().then(
            (result) => {
              if (result.status !== "1") {
                console.log(result.message);
                //toast.error(result.message);
              } else {
                if (result.data.length > 0) {
                  this.setState({ paymentMethod: result.data[0] });
                }
              }
            },
            (error) => {
              toast.warn("Did not get payment methods");
            }
          );
        } else {
          toast.error(result.message);
        }
      },
      (error) => {
        toast.warn("Did not get company");
      }
    );
  }

  doFetchSubscriptions() {
    return serverService.paymentsGetSubscriptionsForCustomer().then(
      (result) => {
        // console.log(result);
        this.setState({ subscriptions: result.data });
        // if(result.status === "1"){
        //     let license = result.data[0];
        //     this.setState({ license:license, });
        // } else {
        //     toast.error("Issues while communicating with the server");
        // }
      },
      (error) => {
        toast.warn("Did not get subscriptions");
      }
    );
  }

  doFetchSubscriptionsSchedules() {
    return serverService.paymentsGetSubscriptionsSchedules().then(
      (result) => {
        // console.log(result);
        this.setState({ subscriptionsSchedules: result.data });
      },
      (error) => {
        toast.warn("Did not get subscriptions");
      }
    );
  }

  doFetchInvoices() {
    return serverService.paymentsGetInvoicesForCustomer().then(
      (result) => {
        // console.log(result);
        this.setState({ invoices: result.data });
        // if(result.status === "1"){
        //     let license = result.data[0];
        //     this.setState({ license:license, });
        // } else {
        //     toast.error("Issues while communicating with the server");
        // }
      },
      (error) => {
        toast.warn("Did not get subscriptions");
      }
    );
  }

  onCompanyDetailsUpdated() {
    toast.success(i18n.t("trans_33_myvox_page_company_details_msg_update_success"));
    this.doCloseModalCompanyDetails();
  }

  onLicenseUpdated(isItADowngrade) {
    if(isItADowngrade){
      toast.success(i18n.t("trans_33_settings_msg_license_updated_downgrade"));
    } else {
      toast.success(i18n.t("trans_33_settings_msg_license_updated"));
    }

    this.doCloseModalChooseLicense();
    this.doFetchCurrentLicense();
    this.doFetchCurrentLicenseLogs();
    this.doFetchCurrentLicensePendingUpdates();
  }

  onChangeCampaignStatus(checked) {
    if (checked) {
      serverService.startLicense().then(
        (result) => {
          if (result.status === "1") {
            this.onLicenseUpdated(false);
          } else {
            toast.error(i18n.t("trans_33_settings_msg_license_update_failed"));
          }
        },
        (error) => {
          toast.warn("Did not update license");
        }
      );

      // let license = this.state.license;
      // license.StartDate = moment.utc().format();
      // license.IdCompany = String(this.state.company.IdCompany);
      // license.Type = String(license.Type);
      // delete license.IdLicense;
      // delete license.StopDate;

      // serverService.insertLicense(license).then(
      //     result => {
      //         if(result.status === "1"){
      //             this.onLicenseUpdated();
      //         } else {
      //             toast.error("Licence update failed");
      //         }
      //     },
      //     error => {
      //         toast.warn("Did not insert license");
      //     }
      // );
    } else {
      serverService.stopLicense().then(
        (result) => {
          if (result.status === "1") {
            this.onLicenseUpdated(false);
          } else {
            toast.error(i18n.t("trans_33_settings_msg_license_update_failed"));
          }
        },
        (error) => {
          toast.warn("Did not update license");
        }
      );

      // let license = this.state.license;
      // license.StopDate = moment.utc().format();
      //
      // serverService.updateLicense(license).then(
      //     result => {
      //         if(result.status === "1"){
      //             this.onLicenseUpdated();
      //         } else {
      //             toast.error("Licence update failed");
      //         }
      //     },
      //     error => {
      //         toast.warn("Did not update license");
      //     }
      // );
    }
  }

  doFetchAllVoxes() {
    serverService.getAllActiveVoxes().then(
      (voxesRes) => {
        let voxes = voxesRes.data;
        for (let i = 0; i < voxes.length; i++) {
          if (i == 0) {
            voxes[i]["MonthlyCost"] = "Free";
          } else {
            voxes[i]["MonthlyCost"] = config.PRICE_EXTRA_VOX_MONTHLY;
          }
        }

        this.setState({ voxes: voxes });
      },
      (error) => {
        toast.warn("Did not get voxes");
      }
    );
  }

  onVoxDeleted() {
    this.doFetchAllVoxes();
  }
}

export { SettingsPage };
