import React from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment'

import { AnswersBodyModal } from './AnswersBodyModal';

interface MyProps {
  onHide(): any;
  show: boolean;
  answers: any;
}

class AnswersViewModal extends React.Component<MyProps> {
  render() {
    const {
      show,
      onHide,
      answers,
    } = this.props;
    console.log(answers)
    return (
      <Modal
        show={show}
        onHide={onHide}
        size='lg'
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
          {
            answers && (
              <>
                <Modal.Header className='modal-header-update modal-header-request p-4 flex-column' closeButton translate="yes">
                  <Modal.Title className='w-100' as="h4" id="example-custom-modal-styling-title">
                    {`"${answers.QuestionText}"`}
                  </Modal.Title>
                  <div className='d-flex align-items-center'>
                    <p className='pr-3 mb-0'>{`By ${answers.VoxName}`}</p>
                    <p className='mb-0'>{moment(answers.Added).format('D MMM YYYY HH:mm:ss')}</p>
                  </div>
                </Modal.Header>
                <Modal.Body className='p-0'>
                  <AnswersBodyModal onHide={onHide} answers={answers.answers.split("<br/>").map(answer => ({ answer: answer.replace("•", "") }))} />
                </Modal.Body>
              </>
            )
          }
        <br/>
    </Modal>
    );
  }
}

export { AnswersViewModal };
