import React from 'react';
import { toast } from "react-toastify";

import i18n from "i18next";
import AdminModalAddNewVoucher from './AdminModalAddNewVoucher';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { serverService } from '../../../_services/server.service';
import ModalConfirmAction from '../../_other/ModalConfirmAction';

import moment from 'moment'

const $ = require('jquery');
$.DataTable = require('datatables.net');

require( 'datatables.net-bs4' );
require( 'datatables.net-select-bs4' );
require('../../datatables.selected.css');
 
const columns = [
    {
        title: 'Code',
        width: 100,
        data: 'Code'
    },
    { 
        title: 'IssueDate',
        width: 100,
        data: 'IssueDate'
    },
    { 
        title: 'Company',
        width: 100,
        data: 'CompanyName'
    }, 
    {
        title: 'UsedDate',
        width: 100,
        data: 'UsedDate'
    },
];


interface MyProps {
    vouchers:any,
    onVoucherAdded(extra:string):any,
    onVoucherDeleted():any,
}


interface MyState {
    submitted: boolean,
    modalAddNewShow:boolean,
    modalConfirmDeleteShow: boolean,
    selectedVoucher: any,
    formFields:any,
}
 

class AdminVouchersTable extends React.Component<MyProps,MyState> {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            modalAddNewShow:false,
            modalConfirmDeleteShow: false,
            selectedVoucher: {},
            formFields:{},
        };


        this.onVoucherAdded = this.onVoucherAdded.bind(this);
    }

     
    componentDidMount() {
        let table = $(this.refs.main).DataTable({
            dom: "<'data-table-wrapper'" +
                    "<'row'<'col-md-6'<'toolbar'>><'col-md-6'f>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'><'col-sm-12 col-md-7'p>>" +
                    ">",
            data: this.props.vouchers,
            columns,
            ordering: true,
            paging:true,
            scrollX: false,
            select: {
                style: 'single'
            },
            order: [[ 0, "desc" ]]
            ,language: {
                search: i18n.t("trans_33_search"),
                emptyTable:  i18n.t("trans_33_table_no_records"),
                paginate: {
                    first: i18n.t("trans_33_first"),
                    previous: i18n.t("trans_33_previous"),
                    next: i18n.t("trans_33_next"),
                    last: i18n.t("trans_33_last")
                }
            }
            ,columnDefs: [
                {
                    render: function ( data, type, row ) {
                        if(data != null && data != "0000-00-00"){
                            return moment(data).format('YYYY-MM-DD HH:mm:ss');
                        } else {
                            return "";
                        }
                    },
                    targets: 1,
                },
                {
                    render: function ( data, type, row ) {
                        if(data != null && data != "0000-00-00"){
                            return moment(data).format('YYYY-MM-DD HH:mm:ss');
                        } else {
                            return "";
                        }
                    },
                    targets: 3,
                }
            ],
        });


        $("#deleteBtn").hide();

        table.on( 'select', function ( e, dt, type, indexes ) {
            $("#deleteBtn").show();
        });

        table.on( 'deselect', function ( e, dt, type, indexes ) {
            $("#deleteBtn").hide();
        });

    }

    componentWillUnmount(){
        $('.data-table-wrapper')
            .find('.vouchersTable')
            .DataTable()
            .destroy(true);
    }

    componentDidUpdate(oldProps) {
        if(this.props.vouchers !== oldProps.vouchers){
            reloadTableData(this.props.vouchers);
        }
    }

    render() {

        let modalAddNewOpen = (itemToEdit) => this.setState({ modalAddNewShow: true, selectedVoucher: itemToEdit });
        let closeAddNewModal = () => this.setState({ modalAddNewShow: false });

        let modalConfirmDeleteOpen = (itemToEdit) => {
            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();
            let data = table.rows( { selected: true }).data();

            this.setState({ modalConfirmDeleteShow: true });
        };
        let modalConfirmDeleteClose = () => this.setState({ modalConfirmDeleteShow: false });

        let deleteSelectedItemWithConfirmation = () => {
            modalConfirmDeleteOpen(null);
        };


        let deleteSelectedItem = () => {
            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();

            let data = table.rows( { selected: true }).data();

            let IdVoucher = data[0].IdVoucher;

            serverService.deleteVoucher(IdVoucher)
                .then( 
                    result => {
                        if(result.status == 1){
                            toast.success("Voucher deleted");
                            $("#deleteBtn").hide();
                            this.props.onVoucherDeleted();
                        } else {
                            toast.error("Delete failed");
                        }

                        this.setState({ modalConfirmDeleteShow: false });
                    },
                    error => {
                        toast.warn("Did not delete user");
                    }
                );
        };


        
        return (
            <div className="row">
                <div className="col-md-12" id="vouchersTableContainer">
                    <table ref="main" className="table table-bordered vouchersTable" />

                    <div className="float-right">
                        <ButtonToolbar>
                            <Button
                                id="deleteBtn"
                                variant="light"
                                onClick={deleteSelectedItemWithConfirmation}
                            >
                                Delete
                            </Button>

                            &nbsp;

                            <Button
                                id="addBtn"
                                variant="light"
                                onClick={modalAddNewOpen}
                            >
                                Add
                            </Button>
                        </ButtonToolbar>
                    </div>

                </div>


                <AdminModalAddNewVoucher
                        show={this.state.modalAddNewShow}
                        onHide={closeAddNewModal}
                        onVoucherAdded={this.onVoucherAdded}
                    />

                    <ModalConfirmAction
                        show={this.state.modalConfirmDeleteShow}
                        actionTitle="Confirm delete"
                        actionBody="Are you sure you want to delete the selected user?"
                        noBtnText="No, go back"
                        yesBtnText="Yes, confirm"
                        onConfirmNo={modalConfirmDeleteClose}
                        onHide={modalConfirmDeleteClose}
                        onConfirmYes={deleteSelectedItem}
                    />

            </div>); 
    }


    onVoucherAdded(item){
        this.props.onVoucherAdded(item);
        this.setState({modalAddNewShow: false});
    }

} 

function reloadTableData(items) {
    const table = $('.data-table-wrapper')
        .find('.vouchersTable')
        .DataTable();
    table.clear();
    table.rows.add(items);
    table.draw();

    $("#deleteBtn").hide();
    $("#editBtn").hide();
}

export default AdminVouchersTable;
