import React from 'react';
import {Form} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../_services/server.service';
import {Trans} from "react-i18next";
import i18n from "i18next";

interface MyProps {
    onVoxSelected(IdVox:number):any,
    getNumberOfVoxesFetched?(numberOfVoxes:number):any,
    selectedIdLocation?:number,
    selectedIdVox?:number,
    voxes?:any,
    onlyMsTeamsBot?:boolean,
    className?:string
}

interface MyState {
    voxes:any,
}

class VoxSelect extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            voxes:[],
        };
    }

    componentDidMount(){
        if(this.props.voxes != null){
            this.setState({voxes:this.props.voxes});
        } else {
            this.fetchVoxes();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.voxes){
            if (this.props.voxes !== prevState.voxes) {
                this.setState({voxes: this.props.voxes});
            }
        }
    }

    render () {
        let {voxes} = this.state;

        let optionItems = "";
        let defaultValue = 0;
        if(voxes && voxes.length > 0){
             optionItems = voxes.map((vox) => {
                 let selected = false;
                 if(this.props.selectedIdVox > 0){
                     if(this.props.selectedIdVox == vox.IdVox){
                         selected = true;
                         defaultValue = vox.IdVox;
                     }
                 }
                 if(this.props.selectedIdLocation > 0){
                     if(this.props.selectedIdLocation == vox.IdLocation){
                         return <option key={vox.IdVox} value={vox.IdVox}>{vox.Name}</option>
                     }
                 } else {
                     return <option key={vox.IdVox} value={vox.IdVox}>{vox.Name}</option>
                 }
             });
        }

        return (
                <Form.Control className={this.props.className} onChange={this.onVoxSelected} size="sm" as="select" defaultValue={defaultValue.toString()}>
                    <option key='0' value='0'>{i18n.t("trans_33_all_voxes")}</option>
                    {optionItems}
                </Form.Control>
        )
    }


    fetchVoxes() {
        let userStr = localStorage.getItem('user');
        let IdCompany = 0;
        if(userStr) {
            let userObj = JSON.parse(userStr);
            IdCompany = userObj.IdCompany;
        }

        serverService.getAllActiveVoxes()
            .then(
                result => {
                    if(result.status === "1"){
                        let voxes = result.data;
                        this.setState({
                            voxes:voxes,
                        });

                        if(typeof this.props.getNumberOfVoxesFetched === 'function') {
                            this.props.getNumberOfVoxesFetched(voxes.length);
                        }
                    } else {
                        toast.warn("Error while fetching voxes");
                    }
                },
                error => {
                    toast.warn("Did not get voxes");
                }
            );
    }

    onVoxSelected = (event) => {
        // this.setState({ selectedValueId: event.target.value });
        this.props.onVoxSelected(event.target.value);
    };
}

export default VoxSelect;
