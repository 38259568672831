import React from 'react';
import { Container,Row,Col, Button } from "react-bootstrap";
import { Player } from 'video-react';

import { serverService } from '../../_services/server.service';


import "../../../node_modules/video-react/dist/video-react.css";
import VideosGrid from "./VideosGrid";
import PdfsGrid from "./PdfsGrid";
import {toast} from "react-toastify";
import {Trans} from "react-i18next";




interface MyProps {
}

interface MyState {
    loading:boolean,
    videoResources:any,
    pdfResources:any,
    pdfPage:number,

    rowsPdf: number,

}

class AdvicesEnterprise extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);


        this.state = {
            loading:false,
            videoResources:[],
            pdfResources:[],
            pdfPage:0,

            rowsPdf: 2,

        };


    }

    componentDidMount() {
        this.setState({
        });

        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
        head.appendChild(script);

        this.doFetchResources();
    }

    render() {

        const { pdfResources, rowsPdf } =  this.state;

        return (
            <div>
                <br/>
                <br/>
                <span style={{fontWeight:'bold', marginLeft:'25px'}}>
                    <Trans>trans_33_advices_enterprise_here_are_your_custom_reports</Trans>
                </span>
                <br/>

                {pdfResources != undefined && rowsPdf != undefined &&
                    <PdfsGrid pdfResources={pdfResources} rowsPdf={rowsPdf} displayDate={true}/>
                }


                <hr style={{backgroundColor:'#20ABEC', opacity:0.1}}/>
                <br/>
                <span style={{fontWeight:'bold', marginLeft:'25px'}}>
                    <Trans>
                        trans_33_advices_enterprise_need_some_explanations
                    </Trans>
                </span>
                <div className="row">
                    <div className="col-md-12">
                        <div className="calendly-inline-widget" data-url="https://calendly.com/voxcollector/60min" />
                    </div>
                </div>
            </div>
        );
    }


    doFetchResources(){
        let userStr = localStorage.getItem('user');
        let IdCompany = 0;
        if(userStr) {
            let userObj = JSON.parse(userStr);
            IdCompany = userObj.IdCompany;
        }

        this.setState({loading: true});
        serverService.getResources(null,null,null,3)
            .then(
                resultsRes => {
                    if(resultsRes && resultsRes.data !== undefined){
                        let videoResources = [];
                        let pdfResources = [];
                        for(let i=0;i<resultsRes.data.length;i++){
                            if(resultsRes.data[i]['Type'] === 2){
                                videoResources.push(resultsRes.data[i]);
                            } else if(resultsRes.data[i]['Type'] === 1){
                                pdfResources.push(resultsRes.data[i]);
                            }
                        }
                        this.setState({videoResources: videoResources,pdfResources: pdfResources,loading:false})
                    } else {
                        this.setState({loading:false});
                    }
                },
                error => {
                    toast.warn("Did not get resources2");
                }
            );
    }
}

export default AdvicesEnterprise;
