/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";
import ModalImage from "react-modal-image";
import {serverService} from "../../_services/server.service";
import {Trans} from "react-i18next";
import i18n from 'i18next';

import {config} from '../../_helpers/C'



interface MyProps {
    show:boolean,
    onHide?(): any,
    onExit?(): any,
}

interface MyState {
    loading:boolean,
    formFields:any,
    selectedChannelType:number,
    isEmailFieldDisabled: boolean,
    CodeMsBot: string,
    IdVoxMsTeamsBot: number,
}


class ModalAddNewChannel extends React.Component<MyProps,MyState> {

    private userObj;
    private slackButtonUrl;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            formFields: {},
            selectedChannelType: 0,
            isEmailFieldDisabled: false,
            CodeMsBot: "",
            IdVoxMsTeamsBot: 0,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.onChannelTypeSelected = this.onChannelTypeSelected.bind(this);
        this.onEntered = this.onEntered.bind(this);
        this.doFetchVoxMsTeamsById = this.doFetchVoxMsTeamsById.bind(this);
        this.handleIConfigureTheChannelCheckedChanged = this.handleIConfigureTheChannelCheckedChanged.bind(this);


    }

    componentDidMount() {
        const userStr = localStorage.getItem('user');
        if(userStr) {
            this.userObj = JSON.parse(userStr);
        }

        if(this.userObj && this.userObj.Type == 3){
            let formFields = this.state.formFields;
            formFields["Email"] = this.userObj.Username;
            this.setState({formFields: formFields, isEmailFieldDisabled:true});
        }
    }


    onEntered(){
        this.setState( {
            loading: false,
            formFields: {},
            selectedChannelType: 0,
            CodeMsBot: "",
        });
    }


    render() {
        let {loading} = this.state;
        let stateObj = {};

        if(this.userObj){
            let doInsertUser = 0;
            if(this.userObj.Type != 3 && this.userObj.Username != this.state.formFields.Email){
                // inserts a new user and sends him an email
                doInsertUser = 1;
            }
            stateObj = {"Name": this.state.formFields.Name, "Type": '1'
                , "CompanyToken": this.userObj.CompanyToken,"IdCompany": this.userObj.IdCompany
                , "Owner": this.state.formFields.Email, "doInsertUser": doInsertUser };
            //console.log(stateObj);
            this.slackButtonUrl = config.SLACK_REGISTER_WEBHOOK_URL + "&state=" + JSON.stringify(stateObj);
        }


        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                onEntered = {this.onEntered}
                onExit = {this.props.onExit}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header  className="add-vox-modal-header"  closeButton translate="yes">
                    <Modal.Title>
                        <Trans>trans_33_add_a_channel</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="add-vox-modal-content" >

                <Trans>trans_33_name</Trans>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Control size="sm"
                                  type="text"
                                  id="Name"
                                  placeholder="Name"
                                  name="Name"
                                  onChange={this.handleChange}
                                  style={{width:'250px',float: 'left'}}
                    />
                </Form>

                <br/>  <br/>

                <Trans>trans_33_channel_owner_email</Trans>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Control size="sm"
                                  type="text"
                                  id="Email"
                                  placeholder="Email"
                                  name="Email"
                                  defaultValue={this.state.formFields.Email}
                                  disabled={this.state.isEmailFieldDisabled}
                                  onChange={this.handleChange}
                                  style={{width:'250px',float: 'left'}}
                    />
                </Form>


                    <br/><br/><br/>

                    <Form.Control onChange={this.onChannelTypeSelected} size="sm" as="select" >
                        <option key='0' value='0'>{i18n.t("trans_33_select_a_channel_type")}</option>
                        <option key={1} value={1}>Slack</option>
                        <option key={2} value={2}>Ms Teams</option>
                    </Form.Control>


                    <br/>

                    {this.state.selectedChannelType == 1 &&
                        <>
                            <Trans>trans_33_modal_slack_link_follow_this_steps</Trans>
                            <br/> <br/>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-1 align-middle" style={{marginTop: 'auto', marginBottom: 'auto'}}><b>1.</b>
                                    </div>
                                    <div className="col-md-11">
                                    <a href={this.slackButtonUrl} target="_blank">
                                        <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack@2x.png"/>
                                    </a>

                                    </div>
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-md-1 align-middle" style={{marginTop: 'auto', marginBottom: 'auto'}}><b>2.</b>
                                    </div>
                                    <div className="col-md-11">
                                        <Trans>trans_33_modal_slack_select_a_chanel</Trans>
                                    </div>
                                </div>

                                <br/>

                                <div className="row">
                                    <div className="col-md-10" style={{marginTop: 'auto', marginBottom: 'auto'}}></div>
                                        <div className="col-md-2">
                                            <Button variant="primary" onClick={this.props.onHide}>
                                                <Trans>trans_33_done</Trans>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                        </>
                    }


                    {this.state.selectedChannelType == 2 &&
                        <>
                            <Form.Check
                                type="checkbox"
                                checked={this.state.formFields.IConfigureTheChannel}
                                onChange={this.handleIConfigureTheChannelCheckedChanged}
                                id="IConfigureTheChannel"
                                label={i18n.t("trans_33_i_configure_the_channel")}
                            />
                        {this.state.formFields.IConfigureTheChannel == 1 &&
                            <div>
                                <br/>
                                <Trans>trans_33_modal_teams_code_follow_this_steps</Trans>
                                <br/> <br/>
                                <div className="container">

                                    <div className="row">
                                        <ModalImage
                                            small={"/assets/tutorial_pulse.gif"}
                                            large={"/assets/tutorial_pulse.gif"}
                                            alt="Watch the video clip here"
                                        />
                                    </div>
                                    <br/>
                                    <div className="row">
                                        <div className="col-md-1 align-middle" style={{marginTop: 'auto', marginBottom: 'auto'}}><b>1.</b>
                                        </div>
                                        <div className="col-md-11">
                                            <Trans>trans_33_add_channel_msteamsbot_step1</Trans>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        <div className="col-md-1 align-middle" style={{marginTop: 'auto', marginBottom: 'auto'}}><b>2.</b>
                                        </div>
                                        <div className="col-md-11">
                                            <Trans>trans_33_add_channel_msteamsbot_step2</Trans>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        <div className="col-md-1 align-middle" style={{marginTop: 'auto', marginBottom: 'auto'}}><b>3.</b>
                                        </div>
                                        <div className="col-md-11">
                                            <Trans>trans_33_add_channel_msteamsbot_step3</Trans>&nbsp;
                                            <span style={{fontWeight: 'bold', fontSize: '18px'}}>{this.state.CodeMsBot}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            }

                            <br/>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-7" style={{marginTop: 'auto', marginBottom: 'auto'}}></div>
                                    <div className="col-md-5">
                                        <Button variant="secondary" onClick={this.props.onHide}>
                                            <Trans>trans_33_Cancel</Trans>
                                        </Button>
                                        &nbsp;&nbsp;
                                        <Button variant="primary" onClick={this.doFetchVoxMsTeamsById}>
                                            <Trans>trans_33_done</Trans>
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        </>
                    }


                    <br/>

                </Modal.Body>

            </Modal>
        );
    }


    handleIConfigureTheChannelCheckedChanged(event) {
        let formFields = this.state.formFields;
        formFields.IConfigureTheChannel = event.target.checked?1:0;
        this.setState({ formFields: formFields });
    }


    doFetchVoxMsTeamsById(){

        if(this.state.formFields.IConfigureTheChannel == 1){
            let IdVoxMsTeamsBot = this.state.IdVoxMsTeamsBot;
            return serverService.getMsTeamsBotById(IdVoxMsTeamsBot).then(
                res => {
                    // console.log(res.data);
                    if(res.data.length > 0){
                        toast.success(i18n.t("trans_33_msg_vox_successfully_assigned"));

                        if(this.state.formFields.Email != this.userObj.Username){
                            serverService.pulseInsertUserAndSendEmail(this.state.formFields.Email)
                                .then(result => {

                                }, error => {
                                    console.log(error);
                                });
                        }

                        this.props.onHide();
                    } else {
                        toast.warn(i18n.t("trans_33_msg_channel_not_assigned"));
                    }

                    return res;
                }
            );
        } else {
            if(this.state.formFields.Email != this.userObj.Username){
                serverService.pulseInsertUserAndSendEmail(this.state.formFields.Email)
                    .then(result => {
                        if(result.status == 1){
                            toast.success(i18n.t("trans_33_msg_email_sent"));
                            this.props.onHide();
                        } else if(result.status == 2){
                            toast.success(i18n.t("trans_33_msg_user_already_exists"));
                            this.props.onHide();
                        } else {
                            toast.warn(i18n.t("Failed to send email"));
                            console.log(result);
                        }

                    }, error => {
                        toast.warn(i18n.t("Failed to send email"));
                        console.log(error);
                    });
            }
        }
    }



    onChannelTypeSelected(event){
        let selectedChannelType = event.target.value;

        if(selectedChannelType == 2){
            serverService.insertDraftMsTeamsBot(this.state.formFields.Email, this.state.formFields.Name)
                .then(result => {
                    if(result['status'] == 1){
                        let IdVoxMsTeamsBot = result['data']['IdVoxMsTeamsBot'];
                        let code = result['data']['Code'];
                        this.setState({selectedChannelType: selectedChannelType, CodeMsBot: code, IdVoxMsTeamsBot: IdVoxMsTeamsBot});
                    }
                }, error => {
                    toast.warn("Issues while inserting draft channel");
                });
        } else {
            this.setState({selectedChannelType: selectedChannelType, CodeMsBot: ""});
        }


    }

    handleChange(e) {
        const {name, value} = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({formFields: formFields});
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({loading: true});

        // let CodeMsTeams = this.props.CodeMsBot;
        // let Email = this.state.formFields.Email;
        //
        // serverService.sendEmailWithMsTeamsInstructions(Email,CodeMsTeams)
        //     .then(result => {
        //         toast.success(result.message);
        //         this.setState({loading: false});
        //     }, error => {
        //         toast.warn("Issues while sending the email");
        //         this.setState({loading: false});
        //     });

    }
}

export default ModalAddNewChannel;