import React from 'react';

import { serverService } from '../../_services/server.service';
import { toast } from "react-toastify";
import { Row,Col } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";

import './ChangePasswordPage.css'
import i18n from "i18next";
import {Trans} from "react-i18next";

import sha1 from 'sha1' 

const qs = require('query-string');



interface MyProps {
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields:any,
}


class ChangePasswordPage extends React.Component<MyProps & RouteComponentProps,MyState> {
    constructor(props) {
        super(props);

        localStorage.removeItem('user');

        this.state = {
            submitted: false,
            loading: false,
            error: '',
            formFields:{}
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });

        const { formFields } = this.state;
        const password1 = formFields.password1;
        const password2 = formFields.password2;

        // stop here if form is invalid
        if (!(password1 && password2)) {
            toast.error(i18n.t("trans_33_missing_fields"));
            return;
        } else  if(password1 != password2){
            toast.error(i18n.t("trans_33_change_password_msg_passwords_do_not_match"));
            return;
        }


        let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (password1.length < 8
            || password1.toLowerCase() == password1
            || !format.test(password1)
        ) {
            toast.error(i18n.t("trans_33_msg_password_not_valid"));
            return;
        }



        let h = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).h;
        let IdCompany = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).IdCompany;
        let IdUser = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).IdUser;


        this.setState({ loading: true });

        serverService.updatePassword(IdCompany, IdUser, sha1(password1), h)
            .then(
                response => {
                    if(response.status == 0){
                        toast.warn(response.message);
                        this.setState({ loading: false })
                    } else {
                        toast.success(response.message);
                        this.setState({ loading: false });

                        setTimeout(function () {
                            window.location.href = "/";
                        }, 2000);

                    }
                },
                error => {
                    toast.error("Server error");
                    this.setState({ loading: false })
                }
            );
    }

    render() {
        const { formFields,submitted, loading, error } = this.state;
        const password1 = formFields.password1;
        const password2 = formFields.password2;

        return (
            <div className="col-md-6 col-md-offset-3 text-center mx-auto" id="loginContainer">
                <h1><Trans>trans_33_change_password_title</Trans></h1>
                <br/>
                <form name="form" className="text-center" onSubmit={this.handleSubmit}>
                    <div className={'form-group' + (submitted && !password1 ? ' has-error' : '')}>
                        <input type="password" className="form-control login-field" name="password1" value={password1}
                               placeholder={i18n.t("trans_33_Password")}
                               onChange={this.handleChange} />
                        {submitted && !password1 &&
                        <div className="help-block"><Trans>trans_33_change_password_msg_pass_mandatory</Trans></div>
                        }
                    </div>
                    <div className={'form-group' + (submitted && !password2 ? ' has-error' : '')}>
                        <input type="password" className="form-control login-field" name="password2" value={password2}
                               placeholder={i18n.t("trans_33_change_password_retype_password")} onChange={this.handleChange} />
                        {submitted && !password2 &&
                        <div className="help-block"><Trans>trans_33_change_password_msg_retype_password_mandatory</Trans></div>
                        }
                    </div>
                    <br/>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <button className="btn-login" disabled={loading}><Trans>trans_33_Submit</Trans></button>
                                {loading &&
                                <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                            </div>
                        </Col>
                    </Row>

                    {error &&
                    <div className={'alert alert-danger'}>{error}</div>
                    }
                </form>
            </div>
        );
    }
}

export { ChangePasswordPage };