/**
 * Created by Alex on 17-Oct-19.
 */
import React from 'react';

import { serverService } from '../../_services/server.service';

import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import {toast} from "react-toastify";
import i18n from "i18next";

interface MyProps {
}

interface MyState {
    options:any,
    selectedOptions:any,
}

class SelectDaysAutocomplete extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            options:['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'],
            selectedOptions:[],
        };

        this.fetchDays = this.fetchDays.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    componentDidMount(){
        this.fetchDays();
    }

    componentDidUpdate(prevProps, prevState) {
    }

    render() {
        return (
            <Typeahead
                multiple={true}
                id="selectDaysAct"
                options={this.state.options}
                selected={this.state.selectedOptions}
                onChange={this.handleOnChange}
                placeholder={i18n.t("trans_33_select_days_acp_placeholder")}
            />
        );
    }


    fetchDays() {
        let userStr = localStorage.getItem('user');
        let IdCompany = 0;
        if(userStr) {
            let userObj = JSON.parse(userStr);
            IdCompany = userObj.IdCompany;
        }

        serverService.getCompany(IdCompany)
            .then(
                result => {
                    let selectedDaysStr = result.data[0].SelectedDays;
                    let selectedDaysArr = selectedDaysStr.split(",");
                    if(result.status === "1"){
                        this.setState({
                            selectedOptions:selectedDaysArr,
                        });
                    }
                },
                error => {
                    toast.warn("Did not get company");
                }
            );
    }


    handleOnChange(selectedObjects:any) {
        let userStr = localStorage.getItem('user');
        let IdCompany = 0;
        if(userStr) {
            let userObj = JSON.parse(userStr);
            IdCompany = userObj.IdCompany;
        }

        let selectedDays = selectedObjects.join();
        let company = {'IdCompany':IdCompany.toString(),'SelectedDays':selectedDays};
        serverService.updateCompany(company)
            .then(
                result => {

                },
                error => {
                    toast.warn("Did not update company");
                }
            );
    }

}

export default SelectDaysAutocomplete;