import React from "react";
import moment from "moment";

import { serverService } from "../../_services/server.service";
import { Tab, Tabs, OverlayTrigger, Popover } from "react-bootstrap";
import ResultsSelfStarter from "./ResultsSelfStarter";

import ResultsEnterprise from "./ResultsEnterprise";
import ResultsStandard from "./ResultsStandard";
import { toast } from "react-toastify";
import ModalUpgradeLicense from "../_modals/ModalUpgradeLicense";

import PubSub from "../../_helpers/PubSub.js";
import { Trans } from "react-i18next";
import i18n from "i18next";

interface MyProps {}

interface MyState {
  license: any;
  modalUpgradeLicenseShow: boolean;
  activeTabKey: string;
  licenseForFeature: string;
}

const popoverCulturalAnalytics = (
  <Popover id="popover">
    <Popover.Content>
      <Trans>trans_33_info_results_cultural_analytics</Trans>
    </Popover.Content>
  </Popover>
);

const popoverLiveReporting = (
  <Popover id="popover">
    <Popover.Content>
      <Trans>trans_33_info_results_live_reporting</Trans>
    </Popover.Content>
  </Popover>
);

class ResultsPage extends React.Component<MyProps, MyState> {
  private userObj;

  constructor(props) {
    super(props);

    this.state = {
      license: {},
      modalUpgradeLicenseShow: false,
      activeTabKey: "self-starter",
      licenseForFeature: "",
    };

    this.modalUpgradeLicenseOpen = this.modalUpgradeLicenseOpen.bind(this);
    this.modalUpgradeLicenseClose = this.modalUpgradeLicenseClose.bind(this);
    this.onTabSelected = this.onTabSelected.bind(this);
  }

  componentWillMount() {
    this.userObj = JSON.parse(localStorage.getItem("user"));
  }

  componentDidMount() {
    PubSub.subscribe("updateLicense", (license) => {
      this.setState({ license: license });
    });
  }

  componentWillUnmount() {
    PubSub.unsubscribe("updateLicense");
  }

  render() {
    let { activeTabKey } = this.state;

    let LicenseType = 0;
    if (this.userObj && this.userObj.LicenseType) {
      LicenseType = this.userObj.LicenseType;
    }

    let selfStarterTitle = (
      <span>
        <Trans>trans_33_results_table_view</Trans> &nbsp;{" "}
        <img src="/assets/images/icon_lock24.png" />
      </span>
    );
    let standardTitle = (
      <span>
        <Trans>trans_33_results_cultural_analytics</Trans> &nbsp;{" "}
        <img src="/assets/images/icon_lock24.png" />
        &nbsp;
        <OverlayTrigger placement="top" overlay={popoverCulturalAnalytics}>
          <img style={{maxWidth:"20px"}} alt="help" src="/assets/images/info.png" />
        </OverlayTrigger>
      </span>
    );
    let enterpriseTitle = (
      <span>
        <Trans>trans_33_results_live_reporting</Trans> &nbsp;{" "}
        <img src="/assets/images/icon_lock24.png" />
        &nbsp;
        <OverlayTrigger placement="top" overlay={popoverLiveReporting}>
          <img style={{maxWidth:"20px"}}  alt="help" src="/assets/images/info.png" />
        </OverlayTrigger>
      </span>
    );

    if (LicenseType == 1) {
      selfStarterTitle = (
        <span>
          <Trans>trans_33_results_table_view</Trans>
        </span>
      );
    } else if (LicenseType == 2) {
      selfStarterTitle = (
        <span>
          <Trans>trans_33_results_table_view</Trans>
        </span>
      );
      standardTitle = (
        <span>
          <Trans>trans_33_results_cultural_analytics</Trans>
          &nbsp;
          <OverlayTrigger placement="top" overlay={popoverCulturalAnalytics}>
            <img style={{maxWidth:"20px"}} alt="help" src="/assets/images/info.png" />
          </OverlayTrigger>
        </span>
      );
    } else if (LicenseType == 3) {
      selfStarterTitle = (
        <span>
          <Trans>trans_33_results_table_view</Trans>
        </span>
      );
      standardTitle = (
        <span>
          <Trans>trans_33_results_cultural_analytics</Trans>
          &nbsp;
          <OverlayTrigger placement="top" overlay={popoverCulturalAnalytics}>
          <img style={{maxWidth:"20px"}} alt="help" src="/assets/images/info.png" />
          </OverlayTrigger>
        </span>
      );
      enterpriseTitle = (
        <span>
          <Trans>trans_33_results_live_reporting</Trans>
          &nbsp;
          <OverlayTrigger placement="top" overlay={popoverLiveReporting}>
          <img style={{maxWidth:"20px"}} alt="help" src="/assets/images/info.png" />
          </OverlayTrigger>
        </span>
      );
    }

    return (
      <div className="container mt-5 pt-5" id="resultsContainer">
        <Tabs
          defaultActiveKey="self-starter"
          id="packagesTabs"
          className="packagesTabs"
          onSelect={this.onTabSelected}
          activeKey={activeTabKey}
        >
          <Tab eventKey="self-starter" title={selfStarterTitle}>
            <ResultsSelfStarter selectedTabName={activeTabKey} />
          </Tab>
          <Tab eventKey="standard" title={standardTitle}>
            <ResultsStandard selectedTabName={activeTabKey} />
          </Tab>
          <Tab eventKey="enterprise" title={enterpriseTitle}>
            <ResultsEnterprise selectedTabName={activeTabKey} />
          </Tab>
        </Tabs>

        <ModalUpgradeLicense
          show={this.state.modalUpgradeLicenseShow}
          licenseForFeature={this.state.licenseForFeature}
          onHide={this.modalUpgradeLicenseClose}
          onCancel={this.modalUpgradeLicenseClose}
          onConfirm={this.modalUpgradeLicenseClose}
        />
      </div>
    );
  }

  onTabSelected(eventKey, event) {
    let LicenseType = 0;
    if (this.userObj && this.userObj.LicenseType) {
      LicenseType = this.userObj.LicenseType;
    }

    let navigateToTab = true;
    if (eventKey == "self-starter") {
      if (LicenseType != 1 && LicenseType != 2 && LicenseType != 3) {
        this.modalUpgradeLicenseOpen(i18n.t("trans_33_license_self_starter"));
        navigateToTab = false;
      }
    } else if (eventKey == "standard") {
      if (LicenseType != 2 && LicenseType != 3) {
        this.modalUpgradeLicenseOpen(i18n.t("trans_33_license_standard"));
        navigateToTab = false;
      }
    } else if (eventKey == "enterprise") {
      if (LicenseType != 3) {
        this.modalUpgradeLicenseOpen(i18n.t("trans_33_license_enterprise"));
        navigateToTab = false;
      }
    }

    if (navigateToTab) {
      this.setState({ activeTabKey: eventKey });
    }
  }

  modalUpgradeLicenseOpen(licenseForFeature) {
    this.setState({
      modalUpgradeLicenseShow: true,
      licenseForFeature: licenseForFeature,
    });
  }

  modalUpgradeLicenseClose() {
    this.setState({ modalUpgradeLicenseShow: false });
  }
}

export { ResultsPage };
