/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import './ModalOnboardingBeforeAddVox.css'
import {Trans} from "react-i18next";

interface MyProps {
    onConfirmAddNewVox(): void,
    show:boolean,
    onHide(): any,
}


class ModalOnboardingBeforeAddVox extends React.Component<MyProps> {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        <img src="/assets/images/onboarding/onboarding_welcome.png" id="welcome_img"/>
                        <span id="welcome_text">
                        <Trans>trans_33_modal_onboarding_before_adding_vox_title1</Trans>
                        <br/>
                        <Trans>trans_33_modal_onboarding_before_adding_vox_title2</Trans>
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   <Trans>trans_33_modal_onboarding_before_adding_vox_description1</Trans>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="success" onClick={this.props.onConfirmAddNewVox}>
                        <Trans>trans_33_modal_onboarding_before_adding_vox_btn</Trans>
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

}

export default ModalOnboardingBeforeAddVox;