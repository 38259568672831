import React from 'react';

import { serverService } from '../../_services/server.service';
import { toast } from "react-toastify";
import { Row,Col } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";

import i18n from "i18next";
import {Trans} from "react-i18next";

const qs = require('query-string');


interface MyProps {
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields:any,
}


class InputQuestionsPage extends React.Component<MyProps & RouteComponentProps,MyState> {
    constructor(props) {
        super(props);

        localStorage.removeItem('user');

        this.state = {
            submitted: false,
            loading: false,
            error: '',
            formFields:{}
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        let IdInputQuestion = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).IdInputQuestion;
        let h = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).h;

        if(IdInputQuestion && h){
            serverService.getInputQuestionsById(IdInputQuestion,h)
                .then(
                    response => {
                        if(response.status == 0){
                            toast.warn(response.message);
                            this.setState({ loading: false })
                        } else {
                            let formFields = this.state.formFields;
                            if(response.data.length > 0){
                                formFields['QuestionText'] = response.data[0].QuestionText;
                            }

                            this.setState({formFields: formFields, loading: false });
                        }
                    },
                    error => {
                        toast.error("Server error");
                        this.setState({ loading: false })
                    }
                );
        }


    }


    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });

        const { formFields } = this.state;
        const AnswerText = formFields.AnswerText;


        if (!AnswerText || !formFields.QuestionText || formFields.QuestionText.length == 0) {
            toast.error(i18n.t("trans_33_missing_fields"));
            return;
        }

        let IdInputQuestion = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).IdInputQuestion;
        let h = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).h;


        this.setState({ loading: true });


        serverService.insertInputQuestionAnswer(IdInputQuestion,AnswerText,h)
            .then(
                response => {
                    if(response.status == 1){
                        toast.success(i18n.t("trans_33_proposed_question_inserted"));
                        formFields.AnswerText = "";
                        this.setState({ loading: false,submitted:false, formFields:formFields });
                    } else {
                        toast.warn(response.message);
                        this.setState({ loading: false })
                    }
                },
                error => {
                    toast.error("Server error");
                    this.setState({ loading: false })
                }
            );
    }

    render() {
        const { formFields,submitted, loading, error } = this.state;
        const QuestionText = formFields.QuestionText;
        const AnswerText = formFields.AnswerText;

        return (
            <div>
                <img src="assets/images/logo.png" />

                <div className="text-center" style={{'paddingLeft':'16px','paddingRight':'16px'}}>
                    <br/>
                    <br/>
                    <br/>
                    <h3><Trans>{QuestionText}</Trans></h3>
                    <br/>
                    <form name="form" className="text-center" onSubmit={this.handleSubmit}>
                        <div className={'form-group' + (submitted && !AnswerText ? ' has-error' : '')}>
                        <input type="text" className="form-control login-field" name="AnswerText" value={AnswerText}
                                   placeholder={i18n.t("trans_33_proposed_question")}
                                   onChange={this.handleChange} />
                            {submitted && !AnswerText &&
                            <div className="help-block"><Trans>trans_33_answer_is_mandatory</Trans></div>
                            }
                        </div>
                        <br/>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <button className="btn btn-primary" style={{'fontSize':'24px'}} disabled={loading}><Trans>trans_33_Submit</Trans></button>
                                    {loading &&
                                    <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    }
                                </div>
                            </Col>
                        </Row>

                        {error &&
                        <div className={'alert alert-danger'}>{error}</div>
                        }
                    </form>
                </div>
            </div>
        );
    }
}

export { InputQuestionsPage };