/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { toast } from 'react-toastify';

import { serverService } from '../../_services/server.service';

import LocationsAutocomplete from '../_other/LocationsAutocomplete';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';

import ItemsAutocomplete from '../_other/ItemsAutocomplete';
import { array } from 'prop-types';
import ItemsSelect from '../_other/ItemsSelect';

import { config } from '../../_helpers/C';
import ModalMsTeamsBotCode from './ModalMsTeamsBotCode';
import { Trans } from 'react-i18next';
import i18n from 'i18next';
import LanguageSelect from "../_other/LanguageSelect";

import moment from 'moment'

let momentTz = require('moment-timezone');



interface MyProps {
  selectedVox: any;
  onVoxAdded(
    extra: string,
    CodeMsBot: string,
    VoxToken: string,
    IdVox: number
  ): any;
  show: boolean;
  onHide(): any;
  hasVoxesAlreadyAdded?: boolean;
  voxes: any;
  onShowMsTeamsBotCode?(CodeMsBot: string): any;
  onShowSlackLink?(VoxToken: string): any;
}

interface MyState {
  submitted: boolean;
  loading: boolean;
  error: string;
  formFields: any;
  isANewLocation: boolean;
  selectedVox: any;
  environments: any;
  numberOfUsers: any;
}

const popoverVoxName = (
  <Popover id='popover-vox-type'>
    <Popover.Title as='h3'>
      <Trans>trans_33_modal_add_vox_name_your_vox</Trans>
    </Popover.Title>
    <Popover.Content>
      <Trans>trans_33_modal_add_vox_info_name_your_vox</Trans>
    </Popover.Content>
  </Popover>
);

const popoverVoxLocation = (
  <Popover id='popover-vox-type'>
    <Popover.Title as='h3'>
      <Trans>trans_33_modal_add_vox_where_is_your_vox</Trans>
    </Popover.Title>
    <Popover.Content>
      <Trans>trans_33_modal_add_vox_info_where_is_your_vox</Trans>
    </Popover.Content>
  </Popover>
);

const popoverVoxType = (
  <Popover id='popover-vox-type'>
    <Popover.Title as='h3'>
      <Trans>trans_33_modal_add_vox_info_types_title</Trans>
    </Popover.Title>
    <Popover.Content>
      <Trans>trans_33_modal_add_vox_info_types_description</Trans>
    </Popover.Content>
  </Popover>
);

const popoverMsTeamsWebhook = (
  <Popover id='popover-slack-button'>
    <Popover.Title as='h3'>
      <Trans>trans_33_modal_add_vox_info_msteams_title</Trans>
    </Popover.Title>
    <Popover.Content>
      <Trans>trans_33_modal_add_vox_info_msteams_description</Trans>
    </Popover.Content>
  </Popover>
);

const popoverPhyscialVoxCode = (
  <Popover id='popover-slack-button'>
    <Popover.Title as='h3'>
      <Trans>trans_33_modal_add_vox_info_msteams_code_title</Trans>
    </Popover.Title>
    <Popover.Content>
      <Trans>trans_33_modal_add_vox_info_msteams_code_description</Trans>
    </Popover.Content>
  </Popover>
);

class ModalAddNewVox extends React.Component<MyProps, MyState> {
  private intervalTimer; 
  private userObj;
  private timezonesObj;

  private isEdit;

  constructor(props) {
    super(props);

    this.isEdit = false;

    let environmentsObj = i18n.t('trans_33_environments', {
      returnObjects: true,
    });
    let numberOfUsersObj = i18n.t('trans_33_number_of_users', {
      returnObjects: true,
    });

    this.timezonesObj = i18n.t('trans_33_timezones', {
      returnObjects: true,
    });

    this.state = {
      submitted: false,
      loading: false,
      error: '',
      formFields: {},
      isANewLocation: true,
      selectedVox: {},
      environments: environmentsObj,
      numberOfUsers: numberOfUsersObj,
    };



    this.handleChange = this.handleChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.onTypeSelected = this.onTypeSelected.bind(this);
    this.handleQuestionHourChange = this.handleQuestionHourChange.bind(this);
    this.handleReportHourChange = this.handleReportHourChange.bind(this);
    this.handleReminderHourChange = this.handleReminderHourChange.bind(this);
    this.handleSendReportWithQuestionChange = this.handleSendReportWithQuestionChange.bind(
      this
    );
    this.handleSendReminderWithQuestionChange = this.handleSendReminderWithQuestionChange.bind(
        this
    );

    this.handleDisplayReportForAllVoxesChange = this.handleDisplayReportForAllVoxesChange.bind(
      this
    );

    this.doFetchVoxSlackList = this.doFetchVoxSlackList.bind(this);
    this.doFetchVoxSlackListInterval = this.doFetchVoxSlackListInterval.bind(
      this
    );
    this.onEntered = this.onEntered.bind(this);
    this.onExit = this.onExit.bind(this);

    this.onIdVoxSlackSelected = this.onIdVoxSlackSelected.bind(this);
    this.onShowMsTeamsBotCode = this.onShowMsTeamsBotCode.bind(this);
    this.onShowSlackLink = this.onShowSlackLink.bind(this);

    this.onEnvironmentSelected = this.onEnvironmentSelected.bind(this);
    this.onNumberOfUsersSelected = this.onNumberOfUsersSelected.bind(this);
    this.onTimezoneSelected = this.onTimezoneSelected.bind(this);

    this.onLanguageSelected = this.onLanguageSelected.bind(this);


  }

  componentDidMount() {
    const userStr = localStorage.getItem('user');
    if (userStr) {
      this.userObj = JSON.parse(userStr);
    }
  }

  onEntered() {
    this.doFetchVoxSlackList();
  }

  onExit() {
    if (this.intervalTimer != null) {
      clearInterval(this.intervalTimer);
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    let formFields = this.state.formFields;
    formFields[name] = value;
    this.setState({ formFields: formFields });
  }

  handleLocationChange(locationString, isANewLocation) {
    let formFields = this.state.formFields;
    formFields.Location = locationString;
    this.setState({ formFields: formFields, isANewLocation: isANewLocation });
  }

  handleQuestionHourChange(date) {
    let formFields = this.state.formFields;
    formFields['QuestionHour'] = date;

    let questionHourDate = new Date(formFields['QuestionHour']);
    let reminderHourDate = new Date(formFields['ReminderHour']);


    questionHourDate.setDate(1);
    questionHourDate.setMonth(1);
    questionHourDate.setFullYear(1977);

    reminderHourDate.setDate(1);
    reminderHourDate.setMonth(1);
    reminderHourDate.setFullYear(1977);


    if(questionHourDate > reminderHourDate){
      formFields['ReminderHour'] = questionHourDate;
    }

    this.setState({ formFields: formFields });
  }

  handleReportHourChange(date) {
    let formFields = this.state.formFields;
    formFields['ReportHour'] = date;
    this.setState({ formFields: formFields });
  }

  handleReminderHourChange(date) {
    let formFields = this.state.formFields;

    let questionHourDate = new Date(formFields['QuestionHour']);
    let reminderHourDate = new Date(date.getTime());


    questionHourDate.setDate(1);
    questionHourDate.setMonth(1);
    questionHourDate.setFullYear(1977);

    reminderHourDate.setDate(1);
    reminderHourDate.setMonth(1);
    reminderHourDate.setFullYear(1977);


    if(questionHourDate > reminderHourDate){
      date = questionHourDate;
    }

    formFields['ReminderHour'] = date;
    this.setState({ formFields: formFields });
  }


  handleSendReportWithQuestionChange(event) {
    let formFields = this.state.formFields;
    formFields['SendReportWithQuestion'] = event.target.checked ? 1 : 0;
    this.setState({ formFields: formFields });
  }

  handleSendReminderWithQuestionChange(event) {
    let formFields = this.state.formFields;
    formFields['SendReminderWithQuestion'] = event.target.checked ? 1 : 0;
    this.setState({ formFields: formFields });
  }


  handleDisplayReportForAllVoxesChange(event) {
    let formFields = this.state.formFields;
    formFields['DisplayReportForAllVoxes'] = event.target.checked ? 1 : 0;
    this.setState({ formFields: formFields });
  }

  onTypeSelected(event) {
    let selectedVoxType = event.target.value;
    let formFields = this.state.formFields;
    formFields['Type'] = selectedVoxType;

    if(selectedVoxType == 1 || selectedVoxType == 3){
      formFields['SendReportWithQuestion'] = 0;
    }

    if(selectedVoxType != 3){
      formFields['SendReminderWithQuestion'] = 1;
    }


    this.setState({ formFields: formFields });
  }

  onIdVoxSlackSelected(IdVoxSlack) {
    let formFields = this.state.formFields;
    formFields['selectedIdVoxSlack'] = IdVoxSlack;
    this.setState({ formFields: formFields });
  }

  handleSubmit(e) {
    e.preventDefault();


    const { formFields } = this.state;
    const { voxes } = this.props;

    const Name = formFields.Name;
    const Location = formFields.Location;
    const Type = formFields.Type;
    const IdEnvironment = formFields.IdEnvironment;
    const IdNumberOfUsers = formFields.IdNumberOfUsers;
    let IdLanguage = null;
    if(formFields.IdLanguage !== "0"){
      IdLanguage = formFields.IdLanguage;
    }


    const selectedIdVoxSlack = formFields.selectedIdVoxSlack;
    const MsTeamsWebhookUrl = formFields.MsTeamsWebhookUrl;
    const PhysicalVoxCode = formFields.PhysicalVoxCode;

    const QuestionHour = moment(formFields.QuestionHour).format(
      'YYYY-MM-DD HH:mm:ss'
    );
    const ReportHour = moment(formFields.ReportHour).format(
      'YYYY-MM-DD HH:mm:ss'
    );
    const ReminderHour = moment(formFields.ReminderHour).format(
        'YYYY-MM-DD HH:mm:ss'
    );

    const SendReportWithQuestion = formFields.SendReportWithQuestion;
    const SendReminderWithQuestion = formFields.SendReminderWithQuestion;
    const DisplayReportForAllVoxes = formFields.DisplayReportForAllVoxes;

    const CodeMsBot = formFields.CodeMsBot;

    if (
      !Name ||
      !Location ||
      Type <= 0 ||
      IdEnvironment == 0 ||
      IdNumberOfUsers == 0
    ) {
      toast.error('Missing fields');
      return;
    }

    let currentVirtualVoxNum = 0;
    let currentPhysicalVoxNum = 0;
    for (let i = 0; i < voxes.length; i++) {
      if (voxes[i]['Status'] > 0) {
        if (
          voxes[i]['Type'] == 2 ||
          voxes[i]['Type'] == 4 ||
          voxes[i]['Type'] == 5
        ) {
          currentVirtualVoxNum++;
        } else if (voxes[i]['Type'] == 1 || voxes[i]['Type'] == 3) {
          currentPhysicalVoxNum++;
        }
      }
    }

    if (!this.isEdit) {
      if (
        (Type == 2 || Type == 4 || Type == 5) &&
        this.userObj.VirtualVoxNum <= currentVirtualVoxNum
      ) {
        //toast.error("The license allows a maximum of " + this.userObj.VirtualVoxNum + " voxes. Please go to Settings to upgrade the license");
        toast.error(
          i18n.t('trans_33_modal_add_vox_msg_max_vox_reached', {
            VitualVoxNum: this.userObj.VirtualVoxNum,
          })
        );

        return;
      } else if (
        (Type == 3) &&
        this.userObj.PendingActivationVoxNum <= currentPhysicalVoxNum
      ) {
        toast.error(
          i18n.t('trans_33_modal_add_vox_msg_vox_pending_activation', {
            PendingActivationVoxNum: this.userObj.PendingActivationVoxNum,
          })
        );
        return;
      }
    }

    if (Type == 4 && (!MsTeamsWebhookUrl || MsTeamsWebhookUrl.length == 0)) {
      toast.error(i18n.t('trans_33_modal_add_vox_msg_no_webhook'));
      return;
    } else if (Type == 4 && !MsTeamsWebhookUrl.includes('office.com/webhook')) {
      toast.error(i18n.t('trans_33_modal_add_vox_msg_link_not_correct'));
      return;
    } else if (
      !this.isEdit &&
      (Type == 1 || Type == 3) &&
      (!PhysicalVoxCode || PhysicalVoxCode.length == 0)
    ) {
      toast.error(
        i18n.t('trans_33_modal_add_vox_msg_need_to_type_in_vox_code')
      );
      return;
    }

    let IdCompany = 0;
    let Timezone = '';

    if (this.userObj) {
      IdCompany = this.userObj.IdCompany;
      Timezone = this.userObj.Timezone;
    }
    if(this.state.formFields.Timezone.length > 0 ){
      Timezone = this.state.formFields.Timezone
    }

    let location = { IdCompany: IdCompany.toString(), Name: Location };

    this.setState({ submitted: true, loading: true });

    serverService.insertLocation(location).then(
      result => {
        let vox = {
          IdVox: 0,
          IdCompany: IdCompany.toString(),
          Name: Name,
          IdLocation: result.data.IdLocation.toString(),
          Type: Type.toString(),
          IdEnvironment: IdEnvironment.toString(),
          IdNumberOfUsers: IdNumberOfUsers.toString(),
          IdLanguage: IdLanguage,
        };

        vox['QuestionHour'] = momentTz
          .tz(QuestionHour, Timezone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss');
        vox['ReportHour'] = momentTz
          .tz(ReportHour, Timezone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss');
        vox['ReminderHour'] = momentTz
            .tz(ReminderHour, Timezone)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');

        vox['SendReportWithQuestion'] = SendReportWithQuestion.toString();
        vox['SendReminderWithQuestion'] = SendReminderWithQuestion.toString();
        vox['DisplayReportForAllVoxes'] = DisplayReportForAllVoxes.toString();

        let voxSlack = {};
        if (Type == 2) {
          voxSlack['IdVoxSlack'] = selectedIdVoxSlack;
        }

        let voxMsTeamsWebhook = {};
        if (Type == 4) {
          voxMsTeamsWebhook['WebhookUrl'] = MsTeamsWebhookUrl;
        }

        let voxMsTeamsBot = {};
        if (Type == 5) {
          voxMsTeamsBot['Code'] = CodeMsBot;
        }

        let voxPhysical = {};
        if (Type == 1 || Type == 3) {
          voxPhysical['Code'] = PhysicalVoxCode;
        }

        if (this.isEdit) {
          vox.IdVox = this.state.selectedVox.IdVox;

          serverService
            .updateVox(vox, voxSlack, voxMsTeamsWebhook, voxMsTeamsBot)
            .then(
              result => {
                this.setState({ submitted: false, loading: false });
                if (result.status === '1') {
                  let messageStr = i18n.t(
                    'trans_33_modal_add_vox_msg_vox_edited'
                  );
                  toast.success(messageStr);
                  this.props.onVoxAdded(result.data, '', '', 0);
                } else {
                  toast.error('Add vox failed');
                }
              },
              error => {
                toast.warn('Did not update vox');
                this.setState({ loading: false });
              }
            );
        } else {
          serverService
            .insertVox(
              vox,
              voxSlack,
              voxMsTeamsWebhook,
              voxMsTeamsBot,
              voxPhysical
            )
            .then(
              result => {
                this.setState({ submitted: false, loading: false });
                if (result.status === '1') {
                  let messageStr = i18n.t(
                    'trans_33_modal_add_vox_msg_vox_added'
                  );
                  toast.success(messageStr);

                  let codeMsBotToPass = '';
                  if (Type == 5) {
                    codeMsBotToPass = formFields.CodeMsBot;
                  }

                  //console.log(result);

                  let voxTokenToPass = '';
                  if (Type == 2 && result.data.VoxToken) {
                    voxTokenToPass = result.data.VoxToken;
                  }
                  this.props.onVoxAdded(
                    result.data,
                    codeMsBotToPass,
                    voxTokenToPass,
                    result.data.IdVox
                  );
                } else {
                  toast.error('Add vox failed ' + result.message);
                }
              },
              error => {
                toast.warn('Did not insert vox');
                this.setState({ loading: false });
              }
            );
        }
      },
      error => {
        toast.warn('Did not insert location');
        this.setState({ loading: false });
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedVox !== prevState.selectedVox) {
      let formFields = this.state.formFields;
      formFields = {};
      formFields.CodeMsBot = Math.random().toString(36).substring(7);
      formFields.SendReportWithQuestion = 1;
      formFields.SendReminderWithQuestion = 1;
      formFields.DisplayReportForAllVoxes = 0;
      formFields.Name = '';
      formFields.Type = 0;
      formFields.IdEnvironment = 0;
      formFields.IdNumberOfUsers = 0;
      formFields.Timezone = this.userObj.Timezone;


      let questionHourDate = new Date();
      questionHourDate.setHours(10);
      questionHourDate.setMinutes(0);
      formFields.QuestionHour = questionHourDate;

      let reportHourDate = new Date();
      reportHourDate.setHours(16);
      reportHourDate.setMinutes(0);
      formFields.ReportHour = reportHourDate;

      let reminderHourDate = new Date();
      reminderHourDate.setHours(10);
      reminderHourDate.setMinutes(0);
      formFields.ReminderHour = reminderHourDate;

      // console.log(this.props.selectedVox);
      // console.log(formFields);

      if (
        this.props.selectedVox &&
        typeof this.props.selectedVox.IdVox !== 'undefined'
      ) {
        formFields.Name = this.props.selectedVox.Name;
        formFields.Location = this.props.selectedVox.Location;
        formFields.Type = this.props.selectedVox.Type;

        formFields.IdEnvironment = this.props.selectedVox.IdEnvironment;
        formFields.IdNumberOfUsers = this.props.selectedVox.IdNumberOfUsers;

        if (
          this.props.selectedVox.QuestionHour != undefined ||
          this.props.selectedVox.QuestionHour != null
        ) {
          formFields.QuestionHour = Date.parse(
            this.props.selectedVox.QuestionHour
          );
        }

        if (
          this.props.selectedVox.SendReportWithQuestion != undefined ||
          this.props.selectedVox.SendReportWithQuestion != null
        ) {
          formFields.SendReportWithQuestion = this.props.selectedVox.SendReportWithQuestion;
        }

        if (
            this.props.selectedVox.SendReminderWithQuestion != undefined ||
            this.props.selectedVox.SendReminderWithQuestion != null
        ) {
          formFields.SendReminderWithQuestion = this.props.selectedVox.SendReminderWithQuestion;
        }


        if (
          this.props.selectedVox.DisplayReportForAllVoxes != undefined ||
          this.props.selectedVox.DisplayReportForAllVoxes != null
        ) {
          formFields.DisplayReportForAllVoxes = this.props.selectedVox.DisplayReportForAllVoxes;
        }

        if (
          this.props.selectedVox.ReportHour != undefined ||
          this.props.selectedVox.ReportHour != null
        ) {
          formFields.ReportHour = Date.parse(this.props.selectedVox.ReportHour);
        }

        if (
            this.props.selectedVox.ReminderHour != undefined ||
            this.props.selectedVox.ReminderHour != null
        ) {
          formFields.ReminderHour = Date.parse(this.props.selectedVox.ReminderHour);
        } else {
          formFields.ReminderHour = formFields.QuestionHour;
        }

        if (
          this.props.selectedVox.CodeMsBot != undefined ||
          this.props.selectedVox.CodeMsBot != null
        ) {
          formFields.CodeMsBot = this.props.selectedVox.CodeMsBot;
        }

        if (this.props.selectedVox.Channel != undefined) {
          formFields.VoxSlackList = [
            {
              Name: this.props.selectedVox.Channel,
              IdVoxSlack: this.props.selectedVox.IdVoxSlack,
              IdItem: this.props.selectedVox.IdVoxSlack,
            },
          ];
          formFields.selectedIdVoxSlack = this.props.selectedVox.IdVoxSlack;
        }

        if (this.props.selectedVox.MsTeamsWebhookUrl != undefined) {
          formFields.MsTeamsWebhookUrl = this.props.selectedVox.MsTeamsWebhookUrl;
        }
      }
      this.setState({
        selectedVox: this.props.selectedVox,
        formFields: formFields,
      }, () => {

        if (
          this.state.selectedVox &&
          typeof this.state.selectedVox.IdVox !== 'undefined' &&
          this.state.selectedVox.IdVox > 0
        ) {
          this.isEdit = true;
        } else {
          this.isEdit = false;
        }


      });
    }
  }

  render() {
    let { loading, formFields, environments, numberOfUsers } = this.state;

    let titleStr = i18n.t('trans_33_modal_add_vox_add_vox');
    if(this.isEdit){
      titleStr = i18n.t('trans_33_modal_add_vox_edit_vox');
    }

    let IdCompany = 0;
    let CompanyTimezone = '';
    let CompanyToken = '';
    let IdLanguage = 0;

    if (this.userObj) {
      CompanyTimezone = this.userObj.Timezone;
      CompanyToken = this.userObj.CompanyToken;
    }

    let ChannelMsBot = this.props.selectedVox.ChannelMsBot;
    let ChannelSlack = this.props.selectedVox.Channel;
    if(this.props.selectedVox.IdLanguage){
      IdLanguage = this.props.selectedVox.IdLanguage
    }


    //let slackButtonUrl = config.SLACK_REGISTER_WEBHOOK_URL + "&state=" + CompanyToken;

    let reportHourPickerLabel = i18n.t(
      'trans_33_modal_add_vox_hour_send_report',
      { CompanyTimezone: CompanyTimezone }
    );


    let reminderHourPickerLabel = i18n.t(
        'trans_33_modal_add_vox_hour_send_reminder',
        { CompanyTimezone: CompanyTimezone }
    );

    // console.log(this.userObj);

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEntered={this.onEntered}
        onExit={this.onExit}
        dialogClassName='modal-90w'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header className="add-vox-modal-header"  closeButton translate='yes'>
          <Modal.Title id='example-custom-modal-styling-title'>
            {titleStr}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-vox-modal-content" >
          <Form onSubmit={this.handleSubmit}>
            <Form.Group  controlId='formBasicName'>
              <Form.Label>
                <Trans>trans_33_modal_add_vox_name_your_vox</Trans>
              </Form.Label>
              &nbsp;
              <OverlayTrigger placement='top' overlay={popoverVoxName}>
                <img style={{maxWidth:"20px"}} alt='help' src='/assets/images/info.png' />
              </OverlayTrigger>
              <Form.Control
                size='sm'
                type='text'
                name='Name'
                value={formFields.Name}
                onChange={this.handleChange}
                placeholder="Enter Your Vox"
              />
            </Form.Group>

            <Form.Group controlId='formBasicName'>
              <Form.Label>
                <Trans>trans_33_modal_add_vox_where_is_your_vox</Trans>
              </Form.Label>
              &nbsp;
              <OverlayTrigger 
              
              placement='top' overlay={popoverVoxLocation}>
<img style={{maxWidth:"20px"}} alt='help' src='/assets/images/info.png' />
              </OverlayTrigger>
              <LocationsAutocomplete
                selectedLocation={formFields.Location}
                handleLocationChange={this.handleLocationChange}
              />
            </Form.Group>

            <Form.Group controlId='formType'>
              <Form.Label>
                <Trans>trans_33_modal_add_vox_which_type_is_your_vox</Trans>
                &nbsp;
                <OverlayTrigger placement='top' overlay={popoverVoxType}>
                <img style={{maxWidth:"20px"}} alt='help' src='/assets/images/info.png' />
                </OverlayTrigger>
              </Form.Label>

              <Form.Control
                disabled={this.isEdit}
                onChange={this.onTypeSelected}
                size='sm'
                as='select'
                value={formFields.Type}
              >
                <option key='0' value='0'>
                  {i18n.t('trans_33_select_type')}
                </option>

                {this.userObj &&
                  (this.userObj.PhysicalVoxNum > 0 ||
                    this.userObj.PendingActivationVoxNum > 0) && (
                    <option key='1' value='1'>
                      {i18n.t('trans_33_Cardboard')}
                    </option>
                  )}

                {this.userObj && this.userObj.VirtualVoxNum > 0 && (
                  <option key='2' value='2'>
                    {i18n.t('trans_33_Slack')}
                  </option>
                )}

                {this.userObj &&
                  (this.userObj.PhysicalVoxNum > 0 ||
                    this.userObj.PendingActivationVoxNum > 0) && (
                    <option key='3' value='3'>
                      {i18n.t('trans_33_Wooden')}
                    </option>
                  )}

                {/*{this.userObj && this.userObj.VirtualVoxNum > 0 && (*/}
                  {/*<option key='4' value='4'>*/}
                    {/*{i18n.t('trans_33_teams_webhook')}*/}
                  {/*</option>*/}
                {/*)}*/}

                <option key='5' value='5'>
                  {i18n.t('trans_33_teams_bot')}
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId='formEnvironment'>
              <Form.Label>
                *<Trans>trans_33_select_vox_environment</Trans>
              </Form.Label>

              <ItemsSelect
                selectedIdItem={formFields.IdEnvironment}
                items={environments}
                onItemSelected={this.onEnvironmentSelected}
                defaultText={i18n.t('trans_33_select_vox_environment')}
              />
            </Form.Group>

            <Form.Group controlId='formEstimatedNumOfUsers'>
              <Form.Label>
                *<Trans>trans_33_select_vox_number_of_users</Trans>
              </Form.Label>

              <ItemsSelect
                selectedIdItem={formFields.IdNumberOfUsers}
                items={numberOfUsers}
                onItemSelected={this.onNumberOfUsersSelected}
                defaultText={i18n.t('trans_33_select_vox_number_of_users')}
              />
            </Form.Group>
            <div className="vox-date-picker" >
              <div className='container'>
                <div className='row align-items-end'>
                  <div className='col-md6'>
                    <span><Trans>trans_33_modal_add_vox_hour_send_question</Trans></span>
                  </div>
                  &nbsp;&nbsp;
                  <div className='col-md6'>
                    <ItemsSelect
                        items={this.timezonesObj}
                        selectedIdItem={this.state.formFields.Timezone}
                        onItemSelected={this.onTimezoneSelected}
                        defaultText={"Select your timezone"}
                    />
                  </div>
                </div>
              </div>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                ampm={false}
                style={{ width: '100%' }}
                margin='normal'
                id='time-picker'
                value={formFields.QuestionHour}
                onChange={this.handleQuestionHourChange}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
              />
            </MuiPickersUtilsProvider>

              {!(formFields.Type == 1 || formFields.Type == 3) &&
                <Form.Check
                    type='checkbox'
                    id='sendSendReportWithQuestionChb'
                    style={{marginTop: "15px"}}
                    label={i18n.t(
                        'trans_33_modal_add_vox_send_report_same_time_question'
                    )}
                    checked={formFields.SendReportWithQuestion}
                    onChange={this.handleSendReportWithQuestionChange}
                />
              }

            </div>
            {!formFields.SendReportWithQuestion && (
                <div className="vox-date-picker" >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                        ampm={false}
                        style={{ width: '100%' }}
                        margin='normal'
                        id='time-picker'
                        label={reportHourPickerLabel}
                        value={formFields.ReportHour}
                        onChange={this.handleReportHourChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
            )}


            {formFields.Type == 5 &&
              <div className="vox-date-picker" style={{marginTop: "15px"}}>
                <Form.Check
                    type='checkbox'
                    id='sendSendReminderWithQuestionChb'
                    label={i18n.t(
                        'trans_33_modal_add_vox_send_reminder_same_time_question'
                    )}
                    checked={formFields.SendReminderWithQuestion}
                    onChange={this.handleSendReminderWithQuestionChange}
                />
              </div>
            }

            {formFields.Type == 5 && !formFields.SendReminderWithQuestion && (
                <div className="vox-date-picker" >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                        ampm={false}
                        style={{ width: '100%' }}
                        margin='normal'
                        id='time-picker'
                        label={reminderHourPickerLabel}
                        value={formFields.ReminderHour}
                        onChange={this.handleReminderHourChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
            )}


            {formFields.Type == 2 &&
              !ChannelSlack &&
              typeof this.state.selectedVox.IdVox !== 'undefined' && (
                <div style={{color:"#1faaec" , textDecoration:"underline" , cursor:"pointer"}} className='text-center'>
                  <br />
                  <br />

                  <span
                      onClick={this.onShowSlackLink}
                  >
                    <span>
                      <Trans>trans_33_modal_add_vox_not_linked</Trans>
                    </span>
                    <br />
                     <span
                         style={{ fontSize: '12px' }}
                         onClick={this.onShowSlackLink}
                     >
                        <Trans>trans_33_modal_add_vox_show_instructions</Trans>
                     </span>
                  </span>
                  <br />
                </div>
              )}

            {formFields.Type == 2 &&
              !ChannelSlack &&
              !(this.isEdit) && (
                <div style={{color:"#1faaec" , textDecoration:"underline" , cursor:"pointer"}} className='text-center'>
                  <br />
                  <br />
                  <span>
                    <Trans>trans_33_modal_add_vox_not_linked</Trans>
                  </span>
                  <br />
                  <span style={{ fontSize: '12px' }}>
                    <Trans>trans_33_modal_add_vox_after_finishing_slack</Trans>
                  </span>
                  <br />
                </div>
              )}

            {formFields.Type == 2 && ChannelSlack && (
              <div style={{color:"#1faaec" , textDecoration:"underline" , cursor:"pointer"}} onClick={this.onShowSlackLink} className='text-center'>
                <br />
                <span>
                  <Trans>trans_33_modal_add_vox_linked</Trans>
                  <b>{ChannelSlack}</b>
                </span>
              </div>
            )}

            {!this.isEdit && (formFields.Type == 1 || formFields.Type == 3) && (
              <div>
                <br />
                <Form.Group controlId='formMsTeamsWebhook'>
                  <Form.Label>
                    <Trans>trans_33_modal_add_vox_code</Trans> &nbsp;
                    <OverlayTrigger
                      placement='top'
                      overlay={popoverPhyscialVoxCode}
                    >
                    <img style={{maxWidth:"20px"}} alt='help' src='/assets/images/info.png' />
                    </OverlayTrigger>
                  </Form.Label>

                  <Form.Control
                    size='sm'
                    type='text'
                    name='PhysicalVoxCode'
                    value={formFields.PhysicalVoxCode}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </div>
            )}

            {formFields.Type == 4 && (
              <div>
                <br />
                <br />
                <Form.Group controlId='formMsTeamsWebhook'>
                  <Form.Label>
                    <Trans>trans_33_modal_add_webhook_url</Trans> &nbsp;
                    <OverlayTrigger
                      placement='top'
                      overlay={popoverMsTeamsWebhook}
                    >
<img style={{maxWidth:"20px"}} alt='help' src='/assets/images/info.png' />
                    </OverlayTrigger>
                  </Form.Label>

                  <Form.Control
                    size='sm'
                    type='text'
                    name='MsTeamsWebhookUrl'
                    value={formFields.MsTeamsWebhookUrl}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </div>
            )}

            {formFields.Type == 5 &&
              !ChannelMsBot &&
              this.isEdit && (
                <div style={{color:"#1faaec" , textDecoration:"underline" , cursor:"pointer"}} className='text-center'>
                  <br />
                  <br />
                  <span
                      style={{ fontSize: '16px' }}
                      onClick={this.onShowMsTeamsBotCode}
                  >

                    <Trans>trans_33_modal_add_vox_not_linked</Trans>

                    <br />

                    <Trans>trans_33_modal_add_vox_show_instructions</Trans>
                  </span>
                  <br />
                </div>
              )}

            {formFields.Type == 5 &&
              !ChannelMsBot &&
              !(this.isEdit) && (
                <div style={{color:"#1faaec" , textDecoration:"underline" , cursor:"pointer"}} className='text-center'>
                  <br />
                  <br />
                  <span>
                    <Trans>trans_33_modal_add_vox_not_linked</Trans>
                  </span>
                  <br />
                  <span style={{ fontSize: '12px' }}>
                    <Trans>
                      trans_33_modal_add_vox_after_finishing_receive_code_teams
                    </Trans>
                  </span>
                  <br />
                </div>
              )}

            {formFields.Type == 5 && ChannelMsBot && (
              <div style={{color:"#1faaec" , textDecoration:"underline" , cursor:"pointer"}} onClick={this.onShowMsTeamsBotCode} className='text-center'>
                <br />
                <span>
                  <Trans>trans_33_modal_add_vox_linked</Trans>
                  <b>{this.props.selectedVox.ChannelMsBot}</b>
                </span>
              </div>
            )}

            <br />

            {this.props.hasVoxesAlreadyAdded && (
                            <div className="vox-date-picker" >
              <Form.Check
                type='checkbox'
                id='displayReportForAllVoxes'
                label={i18n.t(
                  'trans_33_modal_add_vox_displaying_results_only_this_vox'
                )}
                checked={formFields.DisplayReportForAllVoxes}
                onChange={this.handleDisplayReportForAllVoxesChange}
              />
              </div>
            )}

            <br />

            <LanguageSelect
                selectedIdLanguage={IdLanguage}
                onLanguageSelected={this.onLanguageSelected}
                defaultText={i18n.t("trans_33_select_language")}
            />

            <br />

            <Button className="btn btn-block"  variant='primary' type='submit' disabled={loading}>
              {titleStr}
            </Button>
            {loading && (
              <img
                alt='loading'
                src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
              />
            )}
          </Form>
        </Modal.Body>
        <br />
      </Modal>
    );
  }


  onLanguageSelected(IdLanguage) {
    let formFields = this.state.formFields;
    formFields['IdLanguage'] = IdLanguage;
    this.setState({ formFields: formFields });
  }

  doFetchVoxSlackListInterval() {
    let thisPointer = this;
    if (this.intervalTimer != undefined) {
      clearInterval(thisPointer.intervalTimer);
    }
    this.intervalTimer = setInterval(function () {
      thisPointer.doFetchVoxSlackList().then(
        val => {
          if (val.data.length > 0) {
            clearInterval(thisPointer.intervalTimer);
          }
        },
        error => {
          clearInterval(thisPointer.intervalTimer);
          toast.warn('Did not get the vox');
        }
      );
    }, 3000);
  }

  onShowMsTeamsBotCode() {
    let codeMsBotToPass = this.state.formFields.CodeMsBot;
    this.props.onShowMsTeamsBotCode(codeMsBotToPass);
  }

  onShowSlackLink() {
    let VoxToken = this.state.selectedVox.VoxToken;
    this.props.onShowSlackLink(VoxToken);
  }

  onEnvironmentSelected(IdEnvironment) {
    let formFields = this.state.formFields;
    formFields['IdEnvironment'] = IdEnvironment;
    this.setState({ formFields: formFields });
  }

  onNumberOfUsersSelected(IdNumberOfUsers) {
    let formFields = this.state.formFields;
    formFields['IdNumberOfUsers'] = IdNumberOfUsers;
    this.setState({ formFields: formFields });
  }

  onTimezoneSelected(Timezone) {
    let formFields = this.state.formFields;
    formFields['Timezone'] = Timezone;
    this.setState({ formFields: formFields });


    serverService.updateTimezoneAndLanguage(Timezone,this.userObj.IdLanguage)
        .then(
            (response) => {
              if(response.status == 1){
                toast.success("Timezone updated");
                this.userObj.Timezone = Timezone;
                localStorage.setItem("user", JSON.stringify(this.userObj));
              }
            },
            (error) => {
              console.log(error);
              toast.warn(i18n.t("trans_33_msg_something_went_wrong"));
            }
        )

  }

  doFetchVoxSlackList() {
    let CompanyToken = '';
    if (this.userObj) {
      CompanyToken = this.userObj.CompanyToken;
    }

    let IdVox = 0;
    if (this.isEdit) {
      IdVox = this.state.selectedVox.IdVox;
    }

    return serverService
      .getVoxSlackByCompanyToken(CompanyToken, IdVox)
      .then(res => {
        // console.log(res.data);
        if (res.data.length > 0) {
          let formFields = this.state.formFields;
          formFields['VoxSlackList'] = res.data;
          if (
            IdVox == 0 &&
            (formFields['selectedIdVoxSlack'] == undefined ||
              formFields['selectedIdVoxSlack'] == 0)
          ) {
            formFields['selectedIdVoxSlack'] = res.data[0].IdVoxSlack;
          }
          this.setState({ formFields: formFields });
        }

        return res;
      });
  }
}

export default ModalAddNewVox;
