import React from 'react';
import {Form} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../_services/server.service';
import {Trans} from "react-i18next";
import i18n from "i18next";


interface MyProps {
    onLocationSelected(IdLocation:number):any,
    selectedIdLocation?:number,
}

interface MyState {
    locations:any
}

class LocationSelect extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            locations:[],
        };
    }

    componentDidMount(){
        this.fetchLocations();
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }


    render () {
        let locations = this.state.locations;

        let optionItems = "";
        let defaultValue = 0;
        if(locations && locations.length > 0){
             optionItems = locations.map((location) => {
                 let selected = false;
                 if(this.props.selectedIdLocation > 0){
                     if(this.props.selectedIdLocation == location.IdLocation){
                         selected = true;
                         defaultValue = location.IdLocation;
                     }
                 }

                return <option key={location.IdLocation} value={location.IdLocation}>{location.Name}</option>
             });
        }

        return (
                <Form.Control onChange={this.onLocationSelected} size="sm" as="select" defaultValue={defaultValue.toString()}>
                    <option key='0' value='0'>{i18n.t("trans_33_locations_select_all_locations")}</option>
                    {optionItems}
                </Form.Control>
        )
    }


    fetchLocations() {
        let userStr = localStorage.getItem('user');

        serverService.getLocationsWhereThereAreVoxes()
            .then(
                result => {
                    if(result.status === "1"){
                        this.setState({
                            locations:result.data,
                        });
                    } else {
                        toast.warn("Error while fetching locations");
                    }
                },
                error => {
                    toast.warn("Did not get locations");
                }
            );
    }

    onLocationSelected = (event) => {
        // this.setState({ selectedValueId: event.target.value });
        this.props.onLocationSelected(event.target.value);
    };
}

export default LocationSelect;
