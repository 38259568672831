/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { serverService } from '../../../_services/server.service';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import VoxSelect from '../../_other/VoxSelect';
import ModalConfirmAction from "../../_other/ModalConfirmAction";

interface MyProps {
  onHide(): any;
  show: boolean;
  size?: any;
}

interface MyState {
  submitted: boolean;
  loading: boolean;
  error: string;
  success: string;
  formFields: any;
  selectedIdVox: number;
  voxes: any;

  modalConfirmShow:boolean;

}

class ModalSendMessage extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      loading: false,
      error: '',
      success: '',

      formFields: {},
      selectedIdVox: 0,
      voxes: [],

      modalConfirmShow:false
  };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onVoxSelected = this.onVoxSelected.bind(this);
    this.onEntered = this.onEntered.bind(this);

    this.doSendTextMessage = this.doSendTextMessage.bind(this);

  }

  onEntered() {
    let formFields = this.state.formFields;


    serverService.getAllLinkedMsTeamsBotOrSlackVoxes().then(
      result => {
        if (result.status === '1') {
          let onlyMsTeamsVoxes = [];
          for(let i=0;i<result.data.length;i++){
            if(result.data[i]['Type'] == 5){
              onlyMsTeamsVoxes.push(result.data[i])
            }
          }

          this.setState({ voxes: onlyMsTeamsVoxes, });

        } else {
          toast.warn('Error while fetching voxes');
        }
      },
      error => {
        toast.warn('Did not get voxes');
      }
    );
  }


  handleChange(e) {
    const { name, value } = e.target;
    let formFields = this.state.formFields;
    formFields[name] = value;
    this.setState({ formFields: formFields });
  }

  handleSubmit(e) {
    e.preventDefault();

  }

  doSendTextMessage(){
    this.setState({ submitted: true, loading: true, modalConfirmShow:false });


    let formFields = this.state.formFields;

    if (!formFields.TextMessage || formFields.TextMessage.length == 0) {
      toast.warn(i18n.t('trans_33_missing_fields'));
      this.setState({ submitted: false, loading: false });
      return;
    }

    let voxIds = [];
    if (this.state.selectedIdVox == 0) {
      for (let i = 0; i < this.state.voxes.length; i++) {
        voxIds.push(this.state.voxes[i].IdVox);
      }
    } else {
      voxIds.push(this.state.selectedIdVox);
    }


    serverService.sendTextMessage(formFields.TextMessage, voxIds).then(
        result => {
          this.setState({ submitted: false, loading: false });

          if (result.status === '1') {
            toast.success(i18n.t('trans_33_message_sent'));
            this.props.onHide();
          } else {
            toast.error('Did not send');
          }
        },
        error => {
          console.log(error);
          toast.error('Did not send');
          this.setState({ submitted: false, loading: false });
        }
    );
  }

  render() {
    let { formFields, loading } = this.state;

    let modalConfirmOpen = (itemToEdit) => {
      this.setState({ modalConfirmShow: true });
    };
    let modalConfirmClose = () => this.setState({ modalConfirmShow: false });

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size={this.props.size}
        onEntered={this.onEntered}
        dialogClassName='modal-90w'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header
          className='add-vox-modal-header'
          closeButton
          translate='yes'
        >
          <Modal.Title id='example-custom-modal-styling-title'>
            <Trans>trans_33_send_message_title</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='add-vox-modal-content'>
          <p style={{ color: 'red' }}>{this.state.error}</p>

          <p style={{ color: 'green' }}>{this.state.success}</p>

          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId='formCompanyName'>
              <Form.Label>
                *<Trans>trans_33_text_message_broadcast</Trans>
              </Form.Label>
              <Form.Control
                size='sm'
                as='textarea'
                name='TextMessage'
                rows={2}
                value={formFields.TextMessage}
                onChange={this.handleChange}
              />
            </Form.Group>


            <span style={{ display: 'inline-block', padding: "0 10px 0 10px", }}>
              <VoxSelect
                  selectedIdVox={this.state.selectedIdVox}
                  onVoxSelected={this.onVoxSelected}
                  voxes={this.state.voxes}
              />
            </span>


            <div style={{ textAlign: 'right',padding: "0 10px 0 10px" }}>
              <Button variant='primary' disabled={loading} onClick={modalConfirmOpen}>
                <Trans>trans_33_Send</Trans>
              </Button>
              {loading && (
                <img
                  alt='load ing'
                  src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
                />
              )}
            </div>
          </Form>

        </Modal.Body>
        <br />

        <ModalConfirmAction
            show={this.state.modalConfirmShow}
            actionTitle={i18n.t("trans_33_confirm")}
            actionBody={i18n.t("trans_33_are_you_sure")}
            noBtnText={i18n.t("trans_33_no_go_back")}
            yesBtnText={i18n.t("trans_33_yes_confirm")}
            onConfirmNo={modalConfirmClose}
            onHide={modalConfirmClose}
            onConfirmYes={this.doSendTextMessage}
        />
      </Modal>
    );
  }

  onVoxSelected(IdVox) {
    this.setState({ selectedIdVox: IdVox });
  }
}

export default ModalSendMessage;
