import React from 'react';
import { toast } from "react-toastify";

import ModalAddNewVox from './ModalAddNewVox';
import ModalConfirmAction from '../_other/ModalConfirmAction';

import {Button,ButtonToolbar,OverlayTrigger,Popover, Form } from 'react-bootstrap'
import moment from "moment";

import { serverService } from '../../_services/server.service';
import {config} from '../../_helpers/C'

import ModalCopyLink from "./ModalCopyLink";
import ModalMsTeamsBotCode from "./ModalMsTeamsBotCode";
import i18n from "i18next";
import {Trans} from "react-i18next";
import ModalSlackLink from "./ModalSlackLink";

const $ = require('jquery');
$.DataTable = require('datatables.net');

require( 'datatables.net-bs4' );
require( 'datatables.net-select-bs4' );
require('../datatables.selected.css');


const popoverSignOut = (
    <Popover id="popover">
        <Popover.Content>
            <Trans>
                trans_33_info_my_vox_sign_out_description
            </Trans>
        </Popover.Content>
    </Popover>
);

const urlDailyResultForVox:string = config.FRONTEND_RESULTS_BASE_URL + "/daily_for_vox.html";
const urlDailyResultForCompany:string = config.FRONTEND_RESULTS_BASE_URL + "/daily_for_company.html";


interface MyProps {
    voxes:any,
    modalAddNewShow:boolean,
    onVoxAdded(extra:string):any,
    onVoxDeleted():any,
    onVoxResetted():any,
    onVoxSuccessfullyAssignedToChannel():any
}

interface MyState {
    modalCopyLink:boolean,
    selectedLink:string,
    modalAddNewShow: boolean,
    modalConfirmDeleteShow: boolean,
    modalConfirmResetShow: boolean,
    modalMsTeamsBotCodeShow: boolean,
    modalSlackLinkShow:boolean,
    selectedVox: any,
    CodeMsBot: string,
    VoxToken:string,
    justAddedIdVox:number,
    showInactiveVoxes:boolean,
}


class VoxesTable extends React.Component<MyProps,MyState> {
    private textArea:any;

    private userObj;


    constructor(props) {
        super(props);

        let showInactiveVoxes = localStorage.getItem("showInactiveVoxes")=='true';

        this.state = {
            modalCopyLink:false,
            selectedLink:"",
            modalAddNewShow: false
            ,modalConfirmDeleteShow:false
            ,modalConfirmResetShow:false
            ,modalMsTeamsBotCodeShow: false
            ,modalSlackLinkShow:false
            ,selectedVox:{IdVox:0}
            ,CodeMsBot:""
            ,VoxToken:""
            ,justAddedIdVox:0
            ,showInactiveVoxes: showInactiveVoxes
        };

        this.onVoxAdded = this.onVoxAdded.bind(this);
        this.reloadTableData = this.reloadTableData.bind(this);
        this.showLinkToGeneralResults = this.showLinkToGeneralResults.bind(this);
        this.onShowMsTeamsBotCode = this.onShowMsTeamsBotCode.bind(this);
        this.onShowSlackLink = this.onShowSlackLink.bind(this);
        this.handleShowInactiveVoxesChange = this.handleShowInactiveVoxesChange.bind(this);
        this.modalAddNewOpen = this.modalAddNewOpen.bind(this);


    }

    componentDidMount() {
        const userStr = localStorage.getItem('user');
        if(userStr) {
            this.userObj = JSON.parse(userStr);
        }


        let table = $(this.refs.main).DataTable({
            //dom: '<"data-table-wrapper"<"toolbar"><"bottom"f>rtip>',
            dom: "<'data-table-wrapper'" +
                    "<'row'<'col-md-6 my-auto'<'toolbar'>><'col-md-6'>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'><'col-sm-12 col-md-7'p>>" +
                    ">",
            data: this.props.voxes,
            columns : [
                {
                    title: i18n.t("trans_33_vox_name"),
                    width: 120,
                    data: 'Name'
                },
                {
                    title: i18n.t("trans_33_location_tag"),
                    width: 120,
                    data: 'Location'
                },
                {
                    title: i18n.t("trans_33_first_interaction"),
                    width: 120,
                    data: 'FirstInteraction'
                },
                {
                    title: i18n.t("trans_33_last_interaction"),
                    width: 120,
                    data: 'LastInteraction'
                },
                {
                    title: i18n.t("trans_33_Answers"),
                    width: 80,
                    data: 'NumberOfAnswers'
                },
                {
                    title: i18n.t("trans_33_Type"),
                    width: 80,
                    data: 'Type'
                },
                {
                    title: i18n.t("trans_33_link_to_results") +  "&nbsp;<span data-toggle='tooltip' data-placement='left' data-title='" + i18n.t("trans_33_info_my_vox_link_to_daily_results") + "'><img src='/assets/images/icon_info.png'/>" + "</span>",
                    width: 80,
                    data: 'IdVox'
                },
                {
                    title: i18n.t("trans_33_edit"),
                    width: 50,
                    data: 'Edit'
                }
            ],
            ordering: true,
            paging:true,
            select: {
                style: 'single',
                //blurable: true
            },
            language: {
                search: i18n.t("trans_33_search"),
                emptyTable:  i18n.t("trans_33_table_no_records"),
                paginate: {
                    first: i18n.t("trans_33_first"),
                    previous: i18n.t("trans_33_previous"),
                    next: i18n.t("trans_33_next"),
                    last: i18n.t("trans_33_last")
                }
            },
            createdRow: (row, data, dataIndex) => {
                if(data.Status == 0){
                    $(row).addClass("inactiveVoxClass");
                }
            },

            columnDefs: [
                {
                    render: function ( data, type, row ) {
                        if(data != null){
                            return moment(data).format('D MMM YYYY HH:mm:ss');
                        } else {
                            return "";
                        }
                    },
                    targets: 2,
                },
                {
                    render: function ( data, type, row ) {
                        if(data != null){
                            return moment(data).format('D MMM YYYY HH:mm:ss');
                        } else {
                            return "";
                        }
                    },
                    targets: 3,
                },
                {
                    render: function ( data, type, row ) {
                        if(data != null){
                            let voxTypeStr = "N/A";
                            if(data === 1){
                                voxTypeStr = i18n.t("trans_33_Cardboard");
                            } else if(data === 2) {
                                voxTypeStr = i18n.t("trans_33_Slack");
                            } else if(data === 3) {
                                voxTypeStr = i18n.t("trans_33_Wooden");
                            } else if(data === 4) {
                                voxTypeStr = i18n.t("trans_33_teams_webhook");
                            } else if(data === 5) {
                                voxTypeStr = i18n.t("trans_33_teams_bot");
                            } else if(data === 6) {
                                voxTypeStr = i18n.t("trans_33_vox_web");
                            }
                            return voxTypeStr;
                        } else {
                            return "";
                        }
                    },
                    targets: 5,
                },
                {
                    render: function ( data, type, row ) {
                        if(data != null){
                            return "<button class='btn-primary'" +
                                " idcompany=" + row.IdCompany + ""
                                + " idvox=" + row.IdVox + ""
                                + " voxtoken=" + row.VoxToken + ""
                                + ">" + i18n.t("trans_33_Show") + "</button>";
                        } else {
                            return "";
                        }
                    },
                    targets: 6,
                },

                {
                    render: function ( data, type, row ) {
                        return "<img src='/assets/images/icon_edit.png'/>";
                    },
                    targets: 7,
                },

            ],
        });

        $("#resetBtn").hide();
        $("#deleteBtn").hide();
        $("#editBtn").hide();
        $("#sendNowBtn").hide();


        table.on( 'select', function ( e, dt, type, indexes ) {
            let rowData = table.rows( indexes ).data().toArray();
            let voxType = rowData[0].Type;

            if(voxType != 2 && voxType != 4 && voxType != 5){
                $("#resetBtn").show();
            }

            $("#deleteBtn").show();
            $("#editBtn").show();
            $("#sendNowBtn").show();
        });

        table.on( 'deselect', function ( e, dt, type, indexes ) {
            $("#resetBtn").hide();
            $("#deleteBtn").hide();
            $("#editBtn").hide();
            $("#sendNowBtn").hide();
        });

    }
    componentWillUnmount(){
        $('.data-table-wrapper')
            .find('table')
            .DataTable()
            .destroy(true);
    }

    componentDidUpdate(oldProps) {
        if(this.props.voxes !== oldProps.voxes){
            this.reloadTableData(this.props.voxes);
        }

        if(this.props.modalAddNewShow !== oldProps.modalAddNewShow){
            this.setState({ modalAddNewShow: this.props.modalAddNewShow,selectedVox:{} });
        }
    }


    render() {

        let closeAddNewModal = () => this.setState({ modalAddNewShow: false });

        let closeModalMsTeamsBotCode = () => {
            this.props.onVoxSuccessfullyAssignedToChannel();
            this.setState({modalMsTeamsBotCodeShow: false});
        };

        let closeModalSlackLink = () => {
            this.props.onVoxSuccessfullyAssignedToChannel();
            this.setState({modalSlackLinkShow: false});
        };

        let closeCopyLinkModal = () => this.setState({ modalCopyLink: false });

        let modalConfirmDeleteOpen = (itemToEdit) => this.setState({ modalConfirmDeleteShow: true });
        let modalConfirmDeleteClose = () => this.setState({ modalConfirmDeleteShow: false });

        let deleteSelectedItemWithConfirmation = () => {
            modalConfirmDeleteOpen(null);
        };

        let modalConfirmResetOpen = (itemToEdit) => this.setState({ modalConfirmResetShow: true });
        let modalConfirmResetClose = () => this.setState({ modalConfirmResetShow: false });

        let resetSelectedItemWithConfirmation = () => {
            modalConfirmResetOpen(null);
        };

        let deleteSelectedItem = () => {
            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();
            let data = table.rows( { selected: true }).data();
            let IdVox = data[0].IdVox;

            serverService.deleteVox(IdVox)
                .then(
                    result => {
                        if(result.status === "1"){
                            toast.success(i18n.t("trans_33_vox_table_msg_deleted"));
                            $("#deleteBtn").hide();
                            this.props.onVoxDeleted();
                        } else {
                            toast.error("Delete failed");
                        }

                        this.setState({ modalConfirmDeleteShow: false });
                    },
                    error => {
                        toast.warn("Did not delete vox");
                    }
                );
        };

        let editSelectedItem = () => {
            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();
            let data = table.rows( { selected: true }).data();

            this.modalAddNewOpen(data[0]);
        };

        let resetSelectedItem = () => {
            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();

            let data = table.rows( { selected: true }).data();

            let IdVox = data[0].IdVox;

            serverService.resetVox(IdVox)
                .then(
                    result => {
                        if(result.status === "1"){
                            toast.success(i18n.t("trans_33_vox_table_msg_signed_out"));
                            $("#resetBtn").hide();
                            this.props.onVoxResetted();
                        } else {
                            toast.error("Sign out failed");
                        }

                        this.setState({ modalConfirmResetShow: false });
                    },
                    error => {
                        toast.warn("Did not reset vox");
                    }
                );
        };


        let sendQuestionNow = () => {
            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();

            let data = table.rows( { selected: true }).data();

            let IdVox = data[0].IdVox;

            serverService.sendQuestionNow(IdVox)
                .then(
                    result => {
                        if(result.status === "1"){
                            toast.success(i18n.t("Ok"));
                            $("#sendNowBtn").hide();
                        } else {
                            toast.error("Failed");
                        }
                    },
                    error => {
                        toast.warn("Did not reset vox");
                    }
                );
        };

       let copyToClipboard = (e) => {
           const table = $('.data-table-wrapper')
               .find('table')
               .DataTable();
           let data = table.rows( { selected: true }).data();

           let IdVox = data[0].IdVox;
           let IdCompany = data[0].IdCompany;
           let VoxToken = data[0].VoxToken;

           const url = urlDailyResultForVox
               + "?IdCompany=" + IdCompany
               + "&IdVox=" + IdVox
               + "&GroupByQuestion=0"
               + "&h=" + VoxToken;

           this.textArea.value  =  url;
           this.textArea.select();
           document.execCommand('copy');
           e.target.focus();

           toast.success(i18n.t("trans_33_modal_copy_link_msg_success"))
        };

        let hasVoxesAlreadyAdded = true;
        if(this.props.voxes){
            hasVoxesAlreadyAdded = this.props.voxes.length > 0
        }


        return (
            <div>
                <table  ref="main" className="table table-bordered" />
                <div style={{marginBottom:"10px" , transform:"translateY(-3rem)"}} >
                <Form.Check
                    type="checkbox"
                    id="showInactiveVoxes"
                    label={i18n.t("trans_33_show_inactive_voxes")}
                    checked={this.state.showInactiveVoxes}
                    onChange={this.handleShowInactiveVoxesChange}
                />
                </div>
                <div style={{transform:"translateY(-2rem)"}} className="float-left">

                    <Button variant="primary" id="copyBtn" onClick={this.showLinkToGeneralResults}>
                        <Trans>
                            trans_33_show_link_general_daily_results
                        </Trans>
                    </Button>


                    <form>
                      <textarea
                          style={{opacity: .01,height:'0',position:'absolute'}}
                          ref={(textarea) => this.textArea = textarea}
                          defaultValue={""}
                      />
                    </form>

                </div>
                <div style={{transform:"translateY(-2rem)"}}  className="float-right">

                    <ButtonToolbar>

                        <Button
                            id="resetBtn"
                            variant="light"
                            onClick={resetSelectedItemWithConfirmation}
                        >
                            <Trans>trans_33_sign_out</Trans>
                            &nbsp;
                            <OverlayTrigger placement="top" overlay={popoverSignOut}>
                                <img alt="help" src="/assets/images/icon_info.png" />
                            </OverlayTrigger>
                        </Button>

                        &nbsp;

                        {this.userObj && this.userObj['Username'] === "admin1" &&
                            <Button
                                id="sendNowBtn"
                                variant="primary"
                                onClick={sendQuestionNow}
                            >
                                <Trans>Send Teams question now</Trans>
                            </Button>
                        }



                        &nbsp;
                        <Button
                            id="editBtn"
                            variant="primary"
                            onClick={editSelectedItem}
                        >
                            <Trans>trans_33_Edit</Trans>
                        </Button>
                        &nbsp;
                        <Button
                            id="deleteBtn"
                            variant="light"
                            onClick={deleteSelectedItemWithConfirmation}
                        >
                            <Trans>trans_33_Delete</Trans>
                        </Button>

                        &nbsp;

                        <Button
                        variant=""
                            style={{backgroundColor:"#2f242c", color:"white"}}
                            onClick={this.modalAddNewOpen}
                        >
                            <Trans>trans_33_add_new_vox</Trans>
                        </Button>


                        <ModalCopyLink
                            show={this.state.modalCopyLink}
                            selectedLink={this.state.selectedLink}
                            title={i18n.t("trans_33_modal_copy_link_title")}
                            onHide={closeCopyLinkModal}
                        />

                        <ModalAddNewVox 
                            show={this.state.modalAddNewShow}
                            selectedVox={this.state.selectedVox}
                            onHide={closeAddNewModal}
                            onVoxAdded={this.onVoxAdded}
                            hasVoxesAlreadyAdded={hasVoxesAlreadyAdded}
                            voxes={this.props.voxes}
                            onShowMsTeamsBotCode={this.onShowMsTeamsBotCode}
                            onShowSlackLink={this.onShowSlackLink}
                        />

                        <ModalConfirmAction
                            show={this.state.modalConfirmDeleteShow}
                            actionTitle={i18n.t("trans_33_confirm_delete")}
                            actionBody={i18n.t("trans_33_vox_table_msg_confirm_delete_vox")}
                            noBtnText={i18n.t("trans_33_no_go_back")}
                            yesBtnText={i18n.t("trans_33_yes_confirm")}
                            onConfirmNo={modalConfirmDeleteClose}
                            onHide={modalConfirmDeleteClose}
                            onConfirmYes={deleteSelectedItem}
                        />

                        <ModalConfirmAction
                            show={this.state.modalConfirmResetShow}
                            actionTitle={i18n.t("trans_33_confirm_sign_out")}
                            actionBody={i18n.t("trans_33_confirm_sign_out_are_you_sure")}
                            noBtnText={i18n.t("trans_33_no_go_back")}
                            yesBtnText={i18n.t("trans_33_yes_confirm")}
                            onConfirmNo={modalConfirmResetClose}
                            onHide={modalConfirmResetClose}
                            onConfirmYes={resetSelectedItem}
                        />

                        <ModalMsTeamsBotCode
                            show={this.state.modalMsTeamsBotCodeShow}
                            onHide={closeModalMsTeamsBotCode}
                            CodeMsBot={this.state.CodeMsBot}
                            IdVox={this.state.justAddedIdVox}
                            selectedVox={this.state.selectedVox}
                        />

                        <ModalSlackLink
                            show={this.state.modalSlackLinkShow}
                            onHide={closeModalSlackLink}
                            VoxToken={this.state.VoxToken}
                            IdVox={this.state.justAddedIdVox}
                        />

                    </ButtonToolbar>

                </div>

            </div>);
    }


    handleShowInactiveVoxesChange(event){
        let showInactiveVoxes = event.target.checked;
        this.setState({ showInactiveVoxes: showInactiveVoxes });

        localStorage.setItem("showInactiveVoxes",showInactiveVoxes);
        this.props.onVoxDeleted();
    }


    showLinkToGeneralResults(){
        const userStr = localStorage.getItem('user');
        let IdCompany = 0;
        let CompanyToken = "";
        if(userStr) {
            let userObj = JSON.parse(userStr);
            IdCompany = userObj.IdCompany;
            CompanyToken = userObj.CompanyToken;
        }

        const url = urlDailyResultForCompany
            + "?IdCompany=" + IdCompany
            + "&h=" + CompanyToken;
            // + "&IdVox=" + data.idvox.value
            // + "&GroupByQuestion=0"
            // + "&MostFrequentAskedQuestion=1"


        this.setState({modalCopyLink:true, selectedLink:url,});
    }


    onVoxAdded(item,CodeMsBot, VoxToken, IdVox){
        this.props.onVoxAdded(item);

        let partialStateObj = {modalAddNewShow: false};
        partialStateObj['justAddedIdVox'] = IdVox;

        if(CodeMsBot && CodeMsBot.length > 0){
            partialStateObj['modalMsTeamsBotCodeShow'] = true;
            partialStateObj['CodeMsBot'] = CodeMsBot;
        }

        if(VoxToken && VoxToken.length > 0){
            partialStateObj['modalSlackLinkShow'] = true;
            partialStateObj['VoxToken'] = VoxToken;
        }

        this.setState(partialStateObj);
    }



    onShowMsTeamsBotCode(CodeMsBot){
        let partialStateObj = {};
        if(CodeMsBot && CodeMsBot.length > 0){
            partialStateObj['modalAddNewShow'] = false;
            partialStateObj['modalMsTeamsBotCodeShow'] = true;
            partialStateObj['CodeMsBot'] = CodeMsBot;
        }

        this.setState(partialStateObj);
    }

    onShowSlackLink(VoxToken){
        let partialStateObj = {};
        if(VoxToken && VoxToken.length > 0){
            partialStateObj['modalAddNewShow'] = false;
            partialStateObj['modalSlackLinkShow'] = true;
            partialStateObj['VoxToken'] = VoxToken;
        }

        this.setState(partialStateObj);
    }


    modalAddNewOpen(itemToEdit){
        let doShowAddNewModal = true;

        if(typeof itemToEdit.IdVox === 'undefined'){
            const userStr = localStorage.getItem('user');
            let userObj = JSON.parse(userStr);

            // console.log(userObj);
            let voxes = this.props.voxes;
            let currentVirtualVoxNum = 0;
            let currentPhysicalVoxNum = 0;
            for (let i = 0; i < voxes.length; i++) {
                if (voxes[i]['Status'] > 0) {
                    if (
                        voxes[i]['Type'] == 2 ||
                        voxes[i]['Type'] == 4 ||
                        voxes[i]['Type'] == 5
                    ) {
                        currentVirtualVoxNum++;
                    } else if (voxes[i]['Type'] == 1 || voxes[i]['Type'] == 3) {
                        currentPhysicalVoxNum++;
                    }
                }
            }

            if(userObj.VirtualVoxNum <= currentVirtualVoxNum && userObj.PhysicalVoxNum <= currentPhysicalVoxNum){
                doShowAddNewModal = false;
                toast.warn(i18n.t("trans_33_msg_no_voxes_available"));
            }
        }


        this.setState({ modalAddNewShow: doShowAddNewModal, selectedVox:itemToEdit,justAddedIdVox:itemToEdit.IdVox})
    }



    reloadTableData(items) {
        const table = $('.data-table-wrapper')
            .find('table')
            .DataTable();
        table.clear();
        table.rows.add(items);
        table.draw();


        $("#deleteBtn").hide();
        $("#editBtn").hide();
        $("#resetBtn").hide();
        $("#sendNowBtn").hide();



        table.on( 'click', 'button', (e, dt, type, cell, originalEvent) => {
            e.stopPropagation();

            let data = e.target.attributes;


            const url = urlDailyResultForVox
                + "?IdCompany=" + data.idcompany.value
                + "&IdVox=" + data.idvox.value
                + "&GroupByQuestion=0"
                + "&h=" + data.voxtoken.value;

            this.setState({modalCopyLink:true, selectedLink:url,})
        });


        table.on( 'click', 'img', (e, dt, type, cell, originalEvent) => {
           // e.stopPropagation();

            let data = e.target;

            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();
            let voxData = table.rows(data.parentElement.parentElement).data();

            this.modalAddNewOpen(voxData[0]);

        });
    }

}


export default VoxesTable;
