import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {RouteProps, RouteComponentProps} from "react-router-dom";

export const PrivateRoute: React.SFC<RouteProps> = ({
    component: Component,
    ...rest
}: {
    component: React.ComponentType<RouteComponentProps<any>>;
}) => (

        <Route render={props => {

                    const userStr = localStorage.getItem('user');

                    if(userStr){
                        let userObj = JSON.parse(userStr);
                        if(userObj.Type === "1" && props.location.pathname === "/"){ // admin
                            return <Redirect to={{ pathname: '/home', state: { from: props.location } }} />;
                        } else  if(userObj.Type === "2" && props.location.pathname === "/"){
                            return <Redirect to={{ pathname: '/home', state: { from: props.location } }} />;
                        } else  if(userObj.Type == 3 && props.location.pathname !== "/pulse"){
                            return <Redirect to={{ pathname: '/pulse', state: { from: props.location } }} />;
                        } else { 
                            return <Component {...props} />
                        } 
                    } else {
                        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
                    }

                }
            }
        />
    );