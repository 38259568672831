import React from 'react';
import "./ProgressBar.css";

export default function({progress}) {
  return (
    <div className="progressbar">
      <div className="progressbar-meter" style={{width: (progress*100)+'%'}}></div>
    </div>
  );
}
