/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";
import ModalImage from "react-modal-image";
import {serverService} from "../../_services/server.service";
import {Trans} from "react-i18next";
import {config} from '../../_helpers/C'
import ModalTextMessage from "./ModalTextMessage";



interface MyProps {
    show:boolean,
    onHide?(): any,
    VoxToken:string,
    IdVox:number,
}

interface MyState {
    loading:boolean,
    formFields:any,
    voxSuccessfullyAssigned:boolean,
    modalTextMessageShow: boolean
}

 
class ModalSlackLink extends React.Component<MyProps,MyState> {
    private intervalTimer;
    private userObj;


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            formFields: {},
            voxSuccessfullyAssigned:false,
            modalTextMessageShow: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);


        this.doFetchVoxSlackListInterval = this.doFetchVoxSlackListInterval.bind(this);
        this.onExit = this.onExit.bind(this);
        this.onEntered = this.onEntered.bind(this);
    }


    componentDidMount() {
        const userStr = localStorage.getItem('user');
        if(userStr) {
            this.userObj = JSON.parse(userStr);
        }
    }

    render() {
        let {loading,voxSuccessfullyAssigned} = this.state;

        let modalTextMessageOpen = (itemToEdit) => this.setState({ modalTextMessageShow: true });
        let closeTextMessageModal = () => this.setState({ modalTextMessageShow: false });


        let slackButtonUrl = config.SLACK_REGISTER_WEBHOOK_URL + "&state=" + this.props.VoxToken;

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onExit = {this.onExit}
                onEntered = {this.onEntered}
                backdrop="static"
            >
                <Modal.Header className="add-vox-modal-header" closeButton translate="yes">
                    <Modal.Title>
                        <Trans>trans_33_modal_slack_link_title</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="add-vox-modal-content" >
                    <br/>
                    <Trans>trans_33_modal_teams_code_fill_it_manager_email</Trans>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Control size="sm" type="text"
                                      id="Email"
                                      placeholder="Email"
                                      name="Email"
                                      onChange={this.handleChange}
                                      style={{width:'250px',float: 'left'}}
                        />
                        <Button variant="primary"  type="submit" disabled={loading} style={{float: 'left',marginLeft: '10px'}}>
                            <Trans>trans_33_Send</Trans>
                        </Button>
                        {loading &&
                        <img alt="loading"
                             src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                        }
                    </Form>


                    <br/><br/><br/>
                    <Trans>trans_33_modal_slack_link_follow_this_steps</Trans>
                    <br/> <br/>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-1 align-middle" style={{marginTop: 'auto', marginBottom: 'auto'}}><b>1.</b>
                            </div>
                            <div className="col-md-11">
                                <a href={slackButtonUrl} onClick={this.doFetchVoxSlackListInterval} target="_blank">
                                    <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack@2x.png"/>
                                </a>

                                {voxSuccessfullyAssigned &&
                                    <span style={{color:"#3399AA"}}>
                                        &nbsp;&nbsp;<Trans>trans_33_vox_successfully_assigned_to_channel</Trans>
                                    </span>
                                }
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-md-1 align-middle" style={{marginTop: 'auto', marginBottom: 'auto'}}><b>2.</b>
                            </div>
                            <div className="col-md-11">
                                <Trans>trans_33_modal_slack_select_a_chanel</Trans>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-md-1 align-middle" style={{marginTop: 'auto', marginBottom: 'auto'}}><b>3.</b>
                            </div>
                            <div className="col-md-11">
                                {/*disabled={!voxSuccessfullyAssigned}*/}
                                <Button variant="primary" onClick={modalTextMessageOpen} disabled={!voxSuccessfullyAssigned}  >
                                    <Trans>trans_33_text_message</Trans>
                                </Button>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-10" style={{marginTop: 'auto', marginBottom: 'auto'}}></div>
                            <div className="col-md-2">
                                <Button variant="primary" onClick={this.props.onHide}>
                                    <Trans>trans_33_next</Trans>
                                </Button>
                            </div>
                        </div>

                    </div>

                    <br/>

                </Modal.Body>


                <ModalTextMessage
                    size="lg"
                    show={this.state.modalTextMessageShow}
                    onHide={closeTextMessageModal}
                    IdVox={this.props.IdVox}
                />

            </Modal>
        );
    }


    onEntered(){
        this.setState({ voxSuccessfullyAssigned: false });
    }


    onExit(){
        if(this.intervalTimer != null){
            clearInterval(this.intervalTimer);
        }
    }


    doFetchVoxSlackListInterval(){
        let thisPointer = this;
        if(this.intervalTimer != undefined){
            clearInterval(thisPointer.intervalTimer);
        }

        this.intervalTimer = setInterval(function(){
            thisPointer.doFetchVoxSlackList().then(val => {
                    if(val.data.length > 0){
                        clearInterval(thisPointer.intervalTimer);
                    }
                },
                error => {
                    clearInterval(thisPointer.intervalTimer);
                    toast.warn("Did not get the vox");
                });
        }, 3000);
    }


    doFetchVoxSlackList(){
        let CompanyToken = "";
        if(this.userObj){
            CompanyToken = this.userObj.CompanyToken;
        }

        let VoxToken = "";
        if(this.props.VoxToken && typeof(this.props.VoxToken) !== "undefined") {
            VoxToken = this.props.VoxToken;
        }

        return serverService.getVoxSlackByCompanyToken(CompanyToken,VoxToken).then(
            res => {
                // console.log(res.data);
                if(res.data.length > 0){
                    this.setState({voxSuccessfullyAssigned:true});
                }

                return res;
            }
        );
    }


    handleChange(e) {
        const {name, value} = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({formFields: formFields});
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({loading: true});

        let Email = this.state.formFields.Email;
        let VoxToken = this.props.VoxToken;

        let CompanyToken = "";
        if(this.userObj){
            CompanyToken = this.userObj.CompanyToken;
        }

        serverService.sendEmailWithSlackInstructions(Email,VoxToken,CompanyToken)
            .then(result => {
                this.doFetchVoxSlackListInterval();

                toast.success(result.message);
                this.setState({loading: false});
            }, error => {
                toast.warn("Issues while sending the email");
                this.setState({loading: false});
            });

    }
}

export default ModalSlackLink;