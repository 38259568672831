import { toast } from 'react-toastify';
import {authHeader, authHeaderFile, noAuthHeader} from '../_helpers/AuthHeader';

import {config} from '../_helpers/C'

export const serverService = {
    login,
    logout,

    getAllQuestionsForTableDisplay,
    insertQuestion,
    updateQuestion,
    deleteQuestion,
    importQuestions,
    importAdminAnswers,

    getAllTranslations,
    insertTranslation,
    updateTranslation,


    getAllVoxesWithNumberOfAnswers,
    getAllActiveVoxes,
    getAllAdminVoxes,
    getAllVoxesAtLocation,
    getAllLinkedMsTeamsBotVoxes,
    getAllLinkedMsTeamsBotOrSlackVoxes,
    insertVox,
    updateVox,
    deleteVox,
    resetVox,
    sendQuestionNow,

    getLocationsForCompany,
    getLocationsWhereThereAreVoxes,
    insertLocation,

    getCompany,
    updateCompany,

    getResultsForCompany,
    getStrikingFact,
    getResultsWithSkills,
    insertOrUpdateSkillsForQuestion,
    insertOrUpdateTagsForQuestion,

    getResultsNumOfAnswersPerVoxChart,


    getYesterdayResultsForVoxToken,

    getSchedulesDataForAgendaByMonth,
    getSchedulesDataBySearchedText,
    insertSchedule,
    schedulePack,
    scheduleQuestionPack,
    copyAllScheduledQuestionsFromDateToAnotherDate,
    deleteScheduledQuestions,
    importSchedules,

    getQuestionsForCompany,

    getAllQPacks,
    getQPackQuestions,
    getScanQuestions,
    generateQuestionsPack,
    checkIfQuestionsAreScheduled,

    getLastScheduledQuestion,

    getSkillsByType,

    updatePassword,
    forgotPassword,

    getAllAdminQuestionsForTableDisplay,
    importAdminQuestions,
    importAdminQuestionsTranslations,

    paymentsGetClientSecret,
    paymentsGetPaymentMethods,
    paymentsSavePaymentMethod,

    paymentsGetSubscriptionsForCustomer,
    paymentsInsertSubscription,

    paymentsGetSubscriptionsSchedules,

    paymentsGetInvoicesForCustomer,


    getCurrentLicense,
    insertLicense,
    updateLicense,
    startLicense,
    stopLicense,

    getCurrentLicenseLogs,

    getCurrentLicensePendingUpdate,
    insertLicensesPendingUpdate,

    getAllAdminUsersForTableDisplay,
    getAllAdminUsers,
    getCurrentUser,
    insertUser,
    updateUser,
    deleteUser,
    checkIfUsernameAlreadyExists,
    registerUser,
    acceptTos,
    acceptTosAI,
    finishOnboarding,
    updateTimezoneAndLanguage,

    getAdminResultsForCompany,

    getCompanies,
    deleteCompany,

    insertOrUpdateCompanyAdmin,

    getResources,
    getSuggestedResources,
    uploadResource,
    insertResource,
    updateResource,
    deleteResource,

    getVoxSlackByCompanyToken,
    getVoxTeamsBotByIdVox,

    getLogs,
    getLicensePrices,
    

    getLanguages,
    getDataFromExcel,

    getMonthlyReportResources,

    getNumberOfAnswersForVirtualVoxesForBillingCycle,
    getNumberOfAutoPilotQuestionsForBillingCycle,

    sendEmailWithMsTeamsInstructions,
    sendEmailWithSlackInstructions,

    getInputQuestions,
    getInputQuestionsById,
    insertInputQuestion,
    insertInputQuestionAnswer,

    getPulses,
    getPulseById,
    insertOrUpdatePulse,
    getPulseQuestions,
    insertPulseQuestion,
    insertPulseAnswers,
    deletePulse,
    deletePulseQuestion,
    getPulseSummary,


    getScans,
    getScanById,
    insertOrUpdateScan,
    insertScanAnswers,
    getScanResults,
    deleteScan,
    extendScan7Days,


    getVirtualChannels,
    insertDraftMsTeamsBot,
    pulseInsertUserAndSendEmail,
    getMsTeamsBotById,


    sendSlackTextMessage,
    insertTextMessage,

    getPdfReport,
    getPdfReportForAScan,

    getQPickerTags,
    getQuestionTags,

    sendTextMessage,

    getResultsStandardTable,

    checkVatOnVies,

    getVouchers,
    insertVoucher,
    deleteVoucher,

};

const REACT_APP_SERVER_API_URL = config.API_BASE_URL;

function login(email, password) {
    const requestOptions = {
        method: 'GET',
    };

    return fetch(REACT_APP_SERVER_API_URL + `/auth`+"?Username=" + email + "&Password=" + password, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function logout() {
    localStorage.removeItem('user');
}

function getLocationsForCompany(IdCompany) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/locations` + "?IdCompany="+IdCompany, requestOptions)
        .then(handleResponse);
}

function getLocationsWhereThereAreVoxes() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/locations/wheretherearevoxes`, requestOptions)
        .then(handleResponse);
}

function insertLocation(location) {
    const formObj = {"data": location};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/locations`, requestOptions)
        .then(handleResponse);
}

function getCompany(IdCompany) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/companies/` +IdCompany, requestOptions)
        .then(handleResponse);
}

function updateCompany(company) {
    const formObj = {"data": company};

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/companies/`, requestOptions)
        .then(handleResponse);
}


function getResultsForCompany(IdCompany,IdLocation, IdVox ,from,to) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/results` + "?IdCompany=" + IdCompany
        + "&IdLocation=" + IdLocation + "&IdVox=" + IdVox + "&FromDate=" + from + "&ToDate=" + to
        , requestOptions)
        .then(handleResponse);
}



function getYesterdayResultsForVoxToken(VoxToken) {
    const requestOptions = {
        method: 'GET',
    };

    return fetch(REACT_APP_SERVER_API_URL + `/resultssilversquare?t=` + VoxToken
        , requestOptions)
        .then(handleResponse);
}


function getStrikingFact(IdCompany) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/results/striking_fact` + "?IdCompany="+IdCompany, requestOptions)
        .then(handleResponse);
}

function getResultsWithSkills(IdCompany) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }; 

    return fetch(REACT_APP_SERVER_API_URL + `/private/results/all_with_skills_v2` + "?IdCompany=" + IdCompany, requestOptions)
        .then(handleResponse);
}


function getResultsNumOfAnswersPerVoxChart() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/results/num_of_answers_per_vox_chart`, requestOptions)
        .then(handleResponse);
}



function getSchedulesDataForAgendaByMonth(MonthDate, IdLocation, IdVox) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/schedules/for_agenda` + "?MonthDate="+MonthDate
        + "&IdLocation=" + IdLocation + "&IdVox=" + IdVox
        , requestOptions)
        .then(handleResponse);
}

function getSchedulesDataBySearchedText(SearchedText) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/schedules/by_searched_text` + "?SearchedText="+SearchedText
        , requestOptions)
        .then(handleResponse);
}

function checkIfQuestionsAreScheduled(StartDate,QuestionPackArray) {
    const formObj = {"StartDate": StartDate, "QuestionPackArray": JSON.stringify(QuestionPackArray) };

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };


    return fetch(REACT_APP_SERVER_API_URL + `/private/schedules/check_if_questions_scheduled`
        , requestOptions)
        .then(handleResponse);
}

function getLastScheduledQuestion() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/schedules/last_scheduled_question`
        , requestOptions)
        .then(handleResponse);
}



function insertSchedule(schedule) {
    const formObj = {"data": schedule};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/schedules`, requestOptions)
        .then(handleResponse);
}

function schedulePack(IdQPack, StartDate) {
    const formObj = {"IdQPack": IdQPack, "StartDate": StartDate};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/schedules/schedule_pack`, requestOptions)
        .then(handleResponse);
}

function scheduleQuestionPack(StartDate, QuestionPackArray,Overwrite) {
    const formObj = {"StartDate": StartDate,"QuestionPackArray": QuestionPackArray,"Overwrite": Overwrite};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/schedules/schedule_question_pack`, requestOptions)
        .then(handleResponse);
}


function copyAllScheduledQuestionsFromDateToAnotherDate(copyDate,copyIdVox,copyIdLocation, pasteDate) {
    const formObj = {"CopyDate": copyDate, "IdVox":copyIdVox, "IdLocation":copyIdLocation, "PasteDate": pasteDate};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/schedules/copy_paste`, requestOptions)
        .then(handleResponse);
}


function deleteScheduledQuestions(deleteDate,IdVox,IdLocation) {
    const formObj = {"DeleteDate": deleteDate, "IdVox": IdVox, "IdLocation": IdLocation};

    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/schedules/delete_by_date`, requestOptions)
        .then(handleResponse);
}


function importSchedules(excelRows) {
    const formObj = {"excelRows": excelRows};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/schedules/import_from_array`, requestOptions)
        .then(handleResponse);
}


function updatePassword(IdCompany, IdUser, Password, h) {
    console.log(Password);
    const formObj = {"IdCompany": IdCompany, "IdUser":IdUser, "Password":Password, "h": h};

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(formObj),
        headers:{'Content-Type':'application/json',},
    };
    return fetch(REACT_APP_SERVER_API_URL + `/users/update_password`, requestOptions)
        .then(handleResponse);
}

function forgotPassword(Username) {
    const requestOptions = {
        method: 'GET',
    };
    return fetch(REACT_APP_SERVER_API_URL + `/auth/forgot_password` + "?Username=" + Username, requestOptions)
        .then(handleResponse);
}


function getAllQuestionsForTableDisplay() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/questions/table_display`, requestOptions)
        .then(handleResponse);
}


function getQuestionsForCompany() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/questions/all_company`, requestOptions)
        .then(handleResponse);
}


function getAllQPacks(type) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/qpacks` + "?Type=" + type, requestOptions)
        .then(handleResponse);
}

function getQPackQuestions(IdQPack) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/qpacks/` + IdQPack + `/questions`, requestOptions)
        .then(handleResponse);
}

function getScanQuestions(IdScan) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/scans/` + IdScan + `/questions`, requestOptions)
        .then(handleResponse);
}

function generateQuestionsPack(selectedMeasureChallenge, selectedFunkiness, selectedSkills) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/qpacks/generate_qpack?selectedMeasureChallenge=` + selectedMeasureChallenge
        + `&selectedFunkiness=` + selectedFunkiness  + `&selectedSkills=` + selectedSkills, requestOptions)
        .then(handleResponse);
}


function getSkillsByType(Type) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/skills?Type=` + Type, requestOptions)
        .then(handleResponse);
}

function insertOrUpdateSkillsForQuestion(skills,IdQuestion) {
    let data = {'IdQuestion': IdQuestion, "skills" : skills};
    const formObj = {"data": data};

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/skills_for_question`, requestOptions)
        .then(handleResponse);
}

function insertOrUpdateTagsForQuestion(tags, IdQuestion) {
    let data = {'IdQuestion': IdQuestion, "tags" : tags};
    const formObj = {"data": data};

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/tags_for_question`, requestOptions)
        .then(handleResponse);
}


function getAllVoxesWithNumberOfAnswers() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/voxes/with_num_of_answers`, requestOptions)
        .then(handleResponse);
}


function getVoxSlackByCompanyToken(CompanyToken,VoxToken) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/slack/vox_slack?CompanyToken=`
        + CompanyToken + "&VoxToken=" + VoxToken, requestOptions)
        .then(handleResponse);
}

function getVoxTeamsBotByIdVox(IdVox) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/vox_msteams_bot?IdVox=` + IdVox, requestOptions)
        .then(handleResponse);
}

function getAllActiveVoxes() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/voxes`, requestOptions)
        .then(handleResponse);
}


function getAllLinkedMsTeamsBotVoxes() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/voxes/ms_teams_bot`, requestOptions)
        .then(handleResponse);
}

function getAllLinkedMsTeamsBotOrSlackVoxes() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/voxes/ms_teams_bot_or_slack`, requestOptions)
        .then(handleResponse);
}

function getAllVoxesAtLocation(IdLocation) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/voxes?IdLocation=`+IdLocation, requestOptions)
        .then(handleResponse);
}

function insertQuestion(question) {
    const formObj = {"data": question};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/questions`, requestOptions)
        .then(handleResponse);
}

function updateQuestion(question) {
    const formObj = {"data": question};

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/questions/` + question.IdQuestion, requestOptions)
        .then(handleResponse);
}

function deleteQuestion(IdQuestion) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/questions/`+IdQuestion, requestOptions)
        .then(handleResponse);
}


function importQuestions(file) {
    const formData = new FormData();
    formData.append('file', file);

    const requestOptions = {
        method: 'POST',
        headers: authHeaderFile(),
        body: formData,
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/questions/import`, requestOptions)
        .then(handleResponse);
}


function getAllTranslations(IdTranslationGlobal) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + "/private/translations_resources?IdTranslationGlobal=" + IdTranslationGlobal, requestOptions)
        .then(handleResponse);
}

function insertTranslation(questionTranslation) {
    const formObj = {"data": questionTranslation};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/translations_resources`, requestOptions)
        .then(handleResponse);
}

function updateTranslation(questionTranslation) {
    const formObj = {"data": questionTranslation};

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/translations_resources/` + questionTranslation.IdTranslationQuestion, requestOptions)
        .then(handleResponse);
}



function insertVox(vox,voxSlack,voxMsTeams,voxMsTeamsBot,voxPhysical) {
    const formObj = {"data": vox, "vox_slack":voxSlack, "vox_msteams":voxMsTeams, "vox_msteams_bot":voxMsTeamsBot,"vox_physical": voxPhysical};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/voxes`, requestOptions)
        .then(handleResponse);
}

function updateVox(vox, voxSlack,voxMsTeams,voxMsTeamsBot) {
    const formObj = {"data": vox, "vox_slack": voxSlack, "vox_msteams":voxMsTeams, "vox_msteams_bot":voxMsTeamsBot};

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/voxes/` + vox.IdVox, requestOptions)
        .then(handleResponse);
}

function deleteVox(IdVox) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/voxes/`+IdVox, requestOptions)
        .then(handleResponse);
}

function resetVox(IdVox) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader()
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/voxes/reset/`+IdVox, requestOptions)
        .then(handleResponse);
}


function sendQuestionNow(IdVox) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader()
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/voxes/send_question_now/` + IdVox, requestOptions)
        .then(handleResponse);
}


function paymentsGetClientSecret() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/card-wallet`, requestOptions)
        .then(handleResponse);
}

function paymentsGetPaymentMethods() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/payment_methods`, requestOptions)
        .then(handleResponse);
}


function paymentsGetSubscriptionsForCustomer() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/subscriptions`, requestOptions)
        .then(handleResponse);
}

function paymentsInsertSubscription() {
    const subscriptionObj = {"IdProduct": "prod_GXbOgbpcHjXlgK"};

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(subscriptionObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/subscriptions`, requestOptions)
        .then(handleResponse);
}


function paymentsGetSubscriptionsSchedules() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/subscriptions/schedules`, requestOptions)
        .then(handleResponse);
}



function paymentsGetInvoicesForCustomer() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/invoices`, requestOptions)
        .then(handleResponse);
}


function paymentsSavePaymentMethod(PaymentMethodId, CardHolderName) {
    const paymentDetails = {"PaymentMethodId": PaymentMethodId, "CardHolderName": CardHolderName};

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(paymentDetails),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/payment_methods/`, requestOptions)
        .then(handleResponse);
}

function getCurrentLicense() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/licenses/`, requestOptions)
        .then(handleResponse);
}

function insertLicense(license) {
    const formObj = {"data": license};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/licenses`, requestOptions)
        .then(handleResponse);
}


function updateLicense(license) {
    const formObj = {"data": license};

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/licenses/` + license.IdLicense, requestOptions)
        .then(handleResponse);
}

function startLicense() {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader()
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/licenses/start_license`, requestOptions)
        .then(handleResponse);
}

function stopLicense() {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader()
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/licenses/stop_license`, requestOptions)
        .then(handleResponse);
}


function getCurrentLicenseLogs() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/license_logs/`, requestOptions)
        .then(handleResponse);
}


function getCurrentLicensePendingUpdate() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/licenses_pending_update/`, requestOptions)
        .then(handleResponse);
}

function insertLicensesPendingUpdate(licensesPendingUpdate) {
    const formObj = {"data": licensesPendingUpdate};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/licenses_pending_update`, requestOptions)
        .then(handleResponse);
}



function getDataFromExcel(file) {
    const formData = new FormData();
    formData.append('file', file);

    const requestOptions = {
        method: 'POST',
        headers: authHeaderFile(),
        body: formData,
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/schedules/get_data_from_excel`, requestOptions)
        .then(handleResponse);
}


function getCurrentUser() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/users/me`, requestOptions)
        .then(handleResponse);
}

function acceptTos() {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/users/accept_tos`, requestOptions)
        .then(handleResponse);
}

function acceptTosAI() {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/companies/accept_tos_ai`, requestOptions)
        .then(handleResponse);
}

function finishOnboarding() {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/users/finish_onboarding`, requestOptions)
        .then(handleResponse);
}


function updateTimezoneAndLanguage(Timezone, IdLanguage) {

    const formObj = {"Timezone": Timezone, "IdLanguage": IdLanguage};

    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/users/update_timezone_and_company_language`, requestOptions)
        .then(handleResponse);
}




// ADMIN

function importAdminQuestions(file) {
    const formData = new FormData();
    formData.append('file', file);

    const requestOptions = {
        method: 'POST',
        headers: authHeaderFile(),
        body: formData,
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/questions/import`, requestOptions)
        .then(handleResponse);
}

function importAdminQuestionsTranslations(file) {
    const formData = new FormData();
    formData.append('file', file);

    const requestOptions = {
        method: 'POST',
        headers: authHeaderFile(),
        body: formData,
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/questions/import_translations`, requestOptions)
        .then(handleResponse);
}


function importAdminAnswers(file) {
    const formData = new FormData();
    formData.append('file', file);

    const requestOptions = {
        method: 'POST',
        headers: authHeaderFile(),
        body: formData,
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/answers/import`, requestOptions)
        .then(handleResponse);
}


function getAllAdminQuestionsForTableDisplay() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/questions/table_display`, requestOptions)
        .then(handleResponse);
}

function getAllAdminUsersForTableDisplay() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/users/table_display`, requestOptions)
        .then(handleResponse);
}

function getAllAdminUsers() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/users`, requestOptions)
        .then(handleResponse);
}

function getAllAdminVoxes() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/voxes`, requestOptions)
        .then(handleResponse);
}



function insertUser(user) {
    const formObj = {"data": user};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/users`, requestOptions)
        .then(handleResponse);
}

function updateUser(user) {
    const formObj = {"data": user};

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/users/` + user.IdUser, requestOptions)
        .then(handleResponse);
}


function deleteUser(IdUser) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/users/` + IdUser, requestOptions)
        .then(handleResponse);
}

function checkIfUsernameAlreadyExists(Username) {
    const requestOptions = {
        method: 'GET',
        headers: noAuthHeader(),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/public/users/check_if_user_exists` + "?Username=" + Username, requestOptions)
        .then(handleResponse);
}

function registerUser(formFields) {
    const formObj = {"data": formFields};

    const requestOptions = {
        method: 'POST',
        headers: noAuthHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/public/register_user`, requestOptions)
        .then(handleResponse);
}



function getCompanies() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/companies`, requestOptions)
        .then(handleResponse);
}


function insertOrUpdateCompanyAdmin(company) {
    const formObj = {"data": company};

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/companies`, requestOptions)
        .then(handleResponse);
}

function deleteCompany(IdCompany) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/companies/` + IdCompany, requestOptions)
        .then(handleResponse);
}

function getResources(resourceType,selectedIdSkill,selectedIdVox,licenseType) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let typeParam = "";
    if(resourceType !== undefined && resourceType > 0){
        typeParam = "&Type=" + resourceType;
    }

    let skillParam = "";
    if(selectedIdSkill !== undefined && selectedIdSkill > 0){
        skillParam = "&IdSkill=" + selectedIdSkill;
    }

    let voxParam = "";
    if(selectedIdVox !== undefined && selectedIdVox > 0){
        voxParam = "&IdVox=" + selectedIdVox;
    }

    let licenseParam = "";
    if(licenseType !== undefined && licenseType > 0){
        licenseParam = "&LicenseType=" + licenseType;
    }

    return fetch(REACT_APP_SERVER_API_URL + `/private/resources/?param=1`
        + skillParam
        + typeParam
        + voxParam
        + licenseParam
        , requestOptions)
        .then(handleResponse);
}

function getSuggestedResources() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/resources/suggested`, requestOptions)
        .then(handleResponse);
}

function getMonthlyReportResources() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/resources/monthly_report`, requestOptions)
        .then(handleResponse);
}

function uploadResource(fileResource) {
    const formData = new FormData();
    formData.append('file', fileResource);

    const requestOptions = {
        method: 'POST',
        headers: authHeaderFile(),
        body: formData,
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/resources/upload`, requestOptions)
        .then(handleResponse);
}

function insertResource(resourceObject) {
    const formObj = {"data": resourceObject};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/resources`, requestOptions)
        .then(handleResponse);
}


function updateResource(resourceObject) {
    const formObj = {"data": resourceObject};

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/resources`, requestOptions)
        .then(handleResponse);
}

function deleteResource(IdResource) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/resources/` + IdResource, requestOptions)
        .then(handleResponse);
}


function getLogs() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/logs`, requestOptions)
        .then(handleResponse);
}


function getLicensePrices() {
    const requestOptions = {
        method: 'GET',
        headers: noAuthHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/public/license_prices`, requestOptions)
        .then(handleResponse);
}


function getVouchers() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }; 

    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/vouchers`, requestOptions)
        .then(handleResponse);
}

function insertVoucher(voucher) { 
    const formObj = {"data": voucher};
  
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/vouchers`, requestOptions)
        .then(handleResponse);
}
 

function deleteVoucher(IdVoucher) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/vouchers/` + IdVoucher, requestOptions)
        .then(handleResponse);
}

function getAdminResultsForCompany(IdCompany) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/results` + "?IdCompany="+IdCompany
        , requestOptions)
        .then(handleResponse);
}


function getResultsStandardTable(IdSkill, IdVox, IdTag, groupVoxesByDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/results_standard?IdSkill=` + IdSkill + `&IdVox=` + IdVox + `&IdTag=` + IdTag
        + `&groupVoxesByDate=` + groupVoxesByDate
        , requestOptions)
        .then(handleResponse);
}


function checkVatOnVies(country, vat) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/check_vat_on_vies?country=` + country + `&vat=` + vat
        , requestOptions)
        .then(handleResponse);
}


function getLanguages() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/languages`, requestOptions)
        .then(handleResponse);
}


function getNumberOfAnswersForVirtualVoxesForBillingCycle(fromDate,toDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/answers/num_of_answers_for_billing_cycle?fromDate=`+ fromDate + "&toDate=" + toDate, requestOptions)
        .then(handleResponse);
}

function getNumberOfAutoPilotQuestionsForBillingCycle(fromDate,toDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/schedules/num_of_auto_pilot_questions_for_billing_cycle?fromDate=`+ fromDate + "&toDate=" + toDate, requestOptions)
        .then(handleResponse);
}




function sendEmailWithMsTeamsInstructions(Email, CodeMsTeams) {
    let obj = {Email:Email, CodeMsTeams: CodeMsTeams};
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(obj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/misc/send_msteams_email_with_instructions`, requestOptions)
        .then(handleResponse);
}


function sendEmailWithSlackInstructions(Email, VoxToken, CompanyToken) {
    let obj = {Email:Email, VoxToken: VoxToken, CompanyToken: CompanyToken};
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(obj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/misc/send_slack_email_with_instructions`, requestOptions)
        .then(handleResponse);
}



function getInputQuestions() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/input_questions`, requestOptions)
        .then(handleResponse);
}


function getInputQuestionsById(IdInputQuestion, h) {
    const requestOptions = {
        method: 'GET',
    };

    return fetch(REACT_APP_SERVER_API_URL + `/input_questions?IdInputQuestion=` + IdInputQuestion + `&h=` + h, requestOptions)
        .then(handleResponse);
}



function insertInputQuestion(inputQuestion,voxIds) {
    const formObj = {"data": inputQuestion,"voxIds": voxIds};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/input_questions`, requestOptions)
        .then(handleResponse);
}


function insertInputQuestionAnswer(IdInputQuestion, AnswerText, h) {
    const requestOptions = {
        method: 'POST',
    };
    return fetch(REACT_APP_SERVER_API_URL + `/insert_input_question_answer?IdInputQuestion=` + IdInputQuestion
        + "&AnswerText=" + AnswerText + "&h=" + h, requestOptions)
        .then(handleResponse);
}

function getPulses() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/pulses`, requestOptions)
        .then(handleResponse);
}

function getPulseById(IdPulse,h) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/pulses/` + IdPulse + `?h=` + h, requestOptions)
        .then(handleResponse);
}

function getPulseSummary(IdPulse,h) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/pulses/pulse_summary` + `?IdPulse=` + IdPulse + `&h=` + h, requestOptions)
        .then(handleResponse);
}

function getScanById(IdScan,h) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/scans/` + IdScan + `?h=` + h, requestOptions)
        .then(handleResponse);
}


function insertOrUpdatePulse(onePulse, channels, sendNow) {
    const formObj = {"data": onePulse, "channels": channels, "sendNow": sendNow};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/pulses`, requestOptions)
        .then(handleResponse);
}

function deletePulse(IdPulse) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/pulses/`+ IdPulse, requestOptions)
        .then(handleResponse);
}

function deletePulseQuestion(IdPulseQuestion) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/pulses_questions/`+ IdPulseQuestion, requestOptions)
        .then(handleResponse);
}


function getPulseQuestions(IdPulse) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/pulses_questions/`+ IdPulse, requestOptions)
        .then(handleResponse);
}

function insertPulseQuestion(IdPulse, QuestionText, AcceptsComments) {
    const formObj = {"IdPulse": IdPulse, "QuestionText": QuestionText, "AcceptsComments": AcceptsComments};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/pulses/question`, requestOptions)
        .then(handleResponse);
}

function insertPulseAnswers(IdPulse, formFields, h) {

    const formObj = {"IdPulse": IdPulse, "formFields": JSON.stringify(formFields), "h": h};

    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/pulses/answers`, requestOptions)
        .then(handleResponse);
}



function getVirtualChannels() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/virtual_channels`, requestOptions)
        .then(handleResponse);
}

function insertDraftMsTeamsBot(Owner, ChannelName) {
    const formObj = {"Owner": Owner, "ChannelName": ChannelName};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/virtual_channels/draft_msteams_bot`, requestOptions)
        .then(handleResponse);
}


function pulseInsertUserAndSendEmail(Owner) {
    const formObj = {"Owner": Owner};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/virtual_channels/insert_user_and_send_welcome_email`, requestOptions)
        .then(handleResponse);
}


function getScans() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/scans`, requestOptions)
        .then(handleResponse);
}


function insertOrUpdateScan(oneScan, sendNow, selectedQuestionIds) {
    const formObj = {"data": oneScan, "sendNow": sendNow, "questions": selectedQuestionIds};

    const requestOptions = {
        method: 'POST', 
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/scans`, requestOptions)
        .then(handleResponse);
}

function insertScanAnswers(IdScan, formFields, h) {

    const formObj = {"IdScan": IdScan, "formFields": JSON.stringify(formFields), "h": h};

    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/scans/answers`, requestOptions)
        .then(handleResponse);
}


function getScanResults(IdScan, h) {

    const formObj = {"IdScan": IdScan, "h": h};

    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/scans/results`, requestOptions)
        .then(handleResponse);
}




function deleteScan(IdScan) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/scans/`+ IdScan, requestOptions)
        .then(handleResponse);
}


function extendScan7Days(IdScan,h) {
 
    var params = {'IdScan' : IdScan, 'h': h}
    const requestOptions = { 
        method: 'PUT',
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify(params)
    };  
    return fetch(REACT_APP_SERVER_API_URL + `/public/scans/extend`, requestOptions)
        .then(handleResponse); 
} 


function getMsTeamsBotById(IdVoxMsTeamsBot) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/vox_msteams_bot/by_id?IdVoxMsTeamsBot=` + IdVoxMsTeamsBot, requestOptions)
        .then(handleResponse);
}


function insertTextMessage(textMessage) {
    const formObj = {"data": textMessage};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/text_messages_queue`, requestOptions)
        .then(handleResponse);
}

function getPdfReport(fromDate, toDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/misc/pdf_report?fromDate=` + fromDate + `&toDate=` + toDate, requestOptions)
        .then(handleResponse);
}


function getPdfReportForAScan(IdScan, h) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
 
    return fetch(REACT_APP_SERVER_API_URL + `/misc/pdf_report_for_a_scan?IdScan=` + IdScan + `&h=` + h, requestOptions)
        .then(handleResponse);
}



function sendSlackTextMessage(TextToSend,IdVox) {
    const formObj = {"TextToSend": TextToSend,"IdVox": IdVox};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/slack/send_text_message`, requestOptions)
        .then(handleResponse);
}


function getQPickerTags() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/qpicker_tags`, requestOptions)
        .then(handleResponse);
}

function getQuestionTags(IdQuestion) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/qpicker_question_tag/?IdQuestion=` + IdQuestion, requestOptions)
        .then(handleResponse);
}



function sendTextMessage(TextMessage,voxIds) {
    const formObj = {"TextMessage": TextMessage, "voxIds": voxIds};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/misc/send_text_message`, requestOptions)
        .then(handleResponse);
}



function handleResponse(response) {
    return response.text().then(text => {
        let data;
        try {
            data = text && JSON.parse(text);

            if (data.status === "2") {
                logout();
                window.location.replace(config.FRONTEND_DOMAIN_NAME + "/account_suspended");
            } 

            if (data.status === "0" && data.message.includes("Unauthorized")) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    logout();
                    window.location.reload(true);
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        } catch (error){
            return false
        }
    });
}