import React from "react";

import CampaignSchedulePage from "./Self-Starter/CampaignAgendaPage";
import CampaignQuestionsPage from "./Self-Starter/CampaignQuestionsPage";

import { Tab, Tabs,OverlayTrigger,Popover } from "react-bootstrap";

import "./CampaignPage.css";

import CampaignEnterprisePage from "./Enterprise/CampaignEnterprisePage";
import CampaignStandardPage from "./Standard/CampaignStandardPage";

import PubSub from "../../_helpers/PubSub.js";
import ModalUpgradeLicense from "../_modals/ModalUpgradeLicense";
import {Trans} from "react-i18next";
import i18n from "i18next";

const moment = require("moment");

interface MyProps {}

interface MyState {
  whenQuestionPackWasLastScheduled: string;
  license: any;
  modalUpgradeLicenseShow: boolean;
  activeTabKey: string;
  licenseForFeature: string;
}


const popoverQuestionPacks = (
    <Popover id="popover">
      <Popover.Content>
        <Trans>
          trans_33_info_campaign_question_packs_description
        </Trans>
      </Popover.Content>
    </Popover>
);

const popoverDelegate = (
    <Popover id="popover">
      <Popover.Content>
        <Trans>
          trans_33_info_campaign_delegate_description
        </Trans>
      </Popover.Content>
    </Popover>
);

class CampaignPage extends React.Component<MyProps, MyState> {
  private userObj;

  constructor(props) {
    super(props); 

    this.state = {
      whenQuestionPackWasLastScheduled: "",
      license: {},
      modalUpgradeLicenseShow: false,
      activeTabKey: "self-starter",
      licenseForFeature: "",
    };

    this.onQuestionPackScheduled = this.onQuestionPackScheduled.bind(this);
    this.modalUpgradeLicenseOpen = this.modalUpgradeLicenseOpen.bind(this);
    this.modalUpgradeLicenseClose = this.modalUpgradeLicenseClose.bind(this);

    this.onTabSelected = this.onTabSelected.bind(this);
  }

  componentWillMount() {
    this.userObj = JSON.parse(localStorage.getItem("user"));
  }

  componentDidMount() {
    PubSub.subscribe("updateLicense", (license) => {
      this.setState({ license: license });
    });
  }

  componentWillUnmount() {
    PubSub.unsubscribe("updateLicense");
  }

  render() {
    let { activeTabKey } = this.state;

    let LicenseType = 0;
    if (this.userObj && this.userObj.LicenseType) {
      LicenseType = this.userObj.LicenseType;
    }

    let campaignSelfStarterTitle = (
      <span>
        <Trans>trans_33_Schedule</Trans> &nbsp; <img src="/assets/images/icon_lock24.png" />
      </span>
    );
    let campaignStandardTitle = (
      <span>
        <Trans>trans_33_question_packs</Trans> &nbsp; <img src="/assets/images/icon_lock24.png" />
        &nbsp;
        <OverlayTrigger placement="top" overlay={popoverQuestionPacks}>
            <img alt="help" style={{maxWidth:"20px"}} src="/assets/images/info.png" />
         </OverlayTrigger>
      </span>
    );
    let campaignEnterpriseTitle = (
      <span>
        <Trans>trans_33_Delegate</Trans> &nbsp; <img src="/assets/images/icon_lock24.png" />
        &nbsp;
        <OverlayTrigger placement="top" overlay={popoverDelegate}>
        <img alt="help" style={{maxWidth:"20px"}} src="/assets/images/info.png" />
         </OverlayTrigger>
      </span>
    );

    if (LicenseType == 1) {
      campaignSelfStarterTitle = <span><Trans>trans_33_Schedule</Trans></span>;
    } else if (LicenseType == 2) {
      campaignSelfStarterTitle = <span><Trans>trans_33_Schedule</Trans></span>;
      campaignStandardTitle = <span>
        <Trans>trans_33_question_packs</Trans>
        &nbsp;
        <OverlayTrigger placement="top" overlay={popoverQuestionPacks}>
        <img alt="help" style={{maxWidth:"20px"}} src="/assets/images/info.png" />
         </OverlayTrigger>
      </span>;
    } else if (LicenseType == 3) {
      campaignSelfStarterTitle = <span><Trans>trans_33_Schedule</Trans></span>;
      campaignStandardTitle = <span>
        <Trans>trans_33_question_packs</Trans>
        &nbsp;
        <OverlayTrigger placement="top" overlay={popoverQuestionPacks}>
        <img alt="help" style={{maxWidth:"20px"}} src="/assets/images/info.png" />
         </OverlayTrigger>
      </span>;
      campaignEnterpriseTitle = <span>
        <Trans>trans_33_Delegate</Trans>
        &nbsp;
        <OverlayTrigger placement="top" overlay={popoverDelegate}>
        <img alt="help" style={{maxWidth:"20px"}} src="/assets/images/info.png" />
         </OverlayTrigger>
      </span>;
    }

    return (
      <div className="container p-5 mt-5" id="campaignContainer">
        <Tabs
          onSelect={this.onTabSelected}
          activeKey={activeTabKey}
          defaultActiveKey="self-starter"
          id="packagesTabs"
          className="packagesTabs tab-self-starter"
        >
          <Tab eventKey="self-starter" title={campaignSelfStarterTitle}>
            {this.userObj && this.userObj.Type !== 1 && (
              <CampaignSchedulePage
                whenQuestionPackWasLastScheduled={
                  this.state.whenQuestionPackWasLastScheduled
                }
              />
            )}

            {this.userObj && this.userObj.Type === 1 && (
              <Tabs
                defaultActiveKey="agenda"
                id="campaignTabs"
                className="campaignTabs"
              >
                <Tab
                  eventKey="agenda"
                  title={i18n.t("trans_33_campaign_page_agenda")}
                  id="tab-inner-agenda"
                  className="cam-tab-container"
                >
                  <br />
                  <CampaignSchedulePage
                    whenQuestionPackWasLastScheduled={
                      this.state.whenQuestionPackWasLastScheduled
                    }
                  />
                </Tab>
                <br />
                <Tab
                  eventKey="questions"
                  title={i18n.t("trans_33_campaign_page_questions_list")}
                  id="tab-inner-questions"
                  className="cam-tab-container"
                >
                  <CampaignQuestionsPage /> 

                  <br />
                  <br />
                  <br />
                  <br />
                </Tab>
              </Tabs>
            )}
          </Tab>
          <Tab
            eventKey="standard"
            title={campaignStandardTitle}
            className="tab-standard"
          >
            <CampaignStandardPage
              onQuestionPackScheduled={this.onQuestionPackScheduled}
            />
          </Tab>
          <Tab
            eventKey="enterprise"
            title={campaignEnterpriseTitle}
            className="tab-enterprise"
          >
            <CampaignEnterprisePage />
          </Tab>
        </Tabs>

        <ModalUpgradeLicense
          show={this.state.modalUpgradeLicenseShow}
          licenseForFeature={this.state.licenseForFeature}
          onHide={this.modalUpgradeLicenseClose}
          onCancel={this.modalUpgradeLicenseClose}
          onConfirm={this.modalUpgradeLicenseClose}
        />
      </div>
    );
  }

  onTabSelected(eventKey, event) {
    let LicenseType = 0;
    if (this.userObj && this.userObj.LicenseType) {
      LicenseType = this.userObj.LicenseType;
    }

    let navigateToTab = true;
    if (eventKey == "self-starter") {
      if (LicenseType != 1 && LicenseType != 2 && LicenseType != 3) {
        this.modalUpgradeLicenseOpen(i18n.t("trans_33_license_self_starter"));
        navigateToTab = false;
      }
    } else if (eventKey == "standard") {
      if (LicenseType != 2 && LicenseType != 3) {
        this.modalUpgradeLicenseOpen(i18n.t("trans_33_license_standard"));
        navigateToTab = false;
      }
    } else if (eventKey == "enterprise") {
      if (LicenseType != 3) {
        this.modalUpgradeLicenseOpen(i18n.t("trans_33_license_enterprise"));
        navigateToTab = false;
      }
    }

    if (navigateToTab) {
      this.setState({ activeTabKey: eventKey });
    }
  }

  onQuestionPackScheduled() {
    this.setState({ whenQuestionPackWasLastScheduled: moment() });
  }

  modalUpgradeLicenseOpen(licenseForFeature) {
    this.setState({
      modalUpgradeLicenseShow: true,
      licenseForFeature: licenseForFeature,
    });
  }

  modalUpgradeLicenseClose() {
    this.setState({ modalUpgradeLicenseShow: false });
  }
}

export { CampaignPage };
