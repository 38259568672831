/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../../_services/server.service';
import i18n from "i18next";
import {Trans} from "react-i18next";


import moment from 'moment'


interface MyProps {
    onQuestionsImported(extra:string):any,
    onHide():any,
    show:boolean,
    onShow?():any,
    size?:any,
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error:string,
    success:string,
    selectedFile:any,
    formFields:any,

    excelRows:any,
    validationErrors:any,
    isFileValid:boolean,
    areDatesOverlaping:boolean;

}

class ModalImportSchedules extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',
            success: '',

            selectedFile: [],
            formFields:{},

            excelRows:[],
            validationErrors:{},
            isFileValid:false,
            areDatesOverlaping:false,

        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onShow = this.onShow.bind(this);

    }

    componentDidMount(){
    }


    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true, loading: true});

        let {excelRows,validationErrors,isFileValid,areDatesOverlaping} = this.state;


        if(excelRows.length == 0){
            let fileArr = (document.getElementById('fileinput') as HTMLInputElement).files;

            // stop here if form is invalid
            if (!(fileArr[0])) {
                toast.error(i18n.t("trans_33_modal_import_msg_no_file_selected"));
                this.setState({submitted: false, loading: false});
                return;
            }
            serverService.getDataFromExcel(fileArr[0])
                .then(
                    result => {
                        validationErrors = result.validationErrors;

                        areDatesOverlaping = false;
                        if(validationErrors.datesFromDb !== undefined && validationErrors.datesFromDb.length > 0){
                            areDatesOverlaping = true;
                        }

                        isFileValid = true;
                        if(validationErrors.datesNotFormattedCorrectly !== undefined && validationErrors.datesNotFormattedCorrectly.length > 0){
                            isFileValid = false;
                        }
                        if(validationErrors.dateFromPastArray !== undefined && validationErrors.dateFromPastArray.length > 0){
                            isFileValid = false;
                        }
                        if(validationErrors.dateFromFarFutureArray !== undefined && validationErrors.dateFromFarFutureArray.length > 0){
                            isFileValid = false;
                        }
                        if(validationErrors.datesNotInSelectedDays !== undefined && validationErrors.datesNotInSelectedDays.length > 0){
                            isFileValid = false;
                        }
                        if(validationErrors.sameDateArray !== undefined && validationErrors.sameDateArray.length > 0){
                            isFileValid = false;
                        }
                        if(validationErrors.locationsDifference !== undefined && validationErrors.locationsDifference.length > 0){
                            isFileValid = false;
                        }
                        if(validationErrors.voxesDifference !== undefined && validationErrors.voxesDifference.length > 0){
                            isFileValid = false;
                        }

                        this.setState({excelRows: result.data
                            , validationErrors: result.validationErrors
                            , isFileValid: isFileValid
                            , areDatesOverlaping: areDatesOverlaping
                            , loading: false
                        });
                    },
                    error => {
                        toast.warn("Did not import questions");
                        this.setState({ loading: false });
                    }
                );
        } if(excelRows.length > 0 && !isFileValid){
            this.setState({excelRows: [], validationErrors: {}, loading: false});
        }  else if(excelRows.length > 0 && isFileValid){
            serverService.importSchedules(excelRows)
                .then(
                    result => {
                        this.setState({submitted: false, loading: false,});
                        if(result.status === "1"){
                            toast.success(i18n.t("trans_33_modal_import_msg_questions_imported_successfully"));
                            this.props.onQuestionsImported(result.extra);

                            if(result.successes != undefined){
                                let successesStr = result.successes.map((item, key) =>
                                    <li>{item}</li>
                                );
                                this.setState({ success:successesStr});
                            }

                        } else {

                            if(result.errors != undefined){
                                let errorsStr = result.errors.map((item, key) =>
                                    <li>{item}</li>
                                );

                                this.setState({ error:errorsStr});
                            }

                            toast.error(i18n.t("trans_33_modal_import_msg_import_failed"));
                        }
                    },
                    error => {
                        toast.warn("Did not import questions");
                        this.setState({ loading: false });
                    }
                );
        }

    }


    render() {
        let {loading,excelRows,validationErrors,isFileValid,areDatesOverlaping} = this.state;

        let listItems = "";
        if(excelRows && excelRows.length > 0){

            listItems = excelRows.map((oneRow,index) => {
                let date = oneRow[0];
                let question = oneRow[1];
                let location = oneRow[2];
                let vox = oneRow[3];

                let dateTooltip = "";
                let questionTooltip = "";
                let locationTooltip = "";
                let voxTooltip = "";

                let dateClass = "col-md-3 px-2 text-center";
                let questionClass = "col-md-3 px-2 text-center";
                let locationClass = "col-md-3 px-2 text-center";
                let voxClass = "col-md-3 px-2 text-center";


                if(validationErrors.datesNotFormattedCorrectly !== undefined
                    && validationErrors.datesNotFormattedCorrectly.includes(date)){
                    dateTooltip += i18n.t("trans_33_modal_import_schedules_date_not_formatted_correctly") + "\n";
                    dateClass += " text-danger";
                }
                if(validationErrors.dateFromPastArray !== undefined
                    && validationErrors.dateFromPastArray.includes(date)){
                    dateTooltip += i18n.t("trans_33_modal_import_schedules_date_from_the_past") + "\n";
                    dateClass += " text-danger";
                }
                if(validationErrors.dateFromFarFutureArray !== undefined
                    && validationErrors.dateFromFarFutureArray.includes(date)){
                    dateTooltip += i18n.t("trans_33_modal_import_schedules_date_over_6_months") + "\n";
                    dateClass += " text-danger";
                }
                if(validationErrors.datesNotInSelectedDays !== undefined
                    && validationErrors.datesNotInSelectedDays.includes(date)){
                    dateTooltip += i18n.t("trans_33_modal_import_schedules_day_of_week_disabled_for_company") + "\n";
                    dateClass += " text-danger";
                }
                if(validationErrors.sameDateArray !== undefined
                    && validationErrors.sameDateArray.includes(date)){
                    dateTooltip += i18n.t("trans_33_modal_import_schedules_multiple_questions_on_the_same_day") +  "\n";
                    dateClass += " text-danger";
                }

                if(validationErrors.locationsDifference !== undefined
                    &&  validationErrors.locationsDifference.includes(location)){
                    locationTooltip += i18n.t("trans_33_modal_import_schedules_location_does_not_exist") + "\n";
                    locationClass += " text-danger";
                }

                if(validationErrors.voxesDifference !== undefined
                    && validationErrors.voxesDifference.includes(vox)){
                    voxTooltip += i18n.t("trans_33_modal_import_schedules_vox_does_not_exist") + "\n";
                    voxClass += " text-danger";
                }


                return <div className="row">
                    <div className={dateClass} title={dateTooltip} >{date}</div>
                    <div className={questionClass}>{question}</div>
                    <div className={locationClass} title={locationTooltip}>{location}</div>
                    <div className={voxClass} title={voxTooltip}>{vox}</div>
                </div>;
            });
        }

        let importButtonText = "";
        if(listItems.length == 0){
            importButtonText = i18n.t("trans_33_modal_import_and_review")
        } else {
            importButtonText = i18n.t("trans_33_modal_import_confirm")
        }

        if(listItems.length > 0 && !isFileValid){
            importButtonText = i18n.t("trans_33_modal_import_import_new_file");
        }

        return (
            <Modal onShow={this.onShow}
                show={this.props.show}
                onHide={this.props.onHide}
                size={this.props.size}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="add-vox-modal-header" closeButton translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        <Trans>
                            trans_33_modal_import_scheduled_questions
                        </Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="add-vox-modal-content" >

                    <Form onSubmit={this.handleSubmit}>
                        {listItems.length == 0 &&
                        <div className="container">
                            <div className="row">
                                <div className="col-md-1 align-middle" style={{marginTop:'auto',marginBottom:'auto'}}><b>1.</b></div>
                                <div className="col-md-11">
                                    <a target="_blank" href="/assets/examples/VoxCollector_import.xlsx">
                                        <Button variant="primary">
                                            <Trans>
                                                trans_33_modal_import_download_excel_masterfile
                                            </Trans>
                                        </Button>
                                    </a>
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-md-1 align-middle" style={{marginTop:'auto',marginBottom:'auto'}}><b>2.</b></div>
                                <div className="col-md-11">
                                    <Form.Group>
                                        <p style={{color: 'grey'}}>
                                            <Trans>
                                                trans_33_modal_import_fill_the_file
                                            </Trans>
                                        </p>
                                        <Form.Control size="sm" type="file"
                                                      id="fileinput"
                                                      placeholder="Select excel file"
                                                      name="selectedFile"
                                                      onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-md-1 align-middle" style={{marginTop:'auto',marginBottom:'auto'}} ><b>3.</b></div>
                                <div className="col-md-11">
                                    <Button variant="primary" type="submit" disabled={loading}>
                                        {importButtonText}
                                    </Button>
                                    {loading &&
                                    <img alt="loading"
                                         src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                    }
                                </div>
                            </div>
                        </div>
                        }

                        {listItems.length > 0 && !isFileValid &&
                        <p style={{ color: 'red' }}>
                            <Trans>
                                trans_33_modal_import_msg_data_not_correct
                            </Trans>
                        </p>
                        }

                        {listItems.length > 0 && isFileValid &&
                        <p style={{ color: 'green' }}>
                            <Trans>
                                trans_33_modal_import_data_is_valid
                            </Trans>
                        </p>
                        }

                        {listItems.length > 0 &&
                        <div>
                            <div className="row">
                                <div className="col-md-3 px-2 text-center"><b><Trans>trans_33_Date</Trans></b></div>
                                <div className="col-md-3 px-2 text-center"><b><Trans>trans_33_Question</Trans></b></div>
                                <div className="col-md-3 px-2 text-center"><b><Trans>trans_33_Location</Trans></b></div>
                                <div className="col-md-3 px-2 text-center"><b><Trans>trans_33_Vox</Trans></b></div>
                            </div>

                            {listItems}

                            {isFileValid && areDatesOverlaping &&
                            <p style={{color: 'orange'}}>
                                <Trans>trans_33_modal_import_msg_questions_already_exist</Trans>
                            </p>
                            }
                            <br/>
                            <Button variant="primary" type="submit" disabled={loading}>
                                {importButtonText}
                            </Button>
                            {loading &&
                            <img alt="loading"
                                 src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                            }

                        </div>
                        }


                    </Form>

                </Modal.Body>
                <br/>
            </Modal>
        );
    }

    onShow(){
        this.setState({excelRows: [], loading: false
            , validationErrors:{}, isFileValid:false, areDatesOverlaping:false,
        });
    }

}

export default ModalImportSchedules;