import React from "react";

import { serverService } from "../../../_services/server.service";

import CompaniesTable from "./AdminCompaniesTable";

interface MyProps {}

interface MyState {
  companies: any;
}

class AdminCompaniesPage extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
    };

    this.onCompanyAdded = this.onCompanyAdded.bind(this);
    this.onCompanyEdited = this.onCompanyEdited.bind(this);
    this.onCompanyDeleted = this.onCompanyDeleted.bind(this);
  }

  componentDidMount() {
    this.setState({
      companies: [],
    });

    this.doFetchAllCompanies();
  }

  render() {
    return (
      <div className="container mt-5 p-5">
        <CompaniesTable
          companies={this.state.companies}
          onCompanyAdded={this.onCompanyAdded}
          onCompanyEdited={this.onCompanyEdited}
          onCompanyDeleted={this.onCompanyDeleted}
        />
      </div>
    );
  }

  doFetchAllCompanies() {
    serverService.getCompanies().then((result) => {
      this.setState({ companies: result.data });
    });
  }

  onCompanyAdded() {
    this.doFetchAllCompanies();
  }

  onCompanyEdited() {
    this.doFetchAllCompanies();
  }

  onCompanyDeleted() {
    this.doFetchAllCompanies();
  }
}

export { AdminCompaniesPage };
