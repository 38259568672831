/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { serverService } from '../../../_services/server.service';
import QuestionsAutocomplete from '../../_other/QuestionsAutocomplete';

import VoxSelect from '../../_other/VoxSelect';
import LocationSelect from '../../_other/LocationSelect';
import i18n from 'i18next';
import { Trans } from 'react-i18next';

import moment from 'moment'

interface MyProps {
  selectedIdVox: number;
  selectedIdLocation: number;
  searchedText: string;
  selectedDay: any;

  onScheduleAdded(): any;
  show: boolean;
  onHide(): any;
}

interface MyState {
  submitted: boolean;
  loading: boolean;
  error: string;
  formFields: any;

  selectedIdVox: number;
  selectedIdLocation: number;
}

class ModalAddNewSchedule extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      loading: false,
      error: '',
      formFields: {},
      selectedIdVox: 0,
      selectedIdLocation: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleQuestionChange = this.handleQuestionChange.bind(this);
    this.onVoxSelected = this.onVoxSelected.bind(this);
    this.onLocationSelected = this.onLocationSelected.bind(this);
  }

  componentDidMount() {}

  handleChange(e) {
    const { name, value } = e.target;
    const formFields = this.state.formFields;
    formFields[name] = value;
    this.setState({ formFields: formFields });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });

    const { formFields, selectedIdVox, selectedIdLocation } = this.state;
    const Question = formFields.Question;

    // stop here if form is invalid
    if (!Question) {
      toast.error(i18n.t('trans_33_missing_fields'));
      return;
    }

    let userStr = localStorage.getItem('user');
    let IdUser = 0;
    if (userStr) {
      const userObj = JSON.parse(userStr);
      IdUser = userObj.IdUser;
    }

    this.setState({ submitted: true, loading: true });

    let question = {
      IdQuestion: 0,
      IdUser: IdUser.toString(),
      QuestionText: Question,
    };
    serverService.insertQuestion(question).then(
      insertQuestionResult => {
        let insertPromises = [];
        if (selectedIdVox == 0) {
          let getVoxesPromise;
          if (selectedIdLocation == 0) {
            getVoxesPromise = serverService.getAllActiveVoxes();
          } else {
            getVoxesPromise = serverService.getAllVoxesAtLocation(
              selectedIdLocation
            );
          }

          getVoxesPromise.then(
            getVoxesResult => {
              const voxes = getVoxesResult.data;

              for (let i = 0; i < voxes.length; i++) {
                const schedule = {
                  IdVox: voxes[i].IdVox.toString(),
                  IdQuestion: insertQuestionResult.data.IdQuestion.toString(),
                  Date: this.props.selectedDay,
                };
                let tempPromise = serverService.insertSchedule(schedule);
                insertPromises.push(tempPromise);
              }

              Promise.all(insertPromises).then(
                result => {
                  this.setState({ submitted: false, loading: false });

                  this.props.onHide();
                  this.props.onScheduleAdded();
                  toast.success(
                    i18n.t('trans_33_modal_schedule_msg_questions_scheduled')
                  );
                },
                error => {
                  toast.warn('Did not insert schedule');
                  this.setState({ loading: false });
                }
              );
            },
            error => {
              toast.warn('Did not get voxes');
            }
          );
        } else {
          const schedule = {
            IdVox: selectedIdVox,
            IdQuestion: insertQuestionResult.data.IdQuestion.toString(),
            Date: this.props.selectedDay,
          };
          serverService.insertSchedule(schedule);
          insertPromises.push();

          Promise.all(insertPromises).then(
            result => {
              this.setState({ submitted: false, loading: false });

              this.props.onHide();
              this.props.onScheduleAdded();
              toast.success(
                i18n.t('trans_33_modal_schedule_msg_questions_scheduled')
              );
            },
            error => {
              toast.warn('Did not insert schedule');
              this.setState({ loading: false });
            }
          );
        }
      },
      error => {
        toast.warn('Did not insert question');
        this.setState({ loading: false });
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps != this.props) {
      this.setState({
        submitted: false,
        loading: false,
        error: '',
        formFields: {},
        selectedIdVox: this.props.selectedIdVox,
        selectedIdLocation: this.props.selectedIdLocation,
      });
    }
  }

  render() {
    let { loading } = this.state;

    let titleStr = i18n.t('trans_33_modal_schedule_title');
    let confirmButtonStr = i18n.t('trans_33_Submit');
    let cancelButtonStr = i18n.t('trans_33_Cancel');

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        dialogClassName='modal-90w'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header
          className='add-vox-modal-header'
          closeButton
          translate='yes'
        >
          <Modal.Title id='example-custom-modal-styling-title'>
            {titleStr}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='add-vox-modal-content'>
          <Form onSubmit={this.handleSubmit}>
              <div className="p-3" >
              <b>{moment(this.props.selectedDay).format('DD/MM/YY')}</b>
              </div>
            {/* <br /> */}
            <Form.Group controlId='formBasicName'>
              <Form.Label>
                *<Trans>trans_33_Question</Trans>
              </Form.Label>
              <QuestionsAutocomplete
                selectedQuestion={this.state.formFields.Question}
                handleQuestionChange={this.handleQuestionChange}
              />
            </Form.Group>

            <div className="p-3" >
              <Trans>trans_33_modal_schedule_display_at</Trans> &nbsp;
              <br/>
              <span style={{ display: 'inline-block' }}>
                <LocationSelect
                  selectedIdLocation={this.state.selectedIdLocation}
                  onLocationSelected={this.onLocationSelected}
                />
              </span>
              &nbsp;on&nbsp;
              <span style={{ display: 'inline-block' }}>
                <VoxSelect
                  selectedIdLocation={this.state.selectedIdLocation}
                  selectedIdVox={this.state.selectedIdVox}
                  onVoxSelected={this.onVoxSelected}
                />
              </span>
            </div>

            <br />

            <div style={{ textAlign: 'right' }}>
              <Button variant='secondary' onClick={this.props.onHide}>
                {cancelButtonStr}
              </Button>
              &nbsp;
              <Button variant='primary' type='submit' disabled={loading}>
                {confirmButtonStr}
              </Button>
              {loading && (
                <img
                  alt='loading'
                  src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
                />
              )}
            </div>
          </Form>
        </Modal.Body>

        <br />
      </Modal>
    );
  }

  handleQuestionChange(questionString) {
    let formFields = this.state.formFields;
    formFields.Question = questionString;
    this.setState({ formFields: formFields });
  }

  onVoxSelected(IdVox) {
    this.setState({ selectedIdVox: IdVox });
  }

  onLocationSelected(IdLocation) {
    this.setState({ selectedIdLocation: IdLocation });
  }
}

export default ModalAddNewSchedule;
