import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../../_services/server.service';
import { config } from '../../../_helpers/C';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import  {
    formatDate,
    parseDate,
} from 'react-day-picker/build/addons/MomentLocaleUtils';

import SkillSelect from "../../_other/SkillSelect";
import ItemsAutocomplete from "../../_other/ItemsAutocomplete";
import i18n from "i18next";

import sha1 from 'sha1' 



interface MyProps {
    selectedResource:any,
    onResourceAdded(extra:string):any,
    onResourceEdited(extra:string):any,
    show:boolean,
    onHide():any,
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields:any,
    selectedResource:any,

    voxes:any,
    users:any,
}

class AdminModalAddNewResource extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',

            formFields:{},

            selectedResource: {},

            voxes:[],
            users:[],
        };

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleLicenseTypeChange = this.handleLicenseTypeChange.bind(this);

        this.handleUploadFileChange = this.handleUploadFileChange.bind(this);

        this.onSkillSelected = this.onSkillSelected.bind(this);
        this.onUserSelected = this.onUserSelected.bind(this);
        this.onVoxSelected = this.onVoxSelected.bind(this);

        this.fetchAdminVoxes = this.fetchAdminVoxes.bind(this);
        this.fetchAdminUsers = this.fetchAdminUsers.bind(this);
    }

    componentDidMount(){
        this.fetchAdminVoxes();
        this.fetchAdminUsers();
    }

    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }

    handleUploadFileChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;


        let fileArr = (document.getElementById('fileinput') as HTMLInputElement).files;
        const selectedFile = fileArr[0];

        this.setState({ loading: true,submitted: true });

        serverService.uploadResource(selectedFile).then(
                result => {
                    if(result.status == 1){
                        let messageStr = "Resource uploaded";
                        toast.success(messageStr);

                        formFields['Link'] = config.API_BASE_URL + "/resources/" + result.filename;
                        this.setState({ formFields: formFields });

                    } else {
                        toast.error("Upload resource failed");
                    }

                    this.setState({ loading: false,submitted: false });
                },
                error => {
                    toast.warn("Did not upload resources");
                    this.setState({ loading: false });
                }
        );
    }

    handleTypeChange(e) {
        let formFields = this.state.formFields;
        formFields['TypeId'] = e.target.id;
        this.setState({ formFields: formFields });
    }

    handleLicenseTypeChange(e) {
        let formFields = this.state.formFields;
        formFields['LicenseTypeId'] = e.target.id;
        this.setState({ formFields: formFields });
    }

    handleDateChange(from) {
        let formFields = this.state.formFields;
        formFields["ResourceDate"] = from;
        this.setState({ formFields: formFields });
    }


    onSkillSelected(IdSkill){
        let formFields = this.state.formFields;
        formFields['IdSkill'] = IdSkill;
        this.setState({ formFields: formFields });
    }

    onUserSelected(UserName){
        let formFields = this.state.formFields;
        formFields['UserName'] = UserName;
        this.setState({ formFields: formFields });
    }

    onVoxSelected(VoxName){
        let formFields = this.state.formFields;
        formFields['VoxName'] = VoxName;
        this.setState({ formFields: formFields });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});

        const { formFields, users, voxes } = this.state;

        let Type = 0;
        let LicenseType = 0;
        let IdUser = 0;
        let IdVox = 0;

        const Name = formFields.Name;
        const Link = formFields.Link;
        // const selectedFile = formFields.selectedFile;
        const TypeId = formFields.TypeId;
        const LicenseTypeId = formFields.LicenseTypeId;
        const IdSkill = formFields.IdSkill;
        const UserName = formFields.UserName;
        const VoxName = formFields.VoxName;
        const ResourceDate = formFields.ResourceDate;

        console.log(formFields);

        if(TypeId == "TypeRadio1"){
            Type = 1;
        } else if(TypeId == "TypeRadio2"){
            Type = 2;
        }

        if(LicenseTypeId == "LicenseTypeRadio1"){
            LicenseType = 1;
        } else if(LicenseTypeId == "LicenseTypeRadio2"){
            LicenseType = 2;
        } else if(LicenseTypeId == "LicenseTypeRadio3"){
            LicenseType = 3;
        }

        if(UserName){
            for(let i=0;i<users.length;i++){
                if(users[i].Username == UserName){
                    IdUser = users[i].IdUser;
                }
            }
        }

        if(VoxName){
            for(let i=0;i<voxes.length;i++){
                if(voxes[i].Name == VoxName){
                    IdVox = voxes[i].IdVox;
                }
            }
        }

        // stop here if form is invalid
        if (!Name || !Link || !TypeId || !LicenseTypeId) {
            toast.error("Missing fields");
            return;
        }

        let resource = {'IdResource': '0','Name':Name, 'Link':Link
                            , 'Type':Type + '', 'LicenseType':LicenseType + ''
                            , 'IdSkill':IdSkill + '', 'IdUser':IdUser + '', 'IdVox':IdVox + '', 'ResourceDate':ResourceDate};

        //console.log(resource);
        //console.log(selectedFile);

        if(this.state.selectedResource && typeof(this.state.selectedResource.IdResource) !== "undefined"){
            resource.IdResource = this.state.selectedResource.IdResource.toString();

            serverService.updateResource(resource)
                .then(
                    result => {
                        if(result.status == 1){
                            let messageStr = "Resource edited";
                            toast.success(messageStr);
                            this.props.onResourceEdited(result.extra);
                        } else {
                            toast.error("Edit resource failed");
                        }
                    },
                    error => {
                        toast.warn("Did not update resource");
                    }
                );
        } else {
            serverService.insertResource(resource)
                .then(
                    result => {
                        if(result.status == 1){
                            let messageStr = "Resource added";
                            toast.success(messageStr);
                            this.props.onResourceAdded(result.extra);
                        } else {
                            toast.error("Add resource failed");
                        }
                    },
                    error => {
                        toast.warn("Did not insert resource");
                    }
                );
        }

    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.selectedResource !== prevState.selectedResource){
            this.setState({selectedResource:this.props.selectedResource});

            let formFields = this.state.formFields;
            formFields.Name = "";
            formFields.Link = "";
            formFields.selectedFile = "";

            if(this.state.selectedResource && typeof(this.state.selectedResource.IdResource) !== "undefined"){
                formFields.Name = this.props.selectedResource.Name;
                formFields.Link = this.props.selectedResource.Link;

                if(this.props.selectedResource.Type == 1){
                    formFields.TypeId = "TypeRadio1";
                } else if(this.props.selectedResource.Type == 2){
                    formFields.TypeId = "TypeRadio2";
                }

                if(this.props.selectedResource.LicenseType == 1){
                    formFields.LicenseTypeId = "LicenseTypeRadio1";
                } else if(this.props.selectedResource.LicenseType == 2){
                    formFields.LicenseTypeId = "LicenseTypeRadio2";
                } else if(this.props.selectedResource.LicenseType == 3){
                    formFields.LicenseTypeId = "LicenseTypeRadio3";
                }
            }

            this.setState({formFields:formFields});
        }
    }


    render() {

        let {formFields, loading} = this.state;

        let titleStr = "Add Resource";
        if(this.state.selectedResource && typeof(this.state.selectedResource.IdResource) !== "undefined"){
            titleStr = "Edit Resource";
        }

        let confirmButtonStr = "Add Resource";
        if(this.state.selectedResource && typeof(this.state.selectedResource.IdResource) !== "undefined"){
            confirmButtonStr = "Save";
        }

        let pdfChecked = false;
        let videoChecked = false;

        console.log(formFields);

        if(formFields.TypeId === "TypeRadio1"){
            pdfChecked = true;
        } else if(formFields.TypeId === "TypeRadio2"){
            videoChecked = true;
        }

        let selfStarterChecked = false;
        let standardChecked = false;
        let enterpriseChecked = false;

        if(formFields.LicenseTypeId === "LicenseTypeRadio1"){
            selfStarterChecked = true;
        } else if(formFields.LicenseTypeId === "LicenseTypeRadio2"){
            standardChecked = true;
        } else if(formFields.LicenseTypeId === "LicenseTypeRadio3"){
            enterpriseChecked = true;
        }

        console.log("selfStarterChecked: " + selfStarterChecked);
        console.log("standardChecked: " + standardChecked);
        console.log("enterpriseChecked: " + enterpriseChecked);

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        {titleStr}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSubmit}>

                        <Form.Group>
                            <Form.Label>*Name</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Type-in the resource name"
                                          name="Name"
                                          value={this.state.formFields.Name}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>*Link</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Type-in the resource link"
                                          name="Link"
                                          value={this.state.formFields.Link}
                                          onChange={this.handleChange}
                            />
                            OR
                            <br/>

                            <Form.Label>*Select a resource file</Form.Label>
                            <Form.Control size="sm" type="file"
                                          id="fileinput"
                                          placeholder="Select resource file"
                                          name="selectedFile"
                                          onChange={this.handleUploadFileChange}
                            />

                            {loading &&
                            <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            }
                        </Form.Group>


                        <Form.Group onChange={event => this.handleTypeChange(event)}>
                            <Form.Label>*Type</Form.Label>
                            <div key={`custom-inline-radio-type`} className="mb-2">
                                <Form.Check
                                    checked={pdfChecked}
                                    inline
                                    name="typeGroup"
                                    label="pdf"
                                    type="radio"
                                    id="TypeRadio1"
                                />
                                <Form.Check
                                    inline
                                    checked={videoChecked}
                                    name="typeGroup"
                                    label="video"
                                    type="radio"
                                    id="TypeRadio2"
                                />
                            </div>
                        </Form.Group>


                        <Form.Group onChange={event => this.handleLicenseTypeChange(event)}>
                            <Form.Label>*License</Form.Label>
                            <div key={`custom-inline-radio-license`} className="mb-3">
                                <Form.Check
                                    inline
                                    checked={selfStarterChecked}
                                    name="licenseGroup"
                                    label="Self-starter"
                                    type="radio"
                                    id="LicenseTypeRadio1"
                                />
                                <Form.Check
                                    inline
                                    checked={standardChecked}
                                    name="licenseGroup"
                                    label="Standard"
                                    type="radio"
                                    id="LicenseTypeRadio2"
                                />
                                <Form.Check
                                    inline
                                    checked={enterpriseChecked}
                                    name="licenseGroup"
                                    label="Enterprise"
                                    type="radio"
                                    id="LicenseTypeRadio3"
                                />
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Skill</Form.Label>
                            <SkillSelect onSkillSelected={this.onSkillSelected} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>User</Form.Label>
                            <ItemsAutocomplete selectedItem={this.state.formFields.UserName} items={this.state.users} onItemSelected={this.onUserSelected}  />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Vox</Form.Label>
                            <ItemsAutocomplete selectedItem={this.state.formFields.VoxName} items={this.state.voxes} onItemSelected={this.onVoxSelected}  />
                        </Form.Group>


                        <Form.Group>
                            <Form.Label>Date</Form.Label>
                            <div className="InputResourceDate">
                                <DayPickerInput
                                    placeholder={i18n.t('trans_33_choose_date')}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    format="DD/MM/YYYY"
                                    onDayChange={this.handleDateChange}
                                    dayPickerProps={{
                                        firstDayOfWeek:1,
                                    }}
                                />
                            </div>
                        </Form.Group>

                        <br/>

                        <Button variant="primary" type="submit">
                            {confirmButtonStr}
                        </Button>
                    </Form>
                </Modal.Body>
                <br/>
            </Modal>
        );
    }

    fetchAdminVoxes(){
        serverService.getAllAdminVoxes()
            .then(
                result => {
                    if(result.status == 1){
                        this.setState({
                            voxes:result.data,
                        });
                    }
                },
                error => {
                    toast.warn("Did not get voxes");
                }
            );
    }

    fetchAdminUsers(){
        serverService.getAllAdminUsers()
            .then(
                result => {
                    if(result.status == 1){
                        this.setState({
                            users:result.data,
                        });
                    }
                },
                error => {
                    toast.warn("Did not get users");
                }
            );
    }


}

export default AdminModalAddNewResource;