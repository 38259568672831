/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";


interface MyProps {
    actionTitle: string,
    actionBody: string,
    noBtnText: string,
    yesBtnText: string,
    onConfirmNo(): any,
    onConfirmYes(): any,
    show:boolean,
    onHide(): any,
}

interface MyState {
    loading:boolean,
}


class ModalConfirmAction extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
        };

        this.onClickYes = this.onClickYes.bind(this);
    }

    render() {
        let {loading} = this.state;

        return (
            <Modal

                show={this.props.show}
                onHide={this.props.onHide}
                dialogClassName="modal-90w confimDialog"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="add-vox-modal-header" closeButton translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        {this.props.actionTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal_content" dangerouslySetInnerHTML={{__html: this.props.actionBody}}/>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onConfirmNo}>
                        {this.props.noBtnText}
                    </Button>

                    <Button variant="primary" onClick={this.props.onConfirmYes} disabled={loading}>
                        {this.props.yesBtnText}
                    </Button>

                    {loading &&
                    <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                </Modal.Footer>
            </Modal>
        );
    }

    onClickYes() {
        this.setState({loading:true,});
        this.props.onConfirmYes();
    }

}

export default ModalConfirmAction;