import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";
import {serverService} from "../../_services/server.service";
import {Trans} from "react-i18next";
import i18n from "i18next";
import ResultsSelfStarterChart from "../_other/ResultsSelfStarterChart";

interface MyProps {
    show:boolean,
    onHide(): any,
}

interface MyState {
    chart1Json: any;
    chart2Json: any;
}


class ModalAnswersChart extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);
        this.state = {
            chart1Json: [],
            chart2Json: [],
        };


        this.onEntered = this.onEntered.bind(this);

    }


    componentDidMount() {
        serverService.getResultsNumOfAnswersPerVoxChart().then(
            (result) => {
                if (result.status === "1") {
                    let allResults = result.data.allResults;
                    let allVoxes = result.data.allVoxes;

                    let emptyVoxObject = { day: "" };
                    for (let i = 0; i < allVoxes.length; i++) {
                        let IdVox = allVoxes[i]["IdVox"];
                        let Name = allVoxes[i]["Name"];

                        emptyVoxObject[Name] = "";
                    }

                    let tempDate = "";
                    let chart1Json = [];
                    let chart2Json = [];
                    let tempOneObject = { ...emptyVoxObject };
                    for (let i = 0; i < allResults.length; i++) {
                        let oneResult = allResults[i];

                        if (tempDate !== oneResult.Date) {
                            tempDate = oneResult.Date;

                            if (i > 0) {
                                chart1Json.push(tempOneObject);
                            }

                            tempOneObject = { ...emptyVoxObject };
                        } else {
                        }

                        tempOneObject["day"] = oneResult.Date;
                        tempOneObject[oneResult.Name] = oneResult.NumberOfAnswers;
                    }
                    chart1Json.push(tempOneObject);

                    this.setState({ chart1Json: chart1Json });
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                onEntered = {this.onEntered}
                size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        <Trans>trans_33_modal_answers_chart_title</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.chart1Json.length > 0 && (
                        <ResultsSelfStarterChart
                            chart1Data={this.state.chart1Json}
                            displayButtons={true}
                        />
                    )}
                </Modal.Body>

            </Modal>
        );
    }


    onEntered(){



    }

}

export default ModalAnswersChart;