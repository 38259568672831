/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../../_services/server.service';
import i18n from "i18next";
import {Trans} from "react-i18next";


interface MyProps {
    selectedQuestion:any,
    onQuestionAdded(extra:string):any,
    show:boolean,
    onHide():any,
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields:any,
    selectedQuestion:any,
}

class ModalAddNewQuestion extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',

            formFields:{},

            selectedQuestion: {}
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
    }

    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});

        const { formFields } = this.state;
        const QuestionText = formFields.QuestionText;

        // stop here if form is invalid
        if (!(QuestionText)) {
            toast.error(i18n.t("trans_33_msg_missing_fields"));
            return;
        }

        let userStr = localStorage.getItem('user');
        let IdCompany = 0;
        if(userStr) {
            let userObj = JSON.parse(userStr);
            IdCompany = userObj.IdCompany;
        }

        this.setState({submitted: true, loading: true});

        let question = {'IdQuestion': 0,'IdCompany':IdCompany.toString(), 'QuestionText':QuestionText };
        if(this.state.selectedQuestion && typeof(this.state.selectedQuestion.IdQuestion) !== "undefined"){
            question.IdQuestion = this.state.selectedQuestion.IdQuestion;

            serverService.updateQuestion(question)
                .then(
                    result => {
                        this.setState({submitted: false, loading: false});
                        if(result.status == 1){
                            let messageStr = i18n.t("trans_33_msg_question_edited");
                            toast.success(messageStr);
                            this.props.onQuestionAdded(result.extra);
                        } else {
                            toast.error(i18n.t("trans_33_msg_question_edit_failed"));
                        }
                    },
                    error => {
                        toast.warn("Did not update question");
                        this.setState({ loading: false });
                    }
                );
        } else {
            serverService.insertQuestion(question)
                .then(
                    result => {
                        this.setState({submitted: false, loading: false});
                        if(result.status == 1){
                            let messageStr = i18n.t("trans_33_msg_question_added");
                            toast.success(messageStr);
                            this.props.onQuestionAdded(result.extra);
                        } else {
                            toast.error(i18n.t("trans_33_msg_question_add_failed"));
                        }
                    },
                    error => {
                        toast.warn("Did not insert question");
                        this.setState({ loading: false });
                    }
                );
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.selectedQuestion !== prevState.selectedQuestion){
            this.setState({selectedQuestion:this.props.selectedQuestion});

            let formFields = this.state.formFields;
            formFields.QuestionText = "";

            if(this.state.selectedQuestion && typeof(this.state.selectedQuestion.IdQuestion) !== "undefined"){
                formFields.QuestionText = this.props.selectedQuestion.QuestionText;
            }

            this.setState({formFields:formFields});
        }
    }


    render() {
        let {loading} = this.state;

        let titleStr = i18n.t("trans_33_modal_add_question_add");
        if(this.state.selectedQuestion && typeof(this.state.selectedQuestion.IdQuestion) !== "undefined"){
            titleStr = i18n.t("trans_33_modal_add_question_edit");
        }

        let confirmButtonStr = i18n.t("trans_33_modal_add_question_add");
        if(this.state.selectedQuestion && typeof(this.state.selectedQuestion.IdQuestion) !== "undefined"){
            confirmButtonStr = i18n.t("trans_33_save");
        }

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        {titleStr}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSubmit}>

                        <Form.Group controlId="formBasicName">
                            <Form.Label>
                                *<Trans>trans_33_modal_add_question_question_text</Trans>
                            </Form.Label>
                            <Form.Control size="sm" type="text" placeholder={i18n.t("trans_33_modal_add_question_type_in_the_question")}
                                          name="QuestionText"
                                          value={this.state.formFields.QuestionText}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        <br/>

                        <Button variant="primary" type="submit" disabled={loading}>
                            {confirmButtonStr}
                        </Button>
                        {loading &&
                        <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        }
                    </Form>
                </Modal.Body>
                <br/>
            </Modal>
        );
    }

}

export default ModalAddNewQuestion;