import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../../_services/server.service';
import CompaniesAutocomplete from '../CompaniesAutocomplete';
 

import sha1 from 'sha1' 

interface MyProps {
    selectedUser:any,
    onUserAdded(extra:string):any,
    onUserEdited(extra:string):any,
    show:boolean,
    onHide():any,
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields:any,
    selectedUser:any,
    selectedCompanyObject:any,
}

class AdminModalAddNewUser extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',

            formFields:{},

            selectedUser: {},
            selectedCompanyObject:{}
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
    }

    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }

    handleCompanyChange(companyString,companyIfExists) {
        let formFields = this.state.formFields;
        formFields.Company = companyString;
        this.setState({ formFields: formFields, selectedCompanyObject:companyIfExists });
    }


    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});

        const { formFields,selectedCompanyObject } = this.state;
        const Username = formFields.Username;
        const Company = formFields.Company;
        const Password = sha1(formFields.Password);
        const Timezone = formFields.Timezone;

        // stop here if form is invalid
        if (!(Username) || !(Timezone)) {
            toast.error("Missing fields");
            return;
        }


        if(!selectedCompanyObject || !selectedCompanyObject.IdCompany){
            toast.error("You need to select an existing company");
            return;
        }


        let user = {'IdUser': '0','IdCompany':selectedCompanyObject.IdCompany.toString(), 'Username':Username, 'Timezone':Timezone};
        if(Password !== undefined && Password.length > 0){
            user['Password'] = Password;
        }
        if(this.state.selectedUser && typeof(this.state.selectedUser.IdUser) !== "undefined"){
            user.IdUser = this.state.selectedUser.IdUser.toString();

            serverService.updateUser(user)
                .then(result => {
                    if(result.status === "1"){
                        let messageStr = "User edited";
                        toast.success(messageStr);
                        this.props.onUserEdited(result.extra);
                    } else {
                        toast.error("Edit user failed");
                    }
                });
        } else {
            serverService.insertUser(user)
                .then(
                    result => {
                        if(result.status === "1"){
                            let messageStr = "User added";
                            toast.success(messageStr);
                            this.props.onUserAdded(result.extra);
                        } else {
                            toast.error("Add user failed");
                        }
                    },
                    error => {
                        toast.warn("Did not insert user");
                    }
                );
        }

    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.selectedUser !== prevState.selectedUser){
            this.setState({selectedUser:this.props.selectedUser});

            let formFields = this.state.formFields;
            formFields.Username = "";
            formFields.Password = "";
            formFields.Company = "";
            formFields.Timezone = "";

            if(this.state.selectedUser && typeof(this.state.selectedUser.IdUser) !== "undefined"){
                formFields.Username = this.props.selectedUser.Username;
                formFields.Company = this.props.selectedUser.Company;
                formFields.Timezone = this.props.selectedUser.Timezone;
            }

            this.setState({formFields:formFields});
        }
    }


    render() {
        let titleStr = "Add User";
        if(this.state.selectedUser && typeof(this.state.selectedUser.IdUser) !== "undefined"){
            titleStr = "Edit User";
        }

        let confirmButtonStr = "Add User";
        if(this.state.selectedUser && typeof(this.state.selectedUser.IdUser) !== "undefined"){
            confirmButtonStr = "Save";
        }

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        {titleStr}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSubmit}>

                        <Form.Group controlId="formBasicUsername">
                            <Form.Label>*Username</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Type-in the username"
                                          name="Username"
                                          value={this.state.formFields.Username}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Type-in the password"
                                          name="Password"
                                          value={this.state.formFields.Password}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>


                        <Form.Group controlId="formBasicName">
                            <Form.Label>*Company</Form.Label>
                            <CompaniesAutocomplete selectedCompany={this.state.formFields.Company}
                                                   handleCompanyChange={this.handleCompanyChange}
                            />
                        </Form.Group>


                        <Form.Group controlId="formBasicTimezone">
                            <Form.Label>*Timezone</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Type-in the timezone (eg. America/Vancouver)"
                                          name="Timezone"
                                          value={this.state.formFields.Timezone}
                                          onChange={this.handleChange}
                            />
                            <a target="_blank" href="https://en.wikipedia.org/wiki/List_of_tz_database_time_zones">See timezones list</a>
                        </Form.Group>


                        <br/>

                        <Button variant="primary" type="submit">
                            {confirmButtonStr}
                        </Button>
                    </Form>
                </Modal.Body>
                <br/>
            </Modal>
        );
    }

}

export default AdminModalAddNewUser;