import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import i18n from 'i18next';
import {Trans} from "react-i18next";

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DateTimePicker ,
} from '@material-ui/pickers';


import { serverService } from '../../_services/server.service';
import VoxSelect from "../_other/VoxSelect";
import ItemsSelect from "../_other/ItemsSelect";

import moment from 'moment'
let momentTz = require('moment-timezone');



interface MyProps {
    selectedScan:any,
    selectedQPack:any,
    selectedQuestionIds:any,
    show:boolean,
    onHide():any,

    onScanCreated():any,
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields:any,
    selectedScan:any,
    selectedCompanyObject:any,

    radio1Checked:boolean,

    selectedIdVox:number,
    voxes: any,
}

class ModalAddNewScanChooseDateToSend extends React.Component<MyProps,MyState> {

    private userObj;

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',

            formFields:{},

            selectedScan: {},
            selectedCompanyObject:{},

            radio1Checked:true,

            selectedIdVox:0,
            voxes: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDateToSendChange = this.handleDateToSendChange.bind(this);

        this.onEntered = this.onEntered.bind(this);

        this.handleRadioChange = this.handleRadioChange.bind(this);

        this.onVoxSelected = this.onVoxSelected.bind(this);

    }


    onEntered() {
        let formFields = this.state.formFields;
        formFields.Label = i18n.t("trans_33_scan_label");

        if(this.props.selectedScan && typeof(this.props.selectedScan.IdScan) !== "undefined"){
            console.log(this.props.selectedScan);
            formFields.Label = this.props.selectedScan.Label;

            let selectedIdVox = this.props.selectedScan.IdVox;
            let radio1Checked = true;

            if(this.props.selectedScan.Sent == 0){
                radio1Checked = false;

                formFields.DateToSend = this.props.selectedScan.DateToSend;

                // const DateToSend = moment(formFields.DateToSend).format(
                //     'YYYY-MM-DD HH:mm:ss'
                // );
                //
                // oneScan['DateToSend'] = momentTz
                //     .tz(DateToSend, Timezone)
                //     .utc()
                //     .format('YYYY-MM-DD HH:mm:ss');
            }

            this.setState({ selectedIdVox: selectedIdVox, radio1Checked: radio1Checked });
        }

        this.setState({ formFields: formFields });

        serverService.getAllLinkedMsTeamsBotOrSlackVoxes().then(
            result => {
                if (result.status === '1') {
                    let allLinkedVoxes = [];
                    for(let i=0;i<result.data.length;i++){
                        if(result.data[i]['Type'] == 5){
                            result.data[i]['Name'] += " - Teams";
                        } else if(result.data[i]['Type'] == 2){
                            result.data[i]['Name'] += " - Slack";
                        }
                        result.data[i]['IdItem'] = result.data[i]['IdVox'];

                        allLinkedVoxes.push(result.data[i])
                    }
                    this.setState({ voxes: allLinkedVoxes, });

                } else {
                    toast.warn('Error while fetching voxes');
                }
            },
            error => {
                toast.warn('Did not get voxes');
            }
        );
    }


    componentDidMount() {
        const userStr = localStorage.getItem('user');
        if (userStr) {
            this.userObj = JSON.parse(userStr);
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }


    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});

        const { formFields } = this.state;
        const Label = formFields.Label;


        // stop here if form is invalid
        if (!(Label)) {
            toast.error("Missing fields");
            return;
        }

        if (this.state.selectedIdVox == 0) {
            toast.error("Vox not selected");
            return;
        }

        let IdCompany = 0;
        let Timezone = '';

        if (this.userObj) {
            IdCompany = this.userObj.IdCompany;
            Timezone = this.userObj.Timezone;
        }

        let isEditMode = this.state.selectedScan && typeof(this.state.selectedScan.IdScan) !== "undefined";

        let oneScan = {'Label': Label, 'IdVox': this.state.selectedIdVox};

        if(isEditMode){
            oneScan['IdScan'] = this.state.selectedScan.IdScan.toString();
            oneScan['Name'] = this.state.selectedScan['Name'];
        } else {
            oneScan['Name'] = this.props.selectedQPack['Name'];
        }


        if(!this.state.radio1Checked){
            const DateToSend = moment(formFields.DateToSend).format(
                'YYYY-MM-DD HH:mm:ss'
            );

            oneScan['DateToSend'] = momentTz
                .tz(DateToSend, Timezone)
                .utc()
                .format('YYYY-MM-DD HH:mm:ss');
        }


        let sendNow = 0;
        if(this.state.radio1Checked){
            sendNow = 1;
        }

        serverService.insertOrUpdateScan(oneScan, sendNow, this.props.selectedQuestionIds)
            .then(result => {
                if(result.status === "1"){
                    if(isEditMode){
                        toast.success("Scan edited");
                    } else {
                        toast.success("Scan created");
                    }

                    this.props.onScanCreated();
                } else {
                    toast.error("Add/Edit scan failed");
                }
            });

    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.selectedScan !== prevState.selectedScan){
            this.setState({selectedScan:this.props.selectedScan});
        }
    }


    render() {

        let isEditMode = this.state.selectedScan && typeof(this.state.selectedScan.IdScan) !== "undefined";

        let titleStr = "Create scan";
        let confirmButtonStr = "Create scan";
        if(isEditMode){
            titleStr = "Create scan";
            confirmButtonStr = "Create scan";
        }


        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                onEntered={this.onEntered}
                size='lg'
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className='modal-header-update p-4' closeButton translate="yes">
                    <Modal.Title as="h4" id="example-custom-modal-styling-title">
                        {titleStr}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-5'>
                    <Form onSubmit={this.handleSubmit}>


                         <span style={{ display: 'inline-block', width: '100%', maxWidth: 480 }}>
                            <Form.Label className='font-weight-bold'><Trans>trans_33_where_to_send_scan</Trans></Form.Label>

                            <ItemsSelect className="add-new-pulse-select" size="lg" selectedIdItem={this.state.selectedIdVox} items={this.state.voxes}
                                         onItemSelected={this.onVoxSelected} defaultText={i18n.t("trans_33_select_send_scan")} />

                        </span>

                        <br/><br/>

                        <Form.Check
                            inline
                            label={i18n.t("trans_33_send_scan_now") as string}
                            type="radio"
                            id={`inline-radio-1`}
                            checked={this.state.radio1Checked}
                            onChange={this.handleRadioChange}
                        />


                        <Form.Check
                            inline
                            label={i18n.t("trans_33_send_scan_later") as string}
                            type="radio"
                            id={`inline-radio--2`}
                            checked={!this.state.radio1Checked}
                            onChange={this.handleRadioChange}
                        />


                        {!this.state.radio1Checked &&
                            <div className="vox-date-picker" >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DateTimePicker
                                        ampm={false}
                                        style={{ width: '100%' }}
                                        margin='normal'
                                        id='time-picker'
                                        label={i18n.t("trans_33_when_to_send")}
                                        value={this.state.formFields.DateToSend}
                                        onChange={this.handleDateToSendChange}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        }


                        <br/><br/>

                        <Form.Group controlId="formBasicName">
                        <Form.Label className='font-weight-bold'><Trans>trans_33_descriptive_label_add_scan</Trans></Form.Label>
                        <Form.Control
                            size="lg"
                            as='textarea'
                            rows={4}
                            placeholder={i18n.t("trans_33_descriptive_label_add_scan_hint")}
                            name="Label"
                            value={this.state.formFields.Label}
                            onChange={this.handleChange}
                        />


                        </Form.Group>


                        <br/>

                        <Button className='px-4 py-2' size='lg' variant="primary" type="submit">
                            {confirmButtonStr}
                        </Button>
                    </Form>
                </Modal.Body>
                <br/>
            </Modal>
        );
    }




    handleDateToSendChange(date) {
        let formFields = this.state.formFields;
        formFields['DateToSend'] = date;
        this.setState({ formFields: formFields });
    }

    handleRadioChange(){
        this.setState({ radio1Checked: !this.state.radio1Checked });
    }


    onVoxSelected(IdVox){
        this.setState({ selectedIdVox: IdVox });
    }


}

export default ModalAddNewScanChooseDateToSend;