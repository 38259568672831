import React from 'react';
import {Form} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../_services/server.service';

interface MyProps {
    onItemSelected(IdItem:number):any,
    selectedIdItem?:number,
    defaultText:string,
    items:any,
    disabled?:boolean,
    size?: "sm" | "lg",
    className?: string
}

interface MyState {
}

class ItemsSelect extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        let items = this.props.items;
        const { size = "sm", className } = this.props;

        let optionItems = "";
        if(items && items.length > 0){
             optionItems = items.map((item) => {
                return <option key={item.IdItem} value={item.IdItem}>{item.Name}</option>
             });
        }

        let selectedIdItem:string = "0";
        if(this.props.selectedIdItem !== undefined){
            selectedIdItem = String(this.props.selectedIdItem);
        }

        return (
                <Form.Control
                    className={className}
                    disabled={this.props.disabled}
                    onChange={this.onItemSelected} size={size} as="select" value={selectedIdItem}>
                    <option key='0' value='0'>{this.props.defaultText}</option>
                    {optionItems}
                </Form.Control>
        )
    }

    onItemSelected = (event) => {
        this.props.onItemSelected(event.target.value);
    };
}

export default ItemsSelect;
