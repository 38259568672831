import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import i18n from 'i18next';
import {Trans} from "react-i18next";

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DateTimePicker ,
} from '@material-ui/pickers';


import { serverService } from '../../_services/server.service';
import VoxSelect from "../_other/VoxSelect";

import moment from 'moment'
let momentTz = require('moment-timezone');





interface MyProps {
    selectedPulse:any,
    onPulseAdded(extra:string):any,
    onPulseEdited(extra:string):any,
    show:boolean,
    onHide():any,
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields:any,
    selectedPulse:any,
    selectedCompanyObject:any,

    radio1Checked:boolean;
}

class ModalAddNewPulseStep3 extends React.Component<MyProps,MyState> {

    private userObj;

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',

            formFields:{},

            selectedPulse: {},
            selectedCompanyObject:{},

            radio1Checked:true
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDateToSendChange = this.handleDateToSendChange.bind(this);

        this.onEntered = this.onEntered.bind(this);

        this.handleRadioChange = this.handleRadioChange.bind(this);

    }


    onEntered() {
        let formFields = this.state.formFields;
        formFields.Label = i18n.t("trans_33_pulse_label");

        this.setState({ formFields: formFields });
    }


    componentDidMount() {
        const userStr = localStorage.getItem('user');
        if (userStr) {
            this.userObj = JSON.parse(userStr);
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }


    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});

        const { formFields } = this.state;
        const Label = formFields.Label;


        // stop here if form is invalid
        if (!(Label)) {
            toast.error("Missing fields");
            return;
        }


        let IdCompany = 0;
        let Timezone = '';

        if (this.userObj) {
            IdCompany = this.userObj.IdCompany;
            Timezone = this.userObj.Timezone;
        }


        let pulse = {'Label': Label};

        if(!this.state.radio1Checked){
            const DateToSend = moment(formFields.DateToSend).format(
                'YYYY-MM-DD HH:mm:ss'
            );

            pulse['DateToSend'] = momentTz
                .tz(DateToSend, Timezone)
                .utc()
                .format('YYYY-MM-DD HH:mm:ss');
        }


        if(this.state.selectedPulse && typeof(this.state.selectedPulse.IdPulse) !== "undefined"){
            pulse['IdPulse'] = this.state.selectedPulse.IdPulse.toString();

            let sendNow = 0;
            if(this.state.radio1Checked){
                sendNow = 1;
            }

            serverService.insertOrUpdatePulse(pulse,null,sendNow)
                .then(result => {
                    if(result.status === "1"){
                        let messageStr = i18n.t("trans_33_msg_pulse_edited");
                        toast.success(messageStr);
                        this.props.onPulseEdited(result.extra);
                    } else {
                        toast.error("Edit pulse failed");
                    }
                });
        } else {
            toast.error("Pulse not selected");
        }

    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.selectedPulse !== prevState.selectedPulse){
            this.setState({selectedPulse:this.props.selectedPulse});

            let formFields = this.state.formFields;

            if(this.state.selectedPulse && typeof(this.state.selectedPulse.IdPulse) !== "undefined"){
                formFields.Label = this.props.selectedPulse.Label;
            }

            this.setState({formFields:formFields});
        }
    }


    render() {
        let isEditMode = typeof this.state.selectedPulse.IdPulse !== "undefined";

        let titleStr = i18n.t("trans_33_add_pulse_step3_title");
        let confirmButtonStr = i18n.t("trans_33_add_pulse");

        if(isEditMode){
            titleStr = i18n.t("trans_33_edit_pulse");
            confirmButtonStr = i18n.t("trans_33_save");
        }

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                onEntered={this.onEntered}
                size='lg'
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className='modal-header-update p-4' closeButton translate="yes">
                    <Modal.Title as="h4" id="example-custom-modal-styling-title">
                        {titleStr}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-5'>
                    <Form onSubmit={this.handleSubmit}>

                        <Form.Group controlId="formBasicName">
                          <Form.Label className='font-weight-bold'><Trans>trans_33_descriptive_label_add_pulse</Trans></Form.Label>
                          <Form.Control
                              size="sm"
                              as='textarea'
                              rows={4}
                              placeholder={i18n.t("trans_33_descriptive_label_add_pulse_hint")}
                              name="Label"
                              value={this.state.formFields.Label}
                              onChange={this.handleChange}
                          />
                        </Form.Group>

                        <div className='pt-2'>
                          <Form.Check
                              inline
                              className='mr-4'
                              label={i18n.t("trans_33_send_pulse_now") as string}
                              type="radio"
                              id={`inline-radio-1`}
                              checked={this.state.radio1Checked}
                              onChange={this.handleRadioChange}
                          />

                          <Form.Check
                              inline
                              label={i18n.t("trans_33_send_pulse_later") as string}
                              type="radio"
                              id={`inline-radio--2`}
                              checked={!this.state.radio1Checked}
                              onChange={this.handleRadioChange}
                          />
                        </div>

                        {!this.state.radio1Checked &&
                            <>
                              <div className="vox-date-picker" >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DateTimePicker
                                        ampm={false}
                                        style={{ width: '100%' }}
                                        margin='normal'
                                        id='time-picker'
                                        label={i18n.t("trans_33_when_to_send")}
                                        value={this.state.formFields.DateToSend}
                                        onChange={this.handleDateToSendChange}
                                    />
                                </MuiPickersUtilsProvider>
                              </div>
                            </>
                        }
                        <br/><br/>

                        <div className='text-right'>
                          <Button className='px-4 py-2' size='lg' variant="primary" type="submit">
                              {confirmButtonStr}
                          </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }


    handleDateToSendChange(date) {
        let formFields = this.state.formFields;
        formFields['DateToSend'] = date;
        this.setState({ formFields: formFields });
    }

    handleRadioChange(){
        this.setState({ radio1Checked: !this.state.radio1Checked });
    }

}

export default ModalAddNewPulseStep3;