import React from "react";

import { RouteComponentProps } from "react-router-dom";

let sha1 = require("sha1");

interface MyProps {}

interface MyState {
}
 
class AccountSuspendedPage extends React.Component<
  MyProps & RouteComponentProps,
  MyState
> {
  constructor(props) {
    super(props);

    localStorage.removeItem("user");

    this.state = { 
    };

  }

  render() {

    return (
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
        <h1 style={{fontSize:'80px'}}> Account suspended from 18/08/2022 </h1>
    </div> 
    ); 
  }
 
}

export { AccountSuspendedPage };
