import React from 'react';
import { toast } from "react-toastify";

import ModalConfirmAction from '../_other/ModalConfirmAction';

import {Button, ButtonToolbar} from 'react-bootstrap'

import { serverService } from '../../_services/server.service';
import i18n from "i18next";
import ModalPulseQuestions from "./ModalPulseQuestions";
import ModalAddNewPulseStep1 from "./ModalAddNewPulseStep1";
import ModalAddNewPulseStep3 from "./ModalAddNewPulseStep3";
import {Trans} from "react-i18next";
import ModalCopyLink from "../MyVoxPage/ModalCopyLink";

import ModalTosAI from "../_modals/ModalTosAI";
import ModalSummary from "./ModalSummary";

import { config } from '../../_helpers/C';

import moment from 'moment'

const $ = require('jquery');
$.DataTable = require('datatables.net');

// require( 'datatables.net-bs4' );
// require( 'datatables.net-select-bs4' );


interface MyProps {
    onPulseDeleted():any,
    channels:any,
    onAnswerClick: (pulseId: string, name: string) => void,
}

interface MyState {
    pulses: any,
    submitted: boolean,
    modalAddNewStep1Show:boolean,
    modalAddNewStep3Show:boolean,
    modalPulseQuestionsShow:boolean,
    modalConfirmDeleteShow: boolean,
    modalCopyLinkShow:boolean,
    selectedLink: string,
    selectedPulse: any,
    formFields:any,
    selectedIdPulse:number,
    selectedToken:string,
    selectedPulseNumOfAnswers:number,
    isDuringAddingPulse:boolean,

    modalTosAI: boolean,
    modalSummary: boolean,
}

class PulseTable extends React.Component<MyProps,MyState> {

    private userObj;


    constructor(props) {
        super(props);

        this.state = {
            pulses:[],
            submitted:false,
            modalAddNewStep1Show:false,
            modalAddNewStep3Show:false,
            modalPulseQuestionsShow:false,
            modalConfirmDeleteShow:false,
            modalCopyLinkShow:false,
            selectedLink: "",
            formFields:{},
            selectedPulse:{},
            selectedIdPulse:0,
            selectedToken:"",
            selectedPulseNumOfAnswers:0,
            isDuringAddingPulse:false,

            modalTosAI:false,
            modalSummary:false,
        };


        this.onPulseAdded = this.onPulseAdded.bind(this);
        this.onPulseEdited = this.onPulseEdited.bind(this);

        this.handleChange = this.handleChange.bind(this);

        this.reloadTableData = this.reloadTableData.bind(this);
        this.onNavigateToStep3 = this.onNavigateToStep3.bind(this);

        this.showLinkToPulse = this.showLinkToPulse.bind(this);
        this.showLinkToSummary = this.showLinkToSummary.bind(this);
        this.showTosAI = this.showTosAI.bind(this);
        this.showSummary = this.showSummary.bind(this);

        this.onTosAccepted = this.onTosAccepted.bind(this);


    }

    componentDidMount() {

        this.userObj = JSON.parse(localStorage.getItem("user"));
        console.log(this.userObj);

        let columns = [
          {
              title: i18n.t("trans_33_pulse"),
              width: 240,
              data: 'Name',
          },
          {
              title: i18n.t("trans_33_date_and_time"),
              width: 240,
              data: 'DateToSend',
          },
          // {
          //     title: i18n.t("trans_33_channels"),
          //     width: 200,
          //     data: 'ChannelNames',
          // },
          {
              title: i18n.t("Actions"),
              width: 160,
              data: 'IdPulse',
              orderable: false
          },
          {
              title: i18n.t("trans_33_questions"),
              width: 160,
              data: 'IdPulse',
              orderable: false
          },
        ]

        let table = $(this.refs.main).DataTable({
            dom: "<'data-table-wrapper'" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'><'col-sm-12 col-md-7'p>>" +
                    ">",
            // dom: 'Bfrtip',
            data: this.state.pulses,
            columns,
            ordering: true,
            paging:true,
            // buttons: [
            //     { extend: 'excel', text: i18n.t("trans_33_export_excel") }
            // ],
            select: {
                style: 'single',
                //blurable: true
            },

            columnDefs: [
                {
                    render: function ( data, type, row ) {
                        if(data != null){
                            return moment(data).format('D MMM YYYY HH:mm:ss');
                        } else {
                            return "";
                        }
                    },
                    targets: 1,
                },

                {
                    render: function ( data, type, row ) {
                        if(data != null){
                            let linkButtonText = i18n.t("View link");
                            let executiveSummaryButtonText = i18n.t("Summary");


                            let executiveSummaryButton = "";

                            
                            const currentDate = moment().startOf('day');;
                            const endMoment = moment(row.DateToEnd).startOf('day');
                            let daysUntilEnds = endMoment.diff(currentDate, 'days');
                            if(!daysUntilEnds) {
                                daysUntilEnds = 0;
                            }
                            const daysUntilEndsText = "\n" + (daysUntilEnds > 0?' available in ' + daysUntilEnds + ' days':'')


                            console.log(row);

                            if(row.NumOfAnswers > 0 && row.NumOfComments >= 4){
                                executiveSummaryButton = "<button " + (daysUntilEnds <= 0?'':'disabled') + " class='btn btn-outline-primary btn-table-actions'" +
                                    " type='summary_link'" +
                                    " IdPulse=" + row.IdPulse + ""
                                    + " IdVox=" + row.IdVox + ""
                                    + " Token=" + row.Token + ""
                                    + ">" + executiveSummaryButtonText 
                                        + daysUntilEndsText 
                                    + "</button>";
                            }
                          

                            const linkButton =  "<button class='btn btn-outline-primary btn-table-actions'" +
                                " type='link'" +
                                " IdPulse=" + row.IdPulse + ""
                                + " IdVox=" + row.IdVox + ""
                                + " Token=" + row.Token + ""
                                + ">" + linkButtonText
                                + "<i class='fas fa-link ml-2'></i>"
                                + "</button>";

                            return executiveSummaryButton + linkButton;

                        } else {
                            return "";
                        }
                    },
                    targets: 2,
                },

                {
                    render: function ( data, type, row ) {
                        if(data != null){
                            let buttonText = "";
                            if(row.NumOfAnswers > 0){
                                buttonText = i18n.t("trans_33_view_answers");
                            } else {
                                buttonText = i18n.t("trans_33_manage_questions");
                            }

                            return "<button class='btn btn-primary w-100 btn-table-actions'" +
                                " IdPulse=" + row.IdPulse + ""
                                + " IdVox=" + row.IdVox + ""
                                + " name=" + row.Name + ""
                                + " NumOfAnswers=" + row.NumOfAnswers + ""
                                + ">" + buttonText + "</button>";

                        } else {
                            return "";
                        }
                    },
                    targets: 3,
                },
            ]
        });

        $("#deleteBtn").hide();
        $("#editBtn").hide();

        table.on('select', function ( e, dt, type, indexes ) {
            $("#deleteBtn").show();
            $("#editBtn").show();
        });

        table.on('deselect', function ( e, dt, type, indexes ) {
            $("#deleteBtn").hide();
            $("#editBtn").hide();
        });

        this.doFetchAllPulses();

    }

    componentWillUnmount(){
        $('.data-table-wrapper')
            .find('.pulsesTable')
            .DataTable()
            .destroy(true);
    }

    componentDidUpdate(oldProps) {
    }


    render() {
        let modalAddNewStep1Open = (itemToEdit) => this.setState({ modalAddNewStep1Show: true, selectedPulse:itemToEdit });
        let modalAddNewStep3Open = (itemToEdit) => this.setState({ modalAddNewStep3Show: true, selectedPulse:itemToEdit });
        let closeAddNewModal = () => this.setState({ modalAddNewStep1Show: false, modalAddNewStep3Show: false  });


        let modalConfirmDeleteOpen = (itemToEdit) => {
            const table = $('.data-table-wrapper')
                .find('.pulsesTable')
                .DataTable();
            let data = table.rows( { selected: true }).data();

            this.setState({ modalConfirmDeleteShow: true });
        };
        let modalConfirmDeleteClose = () => this.setState({ modalConfirmDeleteShow: false });

        let deleteSelectedItemWithConfirmation = () => {
            modalConfirmDeleteOpen(null);
        };


        let modalPulseQuestionsClose = () => this.setState({ modalPulseQuestionsShow: false });
        let closeCopyLinkModal = () => this.setState({ modalCopyLinkShow: false });



        let editSelectedItem = () => {
            const table = $('.data-table-wrapper')
                .find('.pulsesTable')
                .DataTable();
            let data = table.rows( { selected: true }).data();

            modalAddNewStep3Open(data[0]);
        };


        let deleteSelectedItem = () => {
            const table = $('.data-table-wrapper')
                .find('.pulsesTable')
                .DataTable();

            let data = table.rows( { selected: true }).data();

            let IdPulse = data[0].IdPulse;

            serverService.deletePulse(IdPulse)
                .then(
                    result => {
                        if(result.status == 1){
                            toast.success("Pulse deleted");
                            $("#deleteBtn").hide();
                            this.props.onPulseDeleted();
                            this.doFetchAllPulses();
                        } else {
                            toast.error("Delete failed");
                        }

                        this.setState({ modalConfirmDeleteShow: false });
                    },
                    error => {
                        toast.warn("Did not delete user");
                    }
                );
        };


        return (
          <div>
            <div className="row mb-4">
                <div className="col-md-10">
                  <div className='row align-items-center '>
                    <h3 className='col-md-4 w-auto m-0 font-weight-bold'><Trans>trans_33_pulses</Trans></h3>
                    <div className='col-md-8 d-flex flex-nowrap align-items-center w-100 input-group relative border border-secondary rounded'>
                      <i className="pl-2 fas fa-search"></i>
                      <input
                        className='w-100 p-2 shadow-none border-0'
                        style={{ outline: 0 }}
                        type="text"
                        placeholder={i18n.t("trans_33_search")}
                        onChange={e => {
                          const table = $(this.refs.main)
                            .dataTable();

                          table.fnFilter( e.target.value );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-2 d-flex justify-content-end">
                  {/* <Button
                    className='p-2 mr-3 btn-outline-primary-updated'
                    variant="outline-primary"
                    size="lg"
                    onClick={() => {
                      $(this.refs.main)
                        .DataTable().button().trigger();
                    }}
                  >
                    <Trans>trans_33_export_excel</Trans>
                  </Button> */}
                  <Button
                      className='px-4'
                      id="addBtn"
                      variant="primary"
                      size="lg"
                      onClick={modalAddNewStep1Open}
                  >
                      <Trans>trans_33_add_pulse</Trans>
                  </Button>
                </div>
            </div>
            
            <div className="row">
              <div className="col-md-12" id="usersTableContainer">
                <table style={{ width: '100%' }} ref="main" className="table table-bordered rounded table-data-sort pulsesTable" />
                <div className="float-right">

                    <ButtonToolbar>

                        <Button
                            id="editBtn"
                            variant="light"
                            
                            onClick={editSelectedItem}
                        >
                            <Trans>trans_33_Edit</Trans>
                        </Button>
                        &nbsp;
                        <Button
                            id="deleteBtn"
                            variant="light"
                            onClick={deleteSelectedItemWithConfirmation}
                        >
                            <Trans>trans_33_Delete</Trans>
                        </Button>

                    </ButtonToolbar>

                </div>

                <ModalAddNewPulseStep1
                    show={this.state.modalAddNewStep1Show}
                    selectedPulse={this.state.selectedPulse}
                    onHide={closeAddNewModal}
                    onPulseAdded={this.onPulseAdded}
                    onPulseEdited={this.onPulseEdited}
                    channels={this.props.channels}
                />

                <ModalAddNewPulseStep3
                    show={this.state.modalAddNewStep3Show}
                    selectedPulse={this.state.selectedPulse}
                    onHide={closeAddNewModal}
                    onPulseAdded={this.onPulseAdded}
                    onPulseEdited={this.onPulseEdited}
                />

                <ModalConfirmAction
                    show={this.state.modalConfirmDeleteShow}
                    actionTitle={i18n.t("trans_33_confirm_delete")}
                    actionBody={i18n.t("trans_33_pulse_delete_are_you_sure")}
                    noBtnText={i18n.t("trans_33_no_go_back")}
                    yesBtnText={i18n.t("trans_33_yes_confirm")}
                    onConfirmNo={modalConfirmDeleteClose}
                    onHide={modalConfirmDeleteClose}
                    onConfirmYes={deleteSelectedItem}
                />


                <ModalPulseQuestions
                    size="lg"
                    show={this.state.modalPulseQuestionsShow}
                    selectedIdPulse={this.state.selectedIdPulse}
                    selectedPulseNumOfAnswers={this.state.selectedPulseNumOfAnswers}
                    isDuringAddingPulse={this.state.isDuringAddingPulse}
                    onHide={modalPulseQuestionsClose}
                    onNavigateToStep3={this.onNavigateToStep3}
                />

                <ModalCopyLink
                    show={this.state.modalCopyLinkShow}
                    selectedLink={this.state.selectedLink}
                    title={"Copy link"}
                    onHide={closeCopyLinkModal}
                />
 
                <ModalTosAI
                    show={this.state.modalTosAI}
                    onHide={()=> {this.setState({modalTosAI:false})}}
                    onTosAccepted={this.onTosAccepted}
                />

                <ModalSummary
                    show={this.state.modalSummary}
                    onHide={()=> {this.setState({modalSummary:false})}}
                    IdPulse={this.state.selectedIdPulse}
                    Token={this.state.selectedToken}
                /> 

              </div>
            </div>
          </div>
        );
    }


    onPulseAdded(pulse){
        this.setState({modalAddNewStep1Show: false, modalPulseQuestionsShow:true
            ,selectedIdPulse: pulse['IdPulse'],selectedPulseNumOfAnswers:0,isDuringAddingPulse:true
            ,selectedPulse: pulse});
        this.doFetchAllPulses();
    }

    onPulseEdited(item){
        this.setState({modalAddNewStep3Show: false});
        this.doFetchAllPulses();
    }

    onNavigateToStep3(){
        this.setState({modalPulseQuestionsShow: false, modalAddNewStep3Show: true});
    }

    onTosAccepted(){
        this.userObj['AcceptedAITosDate'] = moment().format("YYYY-MM-DD");
        localStorage.setItem("user", JSON.stringify(this.userObj));

        this.setState({ modalTosAI: false, modalSummary: true });
    }

    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }


    doFetchAllPulses() {
        serverService.getPulses().then(
            (result) => {
                this.setState({ pulses: result.data });

                this.setState({pulses: result.data },() => {
                    this.reloadTableData(this.state.pulses);
                });
            },
            (error) => {
                toast.warn("Did not get users");
            }
        );
    }

    showLinkToPulse(IdPulse, PulseToken){
        // https://admin.narvaro.eu/onepulse?IdPulse=5&h=jl4Go3q4BjzYE04


        const url = config.FRONTEND_DOMAIN_NAME + "/onepulse"
            + "?IdPulse=" + IdPulse
            + "&h=" + PulseToken;


        this.setState({modalCopyLinkShow:true, selectedLink:url,});
    }

 
    showTosAI(){
        this.setState({modalTosAI:true});
    }


    showSummary(){
        this.setState({modalSummary:true});
    }


    showLinkToSummary(IdPulse, PulseToken){
        // https://www.narvaro.eu/_api/pulse_summary?IdPulse=5&h=jl4Go3q4BjzYE04

        const url = config.DOMAIN_NAME + "/_api/pulse_summary"
            + "?IdPulse=" + IdPulse
            + "&h=" + PulseToken;


        this.setState({modalCopyLinkShow:true, selectedLink:url,});
    }

    reloadTableData(items) { 
        const table = $('.data-table-wrapper')
            .find('.pulsesTable')
            .DataTable();
        table.clear();
        table.rows.add(items);
        table.draw();


        table.on( 'click', 'button', (e, dt, type, cell, originalEvent) => {
            e.stopPropagation();

            let data = e.target.attributes;

            if(data.type != undefined && data.type.value === "link"){
                this.showLinkToPulse(data.IdPulse.value, data.Token.value)
            }  if(data.type != undefined && data.type.value === "summary_link"){
                // data.IdPulse.value, data.Token.value 

                this.setState({selectedIdPulse: data.IdPulse.value, selectedToken: data.Token.value });

                if(this.userObj['AcceptedAITosDate'] == "0000-00-00 00:00:00"){
                    this.showTosAI()
                } else {
                    this.showSummary();
                }

                
            } else {
              if(+data.NumOfAnswers.value > 0) {
                this.props.onAnswerClick(data.IdPulse.value, data.name.value);
                return;
              }
                this.setState({modalPulseQuestionsShow: true, selectedIdPulse: data.IdPulse.value, selectedPulseNumOfAnswers: data.NumOfAnswers.value,isDuringAddingPulse:false});
            
              return;
            }
        });

        $("#deleteBtn").hide();
        $("#editBtn").hide();
    }


}


export default PulseTable;
