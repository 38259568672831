import React from "react";

import { toast } from "react-toastify";
import { Tab, Tabs,OverlayTrigger,Popover  } from "react-bootstrap";

import "./AdvicesPage.css";
import AdvicesSelfStarter from "./AdvicesSelfStarter";
import AdvicesStandard from "./AdvicesStandard";
import AdvicesEnterprise from "./AdvicesEnterprise";

import PubSub from "../../_helpers/PubSub.js";
import ModalUpgradeLicense from "../_modals/ModalUpgradeLicense";
import {Trans} from "react-i18next";
import i18n from "i18next";

interface MyProps {}

interface MyState {
  license: any;
  modalUpgradeLicenseShow: boolean;
  activeTabKey: string;
  licenseForFeature: string;
}



const popoverAdvancedInsights = (
    <Popover id="popover">
      <Popover.Content>
        <Trans>
          trans_33_info_advices_advanced_insights
        </Trans>
      </Popover.Content>
    </Popover>
);


const popoverYourReports = (
    <Popover id="popover">
      <Popover.Content>
        <Trans>
          trans_33_info_advices_your_reports
        </Trans>
      </Popover.Content>
    </Popover>
);

class AdvicesPage extends React.Component<MyProps, MyState> { 
  private userObj;

  constructor(props) {
    super(props);

    this.state = {
      license: {},
      modalUpgradeLicenseShow: false,
      activeTabKey: "self-starter",
      licenseForFeature: "",
    };

    this.modalUpgradeLicenseOpen = this.modalUpgradeLicenseOpen.bind(this);
    this.modalUpgradeLicenseClose = this.modalUpgradeLicenseClose.bind(this);
    this.onTabSelected = this.onTabSelected.bind(this);
  }

  componentWillMount() {
    this.userObj = JSON.parse(localStorage.getItem("user"));
  }

  componentDidMount() {
    PubSub.subscribe("updateLicense", (license) => {
      this.setState({ license: license });
    });
  }

  componentWillUnmount() {
    PubSub.unsubscribe("updateLicense");
  }

  render() {
    let { activeTabKey } = this.state;

    let LicenseType = 0;
    if (this.userObj && this.userObj.LicenseType) {
      LicenseType = this.userObj.LicenseType;
    }

    let selfStarterTitle = (
      <span>
        <Trans>trans_33_advices_general</Trans> &nbsp; <img src="/assets/images/icon_lock24.png" />
      </span>
    );
    let standardTitle = (
      <span>
        <Trans>trans_33_advices_advances_insights</Trans> &nbsp; <img src="/assets/images/icon_lock24.png" />
        &nbsp;
        <OverlayTrigger placement="top" overlay={popoverAdvancedInsights}>
        <img style={{maxWidth:"20px"}} alt="help" src="/assets/images/info.png" />
         </OverlayTrigger>
      </span>
    );
    let enterpriseTitle = (
      <span>
        <Trans>trans_33_advices_your_reports</Trans> &nbsp; <img src="/assets/images/icon_lock24.png" />
        &nbsp;
        <OverlayTrigger placement="top" overlay={popoverYourReports}>
        <img style={{maxWidth:"20px"}} alt="help" src="/assets/images/info.png" />
         </OverlayTrigger>
      </span>
    );

    if (LicenseType) {
      if (LicenseType == 1) {
        selfStarterTitle = <span><Trans>trans_33_advices_general</Trans></span>;
      } else if (LicenseType == 2) {
        selfStarterTitle = <span><Trans>trans_33_advices_general</Trans></span>;
        standardTitle = <span>
          <Trans>trans_33_advices_advances_insights</Trans>
          &nbsp;
          <OverlayTrigger placement="top" overlay={popoverAdvancedInsights}>
          <img style={{maxWidth:"20px"}} alt="help" src="/assets/images/info.png" />
         </OverlayTrigger>
        </span>;
      } else if (LicenseType == 3) {
        selfStarterTitle = <span><Trans>trans_33_advices_general</Trans></span>;
        standardTitle = <span>
          <Trans>trans_33_advices_advances_insights</Trans>
          &nbsp;
          <OverlayTrigger placement="top" overlay={popoverAdvancedInsights}>
          <img style={{maxWidth:"20px"}} alt="help" src="/assets/images/info.png" />
         </OverlayTrigger>
        </span>;
        enterpriseTitle = <span>
          <Trans>trans_33_advices_your_reports</Trans>
          &nbsp;
          <OverlayTrigger placement="top" overlay={popoverYourReports}>
          <img style={{maxWidth:"20px"}} alt="help" src="/assets/images/info.png" />
         </OverlayTrigger>
        </span>;
      }
    }

    return (
      <div className="container p-5 mt-5" id="advicesContainer">
        <Tabs
          defaultActiveKey="self-starter"
          id="packagesTabs"
          className="packagesTabs"
          onSelect={this.onTabSelected}
          activeKey={activeTabKey}
        >
          <Tab eventKey="self-starter" title={selfStarterTitle}>
            <AdvicesSelfStarter />
          </Tab>
          <Tab eventKey="standard" title={standardTitle}>
            <AdvicesStandard />
          </Tab>
          <Tab eventKey="enterprise" title={enterpriseTitle}>
            <AdvicesEnterprise />
          </Tab>
        </Tabs> 

        <ModalUpgradeLicense
          show={this.state.modalUpgradeLicenseShow}
          licenseForFeature={this.state.licenseForFeature}
          onHide={this.modalUpgradeLicenseClose}
          onCancel={this.modalUpgradeLicenseClose}
          onConfirm={this.modalUpgradeLicenseClose}
        />
      </div>
    );
  }

  onTabSelected(eventKey, event) {
    let LicenseType = 0;
    if (this.userObj && this.userObj.LicenseType) {
      LicenseType = this.userObj.LicenseType;
    }

    let navigateToTab = true;
    if (eventKey == "self-starter") {
      if (LicenseType != 1 && LicenseType != 2 && LicenseType != 3) {
        this.modalUpgradeLicenseOpen(i18n.t("trans_33_license_self_starter"));
        navigateToTab = false;
      }
    } else if (eventKey == "standard") {
      if (LicenseType != 2 && LicenseType != 3) {
        this.modalUpgradeLicenseOpen(i18n.t("trans_33_license_standard"));
        navigateToTab = false;
      }
    } else if (eventKey == "enterprise") {
      if (LicenseType != 3) {
        this.modalUpgradeLicenseOpen(i18n.t("trans_33_license_enterprise"));
        navigateToTab = false;
      }
    }

    if (navigateToTab) {
      this.setState({ activeTabKey: eventKey });
    }
  }

  modalUpgradeLicenseOpen(licenseForFeature) {
    this.setState({
      modalUpgradeLicenseShow: true,
      licenseForFeature: licenseForFeature,
    });
  }

  modalUpgradeLicenseClose() {
    this.setState({ modalUpgradeLicenseShow: false });
  }
}

export { AdvicesPage };
