/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../../_services/server.service';
import i18n from "i18next";
import {Trans} from "react-i18next";


interface MyProps {
    onQuestionsImported(extra:string):any,
    onHide():any,
    show:boolean,
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error:string,
    success:string,
    selectedFile:any,
    formFields:any,
}

class ModalImportQuestions extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',
            success: '',

            selectedFile: [],
            formFields:{},

        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
    }


    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true, loading: true,});

        let fileArr = (document.getElementById('fileinput') as HTMLInputElement).files;

        // stop here if form is invalid
        if (!(fileArr)) {
            toast.error(i18n.t("trans_33_modal_import_msg_no_file_selected"));
            return;
        }

        serverService.importQuestions(fileArr[0])
            .then(
                result => {
                    this.setState({submitted: false, loading: false,});
                    if(result.status === "1"){
                        let messageStr = i18n.t("trans_33_modal_import_msg_questions_imported_successfully");
                        toast.success(messageStr);
                        this.props.onQuestionsImported(result.extra);

                        if(result.successes != undefined){
                            let successesStr = result.successes.map((item, key) =>
                                <li>{item}</li>
                            );
                            this.setState({ success:successesStr});
                        }

                    } else {

                        if(result.errors != undefined){
                            let errorsStr = result.errors.map((item, key) =>
                                <li>{item}</li>
                            );

                            this.setState({ error:errorsStr});
                        }

                        toast.error(i18n.t("trans_33_modal_import_msg_import_failed"));
                    }
                },
                error => {
                    toast.warn("Did not import questions");
                    this.setState({ loading: false });
                }
            );
    }


    render() {
        let {loading} = this.state;

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        <Trans>trans_33_Import</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ color: 'red' }}>
                        {this.state.error}
                    </p>

                    <p style={{ color: 'green' }}>
                        {this.state.success}
                    </p>

                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group>
                            <Form.Label>*<Trans>trans_33_modal_import_choose_excel_file</Trans></Form.Label>
                            <Form.Control size="sm" type="file"
                                          id="fileinput"
                                          placeholder={i18n.t("trans_33_select_excel_file")}
                                          name="selectedFile"
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        <br/>

                        <Button variant="primary" type="submit" disabled={loading}>
                            <Trans>
                                trans_33_Import
                            </Trans>
                        </Button>
                        {loading &&
                        <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        }
                    </Form>
                </Modal.Body>
                <br/>
            </Modal>
        );
    }

}

export default ModalImportQuestions;