/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form} from 'react-bootstrap'
import { toast } from "react-toastify";

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import  {
    formatDate,
    parseDate,
} from 'react-day-picker/build/addons/MomentLocaleUtils';

import {serverService} from "../../../_services/server.service";
import i18n from "i18next";
import {Trans} from "react-i18next";

interface MyProps {
    onConfirmSchedulePack(): void,
    show:boolean,
    onHide(): any,
    selectedIdQPack:number,
}

interface MyState {
    formFields:any,
    loading:boolean,
}


class ModalSchedulePack extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);
        this.state = {
            formFields:{},
            loading:false,
        };

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleDateChange(from) {
        let formFields = this.state.formFields;
        formFields["StartDate"] = from;
        this.setState({ formFields: formFields });
    }

    handleSubmit(e) {

        e.preventDefault();

        const { formFields } = this.state;
        const StartDate = formFields.StartDate;

        // stop here if form is invalid
        if (!(StartDate)) {
            toast.error(i18n.t("trans_33_modal_onboarding_gift_pack_msg_select_date"));
            return;
        }

        let userStr = localStorage.getItem('user');
        let IdCompany = 0;
        if(userStr) {
            let userObj = JSON.parse(userStr);
            IdCompany = userObj.IdCompany;
        }
        this.setState({ loading: true });
        serverService.schedulePack(this.props.selectedIdQPack,StartDate)
            .then(
                result => {
                    this.setState({ loading: false });
                    if(result.status == 1){
                        toast.success(i18n.t("trans_33_modal_onboarding_pack_msg_questions_scheduled"));
                        this.props.onConfirmSchedulePack();
                    } else {
                        toast.warn("An issue occurred while scheduling questions")
                    }
                }
                ,
                error => {
                    toast.warn("Did not schedule");
                    this.setState({ loading: false });
                }
            );
    }

    render() {
        let {loading} = this.state;
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        {/*<img src="/assets/images/onboarding/onboarding_gift.png" id="gift_img"/>*/}
                        <Trans>
                            trans_33_modal_schedule_pack_title
                        </Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <Trans>
                            trans_33_modal_schedule_pack_description1
                        </Trans>
                    </p>

                    <Form onSubmit={this.handleSubmit}>
                            <Form.Group controlId="formBasicName">
                                <Trans>
                                    trans_33_modal_schedule_pack_when_to_start
                                </Trans>
                                <br/>
                                <div className="InputStartDate">
                                    <DayPickerInput
                                        placeholder={i18n.t('trans_33_choose_date')}
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        format="DD/MM/YYYY"
                                        onDayChange={this.handleDateChange}
                                        dayPickerProps={{
                                            disabledDays: {before: new Date(),},
                                            firstDayOfWeek:1,
                                        }}
                                    />
                                </div>
                            </Form.Group>

                        <br/>

                        <Modal.Footer>
                            <a href="#" onClick={this.props.onHide}>
                                <Trans>
                                    trans_33_modal_schedule_pack_no_btn
                                </Trans>
                            </a>
                            <Button variant="primary" type="submit">
                                <Trans>
                                    trans_33_modal_schedule_pack_schedule_btn
                                </Trans>
                            </Button>
                            {loading &&
                            <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            }
                        </Modal.Footer>

                    </Form>

                </Modal.Body>

            </Modal>
        );
    }


}

export default ModalSchedulePack;