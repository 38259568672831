/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';

import { Button, Modal, Form, Row, Col,OverlayTrigger, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { serverService } from '../../_services/server.service';
import { Trans } from 'react-i18next';
import i18n from 'i18next';
import ItemsSelect from '../_other/ItemsSelect';

interface MyProps {
  show: boolean;
  onHide(): any;
  company: any;
  title: string;
  backdrop: any;
  closeButton: boolean;
  onCompanyDetailsUpdated: any;
}

interface MyState {
  submitted: boolean;
  loading: boolean;
  error: string;
  formFields: any;
  industries: any;

  isVatValidationSubmitted: boolean;
  isVatValid: boolean;
  vatAddress: string;
}

class ModalCompanyDetails extends React.Component<MyProps, MyState> {
  private countriesObj;

  constructor(props) {
    super(props);

    let industriesObj = i18n.t('trans_33_industries', { returnObjects: true });

    this.countriesObj = i18n.t('trans_33_countries_vat', {
      returnObjects: true,
    });

    this.state = {
      submitted: false,
      loading: false,
      error: '',

      formFields: {},
      industries: industriesObj,

      isVatValidationSubmitted: false,
      isVatValid: false,
      vatAddress: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleVatChange = this.handleVatChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNoVatChange = this.handleNoVatChange.bind(this);


    this.onModalEntered = this.onModalEntered.bind(this);
    this.onIndustrySelected = this.onIndustrySelected.bind(this);
    this.onCountryCodeSelected = this.onCountryCodeSelected.bind(this);

    this.checkVatOnVies = this.checkVatOnVies.bind(this);

  }

  handleChange(e) {
    const { name, value } = e.target;
    let formFields = this.state.formFields;
    formFields[name] = value;
    this.setState({ formFields: formFields });
  }

  handleVatChange(e) {
    const { name, value } = e.target;
    let formFields = this.state.formFields;
    formFields[name] = value;
    this.setState({ formFields: formFields, isVatValid: false, isVatValidationSubmitted: false });
  }



  handleNoVatChange(event) {
    let noVat = event.target.checked;
    let formFields = this.state.formFields;
    formFields['noVat'] = noVat;
    formFields.VATNumber = "";
    formFields.CountryCode = '0';
    this.setState({ formFields: formFields });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true, loading: true });

    const { formFields } = this.state;
    const Name = formFields.Name;
    const ResponsableFirstName = formFields.ResponsableFirstName;
    const ResponsableName = formFields.ResponsableName;
    const AddressStreet = formFields.AddressStreet?formFields.AddressStreet.trim():"";
    const AddressStreetNumber = formFields.AddressStreetNumber;
    const PostalCode = formFields.PostalCode;

    const CountryCode = formFields.CountryCode;
    const VATNumber = formFields.VATNumber;

    const noVat = formFields.noVat;
    const NoVatCountry = formFields.NoVatCountry;

    const PhoneNumber = formFields.PhoneNumber;
    const Email = formFields.Email;
    const IdIndustry = formFields.IdIndustry;


    let vatNotValid =  !noVat && (( CountryCode == '0' || !VATNumber) || !this.state.isVatValid);
    if(vatNotValid){
      toast.error(i18n.t('trans_33_vat_not_valid'));
      this.setState({ submitted: false, loading: false });
      return;
    }

    // stop here if form is invalid
    if (
      !Name ||
      !ResponsableFirstName ||
      !ResponsableName ||
      !AddressStreet ||
      !AddressStreetNumber ||
      vatNotValid ||
      !PhoneNumber ||
      !Email ||
      !IdIndustry
    ) {
      toast.error(i18n.t('trans_33_msg_missing_fields'));
      this.setState({ submitted: false, loading: false });
      return;
    }

    if (
      !/^[a-zA-Z]+$/.test(ResponsableFirstName) ||
      !/^[a-zA-Z]+$/.test(ResponsableName)
    ) {
      toast.error(i18n.t('trans_33_msg_name_should_contain_only_letters'));
      this.setState({ submitted: false, loading: false });
      return;
    }

    if (!validateEmail(Email)) {
      toast.error(i18n.t('trans_33_msg_email_not_valid'));
      this.setState({ submitted: false, loading: false });
      return;
    }

    let company = this.props.company;
    company.Name = Name.trim();
    company.ResponsableFirstName = ResponsableFirstName.trim();
    company.ResponsableName = ResponsableName.trim();
    company.AddressStreet = AddressStreet.trim();
    company.AddressStreetNumber = AddressStreetNumber.trim();
    company.AddressPostalCode = PostalCode.trim();
    company.VATNumber = CountryCode + VATNumber.trim();
    company.PhoneNumber = PhoneNumber.trim();
    company.Email = Email.trim();
    company.IdIndustry = IdIndustry;

    if(noVat){
      company['NoVatCountry'] = NoVatCountry.trim();
    } else {
      company['NoVatCountry'] = "";
    }

    serverService.updateCompany(company).then(
      response => {
        this.setState({ submitted: false, loading: false });

        if (
          response == undefined ||
          response.status == 0 ||
          response.status == '0'
        ) {
          toast.error(response.message);
          return;
        }

        this.props.onCompanyDetailsUpdated();
      },
      error => {
        toast.warn('Did not update company');
        this.setState({ loading: false });
      }
    );
  }

  render() {
    let { formFields, industries, loading } = this.state;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEntered={this.onModalEntered}
        dialogClassName='modal-90w'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop={this.props.backdrop}
      >
        <Modal.Header closeButton={this.props.closeButton} translate='yes'>
          <Modal.Title id='example-custom-modal-styling-title'>
            {this.props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId='formCompanyName'>
              <Form.Label>
                *<Trans>trans_33_company_name</Trans>
              </Form.Label>
              <Form.Control
                size='sm'
                type='text'
                placeholder={i18n.t('trans_33_type_in_the_company_name')}
                name='Name'
                value={formFields.Name}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group controlId='formResponsableFirstName'>
              <Form.Label>
                *<Trans>trans_33_responsable_first_name</Trans>
              </Form.Label>
              <Form.Control
                size='sm'
                type='text'
                placeholder={i18n.t(
                  'trans_33_type_in_the_first_name_of_responsable'
                )}
                name='ResponsableFirstName'
                value={formFields.ResponsableFirstName}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group controlId='formResponsableName'>
              <Form.Label>
                *<Trans>trans_33_responsable_name</Trans>
              </Form.Label>
              <Form.Control
                size='sm'
                type='text'
                placeholder={i18n.t('trans_33_type_in_the_name_of_responsable')}
                name='ResponsableName'
                value={formFields.ResponsableName}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group controlId='formCompanyStreet'>
              <Form.Label>
                *<Trans>trans_33_Street</Trans>
              </Form.Label>
              <Form.Control
                size='sm'
                type='text'
                placeholder={i18n.t('trans_33_Street')}
                name='AddressStreet'
                value={formFields.AddressStreet}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group controlId='formCompanyStreetNumber'>
              <Form.Label>
                *<Trans>trans_33_company_street_number</Trans>
              </Form.Label>
              <Form.Control
                size='sm'
                type='text'
                placeholder={i18n.t('trans_33_company_street_number')}
                name='AddressStreetNumber'
                value={formFields.AddressStreetNumber}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group controlId='formCompanyPostalCode'>
              <Form.Label>
                *<Trans>trans_33_postal_code</Trans>
              </Form.Label>
              <Form.Control
                size='sm'
                type='text'
                placeholder={i18n.t('trans_33_postal_code')}
                name='PostalCode'
                value={formFields.PostalCode}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group controlId='formVATNumber'>
              <Form.Label>
                *<Trans>trans_33_vat_number</Trans>
              </Form.Label>
              &nbsp;
              <OverlayTrigger placement="top" overlay={( <Popover id="popover">
                <Popover.Content>
                  <Trans>trans_33_info_vat</Trans>
                </Popover.Content>
              </Popover>)}>
                <img style={{maxWidth:"20px"}} alt="help" src="/assets/images/info.png" />
              </OverlayTrigger>


              <div className='container'>
                <div className='row'>
                  <div className='col-md3'>
                    <ItemsSelect
                        disabled={formFields.noVat}
                        selectedIdItem={formFields.CountryCode}
                      items={this.countriesObj}
                      onItemSelected={this.onCountryCodeSelected}
                      defaultText={i18n.t('trans_33_select_country_code')}
                    />
                  </div>
                  &nbsp;
                  <div className='col-md5'>
                    <Form.Control
                      disabled={formFields.noVat}
                      size='sm'
                      type='text'
                      placeholder={i18n.t('trans_33_type_in_the_vat_number')}
                      name='VATNumber'
                      value={formFields.VATNumber}
                      onChange={this.handleVatChange}
                      style={{"minHeight": "40px"}}
                    />
                  </div>
                  &nbsp;&nbsp;
                  <div className='col-md4'>

                    <Button variant='primary' disabled={formFields.noVat} onClick={this.checkVatOnVies}>
                      <Trans>trans_33_check_vat</Trans>
                    </Button>

                    &nbsp;

                    {this.state.isVatValid && this.state.isVatValidationSubmitted &&
                      <img
                          alt="Valid"
                          src="/assets/images/icon_valid.png"/>
                    }

                    {!this.state.isVatValid && this.state.isVatValidationSubmitted &&
                      <img
                          alt="Not valid"
                          src="/assets/images/icon_notvalid.png"/>
                    }
                  </div>
                </div>
                {this.state.isVatValid && this.state.isVatValidationSubmitted &&
                  <div className='row'>
                    <div className='col-md12 mt-2'>
                      ({this.state.vatAddress})
                    </div>
                  </div>
                }

                <div className='row'>
                  <div className='col-md6 mt-2'>
                    <Form.Check
                        type="checkbox"
                        id="noVat"
                        label={i18n.t("trans_33_no_vat")}
                        checked={formFields.noVat}
                        onChange={this.handleNoVatChange}
                    />
                  </div>
                  &nbsp;&nbsp;
                  {formFields.noVat &&
                    <div className='col-md6 mt-2'>
                      <Form.Control
                          size='sm'
                          type='text'
                          placeholder={i18n.t('trans_33_country')}
                          name='NoVatCountry'
                          value={formFields.NoVatCountry}
                          onChange={this.handleChange}
                      />
                    </div>
                  }
                </div>
              </div>
            </Form.Group>

            <Form.Group controlId='formPhoneNumber'>
              <Form.Label>
                *<Trans>trans_33_phone_number</Trans>
              </Form.Label>
              <Form.Control
                size='sm'
                type='text'
                placeholder={i18n.t('trans_33_type_in_the_phone_number')}
                name='PhoneNumber'
                value={formFields.PhoneNumber}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group controlId='formEmail'>
              <Form.Label>
                *<Trans>trans_33_email</Trans>
              </Form.Label>
              <Form.Control
                size='sm'
                type='text'
                placeholder={i18n.t('trans_33_type_in_the_company_email')}
                name='Email'
                value={formFields.Email}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group controlId='formIndustry'>
              <Form.Label>
                *<Trans>trans_33_select_industry</Trans>
              </Form.Label>

              <ItemsSelect
                selectedIdItem={formFields.IdIndustry}
                items={industries}
                onItemSelected={this.onIndustrySelected}
                defaultText={i18n.t('trans_33_select_industry')}
              />
            </Form.Group>

            <Button variant='primary' type='submit' disabled={loading}>
              <Trans>trans_33_save</Trans>
            </Button>
            {loading && (
              <img
                alt='loading'
                src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
              />
            )}
          </Form>
        </Modal.Body>
        <br />
      </Modal>
    );
  }


  checkVatOnVies() {
    let formFields = this.state.formFields;
    let vat = formFields.VATNumber;
    let country = formFields.CountryCode;

    serverService.checkVatOnVies(country,vat).then(result => {
          console.log(result);
          // {countryCode: "BE", vatNumber: "25353", requestDate: null, valid: false, name: "---", address: "---"}
          let isValid = result.valid;
          let vatAddress = result.address;

          this.setState({ isVatValid: isValid, isVatValidationSubmitted: true, vatAddress: vatAddress  });

        }, error => {
            console.log(error);
        })
  }

  onIndustrySelected(IdIndustry) {
    let formFields = this.state.formFields;
    formFields['IdIndustry'] = IdIndustry;
    this.setState({ formFields: formFields });
  }

  onCountryCodeSelected(CountryCode) {
    let formFields = this.state.formFields;
    formFields['CountryCode'] = CountryCode;
    this.setState({ formFields: formFields });
  }

  onModalEntered() {
    let vat = this.props.company.VATNumber;
    let CountryCode = '';
    let VATNumber = '';

    if (vat && vat.length > 2) {
      CountryCode = vat.substring(0, 2);
      VATNumber = vat.substring(2);
    }

    let formFields = this.state.formFields;
    formFields.Name = this.props.company.Name;
    formFields.ResponsableFirstName = this.props.company.ResponsableFirstName;
    formFields.ResponsableName = this.props.company.ResponsableName;
    formFields.AddressStreet = this.props.company.AddressStreet;
    formFields.AddressStreetNumber = this.props.company.AddressStreetNumber;
    formFields.PostalCode = this.props.company.AddressPostalCode;
    formFields.VATNumber = VATNumber;
    formFields.CountryCode = CountryCode;
    formFields.PhoneNumber = this.props.company.PhoneNumber;
    formFields.Email = this.props.company.Email;
    formFields.IdIndustry = this.props.company.IdIndustry;

    formFields.noVat = this.props.company.VATNumber === "0" || this.props.company.VATNumber === "";
    formFields.NoVatCountry = this.props.company.NoVatCountry;


    this.setState({ formFields: formFields });
  }
}

function validateEmail(email) {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default ModalCompanyDetails;
