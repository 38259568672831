import React from 'react';
import { toast } from "react-toastify";

import ModalConfirmAction from '../_other/ModalConfirmAction';

import {Button,ButtonToolbar,Collapse } from 'react-bootstrap'

import { serverService } from '../../_services/server.service';
import i18n from "i18next";
import {Trans} from "react-i18next";

import ModalAddNewChannel from "./ModalAddNewChannel";


const $ = require('jquery');
$.DataTable = require('datatables.net');

require( 'datatables.net-bs4' );
require( 'datatables.net-select-bs4' );

const columns = [
    {
        title: 'Name',
        width: 200,
        data: 'Name'
    },
    {
        title: 'Channel',
        width: 200,
        data: 'Channel'
    },
    {
        title: 'ChannelType',
        width: 200,
        data: 'ChannelType'
    }
];

interface MyProps {
    onChannelDeleted():any,
    onChannelsFetched(channels):any,
}

interface MyState {
    channels: any,
    submitted: boolean,
    modalAddNewChannelShow:boolean,
    modalConfirmDeleteShow: boolean,
    selectedChannel: any,
    formFields:any,
    collapsed: boolean
}

class PulseChannelsTable extends React.Component<MyProps,MyState> {
    constructor(props) {
        super(props);

        this.state = {
            channels:[],
            submitted:false,
            modalAddNewChannelShow:false,
            modalConfirmDeleteShow:false,
            formFields:{},
            selectedChannel:{},
            collapsed: false
        };


        this.onChannelAdded = this.onChannelAdded.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.reloadTableData = this.reloadTableData.bind(this);
        this.doFetchAllChannels = this.doFetchAllChannels.bind(this);
        this.handleCollapse = this.handleCollapse.bind(this);
    }

    componentDidMount() {

        let table = $(this.refs.main).DataTable({
            dom: "<'data-table-wrapper'" +
                    "<'row'<'col-md-6'<'toolbar'>><'col-md-6'>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'><'col-sm-12 col-md-7'>>" +
                    ">",
            data: this.state.channels,
            columns,
            ordering: true,
            paging:true,
            // select: {
            //     style: 'single',
            //     //blurable: true
            // },

            columnDefs: [
                // {
                //     render: function ( data, type, row ) {
                //         if(data != null){
                //             return moment(data).format('D MMM YYYY HH:mm:ss');
                //         } else {
                //             return "";
                //         }
                //     },
                //     targets: 1,
                // },

            ]
        });

        $("#deleteBtn").hide();
        // $("#editBtn").hide();

        table.on('select', function ( e, dt, type, indexes ) {
            $("#deleteBtn").show();
            //$("#editBtn").show();
        });

        table.on('deselect', function ( e, dt, type, indexes ) {
            $("#deleteBtn").hide();
            // $("#editBtn").hide();
        });


        this.doFetchAllChannels();
    }

    componentWillUnmount(){
        $('.data-table-wrapper')
            .find('.channelsTable')
            .DataTable()
            .destroy(true);
    }

    componentDidUpdate(oldProps) {
    }

    render() {
        let modalAddNewChannelOpen = (itemToEdit) => this.setState({ modalAddNewChannelShow: true, selectedChannel:itemToEdit });
        let closeAddNewModal = () => this.setState({ modalAddNewChannelShow: false  });


        let modalConfirmDeleteOpen = (itemToEdit) => {
            const table = $('.data-table-wrapper')
                .find('.channelsTable')
                .DataTable();
            let data = table.rows( { selected: true }).data();

            this.setState({ modalConfirmDeleteShow: true });
        };
        let modalConfirmDeleteClose = () => this.setState({ modalConfirmDeleteShow: false });

        let deleteSelectedItemWithConfirmation = () => {
            modalConfirmDeleteOpen(null);
        };


        let editSelectedItem = () => {
            const table = $('.data-table-wrapper')
                .find('.channelsTable')
                .DataTable();
            let data = table.rows( { selected: true }).data();
        };


        let deleteSelectedItem = () => {
            const table = $('.data-table-wrapper')
                .find('.channelsTable')
                .DataTable();

            let data = table.rows( { selected: true }).data();

            let IdPulseChannel = data[0].IdPulseChannel;
            let Type = data[0].Type;


            // serverService.deletePulseChannel(IdPulseChannel,Type)
            //     .then(
            //         result => {
            //             if(result.status == 1){
            //                 toast.success("Channel deleted");
            //                 $("#deleteBtn").hide();
            //                 this.props.onChannelDeleted();
            //                 this.doFetchAllChannels();
            //             } else {
            //                 toast.error("Delete failed");
            //             }
            //
            //             this.setState({ modalConfirmDeleteShow: false });
            //         },
            //         error => {
            //             toast.warn("Did not delete");
            //         }
            //     );
        };


        return (
          <div>
            <div className='row pb-3 border-bottom'>
              <h3 className='col-md-10 font-weight-bold m-0'><Trans>trans_33_channels</Trans></h3>
              <div className='col-md-2 d-flex justify-content-end'>
                  <ButtonToolbar>

                      {/*<Button*/}
                          {/*id="editBtn"*/}
                          {/*variant="light"*/}
                          {/*onClick={editSelectedItem}*/}
                      {/*>*/}
                          {/*Edit*/}
                      {/*</Button>*/}
                      {/*&nbsp;*/}
                      {/*<Button*/}
                          {/*id="deleteBtn"*/}
                          {/*variant="light"*/}
                          {/*onClick={deleteSelectedItemWithConfirmation}*/}
                      {/*>*/}
                          {/*Delete*/}
                      {/*</Button>*/}

                      &nbsp;

                      <Button
                          id="addBtn"
                          variant="primary"
                          className='px-3 py-2'
                          size='lg'
                          onClick={modalAddNewChannelOpen}
                      >
                          Add
                      </Button>

                  </ButtonToolbar>
              </div>
            </div>
            
            <Collapse in={this.state.collapsed}>
              <div className="px-1 pt-4" id="collapse-channel-table">
                  <div id="tableContainer">

                      <table ref="main" className="w-100 table table-bordered channelsTable" />

                      

                      <ModalAddNewChannel
                          show={this.state.modalAddNewChannelShow}
                          onHide={closeAddNewModal}
                          onExit={this.doFetchAllChannels}
                      />


                      <ModalConfirmAction
                          show={this.state.modalConfirmDeleteShow}
                          actionTitle={i18n.t("trans_33_confirm_delete")}
                          actionBody={i18n.t("trans_33_channel_delete_are_you_sure")}
                          noBtnText={i18n.t("trans_33_no_go_back")}
                          yesBtnText={i18n.t("trans_33_yes_confirm")}
                          onConfirmNo={modalConfirmDeleteClose}
                          onHide={modalConfirmDeleteClose}
                          onConfirmYes={deleteSelectedItem}
                      />

                  </div>
              </div>
            </Collapse>

            <div className='row'>
              <div className='col-md-8'>

              </div>
              <div className='col-md-4 d-flex justify-content-end'>
                <Button
                  className='d-flex align-items-center'
                  onClick={this.handleCollapse}
                  aria-controls="collapse-channel-table"
                  aria-expanded={this.state.collapsed}
                  variant="link"
                >
                  {
                    this.state.collapsed
                      ? "Hide Information"
                      : "View More Infomation"
                  }
                  
                  <i className={`fas fa-chevron-down ml-1 transition-500 ${this.state.collapsed ? 'rotate-180' : ''}`}></i>
                </Button>
              </div>
            </div>
          </div>
        );
    }


    onChannelAdded(pulse){
        this.setState({modalAddNewChannelShow: false});
        this.doFetchAllChannels();
    }


    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }


    doFetchAllChannels() {
        serverService.getVirtualChannels().then(
            (result) => {

                if(result.data){
                    let slackChannels = result.data['slack'];
                    let msTeamsBotChannels = result.data['msteamsbot'];


                    let channels = [];
                    for(let i=0;i<slackChannels.length;i++){
                        channels.push(slackChannels[i]);
                    }
                    for(let i=0;i<msTeamsBotChannels.length;i++){
                        channels.push(msTeamsBotChannels[i]);
                    }



                    this.setState({channels: channels },() => {
                        this.reloadTableData(this.state.channels);
                        this.props.onChannelsFetched(channels)
                    });
                }

            },
            (error) => {
                toast.warn("Did not get");
            }
        );
    }

    reloadTableData(items) {
        const table = $('.data-table-wrapper')
            .find('.channelsTable')
            .DataTable();
        table.clear();
        table.rows.add(items);
        table.draw();


        $("#deleteBtn").hide();
        // $("#editBtn").hide();
    }

    handleCollapse() {
      this.setState({ collapsed: !this.state.collapsed });
    }


}


export default PulseChannelsTable;
