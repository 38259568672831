import React from 'react';
import { toast } from "react-toastify";

import ModalConfirmAction from '../../_other/ModalConfirmAction';
import AdminModalAddNewQuestion from "./AdminModalAddNewQuestion";
import AdminModalImportQuestions from "./AdminModalImportQuestions";

import {Button,ButtonToolbar,Form} from 'react-bootstrap'

import { serverService } from '../../../_services/server.service';

import './AdminQuestionsTable.css'
import AdminModalImportTranslations from "./AdminModalImportTranslations";


import moment from 'moment'

const $ = require('jquery');
$.DataTable = require('datatables.net');

require( 'datatables.net-bs4' );
require( 'datatables.net-select-bs4' );
require('../../datatables.selected.css');

const columns = [
    {
        title: 'Id',
        width: 70,
        data: 'IdTranslationGlobal'
    },
    {
        title: 'Question',
        width: 300,
        data: 'QuestionText'
    },
    {
        title: 'Skills',
        width: 200,
        data: 'SkillsString'
    }
];


interface MyProps {
    questions:any,
    question_skills:any,
    allSkills:any,
    onQuestionAdded():any,
    onQuestionDeleted():any,
    onQuestionsImported():any,
}

interface MyState {
    submitted: boolean,
    modalImportShow: boolean,
    modalAddNewShow:boolean,
    modalImportTranslationsShow:boolean,
    modalConfirmDeleteShow: boolean,
    selectedQuestion: any,
    formFields:any,
}

class AdminQuestionsTable extends React.Component<MyProps,MyState> {
    constructor(props) {
        super(props);

        this.state = {
            submitted:false,
            modalImportShow: false,
            modalImportTranslationsShow: false,
            modalAddNewShow:false,
            modalConfirmDeleteShow:false,
            formFields:{},
            selectedQuestion:{}
        };

        this.onQuestionAdded = this.onQuestionAdded.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.onQuestionsImported = this.onQuestionsImported.bind(this);

    }
    componentDidMount() {
        let table = $(this.refs.main).DataTable({
            dom: "<'data-table-wrapper'" +
                    "<'row'<'col-md-6'<'toolbar'>><'col-md-6'f>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'><'col-sm-12 col-md-7'p>>" +
                    ">",
            data: this.props.questions,
            columns,
            ordering: true,
            paging:true,
            select: {
                style: 'single',
                //blurable: true
            },

            columnDefs: [
                { width: 300, targets: 0 },
                { width: 200, targets: 1 },
            ]
        });

        $("#deleteBtn").hide();
        $("#editBtn").hide();

        table.on( 'select', function ( e, dt, type, indexes ) {
            $("#deleteBtn").show();
            $("#editBtn").show();
        });

        table.on( 'deselect', function ( e, dt, type, indexes ) {
            $("#deleteBtn").hide();
            $("#editBtn").hide();
        });

    }

    componentWillUnmount(){
        $('.data-table-wrapper')
            .find('table')
            .DataTable()
            .destroy(true);
    }

    componentDidUpdate(oldProps) {
        if(this.props.questions !== oldProps.questions){
            reloadTableData(this.props.questions);
        }
    }

    render() {
        let modalAddNewOpen = (itemToEdit) => {
            this.setState({ modalAddNewShow: true, selectedQuestion:itemToEdit });
        };
        let closeAddNewModal = () => this.setState({ modalAddNewShow: false });

        let modalConfirmDeleteOpen = (itemToEdit) => {
            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();
            let data = table.rows( { selected: true }).data();

            this.setState({ modalConfirmDeleteShow: true });
        };
        let modalConfirmDeleteClose = () => this.setState({ modalConfirmDeleteShow: false });

        let modalImportOpen = (itemToEdit) => this.setState({ modalImportShow: true });
        let closeImportModal = () => this.setState({ modalImportShow: false });

        let modalImportTranslationsOpen = (itemToEdit) => this.setState({ modalImportTranslationsShow: true });
        let closeImportTranslationsModal = () => this.setState({ modalImportTranslationsShow: false });

        let deleteSelectedItemWithConfirmation = () => {
            modalConfirmDeleteOpen(null);
        };


        let editSelectedItem = () => {
            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();
            let data = table.rows( { selected: true }).data();

            modalAddNewOpen(data[0]);
        };


        let deleteSelectedItem = () => {
            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();

            let data = table.rows( { selected: true }).data();

            let IdQuestion = data[0].IdQuestion;

            serverService.deleteQuestion(IdQuestion)
                .then(
                    result => {
                        if(result.status === "1"){
                            toast.success("Question deleted");
                            $("#deleteBtn").hide();
                            this.props.onQuestionDeleted();
                        } else {
                            toast.error("Delete failed");
                        }
                        this.setState({ modalConfirmDeleteShow: false });
                    },
                    error => {
                        toast.warn("Did not delete question");
                    }
                );
        };


        return (
            <div className="row">
                <div  className="col-md-12" id="questionTableContainer">

                    <div className="float-right">
                        <br/>
                        <ButtonToolbar>
                            <Button
                                id="importBtn"
                                variant="light"
                                onClick={modalImportOpen}
                            >
                                Import (en)
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                                id="importOtherLanguagesBtn"
                                variant="light"
                                onClick={modalImportTranslationsOpen}
                            >
                                Import (other languages)
                            </Button>
                        </ButtonToolbar>
                    </div>

                    <table ref="main" className="table table-bordered questionsTable" />


                    <div className="float-right">

                        <ButtonToolbar>

                            <Button
                                id="addBtn"
                                variant="light"
                                onClick={modalAddNewOpen}
                            >
                                Add
                            </Button>

                            <Button
                                id="editBtn"
                                variant="light"
                                onClick={editSelectedItem}
                            >
                                Edit
                            </Button>
                            &nbsp;
                            <Button
                                id="deleteBtn"
                                variant="light"
                                onClick={deleteSelectedItemWithConfirmation}
                            >
                                Delete
                            </Button>

                            &nbsp;

                        </ButtonToolbar>


                    </div>


                    <AdminModalAddNewQuestion
                        allSkills={this.props.allSkills}
                        show={this.state.modalAddNewShow}
                        selectedQuestion={this.state.selectedQuestion}
                        onHide={closeAddNewModal}
                        onQuestionAdded={this.onQuestionAdded}
                    />

                    <ModalConfirmAction
                        show={this.state.modalConfirmDeleteShow}
                        actionTitle="Confirm delete"
                        actionBody="Are you sure you want to delete the selected question?"
                        noBtnText="No, go back"
                        yesBtnText="Yes, confirm"
                        onConfirmNo={modalConfirmDeleteClose}
                        onHide={modalConfirmDeleteClose}
                        onConfirmYes={deleteSelectedItem}
                    />

                    <AdminModalImportQuestions
                        show={this.state.modalImportShow}
                        onHide={closeImportModal}
                        onQuestionsImported={this.onQuestionsImported}
                    />

                    <AdminModalImportTranslations
                        show={this.state.modalImportTranslationsShow}
                        onHide={closeImportTranslationsModal}
                        onQuestionsImported={this.onQuestionsImported}
                    />
                </div>


            </div>);
    }

    onQuestionAdded(){
        this.props.onQuestionAdded();
        this.setState({modalAddNewShow: false});
    }

    onQuestionsImported(item){
        this.props.onQuestionsImported();
        //this.setState({modalImportShow: false});
    }


    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});

        const { formFields } = this.state;
        const QuestionText = formFields.QuestionText;

        // stop here if form is invalid
        if (!(QuestionText)) {
            toast.error("Missing fields");
            return;
        }

        let userStr = localStorage.getItem('user');
        let IdUser = 0;
        if(userStr) {
            let userObj = JSON.parse(userStr);
            IdUser = userObj.IdUser;
        }

        let question = {'IdQuestion': '0','IdUser':IdUser.toString(), 'QuestionText':QuestionText };
        serverService.insertQuestion(question)
            .then(
                result => {
                    if(result.status === "1"){
                        let messageStr = "Question added";
                        toast.success(messageStr);
                        this.props.onQuestionAdded();

                        let formFields = this.state.formFields;
                        formFields.QuestionText = "";
                        this.setState({ formFields: formFields });
                    } else {
                        toast.error("Add question failed");
                    }
                },
                error => {
                    toast.warn("Did not insert question");
                }
            );
    }
}

function reloadTableData(items) {
    const table = $('.data-table-wrapper')
        .find('table')
        .DataTable();
    table.clear();
    table.rows.add(items);
    table.draw();

    $("#deleteBtn").hide();
    $("#editBtn").hide();
}

export default AdminQuestionsTable;
