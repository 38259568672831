import React, { Component } from "react"; // , useEffect, useRef
// import PropTypes from "prop-types";
import * as d3 from "d3";
import './RadialBarToBar.css';
import { style } from "d3";

import i18n from "i18next";
import { Trans } from "react-i18next";

// const RangeToBar = props => {
//   const ref = useRef(null);
class RadialBarToBar extends Component {
  // timeout = undefined;
  // isTransition = true;

  constructor() {
    super();
    this.onResize = this.onResize.bind(this);

    // (function(d3) {
    // 'use strict';

    function square(x) {
      return x * x;
    }

    function radial() {
      var linear = d3.scaleLinear();

      function scale(x) {
        return Math.sqrt(linear(x));
      }

      scale.domain = function (_) {
        return arguments.length ? (linear.domain(_), scale) : linear.domain();
      };

      scale.nice = function (count) {
        return (linear.nice(count), scale);
      };

      scale.range = function (_) {
        return arguments.length ? (linear.range(_.map(square)), scale) : linear.range().map(Math.sqrt);
      };

      scale.ticks = linear.ticks;
      scale.tickFormat = linear.tickFormat;

      return scale;
    }

    this.scaleRadial = radial;
    // this.averages = [];
    // })(d3); // https://cdn.jsdelivr.net/gh/holtzy/D3-graph-gallery@master/LIB/d3-scale-radial.js
  }

  componentDidMount() {
    // this.duration = this.props.duration || 0;
    // this.timeout = setTimeout(() => {
    //   this.isTransition = false;
    // }, this.duration + 1);

    this.reviewData();
    // console.log(this.barData);

    this.element = this.viz || document.querySelector('body');
    this.wrapper = d3.select(this.element);
    this.goBack = this.wrapper.select('.go-back');
    this.trends = this.wrapper.select('.trends');
    // this.goBackActive = undefined;
    this.isBar = false;
    this.isTrends = false;
    this.rangeSlider = this.wrapper.select('.range-slider');
    this.goBack.on('click', () => {
      if (this.radialbarG) {
        this.radialbarG.classed("d3-hide", false);
      }
      if (this.barG) {
        this.barG.classed("d3-hide", true);
      }
      if (this.trendsG) {
        this.trendsG.classed("d3-hide", true);
      }
      this.goBack.classed("d3-hidden", true);
      this.trends.classed("d3-hidden", false);
      // this.goBackActive = false;
      // this.rangeSlider.classed("d3-hide", true);
    });
    this.trends.on('click', () => {
      if (this.radialbarG) {
        this.radialbarG.classed("d3-hide", true);
      }
      if (this.barG) {
        this.barG.classed("d3-hide", true);
      }
      if (this.trendsG) {
        this.trendsG.classed("d3-hide", false);
      }
      this.goBack.classed("d3-hidden", false);
      this.isBar = false;
      this.isTrends = true;
      this.drawTrendsChart();
      this.radialbarG.classed("d3-hide", true);
      this.barG.classed("d3-hide", true);
      this.goBack.classed("d3-hidden", false);
      this.trendsG.classed("d3-hide", false);
      this.trends.classed("d3-hidden", true);
    });

    this.tooltip = d3.select("body").append("div").attr("class", "toolTip d3-hide");

    // create the chart
    this.init();

    // redraw chart on each resize
    window.addEventListener('resize', this.onResize, false);
  }

  // shouldComponentUpdate(nextProps) { // or React.PureComponent
  //   const data = JSON.stringify(nextProps.data);
  //   const shouldUpdate = this.initialData !== dats;
  //   if (shouldUpdate) {
  //     this.initialData = data;
  //   }
  //   this.data = nextProps.data;
  //   return shouldUpdate;
  // }

  componentDidUpdate() {
    this.g.selectAll("*").interrupt(); // https://bl.ocks.org/veltman/344418d9602a29825d63 https://github.com/d3/d3-transition/blob/master/README.md#selection_interrupt
    this.init();
  }

  componentWillUnmount() {
    this.g.selectAll("*").interrupt();
    window.removeEventListener('resize', this.onResize, false);
  }

  onResize() {
    this.g.selectAll("*").interrupt();
    this.init();
  }

  anyToNumber(any) {
    return +((any + '').replace(/[^-.0-9]+/g, '')) || 0;
  }

  reviewData() { //data
    this.radialbarData = this.props.radialbarData || [
      {
        "area": "Comfort",
        "value": 0.74,
        "group": "Communication" // Management, Engagement, Health and Wellbeing, Sustainability, Communication.
      },
      {
        "area": "Healthy Habbits",
        "value": 0.3,
        "group": "Management" // Management, Engagement, Health and Wellbeing, Sustainability, Communication.
      },
      {
        "area": "Energy",
        "value": 0.35,
        "group": "Management" // Management, Engagement, Health and Wellbeing, Sustainability, Communication.
      },
      {
        "area": "Horizontal Communication",
        "value": 0.88,
        "group": "Engagement" // Management, Engagement, Health and Wellbeing, Sustainability, Communication.
      },
      {
        "area": "Vertical Communication",
        "value": 0.13,
        "group": "Engagement" // Management, Engagement, Health and Wellbeing, Sustainability, Communication.
      },
      {
        "area": "Process",
        "value": 0.7,
        "group": "Engagement" // Management, Engagement, Health and Wellbeing, Sustainability, Communication.
      },
      {
        "area": "Development",
        "value": 0.41,
        "group": "Health and Wellbeing" // Management, Engagement, Health and Wellbeing, Sustainability, Communication.
      },
      {
        "area": "Job Crafting",
        "value": 0.42,
        "group": "Health and Wellbeing" // Management, Engagement, Health and Wellbeing, Sustainability, Communication.
      },
      {
        "area": "Fairness",
        "value": 0.68,
        "group": "Health and Wellbeing" // Management, Engagement, Health and Wellbeing, Sustainability, Communication.
      },
      {
        "area": "Autonomy",
        "value": 0.12,
        "group": "Sustainability" // Management, Engagement, Health and Wellbeing, Sustainability, Communication.
      },
      {
        "area": "Motivation",
        "value": 0.34,
        "group": "Sustainability" // Management, Engagement, Health and Wellbeing, Sustainability, Communication.
      },
      {
        "area": "Stress (-)",
        "value": 0.7,
        "group": "Sustainability" // Management, Engagement, Health and Wellbeing, Sustainability, Communication.
      },
      {
        "area": "Sustainability",
        "value": 0.3,
        "group": "Communication" // Management, Engagement, Health and Wellbeing, Sustainability, Communication.
      },
      {
        "area": "Leadership quality",
        "value": 0.25,
        "group": "Communication" // Management, Engagement, Health and Wellbeing, Sustainability, Communication.
      },
      {
        "area": "Social support",
        "value": 0.75,
        "group": "Communication" // Management, Engagement, Health and Wellbeing, Sustainability, Communication.
      },
      {
        "area": "Material ressources",
        "value": 0.5,
        "group": "Communication" // Management, Engagement, Health and Wellbeing, Sustainability, Communication.
      }
    ];

    this.radialbarDataObj = this.radialbarData
      .reduce((acc, cur, i) => {
        cur.value = 100 * this.anyToNumber(cur.value);
        acc[cur.area] = cur;
        return acc;
      }, {});

    // https://bl.ocks.org/zanarmstrong/ca0adb7e426c12c06a95
    this.parseTime = d3.timeParse("%d.%m.%Y"); //String to Date
    this.timeFormat = d3.timeFormat("%m/%Y"); //Date to String 
    this.barData = {};
    this.dates = [];

    var csv = this.props.barData || [
      {
        "Date": "Healthy Habits",
        "21.10.2020": null,
        "10.12.2020": null,
        "11.12.2020": null,
        "14.12.2020": null,
        "17.12.2020": null,
        "21.12.2020": null,
        "22.12.2020": null,
        "23.12.2020": null,
        "29.12.2020": {
          "SumYesPercentage": 0,
          "SumWeights": 1
        },
        "05.01.2021": null
      },
      {
        "Date": "Energy",
        "21.10.2020": null,
        "10.12.2020": null,
        "11.12.2020": null,
        "14.12.2020": null,
        "17.12.2020": null,
        "21.12.2020": null,
        "22.12.2020": null,
        "23.12.2020": null,
        "29.12.2020": null,
        "05.01.2021": {
          "SumYesPercentage": 0.12,
          "SumWeights": 0.2
        }
      },
      {
        "Date": "H.Communication",
        "21.10.2020": null,
        "10.12.2020": null,
        "11.12.2020": null,
        "14.12.2020": null,
        "17.12.2020": null,
        "21.12.2020": null,
        "22.12.2020": null,
        "23.12.2020": null,
        "29.12.2020": null,
        "05.01.2021": null
      },
      {
        "Date": "V. Communication",
        "21.10.2020": null,
        "10.12.2020": null,
        "11.12.2020": null,
        "14.12.2020": null,
        "17.12.2020": null,
        "21.12.2020": null,
        "22.12.2020": null,
        "23.12.2020": {
          "SumYesPercentage": 0.3,
          "SumWeights": 0.3
        },
        "29.12.2020": null,
        "05.01.2021": null
      },
      {
        "Date": "Process",
        "21.10.2020": null,
        "10.12.2020": {
          "SumYesPercentage": 0.72915,
          "SumWeights": 1
        },
        "11.12.2020": null,
        "14.12.2020": null,
        "17.12.2020": null,
        "21.12.2020": null,
        "22.12.2020": null,
        "23.12.2020": null,
        "29.12.2020": null,
        "05.01.2021": null
      },
      {
        "Date": "Development",
        "21.10.2020": null,
        "10.12.2020": null,
        "11.12.2020": null,
        "14.12.2020": null,
        "17.12.2020": null,
        "21.12.2020": null,
        "22.12.2020": null,
        "23.12.2020": null,
        "29.12.2020": null,
        "05.01.2021": null
      },
      {
        "Date": "Job Crafting",
        "21.10.2020": null,
        "10.12.2020": null,
        "11.12.2020": null,
        "14.12.2020": null,
        "17.12.2020": null,
        "21.12.2020": null,
        "22.12.2020": null,
        "23.12.2020": null,
        "29.12.2020": null,
        "05.01.2021": null
      },
      {
        "Date": "Fairness",
        "21.10.2020": null,
        "10.12.2020": {
          "SumYesPercentage": 0.364575,
          "SumWeights": 0.5
        },
        "11.12.2020": {
          "SumYesPercentage": 1,
          "SumWeights": 1
        },
        "14.12.2020": {
          "SumYesPercentage": 1,
          "SumWeights": 1
        },
        "17.12.2020": null,
        "21.12.2020": null,
        "22.12.2020": null,
        "23.12.2020": null,
        "29.12.2020": null,
        "05.01.2021": null
      },
      {
        "Date": "Autonomy",
        "21.10.2020": {
          "SumYesPercentage": 0.5,
          "SumWeights": 1
        },
        "10.12.2020": null,
        "11.12.2020": null,
        "14.12.2020": null,
        "17.12.2020": {
          "SumYesPercentage": 0.5,
          "SumWeights": 0.5
        },
        "21.12.2020": null,
        "22.12.2020": null,
        "23.12.2020": null,
        "29.12.2020": null,
        "05.01.2021": {
          "SumYesPercentage": 0.12,
          "SumWeights": 0.2
        }
      },
      {
        "Date": "Motivation",
        "21.10.2020": {
          "SumYesPercentage": 0.5,
          "SumWeights": 1
        },
        "10.12.2020": null,
        "11.12.2020": {
          "SumYesPercentage": 1,
          "SumWeights": 1
        },
        "14.12.2020": {
          "SumYesPercentage": 1,
          "SumWeights": 1
        },
        "17.12.2020": {
          "SumYesPercentage": 0.3,
          "SumWeights": 0.3
        },
        "21.12.2020": {
          "SumYesPercentage": 0.25,
          "SumWeights": 0.5
        },
        "22.12.2020": null,
        "23.12.2020": null,
        "29.12.2020": null,
        "05.01.2021": null
      },
      {
        "Date": "Stress (-)",
        "21.10.2020": null,
        "10.12.2020": {
          "SumYesPercentage": 0.13542500000000002,
          "SumWeights": 0.5
        },
        "11.12.2020": null,
        "14.12.2020": null,
        "17.12.2020": null,
        "21.12.2020": null,
        "22.12.2020": {
          "SumYesPercentage": 0.5,
          "SumWeights": 0.5
        },
        "23.12.2020": null,
        "29.12.2020": null,
        "05.01.2021": null
      },
      {
        "Date": "Sustainability",
        "21.10.2020": null,
        "10.12.2020": null,
        "11.12.2020": null,
        "14.12.2020": null,
        "17.12.2020": null,
        "21.12.2020": null,
        "22.12.2020": null,
        "23.12.2020": null,
        "29.12.2020": null,
        "05.01.2021": null
      },
      {
        "Date": "Leadership quality",
        "21.10.2020": null,
        "10.12.2020": null,
        "11.12.2020": {
          "SumYesPercentage": 1,
          "SumWeights": 1
        },
        "14.12.2020": {
          "SumYesPercentage": 1,
          "SumWeights": 1
        },
        "17.12.2020": {
          "SumYesPercentage": 1,
          "SumWeights": 1
        },
        "21.12.2020": {
          "SumYesPercentage": 0.25,
          "SumWeights": 0.5
        },
        "22.12.2020": null,
        "23.12.2020": {
          "SumYesPercentage": 0.5,
          "SumWeights": 0.5
        },
        "29.12.2020": null,
        "05.01.2021": {
          "SumYesPercentage": 0.6,
          "SumWeights": 1
        }
      },
      {
        "Date": "Social support",
        "21.10.2020": null,
        "10.12.2020": null,
        "11.12.2020": null,
        "14.12.2020": null,
        "17.12.2020": null,
        "21.12.2020": null,
        "22.12.2020": null,
        "23.12.2020": {
          "SumYesPercentage": 0.5,
          "SumWeights": 0.5
        },
        "29.12.2020": {
          "SumYesPercentage": 0,
          "SumWeights": 1
        },
        "05.01.2021": null
      },
      {
        "Date": "Material resources",
        "21.10.2020": null,
        "10.12.2020": null,
        "11.12.2020": null,
        "14.12.2020": null,
        "17.12.2020": null,
        "21.12.2020": null,
        "22.12.2020": null,
        "23.12.2020": null,
        "29.12.2020": null,
        "05.01.2021": null
      }
    ];

    // console.log("33 - Radialbardata:");
    // console.log(this.props.radialbarData );
    //
    //   console.log("33 - Bardata:");
    //   console.log(this.props.barData );


    //     `Date	01.01.2019	01.02.2019	01.03.2019	01.04.2019	01.05.2019	01.06.2019	01.07.2019	01.08.2019
    // Comfort	0,81	0,35	0,95	0,68	0,91	0,82	0,93	0,18
    // Healthy Habbits	0,11	0,97	0,16	0,27	0,02	0,45	0,07	0,25
    // Energy	0,35	0,95	0,09	0,22	0,76	0,15	0,94	0,92
    // Horizontal Communication	0,88	0,68	0,67	0,13	0,1	0,54	0,06	0,66
    // Vertical Communication	0,13	0,89	0,61	0,03	0,32	0,64	0,58	0,32
    // Process	0,56	0,67	0,88	0,66	0,23	0,12	0,73	0,16
    // Development	0,41	0,77	0,3	0,82	0,43	0,87	0,5	0,7
    // Job Crafting	0,46	0,81	0,67	0,74	0,67	0,01	0,98	0,96
    // Fairness	0,86	0,59	0,08	0,95	0,82	0,32	0,12	0,34
    // Autonomy	0,06	0,61	0,51	0,4	0,8	0,01	0,84	0,04
    // Motivation	0,43	0,74	0,88	0,06	0,77	0,31	0,08	0,79
    // Stress (-)	0,55	0,77	0,01	0,56	0,31	0,69	0,17	0,66
    // Sustainability	0,5	0,77	0,88	0,6	0,97	0,42	0,18	0,8
    // Leadership quality	0,16	0,39	0,65	0,77	0,98	0,93	0,89	0,09
    // Social support	0,63	0,69	0,53	0,59	0,27	0,75	0,78	0,87
    // Material ressources	0,38	0,18	0,24	0,24	0,96	0,73	0,19	0,22`;
    // csv = d3.tsvParse(csv); // console.log(JSON.stringify(csv));
    var dates = (d3.keys(csv[0]).filter((d) => d !== "Date"));
    dates.forEach((date) => {
      let datetime = this.parseTime(date);
      this.dates.push({
        "date": this.timeFormat(datetime),
        "datetime": datetime,
        "sort": datetime.getTime(),
        "initDate": date
      });
    });
    // console.log("this.dates", this.dates);
    this.dates.sort((a, b) => a.sort - b.sort);
    csv.forEach((d) => {
      this.barData[d.Date] = {
        "label": d.Date,
        "values": [],
        "value": this.radialbarDataObj[d.Date] ? this.radialbarDataObj[d.Date].value : 0
      };


      // console.log("this.barData[d.Date]", this.barData[d.Date]);
      // console.log("dates", dates);
      dates.forEach((date) => {
        let datetime = this.parseTime(date);
        // console.log("=", d.Date, this.barData[d.Date]);
        if (this.barData[d.Date]) {
          this.barData[d.Date].values.push({
            "date": this.timeFormat(datetime),
            "datetime": datetime,
            "sort": datetime.getTime(),
            "initDate": date,
            "value": d[date] === null ? null : 100 * (this.anyToNumber(d[date].SumYesPercentage / d[date].SumWeights)),
            "SumYesPercentage": d[date] === null ? null : d[date].SumYesPercentage,
            "SumWeights": d[date] === null ? null : d[date].SumWeights,
            "average": 0
          });
        }
      });
      this.barData[d.Date].values.sort((a, b) => a.sort - b.sort);


      // if(d.Date === "Social support"){
      //     console.log("this.barData[d.Date]: ");
      //     console.log(this.barData[d.Date]);
      // }

    });
    this.barsData = Object.values(this.barData);
  }

  init() {
    this.width = this.props.width || this.element.offsetWidth || 600;
    this.height = this.props.height || this.element.offsetHeight || 600;
    this.margin = {
      top: 120,
      right: 120,
      bottom: 120,
      left: 120
    };
    const svg = this.wrapper.select('svg').attr("class", "radialbar-to-bar");
    svg.attr('width', this.width).attr('height', this.height);
    this.width = this.width - (this.margin.right + this.margin.left);
    this.height = this.height - (this.margin.top + this.margin.bottom);

    this.g = svg.select('g.container').attr('transform', `translate(${this.margin.left},${this.margin.top})`);
    this.radialbarG = svg.select('g.radialbar-container');
    this.radialbarItemsG = svg.select('g.items-container');
    this.radialbarOthersG = svg.select('g.others-container');
    this.radialbarLegendG = svg.select('g.legend-container');
    this.barG = svg.select('g.bar-container');
    this.trendsG = svg.select('g.trends-container');

    this.selectedRange = [0, this.dates.length - 1];
    this.prevAverages = {};
    this.radialbarData.forEach((d, i) => {
      d.prevAverage = 0;
    });
    this.drawRadialbarChart();

    // let firstOnChange = true;
    this.range = this.drawRangeSlider();
    this.startDate = this.rangeSlider.select(".handle-WW-label").text(this.dates[0].date);
    this.endDate = this.rangeSlider.select(".handle-EE-label").text(this.dates[this.dates.length - 1].date);
    this.range.onChange((newRange) => { // {begin: N, end: N}
      let noChanges = newRange.begin === this.selectedRange[0] && this.selectedRange[1] === newRange.end;
      this.selectedRange[0] = newRange.begin;
      this.selectedRange[1] = newRange.end;
      if (this.selectedRange[0] === this.dates.length && this.selectedRange[1] === this.dates.length) {
        this.selectedRange[0] = this.dates.length ? this.dates.length - 1 : 0;
      }
      if (this.selectedRange[0] === 0 && this.selectedRange[1] === 0 && this.dates.length) {
        this.selectedRange[1] = 0;
      }
      this.startDate = this.startDate.text(this.dates[this.selectedRange[0]].date);
      this.endDate = this.endDate.text(this.dates[this.selectedRange[1]].date);
      if (noChanges) {
        return;
      }
      // console.log("onChange", newRange);
      if (this.isBar === true) {
        this.drawBarChart(true);
      } else if (this.isTrends === true) {
        this.drawTrendsChart(true);
      } else {
        this.drawRadialbarChart(true);
      }
    })
      .onTouchEnd(() => {
        // console.log("onTouchEnd");
        if (this.isBar === true) {
          this.drawBarChart();
        } else if (this.isTrends === true) {
          this.drawTrendsChart(true);
        } else {
          this.drawRadialbarChart();
        }
      });
    this.range.range(this.selectedRange[0], this.selectedRange[1]);
    this.range.updateUIFromRange();
  }

  isIEorEDGE() {
    if (/MSIE 10/i.test(navigator.userAgent)) {
      // This is internet explorer 10
      return true;
    }
    if (/MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)) {
      // This is internet explorer 9 or 11
      return true;
    }
    if (/Edge\/\d./i.test(navigator.userAgent)) {
      // This is Microsoft Edge
      return true;
    }
    return false;
  }

  drawRadialbarChart(noTransition) {
    let self = this;
    // this.radialbarG.selectAll("*").remove();
    let groups = d3.nest().key(d => d.group).entries(this.radialbarData);
    let groupsByKey = groups.reduce((acc, el) => {
      el.value = el.values.length;
      acc[el.key] = el;
      return acc;
    }, {});
    this.radialbarData.forEach((d, i) => {
      let data = this.barData[d.area];
      d.groups = groupsByKey[d.group].values.length;
      // d.value === Benchmark
      let values = data ? data.values.filter((d, i) => (i >= this.selectedRange[0] && i <= this.selectedRange[1])) : [];
      let prevAverage = d.average;

      let totalYesPercentage = 0;
      let totalWeights = 0;
      for (let i = 0; i < values.length; i++) {
        if (values[i].SumYesPercentage !== null && values[i].SumWeights !== null) {
          totalWeights += values[i].SumWeights;
          totalYesPercentage += values[i].SumYesPercentage;
        }
      }

      // d.average = d3.mean(values.filter(d => d.value !== null), d =>  d.value) || 0;


      d.average = 100 * totalYesPercentage / totalWeights;


      // if (initial) {
      //   d.prevAverage = 0;
      // }
      // if (d.average !== prevAverage) {
      d.prevAverage = prevAverage;
      // }
      // console.log(d.prevAverage, " => ", d.average)
      // Colors are: Green if category score is over the benchmark by more than 3% (or for the stress category, under the benchmark by at least 3%)
      // Red: if category score is below the benchmark by more than 3%(or for the stress category, above the benchmark by more than 3%)
      // Orange: everything that is in the 3% range (+/- 3%)
      d.color = "#69b3a2";
      // let percent = Math.abs(100 * (d.average - d.value) / d.value);
      let percent = d.average - d.value; // so abs(average - benchmark) < 3%
      // where d is not stress
      // let percent = Math(d.average - d.value);
      // if (percent > 3.01) {
      // d.color = "#56b05b"; // green
      // } else if (percent < -3.01) {
      // d.color = "#d2423a"; // red
      // } else {
      // d.color = "#fabd30"; // orange
      // }
      // where d is stress
      // let percent = Math(d.average - d.value);
      // if (percent > 3.01) {
      // d.color = "#56b05b"; // red
      // } else if (percent < -3.01) {
      // d.color = "#d2423a"; // green
      // } else {
      // d.color = "#fabd30"; // orange
      // }

        if (d.area === "Stress (-)") {
          if (percent > 5.00) {
            d.color = "#d2423a"; // red
          } else if (percent < -5.00) {
            d.color = "#56b05b"; // green
          } else {
            d.color = "#fabd30"; // orange
          }
        } else {
          if (percent > 5.00) {
            d.color = "#56b05b"; // green
          } else if (percent < -5.00) {
            d.color = "#d2423a"; // red
          } else {
            d.color = "#fabd30"; // orange
          }
        }
    });
    this.radialbarData.sort((b, a) => a.groups - b.groups);
    // if(!initial){
    //   this.averages = this.radialbarData.map((d, i) => {
    //     if(this.averages[i]){
    //       d.prevAverages = this.averages[i];
    //     }
    //     return d.average;
    //   });
    // }
    groups.sort((b, a) => a.value - b.value);
    // console.log("groups", this.radialbarData, groups);


    const max = 100;//d3.max(this.radialbarData, d => d.average);
    let cfg = {
      radius: 4,
      width: this.width || 600,
      height: this.height || 600,
      factor: 1,
      factorLegend: .85,
      levels: 10,
      radians: 2 * Math.PI,
      opacityArea: 0.5,
      toRight: 5,
      // color: d3.scaleOrdinal().range(["#f17e15", "#4dc9f6", "#3284ba", "#ee801b", "#6F257F", "#CA0D59"]),
      averageColor: "#4dc9f6", // the blue will vary with the slider
      benchmarkColor: "#f17e15" // from initial xls
    };
    cfg.width = cfg.width - (this.margin.left + this.margin.right);
    cfg.height = cfg.height - (this.margin.top + this.margin.bottom);

    let total = this.radialbarData.length;

    this.radialbarG
      .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

    var innerRadius = 40;
    var outerRadius = cfg.factor * Math.min((cfg.width) / 2, (cfg.height) / 2);

    // Scales
    var x = d3.scaleBand()
      .range([0, 2 * Math.PI])    // X axis goes from 0 to 2pi = all around the circle. If I stop at 1Pi, it will be around a half circle
      .align(0)                  // This does nothing
      .domain(this.radialbarData.map(d => d.area)); // The domain of the X axis is the list of states.
    var y = this.scaleRadial()
      .range([innerRadius, outerRadius])   // Domain will be define later.
      .domain([0, max]); // Domain of Y is from 0 to the max seen in the data

    // console.log("this", this);

    this.radialbarItemsG
      // .attr("transform", "translate(0,0)");
      .attr("transform", "translate(" + (cfg.width / 2) + "," + (cfg.height / 2) + ")");

    this.radialbarItemsG.select("circle.outer-circle")
      .attr("r", outerRadius)
      .style("fill", "#ececec");

    this.radialbarItemsG.select("circle.inner-circle")
      .attr("r", innerRadius)
      .style("fill", "#fff");

    let levels = d3.scaleLinear()
      .range([innerRadius, outerRadius])
      .domain([1, 9]);

    let levelsArc = d3.arc()     // imagine your doing a part of a donut plot
      .innerRadius(d => levels(d))
      .outerRadius(d => levels(d))
      .startAngle(0)
      .endAngle(Math.PI * 2)
      .padAngle(0)
      .padRadius(0);

    const groupedHeight = Math.ceil((levels(1) - levels(0)) * 1.6);

    // https://www.d3-graph-gallery.com/graph/circular_barplot_label.html
    // this.radialbarOthersG.select(".levels").interrupt();
    var level = this.radialbarOthersG.select("g.levels").selectAll(".level")
      .data(d3.range(1, 10));
    // EXIT old elements not present in new data.
    level.exit()
      .style("fill-opacity", 1e-6)
      .remove();
    // UPDATE old elements present in new data.
    level
      .attr("transform", "translate(" + (cfg.width / 2) + ", " + (cfg.height / 2) + ")")
      .attr("d", d => levelsArc(d));
    // ENTER new elements present in new data.
    level.enter().append("svg:path")
      .attr("r", "2")
      .attr("class", "level")
      .style("stroke", "#fff")
      .style("pointer-events", "none")
      .style("fill", "none")
      .style("stroke-opacity", "0.75")
      .style("stroke-width", "2px")
      .attr("transform", "translate(" + (cfg.width / 2) + ", " + (cfg.height / 2) + ")")
      .attr("d", d => levelsArc(d));

    this.radialbarLegendG
      .attr("transform", "translate(-" + this.margin.left + ",-" + (this.margin.top + 30) + ")");
    let legendPadding = 22;
    let legendData = [
      { label: i18n.t("trans_33_to_be_improved"), color: "#d2423a" }
      , { label: i18n.t("trans_33_on_par_with_benchmark"), color: "#fabd30" }
      , { label: i18n.t("trans_33_Good"), color: "#56b05b" }
    ];
    var legendText = this.radialbarLegendG.selectAll(".legend-text")
      .data(legendData);
    // EXIT old elements not present in new data.
    legendText.exit()
      .style("fill-opacity", 1e-6)
      .remove();
    // UPDATE old elements present in new data.
    legendText
      .attr("y", (d, i) => i * -legendPadding + 5);
    //  .each(function (d) {
    //    d.width = getTextWidth(`${d.label}`, '14px', 'sans-serif') + 35;
    //  })
    //  .attr("x", (d, i) => {
    //    if (i === 0) {
    //      return 12;
    //    } else if (i === 1) {
    //      return legendData[0].width + 12;
    //    } else {
    //      return legendData[0].width + 12 + legendData[1].width;
    //    }
    //  });
    // ENTER new elements present in new data.
    legendText.enter().append("svg:text")
      .attr("class", "legend-text")
      // .attr("x", 0)
      // .attr("y", 0)
      .attr("x", 14)
      .attr("y", (d, i) => i * -legendPadding + 5)
      .style("pointer-events", "none")
      .style("font", "14px sans-serif")
      .text(d => d.label)
    // .each(function (d) {
    //   d.width = getTextWidth(`${d.label}`, '14px', 'sans-serif') + 35;
    //   // d.width = this.getComputedTextLength();
    // })
    // .attr("x", (d, i) => {
    //   if (i === 0) {
    //     return 12;
    //   } else if (i === 1) {
    //     return legendData[0].width + 12;
    //   } else {
    //     return legendData[0].width + 12 + legendData[1].width;
    //   }
    // });

    var legendCircle = this.radialbarLegendG.selectAll(".legend-circle")
      .data(legendData);
    // EXIT old elements not present in new data.
    legendCircle.exit()
      .style("fill-opacity", 1e-6)
      .remove();
    // UPDATE old elements present in new data.
    legendCircle
      .attr("cy", (d, i) => i * -legendPadding);
    // .attr("cx", (d, i) => {
    //   if (i === 0) {
    //     return 0;
    //   } else if (i === 1) {
    //     return legendData[0].width + 0;
    //   } else {
    //     return legendData[0].width + 0 + legendData[1].width + 0;
    //   }
    // })
    // ENTER new elements present in new data.
    legendCircle.enter().append("svg:circle")
      .attr("r", 8)
      .attr("class", "legend-circle")
      .style("pointer-events", "none")
      .style("fill", d => d.color)
      .style("stroke-width", "0")
      .attr("cx", 0)
      // .attr("cx", (d, i) => {
      //   if (i === 0) {
      //     return 0;
      //   } else if (i === 1) {
      //     return legendData[0].width + 0;
      //   } else {
      //     return legendData[0].width + 0 + legendData[1].width + 0;
      //   }
      // })
      .attr("dy", "0")
      // .attr("cy", -5)
      .attr("cy", (d, i) => i * -legendPadding);

    // this.radialbarOthersG.select(".sectors").interrupt();
    var sector = this.radialbarOthersG.select("g.sectors").selectAll(".sector")
      .data(this.radialbarData);
    // EXIT old elements not present in new data.
    sector.exit()
      .style("fill-opacity", 1e-6)
      .remove();
    // UPDATE old elements present in new data.
    sector
      .attr("x1", cfg.width / 2)
      .attr("y1", cfg.height / 2)
      .attr("x2", function (d, i) {
        return cfg.width / 2 * (1 - cfg.factor * Math.sin(i * cfg.radians / total));
      })
      .attr("y2", function (d, i) {
        return cfg.height / 2 * (1 - cfg.factor * Math.cos(i * cfg.radians / total));
      });
    // ENTER new elements present in new data.
    sector.enter().append("line")
      .attr("class", "sector")
      .attr("x1", cfg.width / 2)
      .attr("y1", cfg.height / 2)
      .attr("x2", function (d, i) {
        return cfg.width / 2 * (1 - cfg.factor * Math.sin(i * cfg.radians / total));
      })
      .attr("y2", function (d, i) {
        return cfg.height / 2 * (1 - cfg.factor * Math.cos(i * cfg.radians / total));
      })
      .style("pointer-events", "none")
      .style("stroke", "#fff")
      .style("stroke-opacity", "0.75")
      .style("stroke-width", "2px");

    // https://bl.ocks.org/bricedev/8aaef92e64007f882267 - d3.v4
    // https://next.plnkr.co/edit/vIwzn3piaBSqTfmVxrKt?p=preview&utm_source=legacy&utm_medium=worker&utm_campaign=next&preview - d3.v3
    let categoryArc = d3.arc()     // imagine your doing a part of a donut plot
      .innerRadius(innerRadius)
      .outerRadius(d => y(d.average))
      .startAngle(d => x(d.area))
      .endAngle(d => x(d.area) + x.bandwidth())
      .padAngle(0)
      .padRadius(0)

    // let transition = d3.transition().duration(500);
    // Add the bars
    let transition = d3.scaleLinear()
      .range([0, 500])
      .domain([1, 51]);
    var timeout = {};
    var timeouts = {};

    // this.radialbarItemsG.select(".categories").interrupt();
    var categoryPath = this.radialbarItemsG.select("g.categories").selectAll(".category-path")
      .data(this.radialbarData);
    // EXIT old elements not present in new data.
    categoryPath.exit()
      .style("fill-opacity", 1e-6)
      .remove();
    // UPDATE old elements present in new data.
    categoryPath
      .attr("fill", d => d.color || "#69b3a2")
      .on('mousemove', (d) => {
        this.tooltip
          .classed("d3-hide", false)
          .html(`<b>${d.area}</b><br>${i18n.t("trans_33_your_results")}: ${d.average.toFixed(0)}%<br>${i18n.t("trans_33_Benchmark")}: ${d.value.toFixed(0)}%`)
          .each(function () {
            d.firstRow = getTextWidth(`${d.area}`, '12px', 'sans-serif');
            d.secondRow = getTextWidth(i18n.t("trans_33_your_results") + `: ${d.average.toFixed(0)}%`, '12px', 'sans-serif');
            d.thirdRow = getTextWidth(i18n.t("trans_33_Benchmark") + `: ${d.value.toFixed(0)}%`, '12px', 'sans-serif');
            d.maxRow = Math.max(...[d.firstRow, d.secondRow, d.thirdRow]) + 28;
          })
          .style("left", (d3.event.pageX - d.maxRow / 2) + "px")
          .style("top", (d3.event.pageY - 75) + "px");
      })
      .on("mouseout", () => {
        this.tooltip.classed("d3-hide", true);
      })
      // .attr("d", d => categoryArc(d));
      // .attr("d", d => categoryArc({ ...d, average: d.prevAverage }))
      .each(function (d, i) {
        let el = d3.select(this);
        if (noTransition) {
          el.attr("d", categoryArc(d));
          // self.prevAverages[i] = 0.0001;
          self.prevAverages[i] = d.average;
          return;
        }
        // console.log(i, " current average = ", d)
        let transitionAverage = d3.scaleLinear()
          .range([self.prevAverages[i] || d.prevAverage, d.average])
          // .range([0, d.average])
          .domain([1, 51]);
        // console.log(d3.range(0,51).reverse());
        if (!timeouts[i]) {
          timeouts[i] = [];
        } else {
          timeouts[i].forEach(tout => {
            clearTimeout(tout);
          });
          timeouts[i] = [];
          el.attr("d", categoryArc({ ...d, average: self.prevAverages[i] || d.prevAverage }));
        }
        try {
          d3.range(1, 52).reverse().forEach((c, j) => {
            let tout = setTimeout(() => {
              let average = transitionAverage(c);
              self.prevAverages[i] = average;
              el.attr("d", categoryArc({ ...d, average }));
            }, transition(c));
            timeouts[i].push(tout);
          });
        } catch (error) {
          if (timeout[i]) {
            clearTimeout(timeout[i]);
          }
          timeout[i] = setTimeout(() => {
            el.attr("d", categoryArc(d));
          }, 500);
        }
        // if (timeout[i]) {
        //   clearTimeout(timeout[i]);
        // }
        // timeout[i] = setTimeout(() => {
        //   el.attr("d", categoryArc(d));
        // }, 500);
      });
    // ENTER new elements present in new data.
    categoryPath.enter().append("path")
      .attr("class", "category-path")
      .attr("fill", d => d.color || "#69b3a2")
      .on('mousemove', (d) => {
        this.tooltip
          .classed("d3-hide", false)
          .html(`<b>${d.area}</b><br>${i18n.t("trans_33_your_results")}: ${d.average.toFixed(0)}%<br>${i18n.t("trans_33_Benchmark")}: ${d.value.toFixed(0)}%`)
          .each(function () {
            d.firstRow = getTextWidth(`${d.area}`, '12px', 'sans-serif');
            d.secondRow = getTextWidth(i18n.t("trans_33_your_results") + `: ${d.average.toFixed(0)}%`, '12px', 'sans-serif');
            d.thirdRow = getTextWidth(i18n.t("trans_33_Benchmark") + `: ${d.value.toFixed(0)}%`, '12px', 'sans-serif');
            d.maxRow = Math.max(...[d.firstRow, d.secondRow, d.thirdRow]) + 28;
          })
          .style("left", (d3.event.pageX - d.maxRow / 2) + "px")
          .style("top", (d3.event.pageY - 75) + "px");
      })
      .on("mouseout", () => {
        this.tooltip.classed("d3-hide", true);
      })
      .attr("d", d => categoryArc(d));

    // var groupData = d3.range(0, 4);
    // var groupColor = ["#76c3b9", "#66b5aa", "#5aaba0", "#4c9188", "#52b0c7", "#4f98cd", "#4084af"];
    var groupColor = (i) => {
      let colors = ["#76c3b9", "#5aaba0", "#4c9188", "#52b0c7", "#4f98cd", "#4084af"];
      return colors[i % colors.length];
    };
    let sum = 0;
    groups.forEach((d, i) => {
      d.start = sum;
      sum = sum + d.value;
      d.end = sum;
      d.color = groupColor(i) || "#69b3a2";
    });
    // var xGroup = d3.scaleBand()
    //   .range([0, 2 * Math.PI])    // X axis goes from 0 to 2pi = all around the circle. If I stop at 1Pi, it will be around a half circle
    //   .align(0)                  // This does nothing
    //   .domain(groups.map(d => d.key));
    // console.log([0, Math.PI / 4, Math.PI / 2, Math.PI, 2 * Math.PI], groups.map(d => d.key));
    var xGroup = d3.scaleLinear()
      .range([0, 2 * Math.PI])
      .domain([0, sum]);

    let groupArc = d3.arc()     // imagine your doing a part of a donut plot
      .innerRadius(outerRadius)
      .outerRadius(outerRadius + groupedHeight)
      .startAngle(d => xGroup(d.start))
      .endAngle(d => xGroup(d.end)) //  + xGroup.bandwidth()
      .padAngle(0)
      .padRadius(0);
    // Add the bars

    // this.radialbarItemsG.select(".groups").interrupt();
    var groupPath = this.radialbarItemsG.select("g.groups").selectAll(".group-path")
      .data(groups);
    // EXIT old elements not present in new data.
    groupPath.exit()
      .style("fill-opacity", 1e-6)
      .remove();
    // UPDATE old elements present in new data.
    groupPath
      .attr("id", (d, i) => "arc-" + i)
      .attr("fill", d => d.color)
      .attr("d", d => groupArc(d));
    // ENTER new elements present in new data.
    groupPath.enter().append("path")
      .attr("id", (d, i) => "arc-" + i)
      .attr("class", "group-path")
      .attr("fill", d => d.color)
      .attr("d", d => groupArc(d));

    const isIEorEDGE = this.isIEorEDGE();
    // Populate the <text> elements with our data-driven titles.
    var groupText = this.radialbarItemsG.select("g.groups").selectAll(".group-text")
      .data(groups);
    // EXIT old elements not present in new data.
    groupText.exit()
      .style("fill-opacity", 1e-6)
      .remove();
    // UPDATE old elements present in new data.
    groupText
      .select("textPath")
      .attr("xlink:href", (d, i) => "#arc-" + i)
      .text(d => d.key)
      .attr("startOffset", function (d) {
        d.startAngle = groupArc.startAngle()(d);
        d.endAngle = groupArc.endAngle()(d);
        d.path = (2 * Math.PI * (outerRadius + groupedHeight)) * (d.endAngle - d.startAngle) / (2 * Math.PI); // C = 2*PI*r
        // d.textLength = this.getComputedTextLength();
        d.textLength = getTextWidth(`${d.key}`, '16px', 'sans-serif');
        return ((isIEorEDGE ? -10 : 0) + ((d.path - d.textLength) / 2)) + "px";
      });
    // ENTER new elements present in new data.
    groupText.enter().append("text")
      .attr("class", "group-text")
      // .attr("transform", function (d) {
      //   let x0 = groupArc.startAngle()(d);
      //   let x1 = groupArc.endAngle()(d);
      //   // console.log({x0, x1});
      //   return "translate(" + groupArc.centroid(d) + ")rotate(" + computeTextRotation({x0, x1}) + ")";
      // })
      .attr("dy", "17px") // rotation align
      .append("textPath") // https://www.visualcinnamon.com/2015/09/placing-text-on-arcs.html
      .style("text-anchor", "start")
      .attr("xlink:href", (d, i) => "#arc-" + i)
      .style("fill", "#fff")
      .style("font-weight", "bold")
      .style("font-size", "16px")
      .text(d => d.key)
      .attr("startOffset", function (d) {
        d.startAngle = groupArc.startAngle()(d);
        d.endAngle = groupArc.endAngle()(d);
        d.path = (2 * Math.PI * (outerRadius + groupedHeight)) * (d.endAngle - d.startAngle) / (2 * Math.PI); // C = 2*PI*r
        // d.textLength = this.getComputedTextLength();
        d.textLength = getTextWidth(`${d.key}`, '16px', 'sans-serif');
        return ((isIEorEDGE ? -10 : 0) + ((d.path - d.textLength) / 2)) + "px";
      });


    var categoryLine = this.radialbarItemsG.select("g.categories").selectAll(".category-line")
      .data(this.radialbarData);
    // EXIT old elements not present in new data.
    categoryLine.exit()
      .style("fill-opacity", 1e-6)
      .remove();
    // UPDATE old elements present in new data.
    categoryLine
      .attr("x1", d => (y(max) + 7 + groupedHeight))
      .attr("x2", d => (y(max) + 30 + groupedHeight))
      .attr("transform", d => "rotate(" + ((x(d.area) + x.bandwidth() / 2) * 180 / Math.PI - 90) + ")" + "translate(0,0)");
    // ENTER new elements present in new data.
    categoryLine.enter().append("line")
      .attr("class", "category-line")
      .attr("x1", d => (y(max) + 7 + groupedHeight))
      .attr("y1", 0)
      .attr("x2", d => (y(max) + 30 + groupedHeight))
      .attr("y2", 0)
      .style("stroke", "#a1a1a1")
      .style("fill", "none")
      .style("stroke-width", "2px")
      .attr("stroke-dasharray", "2,2")
      .attr("transform", d => "rotate(" + ((x(d.area) + x.bandwidth() / 2) * 180 / Math.PI - 90) + ")" + "translate(0,0)");


    var categoryCircle = this.radialbarItemsG.select("g.categories").selectAll(".category-circle")
      .data(this.radialbarData);
    // EXIT old elements not present in new data.
    categoryCircle.exit()
      .style("fill-opacity", 1e-6)
      .remove();
    // UPDATE old elements present in new data.
    categoryCircle
      .attr("transform", d => "rotate(" + ((x(d.area) + x.bandwidth() / 2) * 180 / Math.PI - 90) + ")" + "translate(" + (y(max) + groupedHeight) + ",0)");
    // ENTER new elements present in new data.
    categoryCircle.enter().append("circle")
      .attr("class", "category-circle")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", 4)
      .style("stroke", "#515151")
      .style("fill", "#fff")
      .style("stroke-width", "2px")
      .attr("transform", d => "rotate(" + ((x(d.area) + x.bandwidth() / 2) * 180 / Math.PI - 90) + ")" + "translate(" + (y(max) + groupedHeight) + ",0)");



    var categoryPoint = this.radialbarItemsG.select("g.categories").selectAll(".category-point")
      .data(this.radialbarData);
    // EXIT old elements not present in new data.
    categoryPoint.exit()
      .style("fill-opacity", 1e-6)
      .remove();
    // UPDATE old elements present in new data.
    categoryPoint
      .attr("transform", d => "rotate(" + ((x(d.area) + x.bandwidth() / 2) * 180 / Math.PI - 90) + ")" + "translate(" + (y(max) + 34 + groupedHeight) + ",0)");
    // ENTER new elements present in new data.
    categoryPoint.enter().append("circle")
      .attr("class", "category-point")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", 3)
      .style("fill", "#515151")
      .style("stroke-width", "0")
      .attr("transform", d => "rotate(" + ((x(d.area) + x.bandwidth() / 2) * 180 / Math.PI - 90) + ")" + "translate(" + (y(max) + 34 + groupedHeight) + ",0)");



    function getTextWidth(text, fontSize, fontName) {
      let c = document.createElement('canvas');
      let ctx = c.getContext('2d');
      ctx.font = fontSize + ' ' + fontName;
      return ctx.measureText(text).width;
    } // getTextWidth(d, '12px', 'myriad-pro-condensed, robotoCondensed-light, sans-serif');

    // Add the labels
    var categoryLabel = this.radialbarItemsG.select("g.categories").selectAll(".category-label")
      .data(this.radialbarData);
    // EXIT old elements not present in new data.
    categoryLabel.exit()
      .style("fill-opacity", 1e-6)
      .remove();
    // UPDATE old elements present in new data.
    categoryLabel
      .attr("text-anchor", d => (x(d.area) + x.bandwidth() / 2 + Math.PI) % (2 * Math.PI) < Math.PI ? "end" : "start")
      .attr("transform", d => "rotate(" + ((x(d.area) + x.bandwidth() / 2) * 180 / Math.PI - 90) + ")" + "translate(" + (y(max) + 49 + groupedHeight) + ",0)")
      .select("text")
      .text(d => d.area)
      .attr("transform", d => (x(d.area) + x.bandwidth() / 2 + Math.PI) % (2 * Math.PI) < Math.PI ? "rotate(180)" : "rotate(0)")
      .on('click', (d) => {
        this.selectedArea = d;
        this.isBar = true;
        this.isTrends = false;
        this.drawBarChart();
        this.radialbarG.classed("d3-hide", true);
        this.barG.classed("d3-hide", false);
        this.goBack.classed("d3-hidden", false);
        this.trendsG.classed("d3-hide", true);
        this.trends.classed("d3-hidden", true);
      })
      .on("mouseover", (d) => {
        this.tooltip
          .classed("d3-hide", false)
          .html(`<b>${d.area}</b><br>${i18n.t("trans_33_your_results")}: ${d.average.toFixed(0)}%<br>${i18n.t("trans_33_Benchmark")}: ${d.value.toFixed(0)}%`)
          .each(function () {
            d.firstRow = getTextWidth(`${d.area}`, '12px', 'sans-serif');
            d.secondRow = getTextWidth(i18n.t("trans_33_your_results") + `: ${d.average.toFixed(0)}%`, '12px', 'sans-serif');
            d.thirdRow = getTextWidth(i18n.t("trans_33_Benchmark") + `: ${d.value.toFixed(0)}%`, '12px', 'sans-serif');
            d.maxRow = Math.max(...[d.firstRow, d.secondRow, d.thirdRow]) + 28;
          })
          .style("left", (d3.event.pageX - d.maxRow / 2) + "px")
          .style("top", (d3.event.pageY - 75) + "px");
      })
      .on("mouseout", (d) => {
        this.tooltip.classed("d3-hide", true);
      });
    // ENTER new elements present in new data.
    categoryLabel.enter().append("g")
      .attr("class", "category-label")
      .attr("text-anchor", d => (x(d.area) + x.bandwidth() / 2 + Math.PI) % (2 * Math.PI) < Math.PI ? "end" : "start")
      .attr("transform", d => "rotate(" + ((x(d.area) + x.bandwidth() / 2) * 180 / Math.PI - 90) + ")" + "translate(" + (y(max) + 49 + groupedHeight) + ",0)")
      .append("text")
      .text(d => d.area)
      .attr("transform", d => (x(d.area) + x.bandwidth() / 2 + Math.PI) % (2 * Math.PI) < Math.PI ? "rotate(180)" : "rotate(0)")
      .style("font-size", "12px")
      .style("font-weight", "bold")
      .style("fill", "#515151")
      .style("pointer-events", "all")
      .style("cursor", "pointer")
      .attr("alignment-baseline", "middle")
      .on('click', (d) => {
        this.selectedArea = d;
        this.isBar = true;
        this.isTrends = false;
        this.drawBarChart();
        this.radialbarG.classed("d3-hide", true);
        this.barG.classed("d3-hide", false);
        this.goBack.classed("d3-hidden", false);
      })
      .on("mouseover", (d) => {
        this.tooltip
          .classed("d3-hide", false)
          .html(`<b>${d.area}</b><br>${i18n.t("trans_33_your_results")}: ${d.average.toFixed(0)}%<br>${i18n.t("trans_33_Benchmark")}: ${d.value.toFixed(0)}%`)
          .each(function () {
            d.firstRow = getTextWidth(`${d.area}`, '12px', 'sans-serif');
            d.secondRow = getTextWidth(i18n.t("trans_33_your_results") + `: ${d.average.toFixed(0)}%`, '12px', 'sans-serif');
            d.thirdRow = getTextWidth(i18n.t("trans_33_Benchmark") + `: ${d.value.toFixed(0)}%`, '12px', 'sans-serif');
            d.maxRow = Math.max(...[d.firstRow, d.secondRow, d.thirdRow]) + 28;
          })
          .style("left", (d3.event.pageX - d.maxRow / 2) + "px")
          .style("top", (d3.event.pageY - 75) + "px");
      })
      .on("mouseout", (d) => {
        this.tooltip.classed("d3-hide", true);
      });


  }

  drawBarChart(noTransition) {
    let data = this.barData[this.selectedArea.area];
    let isData = false;
    if (!data) {
      let benchmark = this.radialbarDataObj[this.selectedArea.area] ? (this.radialbarDataObj[this.selectedArea.area].value || 0) : 0;
      // console.log("drawBarChart data", data, this.selectedArea.area, this.radialbarDataObj);
      data = {
        "label": this.selectedArea.area,
        "values": [],
        "value": benchmark
      }
    }
    let values = data.values.filter((d, i) => (i >= this.selectedRange[0] && i <= this.selectedRange[1]));
    let valuesByMonth = d3.nest().key(d => d.date).entries(values);
    valuesByMonth.forEach(d => {
      d.date = d.key;
      d.value = 100 * this.anyToNumber(d3.sum(d.values, d => this.anyToNumber(d.SumYesPercentage)) / d3.sum(d.values, d => this.anyToNumber(d.SumWeights)));
      if (d.value) {
        isData = true;
      }
    });
    values = valuesByMonth;
    // console.log("valuesByMonth", valuesByMonth);
    let isValues = (data.values.filter((d, i) => d.value !== null)).length;
    values.forEach((d, i) => {
      // let _values = values.slice(0, i).map((d) => d.value) || []; // (start, end)
      let _values = values.slice(0, i + 1) || []; // (start, end)
      // console.log("_values", _values);
      let sumYesPercentageArray = [];
      let sumWeightsArray = [];
      _values.forEach(_value => {
        _value.values.forEach(d => {
          if (d.SumYesPercentage !== null && d.SumWeights !== null) {
            sumYesPercentageArray.push(d.SumYesPercentage);
            sumWeightsArray.push(d.SumWeights);
          }
        });
      });
      // console.log("Array", sumYesPercentageArray, sumWeightsArray);
      // _values.push(d.value);
      // d.average = d3.mean(_values.filter(d => d !== null), (d) => d); // || null;
      d.average = null;
      if (sumYesPercentageArray.length && sumWeightsArray.length) {
        d.average = 100 * d3.mean(sumYesPercentageArray) / d3.mean(sumWeightsArray)
      }

      // if(this.selectedArea.area.includes("H.")){
      //   console.log("average", d.average);
      // }
      // console.log("average", d.average);
    });
    // values = values.filter(d => d.value !== null);
    // console.log("values", values);
    var margin = {
      top: 20,
      right: 20,
      bottom: 60,
      left: 20
    };
    let transition = d3.transition().duration(noTransition ? 0 : 500);
    let width = this.width - margin.left - margin.right;
    let height = this.height - margin.top - margin.bottom;

    let xScale = d3.scaleBand()
      .rangeRound([0, width])
      .padding(0.2)
      // .domain(d3.extent(data.values, (d)=>d.date));
      .domain(values.map((d) => d.date));
    let yScale = d3.scaleLinear()
      .rangeRound([height, 0])
      .domain([0, Math.max(1, data.value, d3.max(values, (d) => d.value) || 0)]);
    var g = this.barG.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    // console.log("yScale", yScale.domain(), d3.max(values, (d) => d.value));

    // axis-x
    g.select("g.x-axis")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(xScale))
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-45)");

    // axis-y
    g.select("g.y-axis")
      .call(d3.axisLeft(yScale).tickSize(-width, 0).tickFormat(d => d + "%"));


    // General Update Pattern, I - https://bl.ocks.org/mbostock/3808218
    // General Update Pattern, II - https://bl.ocks.org/mbostock/3808234
    // JOIN new data with old elements.
    g.select(".bars").interrupt()
    var rect = g.select(".bars").selectAll("rect")
      .data(values)
    // EXIT old elements not present in new data.
    rect.exit()
      .transition(transition)
      .style("fill-opacity", 1e-6)
      .remove();
    // UPDATE old elements present in new data.
    rect
      .on("mouseover", (d) => {
        this.tooltip
          .style("left", (xScale(d.date) + this.margin.left - 15 + xScale.bandwidth() / 2) + "px")
          // .style("left", (d3.event.pageX - 55) + "px")
          .style("top", (yScale(d.value || 0) + this.margin.top - 25) + "px")
          .classed("d3-hide", false)
          .text(d.value === null ? "NA" : (d.value.toFixed(0) + "%"));
      })
      .on("mouseout", (d) => {
        this.tooltip.classed("d3-hide", true);
      })
      .transition(transition)
      // .style("fill-opacity", 1)
      .attr("width", xScale.bandwidth() || 0)
      .attr("height", (d) => (height - yScale(d.value || 0)) || 0)
      .attr("x", (d) => xScale(d.date) || 0)
      .attr("y", (d) => yScale(d.value || 0) || 0);
    // ENTER new elements present in new data.
    rect.enter().append("rect")
      .attr("class", "bar")
      // ENTER + UPDATE
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => (height - yScale(d.value || 0)) || 0)
      .attr("x", (d) => xScale(d.date) || 0)
      .attr("y", (d) => yScale(d.value || 0) || 0)
      // .style("fill-opacity", 1e-6)
      .on("mouseover", (d) => {
        this.tooltip
          .style("left", (xScale(d.date) + this.margin.left - 15 + xScale.bandwidth() / 2) + "px")
          // .style("left", (d3.event.pageX - 55) + "px")
          .style("top", (yScale(d.value) + this.margin.top - 25) + "px")
          .classed("d3-hide", false)
          .text(d.value === null ? "NA" : (d.value.toFixed(0) + "%"));
      })
      .on("mouseout", (d) => {
        this.tooltip.classed("d3-hide", true);
      })
      .transition(transition)
      // .style("fill-opacity", 1)
      .attr("width", xScale.bandwidth() || 0)
      .attr("height", (d) => (height - yScale(d.value || 0)) || 0)
      .attr("x", (d) => xScale(d.date) || 0)
      .attr("y", (d) => yScale(d.value) || 0);


    g.select(".line")
      // .style("visibility", isData ? "visible": "hidden")
      .attr("x1", 0)
      .attr("x2", width)
      .attr("y1", yScale(data.value || 0) || 0)
      .attr("y2", yScale(data.value || 0) || 0);

    // line chart
    let line = d3.line()
      .defined(function (d) { return d.average !== undefined && d.average !== null; })
      .x(function (d) { return xScale(d.date) + xScale.bandwidth() / 2; })
      .y(function (d) { return yScale(d.average); })
      .curve(d3.curveMonotoneX);

    g.select(".path").interrupt();
    g.select(".path")
      .style("visibility", isData ? "visible" : "hidden")
      .transition(transition)
      .attr("d", line(isValues ? (values || []) : []));

    g.select(".x-title")
      .attr("transform", "translate(" + (width / 2) + "," + (height + margin.top + 40) + ")")

    g.select(".y-title")
      .text(this.selectedArea.area)
      .attr("y", 0 - margin.left - 40)
      .attr("x", 0 - (height / 2));

    let benchmarkY = (yScale(data.value || 0) + 3) || 0;//20
    let averageY = values.length ? (yScale(values[values.length - 1].average) || 0) : 0;//0

    g.select(".no-data")
      .style("visibility", isData ? "hidden" : "visible")
      .attr("transform", "translate(" + (width / 2) + "," + (height / 2) + ")");

    g.select(".benchmark-label")
      // .style("visibility", isData ? "visible": "hidden")
      .attr("transform", "translate(" + (width + 5) + "," + (benchmarkY) + ")");

    // console.log((benchmarkY + 20) > averageY && (benchmarkY - 20) < averageY, averageY > benchmarkY);
    g.select(".average-label")
      .style("visibility", isData ? "visible" : "hidden")
      .attr("transform", "translate(" + (width + 5) + "," + ((benchmarkY + 15) > averageY && (benchmarkY - 15) < averageY ? (averageY > benchmarkY ? benchmarkY + 15 : benchmarkY - 15) : averageY + 3) + ")");

  }

  drawTrendsChart(noTransition) {
    let data = [];
    let isData = this.barsData && this.barsData.length;
    // if(!isData){
    //   g.select(".no-data")
    //   .style("visibility", isData ? "hidden": "visible")
    //   .attr("transform", "translate(" + (width / 2) + "," + (height / 2) + ")");
    //   return;
    // }
    var groupColor = (i) => {
      let colors = ["#8dd3c7", "#bebada", "#fb8072", "#80b1d3", "#fdb462", "#b3de69", "#ffc0cb", "#bc80bd", "#ccebc5"]; // "#ffffb3", , "#ffed6f" "#d9d9d9" = grey
      return colors[i % colors.length];
    };
    let firstEmpty = true;
    this.barsData.forEach(d => {
      let values = d.values.filter((d, i) => (i >= this.selectedRange[0] && i <= this.selectedRange[1]));
      let valuesByMonth = d3.nest().key(d => d.date).entries(values);
      valuesByMonth.forEach(d => {
        d.date = d.key;
        d.value = 100 * this.anyToNumber(d3.sum(d.values, d => this.anyToNumber(d.SumYesPercentage)) / d3.sum(d.values, d => this.anyToNumber(d.SumWeights)));
      });
      values = valuesByMonth;
      let empty = d3.sum(valuesByMonth, d => d.value);
      data.push({
        label: d.label,
        empty: empty === 0 || empty === null,
        firstEmpty: firstEmpty,
        values: valuesByMonth
      });
      if (firstEmpty) {
        firstEmpty = false;
      }
    });

    // console.log("data", data);
    let values = [].concat(...data.map(d => d.values));
    values.forEach(d => {
      d.sort = d.values && d.values.length ? d.values[0].sort : null;
      d.datetime = d.values && d.values.length ? d.values[0].datetime : null;
    });
    values.sort((a, b) => a.sort - b.sort);
    // let dates = [...new Set(values.map(d => d.date))];
    let dates = d3.extent([...new Set(values.map(d => d.datetime))]);
    // console.log("dates", dates);

    data.forEach((c, k) => {
      c.color = groupColor(k);
      c.values.forEach((d, i) => {
        // let _values = values.slice(0, i).map((d) => d.value) || []; // (start, end)
        let _values = c.values.slice(0, i + 1) || []; // (start, end)
        // console.log("_values", _values);
        let sumYesPercentageArray = [];
        let sumWeightsArray = [];
        _values.forEach(_value => {
          _value.values.forEach(d => {
            if (d.SumYesPercentage !== null && d.SumWeights !== null) {
              sumYesPercentageArray.push(d.SumYesPercentage);
              sumWeightsArray.push(d.SumWeights);
            }
          });
        });
        // console.log("Array", sumYesPercentageArray, sumWeightsArray);
        // _values.push(d.value);
        // d.average = d3.mean(_values.filter(d => d !== null), (d) => d); // || null;
        d.average = null;
        if (sumYesPercentageArray.length && sumWeightsArray.length) {
          d.average = 100 * d3.mean(sumYesPercentageArray) / d3.mean(sumWeightsArray)
        }
        // console.log("average", d.average);
      });
    });
    // values = values.filter(d => d.value !== null);
    // console.log("values", values);
    var margin = {
      top: 20,
      right: 20,
      bottom: 60,
      left: 20
    };
    // let transition = d3.transition().duration(noTransition ? 0 : 500);
    let width = this.width - margin.left - margin.right;
    let height = this.height - margin.top - margin.bottom;
    let xScale = d3.scaleTime()
      .rangeRound([0, width])
      // .padding(0.2)
      .domain(dates)
    // .domain(values.map((d) => d.date));
    // console.log("xScale domain", dates);
    let yScale = d3.scaleLinear()
      .rangeRound([height, 0])
      .domain([d3.min(values, d => d3.min(d.values, c => c.value || 0)), d3.max(data, d => d3.max(d.values, c => c.value || 0))]);
    var g = this.trendsG.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    // console.log("yScale", yScale.domain(), d3.max(values, (d) => d.value));
    // console.log(values, d3.extent(values, d => d.date));
    // axis-x
    g.select("g.x-axis")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(xScale))
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-45)");

    // axis-y
    g.select("g.y-axis")
      .call(d3.axisLeft(yScale).tickSize(-width, 0).tickFormat(d => d + "%"));

    // line chart
    let line = d3.line()
      .defined(function (d) { return d.average !== undefined && d.average !== null; })
      // .x(function (d) { return xScale(d.date) + xScale.bandwidth() / 2; })
      .x(function (d) { return xScale(d.datetime); })
      .y(function (d) { return yScale(d.average); })
      .curve(d3.curveMonotoneX);

    let labels = [];
    let paths = [];
    let points = [];

    // g.select(".path").interrupt();
    // g.select(".path")
    //   .style("visibility", isData ? "visible": "hidden")
    //   .transition(transition)
    //   .attr("d", line(values || []));
    // General Update Pattern, I - https://bl.ocks.org/mbostock/3808218
    // General Update Pattern, II - https://bl.ocks.org/mbostock/3808234
    // JOIN new data with old elements.
    g.select(".paths").interrupt();
    var path = g.select(".paths").selectAll("path")
      .data(data);
    // EXIT old elements not present in new data.
    path.exit()
      // .transition(transition)
      .style("fill-opacity", 1e-6)
      .remove();
    // UPDATE old elements present in new data.
    path
      .on("mouseover", (d) => {
        paths.style("stroke", c => c.label === d.label ? c.color : "#e4e4e4");
        points.style("fill", c => c.label === d.label ? c.color : "#e4e4e4");
        labels.style("font-weight", c => c.data.label === d.label ? "bold" : "normal")
          .style("fill", c => c.data.label === d.label ? c.data.color : "#e4e4e4");
      })
      .on("mouseout", () => {
        paths.style("stroke", c => c.color);
        point.style("fill", c => c.color);
        labels.style("font-weight", "normal").style("fill", c => c.data.color);
      })
      // .transition(transition)
      .style("stroke", d => d.color)
      .attr("d", d => line(d.values || []));
    // ENTER new elements present in new data.
    path.enter().append("path")
      .attr("class", "path")
      // ENTER + UPDATE
      .style("cursor", "pointer")
      .style("stroke", d => d.color)
      .attr("d", d => line(d.values || []))
      .on("mouseover", (d) => {
        paths.style("stroke", c => c.label === d.label ? c.color : "#e4e4e4");
        points.style("fill", c => c.label === d.label ? c.color : "#e4e4e4");
        labels.style("font-weight", c => c.data.label === d.label ? "bold" : "normal")
          .style("fill", c => c.data.label === d.label ? c.data.color : "#e4e4e4");
      })
      .on("mouseout", () => {
        paths.style("stroke", c => c.color);
        point.style("fill", c => c.color);
        labels.style("font-weight", "normal").style("fill", c => c.data.color);
      })
      // .transition(transition)
      // .style("fill-opacity", 1)
      // .style("stroke", (d,i)=>groupColor(i))
      .attr("d", d => line(d.values || []));

    g.select(".points").interrupt()
    var point = g.select(".points").selectAll("circle") // last point
      .data(data);
    // EXIT old elements not present in new data.
    point.exit()
      // .transition(transition)
      .style("fill-opacity", 1e-6)
      .remove();
    // UPDATE old elements present in new data.
    point
      .style("fill", d => d.color)
      // .transition(transition)
      .attr("cx", (d) => d.values && d.values.length ? xScale(d.values[d.values.length - 1].datetime) : null)
      .attr("cy", (d) => d.values && d.values.length ? yScale(d.values[d.values.length - 1].average) : null);
    // ENTER new elements present in new data.
    point.enter().append("circle")
      .attr("class", "point")
      .attr("r", 1.5)
      // ENTER + UPDATE
      .style("fill", d => d.color)
      .attr("cx", (d) => d.values && d.values.length ? xScale(d.values[d.values.length - 1].datetime) : null)
      .attr("cy", (d) => d.values && d.values.length ? yScale(d.values[d.values.length - 1].average) : null)
      // .transition(transition)
      // .style("fill-opacity", 1)
      // .style("fill", (d,i)=>groupColor(i))
      .attr("cx", (d) => d.values && d.values.length ? xScale(d.values[d.values.length - 1].datetime) : null)
      .attr("cy", (d) => d.values && d.values.length ? yScale(d.values[d.values.length - 1].average) : null);

    paths = g.select(".paths").selectAll("path");
    points = g.select(".points").selectAll("circle");

    // Create some nodes
    // console.log("data", data);
    const labelData = data.map(d => {
      return {
        fx: 0,
        targetY: d.values && d.values.length ? yScale(d.values[d.values.length - 1].average) : null,
        data: d
      };
    });
    // console.log("labels", labels);

    // https://bl.ocks.org/wdickerson/bd654e61f536dcef3736f41e0ad87786
    // Define a custom force
    const forceClamp = (min, max) => {
      let nodes;
      const force = () => {
        nodes.forEach(n => {
          if (n.y > max) n.y = max;
          if (n.y < min) n.y = min;
        });
      };
      force.initialize = (_) => nodes = _;
      return force;
    }

    // Set up the force simulation
    const labelHeight = 14;
    const force = d3.forceSimulation()
      .nodes(labelData)
      .force('collide', d3.forceCollide(labelHeight / 2))
      .force('y', d3.forceY(d => d.targetY).strength(1))
      .force('clamp', forceClamp(0, height))
      .stop();

    // Execute the simulation
    for (let i = 0; i < 300; i++) force.tick();

    // Assign values to the appropriate marker
    labelData.sort((a, b) => a.y - b.y);

    // Add labels
    g.select(".labels").interrupt()
    const label = g.select(".labels").selectAll("text").data(labelData, d => d.data.label);
    label.exit().remove();
    label.attr('y', d => d.y)
      .style("fill", d => d.data.color)
      .on("mouseover", (d) => {
        // paths.style("opacity", (c) => c.label === d.data.label ? 1 : 0.3);
        // points.style("opacity", (c) => c.label === d.data.label ? 1 : 0.3);
        paths.style("stroke", c => c.label === d.data.label ? c.color : "#e4e4e4");
        points.style("fill", c => c.label === d.data.label ? c.color : "#e4e4e4");
        labels.style("font-weight", c => c.data.label === d.data.label ? "bold" : "normal")
          .style("fill", c => c.data.label === d.data.label ? c.data.color : "#e4e4e4");
      })
      .on("mouseout", () => {
        // paths.style("opacity", 1);
        // point.style("opacity", 1);
        labels.style("font-weight", "normal").style("fill", c => c.data.color);
        paths.style("stroke", c => c.color);
        point.style("fill", c => c.color);
      });
    label.enter().append('text')
      .attr('class', 'label')
      .text(d => d.data.label)
      .attr('alignment-baseline', 'middle')
      .style("fill", d => d.data.color)
      .style("cursor", "pointer")
      .attr('x', width + 15)
      .attr('y', d => d.y)
      .on("mouseover", (d) => {
        paths.style("stroke", c => c.label === d.data.label ? c.color : "#e4e4e4");
        points.style("fill", c => c.label === d.data.label ? c.color : "#e4e4e4");
        labels.style("font-weight", c => c.data.label === d.data.label ? "bold" : "normal")
          .style("fill", c => c.data.label === d.data.label ? c.data.color : "#e4e4e4");
      })
      .on("mouseout", () => {
        labels.style("font-weight", "normal").style("fill", c => c.data.color);
        paths.style("stroke", c => c.color);
        point.style("fill", c => c.color);
      });

    labels = g.select(".labels").selectAll("text");

    // let labelGroups = d3.nest().key(d => d.values && d.values.length ? (d.values[d.values.length - 1].average || 0) : 0).entries(data);
    // let labelGroups = d3.nest().key(d => d.values && d.values.length ? Math.round((d.values[d.values.length - 1].average || 0) / 5) * 5 : 0).entries(data);
    // let labelGroupsObj = labelGroups.reduce((acc,el) => {
    //   acc[el.key] = el;
    //   return acc;
    // }, {});
    // labelGroups.forEach(d => {
    //   d.key = d.values && d.values.length ? d.values[d.values.length - 1].values[d.values[d.values.length - 1].values.length - 1].average : d.key
    // });
    // console.log("data", data);
    // console.log("labelGroups", labelGroups);


    // g.select(".labels").interrupt()
    // var label = g.select(".labels").selectAll("text")
    //   .data(data);
    //   // .data(labelGroups);
    // // console.log("label", label);
    // // EXIT old elements not present in new data.
    // label.exit()
    //   .text("")
    //   // .transition(transition)
    //   .style("fill-opacity", 1e-6)
    //   .remove();
    // // UPDATE old elements present in new data.
    // label
    //   .on("mouseover", (d, i) => {
    //     paths.style("opacity", (c,j) => i === j ? 1 : 0.5);
    //     points.style("opacity", (c,j) => i === j ? 1 : 0.5);
    //     // let key = d.values && d.values.length ? (d.values[d.values.length - 1].average || 0) : 0;
    //     let label = ((d.values || []).map(d => d.label)).join("<br>");
    //     // console.log(key, labelGroups, labelGroups[key]);
    //     this.tooltip
    //       // .style("left", (margin.left + width + 5) + "px")
    //       // .style("top", (margin.top + yScale(key)) + "px")
    //       .style("left", (d3.event.pageX) + "px")
    //       .style("top", (d3.event.pageY - 50) + "px")
    //       .classed("d3-hide", false)
    //       .html(label);
    //   })
    //   .on("mouseout", () => {
    //     path.style("opacity", 1);
    //     point.style("opacity", 1);
    //     this.tooltip.classed("d3-hide", true);
    //   })
    //   .on("mouseout", () => {
    //     paths.style("opacity", 1);
    //     points.style("opacity", 1);
    //     this.tooltip.classed("d3-hide", true);
    //   })
    //   // .text(d => {
    //   //   let key = d.values && d.values.length ? (d.values[d.values.length - 1].average || 0) : 0;
    //   //   // return d.empty ? (d.firstEmpty ? "Others" : "") : labelGroups[key].values[0].label + (labelGroups[key].values.length === 1 ? "" : ",...");
    //   //   return d.empty ? (d.firstEmpty ? (labelGroups[key].values[0].label + (labelGroups[key].values.length === 1 ? "" : ",...")) : "") : labelGroups[key].values[0].label;
    //   // })
    //   // .text(d => d.values[0].label + (d.values.length === 1 ? "" : ",..."))
    //   .text(d => d.label)
    //   // .transition(transition)
    //   .attr("x", width + 5)
    //   // .attr("y", (d) => yScale(d.key));
    //   .attr("y", (d) => d.values && d.values.length ? yScale(d.values[d.values.length - 1].average) : null);
    // // ENTER new elements present in new data.
    // label.enter().append("text")
    //   .attr("class", "label")
    //   .attr("dy", "4px")
    //   .attr("x", width + 5)
    //   // ENTER + UPDATE
    //   .on("mouseover", (d, i) => {
    //     // console.log(d,i);
    //     paths.style("opacity", (c,j) => i === j ? 1 : 0.5);
    //     points.style("opacity", (c,j) => i === j ? 1 : 0.5);
    //     // let key = d.values && d.values.length ? (d.values[d.values.length - 1].average || 0) : 0;
    //     let label = ((d.values || []).map(d => d.label)).join("<br>");
    //     // console.log(key, labelGroups, labelGroups[key]);
    //     this.tooltip
    //       // .style("left", (margin.left + width + 5) + "px")
    //       // .style("top", (margin.top + yScale(key)) + "px")
    //       .style("left", (d3.event.pageX) + "px")
    //       .style("top", (d3.event.pageY - 50) + "px")
    //       .classed("d3-hide", false)
    //       .html(label);
    //   })
    //   .on("mouseout", () => {
    //     paths.style("opacity", 1);
    //     points.style("opacity", 1);
    //     this.tooltip.classed("d3-hide", true);
    //   })
    //   // .text(d => d.values[0].label + (d.values.length === 1 ? "" : ",..."))
    //   .text(d => d.label)
    //   // .transition(transition)
    //   .style("fill-opacity", 1)
    //   // .attr("y", (d) => yScale(d.key));
    //   .attr("y", (d) => d.values && d.values.length ? yScale(d.values[d.values.length - 1].average) : null);

    g.select(".x-title")
      .attr("transform", "translate(" + (width / 2) + "," + (height + margin.top + 40) + ")")

    g.select(".y-title")
      // .text(this.selectedArea.area)
      .attr("y", 0 - margin.left - 40)
      .attr("x", 0 - (height / 2));

    // let benchmarkY = (yScale(data.value || 0) + 3) || 0;//20
    // let averageY = values.length ? (yScale(values[values.length - 1].average) || 0) : 0;//0

    g.select(".no-data")
      .style("visibility", isData ? "hidden" : "visible")
      .attr("transform", "translate(" + (width / 2) + "," + (height / 2) + ")");

    // console.log((benchmarkY + 20) > averageY && (benchmarkY - 20) < averageY, averageY > benchmarkY);
    // g.select(".average-label")
    //   .style("visibility", isData ? "visible": "hidden")
    //   .attr("transform", "translate(" + (width + 5) + "," + ((benchmarkY + 15) > averageY && (benchmarkY - 15) < averageY ? (averageY > benchmarkY ? benchmarkY + 15 : benchmarkY - 15) : averageY + 3) + ")");

  }

  /*jslint browser: true */
  /*jslint this */


  // https://rasmusfonseca.github.io/d3RangeSlider/
  drawRangeSlider() { //rangeMin, rangeMax, containerSelector

    var minWidth = 50;
    var rangeMin = 0;
    var rangeMax = this.dates.length - 1;
    // console.log(this.dates, rangeMin, rangeMax);
    var sliderRange = {
      begin: rangeMin,
      end: rangeMin
    };
    var changeListeners = [];
    var touchEndListeners = [];
    var container = this.rangeSlider;
    var containerHeight = container.node().offsetHeight;

    // Set up play button if requested

    var sliderBox = container.select(".slider-container")
      .style("position", "relative")
      .style("height", containerHeight + "px")
      .style("min-width", (minWidth * 2) + "px");

    //Create elements in container
    var slider = sliderBox
      .select(".slider");
    var handleW = slider.select(".handle-WW");
    var handleE = slider.select(".handle-EE");

    /** Update the `left` and `width` attributes of `slider` based on `sliderRange` */
    function updateUIFromRange() {
      var conW = sliderBox.node().clientWidth;
      var rangeW = sliderRange.end - sliderRange.begin;
      var slope = (conW - minWidth) / (rangeMax - rangeMin);
      var uirangeW = minWidth + rangeW * slope;
      var ratio = (sliderRange.begin - rangeMin) / (rangeMax - rangeMin - rangeW);
      if (isNaN(ratio)) {
        ratio = 0;
      }
      var uirangeL = ratio * (conW - uirangeW);

      slider
        .style("left", uirangeL + "px")
        .style("width", uirangeW + "px");
    }

    /** Update the `sliderRange` based on the `left` and `width` attributes of `slider` */
    function updateRangeFromUI() {
      var uirangeL = parseFloat(slider.style("left"));
      var uirangeW = parseFloat(slider.style("width"));
      var conW = sliderBox.node().clientWidth; //parseFloat(container.style("width"));
      var slope = (conW - minWidth) / (rangeMax - rangeMin);
      var rangeW = (uirangeW - minWidth) / slope;
      var uislope = 0;
      if (conW === uirangeW) {
        uislope = 0;
      } else {
        uislope = (rangeMax - rangeMin - rangeW) / (conW - uirangeW);
      }
      var rangeL = rangeMin + uislope * uirangeL;
      sliderRange.begin = Math.round(rangeL);
      sliderRange.end = Math.round(rangeL + rangeW);

      //Fire change listeners
      changeListeners.forEach(function (callback) {
        callback({
          begin: sliderRange.begin,
          end: sliderRange.end
        });
      });
    }

    // configure drag behavior for handles and slider
    var dragResizeE = d3.drag()
      .on("start", function () {
        d3.event.sourceEvent.stopPropagation();
      })
      .on("end", function () {
        touchEndListeners.forEach(function (callback) {
          callback({
            begin: sliderRange.begin,
            end: sliderRange.end
          });
        });
      })
      .on("drag", function () {
        var dx = d3.event.dx;
        if (dx === 0) return;
        var conWidth = sliderBox.node().clientWidth; //parseFloat(container.style("width"));
        var newLeft = parseInt(slider.style("left"));
        var newWidth = parseFloat(slider.style("width")) + dx;
        newWidth = Math.max(newWidth, minWidth);
        newWidth = Math.min(newWidth, conWidth - newLeft);
        slider.style("width", newWidth + "px");
        updateRangeFromUI();
      });

    var dragResizeW = d3.drag()
      .on("start", function () {
        this.startX = d3.mouse(this)[0];
        d3.event.sourceEvent.stopPropagation();
      })
      .on("end", function () {
        touchEndListeners.forEach(function (callback) {
          callback({
            begin: sliderRange.begin,
            end: sliderRange.end
          });
        });
      })
      .on("drag", function () {
        var dx = d3.mouse(this)[0] - this.startX;
        if (dx === 0) return;
        var newLeft = parseFloat(slider.style("left")) + dx;
        var newWidth = parseFloat(slider.style("width")) - dx;

        if (newLeft < 0) {
          newWidth += newLeft;
          newLeft = 0;
        }
        if (newWidth < minWidth) {
          newLeft -= minWidth - newWidth;
          newWidth = minWidth;
        }

        slider.style("left", newLeft + "px");
        slider.style("width", newWidth + "px");

        updateRangeFromUI();
      });

    var dragMove = d3.drag()
      .on("start", function () {
        d3.event.sourceEvent.stopPropagation();
      })
      .on("end", function () {
        touchEndListeners.forEach(function (callback) {
          callback({
            begin: sliderRange.begin,
            end: sliderRange.end
          });
        });
      })
      .on("drag", function () {
        var dx = d3.event.dx;
        var conWidth = sliderBox.node().clientWidth; //parseInt(container.style("width"));
        var newLeft = parseInt(slider.style("left")) + dx;
        var newWidth = parseInt(slider.style("width"));

        newLeft = Math.max(newLeft, 0);
        newLeft = Math.min(newLeft, conWidth - newWidth);
        slider.style("left", newLeft + "px");

        updateRangeFromUI();
      });

    handleE.call(dragResizeE);
    handleW.call(dragResizeW);
    slider.call(dragMove);

    //Click on bar
    sliderBox.on("mousedown", function (ev) {
      var x = d3.mouse(sliderBox.node())[0];
      var props = {};
      var sliderWidth = parseFloat(slider.style("width"));
      var conWidth = sliderBox.node().clientWidth; //parseFloat(container.style("width"));
      props.left = Math.min(conWidth - sliderWidth, Math.max(x - sliderWidth / 2, 0));
      props.left = Math.round(props.left);
      props.width = Math.round(props.width);
      slider.style("left", props.left + "px")
        .style("width", props.width + "px");
      updateRangeFromUI();
    });

    //Reposition slider on window resize
    window.addEventListener("resize", function () {
      updateUIFromRange();
    });

    function onChange(callback) {
      changeListeners.push(callback);
      return this;
    }

    function onTouchEnd(callback) {
      touchEndListeners.push(callback);
      return this;
    }

    function setRange(b, e) {
      sliderRange.begin = b;
      sliderRange.end = e;

      updateUIFromRange();

      //Fire change listeners
      changeListeners.forEach(function (callback) {
        callback({
          begin: sliderRange.begin,
          end: sliderRange.end
        });
      });
    }


    /**
     * Returns or sets the range depending on arguments.
     * If `b` and `e` are both numbers then the range is set to span from `b` to `e`.
     * If `b` is a number and `e` is undefined the beginning of the slider is moved to `b`.
     * If both `b` and `e` are undefined the currently set range is returned as an object with `begin` and `end`
     * attributes.
     * If any arguments cause the range to be outside of the `rangeMin` and `rangeMax` specified on slider creation
     * then a warning is printed and the range correspondingly clamped.
     * @param b beginning of range
     * @param e end of range
     * @returns {{begin: number, end: number}}
     */
    function range(b, e) {
      var rLower;
      var rUpper;

      if (typeof b === "number" && typeof e === "number") {

        rLower = Math.min(b, e);
        rUpper = Math.max(b, e);

        //Check that lower and upper range are within their bounds
        if (rLower < rangeMin || rUpper > rangeMax) {
          console.log("Warning: trying to set range (" + rLower + "," + rUpper + ") which is outside of bounds (" + rangeMin + "," + rangeMax + "). ");
          rLower = Math.max(rLower, rangeMin);
          rUpper = Math.min(rUpper, rangeMax);
        }

        //Set the range
        setRange(rLower, rUpper);
      } else if (typeof b === "number") {

        rLower = b;
        var dif = sliderRange.end - sliderRange.begin;
        rUpper = rLower + dif;

        if (rLower < rangeMin) {
          console.log("Warning: trying to set range (" + rLower + "," + rUpper + ") which is outside of bounds (" + rangeMin + "," + rangeMax + "). ");
          rLower = rangeMin;
        }
        if (rUpper > rangeMax) {
          console.log("Warning: trying to set range (" + rLower + "," + rUpper + ") which is outside of bounds (" + rangeMin + "," + rangeMax + "). ");
          rLower = rangeMax - dif;
          rUpper = rangeMax;
        }

        setRange(rLower, rUpper);
      }

      return {
        begin: sliderRange.begin,
        end: sliderRange.end
      };
    }


    setRange(sliderRange.begin, sliderRange.end);

    return {
      range: range,
      onChange: onChange,
      onTouchEnd: onTouchEnd,
      updateUIFromRange: updateUIFromRange
    };
  }

  render() {
    return (
      <div className="radialbar-to-bar-wrapper" ref={viz => (this.viz = viz)}>
        <div className="trends">Trends</div>
        <div className="go-back d3-hidden">Go Back</div>
        <svg>
          <g className="container">
            <g className="radialbar-container">
              <g className="items-container">
                <circle className="outer-circle" cx="0" cy="0" r="0"></circle>
                <circle className="inner-circle" cx="0" cy="0" r="0"></circle>
                <g className="categories"></g>
                <g className="groups"></g>
              </g>
              <g className="others-container">
                <g className="levels"></g>
                <g className="sectors"></g>
              </g>
              <g className="legend-container"></g>
            </g>
            <g className="bar-container d3-hide" transform="translate(0,0)">
              <g className="axis x-axis"></g>
              <g className="axis y-axis"></g>
              <g className="bars"></g>
              <line className="line" x1="0" x2="0" y1="0" y2="0"></line>
              <path className="path"></path>
              <text className="x-title" transform="translate(0,0)" textAnchor="middle">Month</text>
              <text className="y-title" transform="rotate(-90)" y="0" x="0" dy="14px" textAnchor="middle"></text>
              <text className="benchmark-label" transform="translate(0,0)" textAnchor="start"><Trans>trans_33_Benchmark</Trans></text>
              <text className="average-label" transform="translate(0,0)" textAnchor="start"><Trans>trans_33_your_results</Trans></text>
              <text className="no-data" transform="translate(0,0)" textAnchor="start"><Trans>trans_33_no_data_to_display</Trans></text>
            </g>
            <g className="trends-container d3-hide" transform="translate(0,0)">
              <g className="axis x-axis"></g>
              <g className="axis y-axis"></g>
              <g className="paths"></g>
              <g className="points"></g>
              <g className="labels"></g>
              <text className="x-title" transform="translate(0,0)" textAnchor="middle">Month</text>
              <text className="y-title" transform="rotate(-90)" y="0" x="0" dy="14px" textAnchor="middle">Average</text>
              <text className="no-data" transform="translate(0,0)" textAnchor="start">No data to display</text>
            </g>
          </g>
        </svg>
        <div className="range-slider">
          <div className="slider-background"></div>
          <div className="slider-container" style={{ "position": "relative", "height": "0px", "minWidth": "20px" }}>
            <div className="slider" style={{ "left": "0px", "width": "100%" }}>
              <div className="handle WW handle-WW"></div>
              <div className="handle EE handle-EE"></div>
              <div className="handle-label handle-WW-label">0</div>
              <div className="handle-label handle-EE-label">0</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

// RadialBarToBar.defaultProps = {
//   duration: 0
// };

// RadialBarToBar.propTypes = {
//   width: PropTypes.number,
//   height: PropTypes.number,
//   duration: PropTypes.number
// };

export default RadialBarToBar;