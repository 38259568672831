import React from "react";

import { serverService } from "../../../_services/server.service";

import AdminLicensesTable from "./AdminLicensesTable";
import AdminLicensePricesTable from "./AdminLicensePricesTable";
import AdminVouchersTable from "./AdminVouchersTable";


interface MyProps {}

interface MyState {
  licenses: any;
  license_prices: any;
  vouchers: any;
}

class AdminLicensesPage extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
      licenses: [],
      license_prices:[],
      vouchers:[]
    };


    this.onVoucherAdded = this.onVoucherAdded.bind(this);
    this.onVoucherDeleted = this.onVoucherDeleted.bind(this);
  }

  componentDidMount() {
    this.setState({
      licenses: [],
      license_prices:[],
      vouchers:[]
    });


    this.doFetchAllVouchers();
    this.doFetchAllLicensePrices();
    // this.doFetchAllLicenses();
  }

  render() {
    return (
      <div className="container mt-5 p-5">

      <h2>Vouchers</h2>
      <AdminVouchersTable vouchers={this.state.vouchers}  onVoucherAdded={this.onVoucherAdded} onVoucherDeleted={this.onVoucherDeleted} />

        <h2>Prices</h2>
        <AdminLicensePricesTable license_prices={this.state.license_prices} />

        <h2>Licenses - ToDo</h2>
        {/*<AdminLicensesTable licenses={this.state.licenses} />*/}
      </div>
    );
  }


  onVoucherAdded() {
    this.doFetchAllVouchers();
  }

  onVoucherDeleted() {
    this.doFetchAllVouchers();
  }


  doFetchAllVouchers() {
    serverService.getVouchers().then((result) => {
      if(result.status == 1){
        this.setState({ vouchers: result.data });
      }
    });
  }

  doFetchAllLicensePrices() {
    serverService.getLicensePrices().then((result) => {
      if(result.status == 1){
        this.setState({ license_prices: result.data });
      }
    });
  }

  doFetchAllLicenses() {
    serverService.getLogs().then((result) => {
      this.setState({ licenses: result.data });
    });
  }
}

export { AdminLicensesPage };
