/**
 * Created by Alex on 17-Oct-19.
 */
import React from 'react';

import { serverService } from '../../_services/server.service';

import {Typeahead} from 'react-bootstrap-typeahead';

import {toast} from "react-toastify";
import i18n from "i18next";

interface MyProps {
    selectedLocation:string,
    handleLocationChange(locationText:string, isANewLocation:boolean):any,
}

interface MyState {
    locations:any,
    selectedLocation:string,
}

class LocationsAct extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            locations: [],
            selectedLocation:'',
        };

        this.handleOnInputChange = this.handleOnInputChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.doesLocationAlreadyExists = this.doesLocationAlreadyExists.bind(this);
        this.fetchLocations = this.fetchLocations.bind(this);

    }

    componentDidMount(){
        this.fetchLocations();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selectedLocation !== prevState.selectedLocation) {
            this.setState({selectedLocation: this.props.selectedLocation});
        }
    }

    render() {
        let selectedLocation = this.state.selectedLocation;
        if(selectedLocation == null){
            selectedLocation = "";
        }
        return (
            <Typeahead
                allowNew
                bsSize="small"
                selected={[{'Name':selectedLocation}]}
                id="locationsAct"
                options={this.state.locations}
                labelKey="Name"
                onInputChange={this.handleOnInputChange}
                onChange={this.handleOnChange}
                placeholder={i18n.t("trans_33_locations_acp_search_for_location")}
                newSelectionPrefix={i18n.t("trans_33_locations_acp_add_location")}
            />
        );
    }

    handleOnInputChange(locationString:string) {
        this.props.handleLocationChange(locationString,this.doesLocationAlreadyExists(locationString));
    }

    handleOnChange(locationsObject:any) {
        if(locationsObject != undefined && locationsObject[0] != undefined && locationsObject[0].Name != undefined ){
            this.props.handleLocationChange(locationsObject[0].Name,this.doesLocationAlreadyExists(locationsObject[0].Name));
        }
    }

    fetchLocations() {
        let userStr = localStorage.getItem('user');
        let IdCompany = 0;
        if(userStr) {
            let userObj = JSON.parse(userStr);
            IdCompany = userObj.IdCompany;
        }

        serverService.getLocationsForCompany(IdCompany)
            .then(
                result => {
                    if(result.status === "1"){
                        this.setState({
                            locations:result.data,
                        });
                    }
                },
                error => {
                    toast.warn("Did not get locations");
                }
            );
    }

    doesLocationAlreadyExists(locationText:string){
        const locations = this.state.locations;
        if(locations){
            for(let i=0;i<locations.length;i++){
                if(locations[i].Name.toLowerCase() == locationText.toLowerCase()){
                    return true;
                }
            }
        }
        return false;
    }
}




export default LocationsAct;