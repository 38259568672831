/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";
import {Link} from "react-router-dom";

import './ModalCopyLink.css'
import SelectDaysAutocomplete from "./SelectDaysAutocomplete";
import {Trans} from "react-i18next";
import i18n from "i18next";

interface MyProps {
    show:boolean,
    onHide(): any,
    selectedLink:string,
    title: string,
}


class ModalCopyLink extends React.Component<MyProps> {
    private textArea:any;

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                size="lg"
                centered
            >
                <Modal.Header className="add-vox-modal-header" translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-5 add-vox-modal-content">
                    <div className='copy-link-body-wrapper'>
                      <i className="fas fa-link mx-2"></i>
                      <textarea
                        rows={1}
                        ref={(textarea) => this.textArea = textarea}
                        id="copyLinkTextarea"
                        value={this.props.selectedLink}
                        onChange={this.onChange}
                      />
                      <Button className='copy-link-btn' variant="primary" onClick={this.copyToClipboard}>
                          <Trans>trans_33_modal_copy_link_copy_btn</Trans>
                      </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    onChange(){}


    copyToClipboard = (e) => {
        this.textArea.select();
        document.execCommand('copy');
        e.target.focus();

        toast.success(i18n.t("trans_33_modal_copy_link_msg_success"))
    };

}

export default ModalCopyLink;