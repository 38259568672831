import React from 'react';

import { serverService } from '../../_services/server.service';
import { toast } from 'react-toastify';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';

import './RegisterPage.css';

import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';

import { config } from '../../_helpers/C';

import { Trans } from 'react-i18next';
import i18n from 'i18next';
import TextField from '@material-ui/core/TextField';

import LicenseSelect from '../_other/LicenseSelect';

import sha1 from 'sha1' 
const qs = require('query-string');

const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);

const CARD_ELEMENT_OPTIONS = {
  style: {
    padding: '10px 12px',
    iconStyle: 'solid',
    hidePostalCode: true,
    base: {
      iconColor: 'rgb(240, 57, 122)',
      color: '#0a1727',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#0a1727',
      },
    },
    invalid: {
      color: '#94180f',
      iconColor: '#94180f',
    },
  },
};

interface MyPropsStripe {
  stripe: any;
  elements: any;
  onCardChange(card: any): any;
}

interface MyStateStripe {}

class StripeCardComponent extends React.Component<
  MyPropsStripe,
  MyStateStripe
> {
  onChange = async (event) => {
    const { stripe, elements } = this.props;

    let card = elements.getElement(CardElement);
    this.props.onCardChange(card);
  };

  render() {
    return (
      <div>
        <CardElement options={CARD_ELEMENT_OPTIONS} onChange={this.onChange} />
      </div>
    );
  }
}

interface MyProps {
  stripe: any;
  elements: any;
  handleResult: any;
}

interface MyState {
  submitted: boolean;
  loading: boolean;
  error: string;
  formFields: any;

  licenseType: number;
  virtualVoxNum: number;
  physicalVoxNum: number;
}

class RegisterPage extends React.Component<
  MyProps & RouteComponentProps,
  MyState
> {
  constructor(props) {
    super(props);

    let license = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).license;
    let virtualVoxNum = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).virtualVoxNum;
    let physicalVoxNum = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).physicalVoxNum;

    if (!license) {
      license = 'self-starter';
    }

    if (!virtualVoxNum) {
      virtualVoxNum = 1;
    }

    if (!physicalVoxNum) {
      physicalVoxNum = 0;
    }

    let licenseType = 0;
    if (license == 'self-starter') {
      licenseType = 1;
    } else if (license == 'standard') {
      licenseType = 2;
    } else if (license == 'enterprise') {
      licenseType = 3;
    }

    this.state = {
      submitted: false,
      loading: false,
      error: '',
      formFields: { Username: '', Password: '', Email: '' },

      licenseType: licenseType,
      virtualVoxNum: virtualVoxNum,
      physicalVoxNum: physicalVoxNum,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.onCardChange = this.onCardChange.bind(this);

    this.onLicenseSelected = this.onLicenseSelected.bind(this);
  }

  handleSubmit(evt) {
    evt.preventDefault();

    let formFields = this.state.formFields;
    let card = formFields.card;

    const { licenseType, virtualVoxNum, physicalVoxNum } = this.state;

    const acceptTosPolicy = formFields.acceptTosPolicy;

    let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (
      formFields.Password.length < 8 ||
      formFields.Password.toLowerCase() == formFields.Password ||
      !format.test(formFields.Password)
    ) {
      toast.error(i18n.t('trans_33_msg_password_not_valid'));
      return;
    }

    if (card == undefined || card == null) {
      toast.error(i18n.t('trans_33_msg_card_info_not_correct'));
      return;
    }

    if (!acceptTosPolicy) {
      toast.error(i18n.t('trans_33_msg_accept_policies'));
      return;
    }

    if (licenseType < 0 || virtualVoxNum < 0 || physicalVoxNum < 0) {
      toast.error(i18n.t('trans_33_msg_warning_pricing_variables_not_defined'));
      return;
    }

    formFields['EncPassword'] = sha1(formFields.Password);

    this.setState({ loading: true });

    stripePromise
      .then((stripe) => {
        return stripe.createPaymentMethod({
          type: 'card',
          card: card,
        });
      })
      .then((result) => {
        if (
          result.paymentMethod === undefined ||
          result.paymentMethod.id.length == 0
        ) {
          toast.error(i18n.t('trans_33_msg_error_card_failed'));
          throw 'Card failed';
        }

        formFields.PaymentMethodId = result.paymentMethod.id;

        formFields.licenseType = licenseType;
        formFields.virtualVoxNum = virtualVoxNum;
        formFields.physicalVoxNum = physicalVoxNum;

        let newFormFields = Object.assign({}, formFields);
        delete newFormFields.card;

        return serverService.registerUser(newFormFields);
      })
      .then(
        (response) => {
          if (response != undefined) {
            if (response.status == 1 || response.status == '1') {
              this.setState({
                submitted: false,
                loading: false,
                formFields: { Username: '', Password: '', Email: '' },
              });

              if (response.data == undefined || response.data.length == 0) {
                this.setState({ error: i18n.t('trans_33_msg_server_error') });
              } else {
                toast.success(i18n.t('trans_33_msg_registration_completed'));

                let user = response.data[0];
                localStorage.setItem('user', JSON.stringify(user));

                setTimeout(function () {
                  window.location.href = '/home';
                }, 3000);
              }
            } else {
              toast.warn(response.message);
              this.setState({ submitted: false, loading: false });
            }
          }
        },
        (error) => {
          this.setState({ submitted: false, loading: false });
          console.log(error);
        }
      );
  }

  handleChange(e) {
    const { name, value } = e.target;
    let formFields = this.state.formFields;
    formFields[name] = value;
    this.setState({ formFields: formFields });
  }

  render() {
    const { formFields, submitted, loading, error } = this.state;

    const Password = formFields.Password;
    const Email = formFields.Email;
    const acceptTosPolicy = formFields.acceptTosPolicy;

    return (
      <>
        <div className='split left'>
          <div className='centered'>
            <img src='assets/images/logo.png' alt='Avatar woman' />
            <br />
            <br />
            <br />
            <br />
            <h2>
              <Trans>trans_33_register_page_title</Trans>
            </h2>
            <p>
              <Trans>trans_33_register_page_description</Trans>{' '}
            </p>
          </div>
        </div>

        <div className='split right'>
          <div className='container centered'>
            <div
              className='col-md-8 col-md-offset-3 text-center mx-auto'
              id='loginContainer'
            >
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <img
                src='assets/images/logo.png'
                className='logo-right'
                alt='Avatar woman'
              />

              <h2 className='register-text mt-4'>
                <Trans>trans_33_start_your_subscription</Trans>
              </h2>
              <br />
              <div
                className={
                  'form-group' + (submitted && !Email ? ' has-error' : '')
                }
              >
                <TextField
                  variant='outlined'
                  onChange={this.handleChange}
                  className='form-field card_fieldset'
                  label={i18n.t('trans_33_enter_your_email')}
                  type='email'
                  name='Email'
                  value={Email}
                  placeholder={i18n.t('trans_33_enter_your_email')}
                  InputProps={{
                    style: { color: '#000' },
                  }}
                />
                {submitted && !Email && (
                  <div className='help-block'>
                    <Trans>trans_33_email_is_mandatory</Trans>
                  </div>
                )}
              </div>

              <div
                className={
                  'form-group' + (submitted && !Password ? ' has-error' : '')
                }
              >
                <TextField
                  variant='outlined'
                  onChange={this.handleChange}
                  className='form-field card_fieldset'
                  label={i18n.t('trans_33_password')}
                  name='Password'
                  value={Password}
                  placeholder={i18n.t('trans_33_password')}
                  type='password'
                  InputProps={{
                    style: { color: '#000' },
                  }}
                />

                <div className='help-block' style={{ color: '#EEE' }}>
                  <Trans>trans_33_password_requirements</Trans>
                </div>

                {submitted && !Password && (
                  <div className='help-block'>
                    <Trans>trans_33_password_is_mandatory</Trans>
                  </div>
                )}
              </div>

              <div className='form-field text-center mx-auto'>
                <div className='card_fieldset' style={{ marginBottom: '8px' }}>
                  <Elements stripe={stripePromise}>
                    <ElementsConsumer>
                      {({ elements, stripe }) => (
                        <StripeCardComponent
                          elements={elements}
                          stripe={stripe}
                          onCardChange={this.onCardChange}
                        />
                      )}
                    </ElementsConsumer>
                  </Elements>
                </div>
                <img
                  src='/assets/images/powered_by_stripe.png'
                  width={120}
                  style={{ float: 'right' }}
                />
              </div>

              <br />
              <br />

              <div className='help-block' style={{ color: '#EEE' }}>
                <Trans>trans_33_card_will_not_be_charged_before_start</Trans>
              </div>

              <br />

              <div className='col-md-12 text-center mt-2'>
                <LicenseSelect
                  theme='light'
                  licenseType={this.state.licenseType}
                  virtualVoxNum={this.state.virtualVoxNum}
                  physicalVoxNum={this.state.physicalVoxNum}
                  onLicenseSelected={this.onLicenseSelected}
                />
              </div>



              <div className='col-md-12 mt-2 terms-center'>
                <div className='custom-control custom-checkbox'>
                  <input
                      checked={acceptTosPolicy}
                      className='custom-control-input'
                      type='checkbox'
                      id='acceptTosPolicy'
                      name='acceptTosPolicy'
                      onChange={this.handleCheckboxChange}
                  />

                  <br />

                  <label
                      className='custom-control-label terms-right'
                      htmlFor='acceptTosPolicy'
                      style={{ color: '#EEE' }}
                  >
                    <Trans>trans_33_register_authorize</Trans>
                    &nbsp;
                    <a href='https://www.voxcollector.com/pdf/TOS.pdf'>
                      <Trans>trans_33_register_authorize_tos</Trans>
                    </a>
                    &nbsp;and&nbsp;
                    <a href='https://www.voxcollector.com/pdf/privacy.pdf'>
                      <Trans>trans_33_register_authorize_privacy_policy</Trans>
                    </a>
                  </label>
                </div>
              </div>


              <div className='form-group'>
                <form name='form' onSubmit={this.handleSubmit}>
                  <button
                    className='landing-button  mt-4'
                    style={{ backgroundColor: '#0a1727', color: '#FFFFFF' }}
                    disabled={loading}
                  >
                    <Trans>trans_33_register</Trans>
                  </button>
                  {loading && (
                    <img
                      alt='loading'
                      src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
                    />
                  )}
                </form>
              </div>

              {error && <div className={'alert alert-danger'}>{error}</div>}
            </div>
          </div>
        </div>
      </>
    );
  }

  onLicenseSelected(licenseType, virtualVoxNum, physicalVoxNum) {
    this.setState({
      licenseType: licenseType,
      virtualVoxNum: virtualVoxNum,
      physicalVoxNum: physicalVoxNum,
    });
  }

  onCardChange(card) {
    let formFields = this.state.formFields;
    formFields['card'] = card;
    this.setState({ formFields: formFields });
  }

  handleCheckboxChange(e) {
    const { name, checked } = e.target;
    let formFields = this.state.formFields;
    formFields[name] = checked;
    this.setState({ formFields: formFields });
  }

  handleResult() {
    toast('Handle result');
  }
}

function validateEmail(email) {
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export { RegisterPage };
