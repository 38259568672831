import React from 'react';
import { Container,Row,Col } from "react-bootstrap";
import { Player } from 'video-react';

import { serverService } from '../../_services/server.service';


import "../../../node_modules/video-react/dist/video-react.css";
import {Trans} from "react-i18next";

interface MyProps {
    videoResources:any,
    rowsVideo: number,
}

interface MyState {
    loading:boolean,
     videoPage:number,
}

class VideosGrid extends React.Component<MyProps,MyState> {
    constructor(props) {
        super(props);

        this.state = {
            loading:false,
            videoPage:0,
        };

        this.videoPrevClick = this.videoPrevClick.bind(this);
        this.videoNextClick = this.videoNextClick.bind(this);

    }

    componentDidMount() {
        this.setState({
        });

    }

    render() {

        const { videoResources, rowsVideo } =  this.props;
        const { videoPage } =  this.state;

        let videoItems = [];
        for (let i = 0; i < rowsVideo; i++) {
            let colItems = [];
            for (let j = 0; j < 3; j++) {
                let resourceIndex = videoPage*(rowsVideo*3) + i*3 + j;
                //console.log("Vp: " + videoPage + " i: " + i + " j: " + j + " rIndex: " +  resourceIndex);
                let oneResource = videoResources[resourceIndex];
                if(oneResource === undefined){
                    break;
                }
                colItems.push(
                    <Col xs={4} className="top-buffer">
                        <Player
                            playsInline
                            startTime="3"
                            src={oneResource.Link}
                        />
                    </Col>
                );
            }

            videoItems.push(<Row key={i}>
                {
                    colItems
                }
            </Row>);
        }


        return (
            <div>
                <Container>
                    {videoItems}
                    <div className="video-pagination">
                        {videoPage > 0 &&
                            <span className="video-btn-previous" onClick={this.videoPrevClick}>
                                <b>
                                    <Trans>
                                        trans_33_previous
                                    </Trans>
                                </b>
                            </span>
                        }

                        {videoPage < videoResources.length/(rowsVideo*3) - 1 &&
                            <span className="video-btn-next" onClick={this.videoNextClick}>&nbsp;&nbsp;&nbsp;
                                <b>
                                    <Trans>
                                        trans_33_next
                                    </Trans>
                                </b>
                            </span>
                        }

                    </div>
                </Container>
            </div>
        );
    }

    videoPrevClick(){
        let videoPage = this.state.videoPage;
        this.setState({videoPage:videoPage-1});
    }

    videoNextClick(){
        let videoPage = this.state.videoPage;
        this.setState({videoPage:videoPage+1});
    }
}

export default VideosGrid;
