import React from "react";

import { serverService } from "../../../_services/server.service";

import VoxSelect from "../../_other/VoxSelect";
import LocationSelect from "../../_other/LocationSelect";
import ModalAddNewSchedule from "./ModalAddNewSchedule";
import ModalConfirmAction from "../../_other/ModalConfirmAction";
import PubSub from "../../../_helpers/PubSub.js";

import { toast } from "react-toastify";
import {
  Button,
  Spinner,
  Accordion,
  Card,
  Form,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";

import "./CampaignAgendaPage.css";

import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";

import BootstrapSwitchButton from "bootstrap-switch-button-react";
import ModalOnboardingScheduleGiftPack from "../../_onboarding/ModalOnboardingScheduleGiftPack";
import ModalImportSchedules from "./ModalImportSchedules";
import {Trans} from "react-i18next";
import i18n from "i18next";
import ModalSendMessage from "./ModalSendMessage";

const moment = require("moment");

interface MyProps {
  whenQuestionPackWasLastScheduled;
}

interface MyState {
  company: any;
  selectedDay: any;
  selectedMonth: any;
  selectedIdVox: number;
  selectedIdLocation: number;
  searchedText: string;
  isAutoPilotOn: boolean;

  weekDisplayModel: any;
  weekDisplaySelectedDayToggle: boolean;

  loading: boolean;
  showVoxAndLocationFilters: boolean;

  modalAddNewScheduleShow: boolean;
  selectedDayAddButton: string;

  selectedDaysModifiers: any;

  schedulesDataFormattedObject: any;
  schedulesDataFormattedObjectFromSearch: any;
  weeklyViewColorCodes: any;
  currentSelectedToggleDate: string;

  copyDate: string;
  isInCopyState: boolean;
  copyIdVox: number;
  copyIdLocation: number;
  pasteDate: string;
  modalConfirmPasteShow: boolean;

  deleteDate: string;
  modalConfirmDeleteShow: boolean;

  modalOnboardingScheduleGiftPack: boolean;
  modalImportShow: boolean;
  modalSendMessageShow: boolean;
}

const popoverCopy = (
  <Popover id="popover-copy">
    <Popover.Title as="h3">
      <Trans>
        trans_33_info_copy_title
      </Trans>
    </Popover.Title>
    <Popover.Content>
      <Trans>
        trans_33_info_copy_description
      </Trans>
    </Popover.Content>
  </Popover>
);

const popoverPaste = (
  <Popover id="popover-paste">
    <Popover.Title as="h3">
      <Trans>
        trans_33_info_paste_title
      </Trans>
    </Popover.Title>
    <Popover.Content>
      <Trans>
        trans_33_info_paste_description
      </Trans>
    </Popover.Content>
  </Popover>
);

const popoverAutoPilot = (
  <Popover id="popover-auto-pilot">
    <Popover.Title as="h3">
      <Trans>
        trans_33_info_auto_pilot_title
      </Trans>
    </Popover.Title>
    <Popover.Content>
      <Trans>
        trans_33_info_auto_pilot_description
      </Trans>
    </Popover.Content>
  </Popover>
);

class CampaignAgendaPage extends React.Component<MyProps, MyState> {
  public searchTimeout;

  constructor(props) {
    super(props);

    this.searchTimeout = 0;

    this.state = {
      company: {},
      selectedDay: undefined,
      selectedMonth: undefined,
      selectedIdVox: 0,
      selectedIdLocation: 0,
      searchedText: "",
      isAutoPilotOn: false,

      weekDisplayModel: [],
      weekDisplaySelectedDayToggle: true,

      loading: false,
      showVoxAndLocationFilters: false,

      modalAddNewScheduleShow: false,
      selectedDayAddButton: "",

      selectedDaysModifiers: {},
      schedulesDataFormattedObject: [],
      schedulesDataFormattedObjectFromSearch: [],
      weeklyViewColorCodes: 0,
      currentSelectedToggleDate: "",

      copyDate: "",
      isInCopyState: false,
      copyIdVox: 0,
      copyIdLocation: 0,
      pasteDate: "",
      modalConfirmPasteShow: false,

      deleteDate: "",
      modalConfirmDeleteShow: false,

      modalOnboardingScheduleGiftPack: false,
      modalImportShow: false,

      modalSendMessageShow:false,
    };

    this.onChangeAutoPilot = this.onChangeAutoPilot.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
    this.onVoxSelected = this.onVoxSelected.bind(this);
    this.onLocationSelected = this.onLocationSelected.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

    this.getNumberOfVoxesFetched = this.getNumberOfVoxesFetched.bind(this);

    this.onScheduleAdded = this.onScheduleAdded.bind(this);

    this.doPaste = this.doPaste.bind(this);
    this.cancelInCopyState = this.cancelInCopyState.bind(this);
    this.doDelete = this.doDelete.bind(this);

    this.onQuestionsImported = this.onQuestionsImported.bind(this);
    this.markOnboadringAsFinished = this.markOnboadringAsFinished.bind(this);

    this.navigateToRequestQuestionsPage = this.navigateToRequestQuestionsPage.bind(this);


  }

  componentDidMount() {
    this.doFetchCompany().then((result1) => {
      this.fetchDataForSelectedMonth().then((result2) => {
        this.showWeeklyViewPanelBySelectedDay(moment());
      });
    });


    PubSub.subscribe("fetchDataForSelectedMonth", () => {
      this.fetchDataForSelectedMonth();
    });
  }

  componentWillUnmount() {
    PubSub.unsubscribe("fetchDataForSelectedMonth");
  }



  componentDidUpdate(nextProps) {
    const { whenQuestionPackWasLastScheduled } = this.props;
    if (
      nextProps.whenQuestionPackWasLastScheduled !==
      whenQuestionPackWasLastScheduled
    ) {
      this.fetchDataForSelectedMonth();
    }
  }

  render() {
    const {
      company,
      weekDisplayModel,
      loading,
      selectedIdLocation,
      selectedIdVox,
      searchedText,
      showVoxAndLocationFilters,
      selectedDaysModifiers,
      selectedDay,
      weekDisplaySelectedDayToggle,
      schedulesDataFormattedObject,
      schedulesDataFormattedObjectFromSearch,
      weeklyViewColorCodes,
      isAutoPilotOn,
      copyDate,
      isInCopyState,
      currentSelectedToggleDate,
    } = this.state;

    let disabledDaysOfWeek = [];
    let companySelectedDays = company.SelectedDays;
    if (companySelectedDays !== undefined) {
      if (!companySelectedDays.includes("Monday")) {
        disabledDaysOfWeek.push(1);
      }
      if (!companySelectedDays.includes("Tuesday")) {
        disabledDaysOfWeek.push(2);
      }
      if (!companySelectedDays.includes("Wednesday")) {
        disabledDaysOfWeek.push(3);
      }
      if (!companySelectedDays.includes("Thursday")) {
        disabledDaysOfWeek.push(4);
      }
      if (!companySelectedDays.includes("Friday")) {
        disabledDaysOfWeek.push(5);
      }
      if (!companySelectedDays.includes("Saturday")) {
        disabledDaysOfWeek.push(6);
      }
      if (!companySelectedDays.includes("Sunday")) {
        disabledDaysOfWeek.push(0);
      }
    }

    let modalAddNewScheduleOpen = (date: string) =>
      this.setState({
        modalAddNewScheduleShow: true,
        selectedDayAddButton: date,
      });
    let closeAddNewScheduleModal = () =>
      this.setState({ modalAddNewScheduleShow: false });

    let modalConfirmPasteOpen = (pasteDate: string) =>
      this.setState({ modalConfirmPasteShow: true });
    let modalConfirmPasteClose = () =>
      this.setState({ modalConfirmPasteShow: false });

    let modalConfirmDeleteOpen = (selectedDate: string) =>
      this.setState({ modalConfirmDeleteShow: true });
    let modalConfirmDeleteClose = () =>
      this.setState({ modalConfirmDeleteShow: false });

    let modalOnboardingScheduleGiftPackClose = () =>{
      this.setState({ modalOnboardingScheduleGiftPack: false });
      this.markOnboadringAsFinished();
    };

    let modalOnboardingScheduleGiftPackClick = () => {
      this.setState({ modalOnboardingScheduleGiftPack: false });
      this.fetchDataForSelectedMonth();
    };

    let modalImportOpen = (itemToEdit) =>
      this.setState({ modalImportShow: true });
    let closeImportModal = () => this.setState({ modalImportShow: false });


    let modalSendMessageOpen = (itemToEdit) => this.setState({ modalSendMessageShow: true });
    let closeSendMessageModal = () => this.setState({ modalSendMessageShow: false });




    const activeKeyForWeekview = moment(selectedDay).format("YYYY-MM-DD");

    let listItems = "";
    if (weekDisplayModel && weekDisplayModel.length > 0) {
      listItems = weekDisplayModel.map((oneDay) => {
        let dayShortNameFormat;
        let dayNumberFormat;
        if (weekDisplaySelectedDayToggle) {
          dayShortNameFormat = moment(oneDay.date).format("dd");
          dayNumberFormat = moment(oneDay.date).format("Do");
        } else {
          dayShortNameFormat = moment(oneDay.date).format("dddd");
          dayNumberFormat = moment(oneDay.date).format("DD/MM/YY");
        }

        let questionsItems = "";
        let tempSchedulesDataObject;
        if (weekDisplaySelectedDayToggle) {
          tempSchedulesDataObject = schedulesDataFormattedObject[oneDay.date];
        } else {
          tempSchedulesDataObject =
            schedulesDataFormattedObjectFromSearch[oneDay.date];
        }

        let firstQuestion = i18n.t("trans_33_no_questions");
        let eventKeyToggle = "do_not_expand_toggle_" + oneDay.date;
        let eventKeyCollapse = "do_not_expand_collapse_" + oneDay.date;
        if (
          tempSchedulesDataObject !== undefined &&
          tempSchedulesDataObject.length > 0
        ) {
          firstQuestion = tempSchedulesDataObject[0].QuestionText;
          eventKeyToggle = oneDay.date;
          eventKeyCollapse = oneDay.date;

          tempSchedulesDataObject.forEach((oneQuestionObject) => {
            questionsItems +=
              "<b>" +
              oneQuestionObject.VoxName +
              "</b>" +
              " - " +
              oneQuestionObject.QuestionText +
              (oneQuestionObject.Type === 1 ? " - <i>" + i18n.t('trans_33_auto_pilot') + "</i>" : "") +
              "<br/>";
          });
        }

        let colorClass = "colorLightGrey";
        if (weekDisplaySelectedDayToggle) {
          if (currentSelectedToggleDate === oneDay.date) {
            colorClass = "colorBlue";
          } else {
            if (weeklyViewColorCodes[oneDay.date] !== undefined) {
              colorClass = weeklyViewColorCodes[oneDay.date].color;
            }
          }
        }

        return (
          <Card key={oneDay.date}>
            <Accordion.Toggle
              onClick={(e) => {
                e.stopPropagation();
                let selectedDayDate = moment(oneDay.date).toDate();
                this.setState({
                  currentSelectedToggleDate: oneDay.date,
                  selectedDay: selectedDayDate,
                });
              }}
              className={colorClass}
              as={Card.Header}
              eventKey={eventKeyToggle}
              key={"toggle_" + eventKeyToggle}
            >
              <div className="row">
                <div className="col-md3 px-2 text-center">
                  {dayShortNameFormat}
                  <br />
                  <b>{dayNumberFormat}</b>
                </div>
                <div className="col-md6 my-auto">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {firstQuestion}
                </div>
                <div className="col-md3 my-auto ml-auto">
                  {tempSchedulesDataObject &&
                    tempSchedulesDataObject.length > 0 && (
                      <OverlayTrigger placement="right" overlay={popoverCopy}>
                        <img
                          id="copyBtn"
                          alt="Copy"
                          src="/assets/images/icon_copy32.png"
                          onClick={(e) => {
                            e.stopPropagation();
                            toast.success(
                              i18n.t("trans_33_campaign_agenda_copy_msg_success") + oneDay.date
                            );
                            this.setState({
                              isInCopyState: true,
                              copyDate: oneDay.date,
                              copyIdVox: selectedIdVox,
                              copyIdLocation: selectedIdLocation,
                            });
                          }}
                        />
                      </OverlayTrigger>
                    )}

                  {moment().isSameOrBefore(oneDay.date, "day") && (
                    <span>
                      {copyDate.length > 0 && (
                        <OverlayTrigger
                          placement="right"
                          overlay={popoverPaste}
                        >
                          <img
                            id="pasteBtn"
                            alt="Paste"
                            src="/assets/images/icon_paste32.png"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.setState(
                                { pasteDate: oneDay.date },
                                function () {
                                  if (
                                    tempSchedulesDataObject &&
                                    tempSchedulesDataObject.length > 0
                                  ) {
                                    modalConfirmPasteOpen(oneDay.date);
                                  } else {
                                    this.doPaste();
                                  }
                                }
                              );
                            }}
                          />
                        </OverlayTrigger>
                      )}
                      {moment().isBefore(oneDay.date, "day") &&
                        tempSchedulesDataObject &&
                        tempSchedulesDataObject.length > 0 && (
                          <img
                            id="deleteBtn"
                            alt="Delete scheduled questions"
                            src="/assets/images/icon_delete32.png"
                            onClick={(e) => {
                              this.setState(
                                { deleteDate: oneDay.date },
                                function () {
                                  modalConfirmDeleteOpen(oneDay.date);
                                }
                              );
                              e.stopPropagation();
                            }}
                          />
                        )}
                      &nbsp;
                      <Button
                        id="addBtn"
                        variant="light"
                        onClick={(e) => {
                          e.stopPropagation();
                          modalAddNewScheduleOpen(oneDay.date);
                        }}
                      >
                        <Trans>trans_33_add</Trans>
                      </Button>
                    </span>
                  )}
                </div>
              </div>
            </Accordion.Toggle>

            <Accordion.Collapse
              eventKey={eventKeyCollapse}
              key={"collapse_" + eventKeyCollapse}
            >
              <Card.Body>
                <div dangerouslySetInnerHTML={{ __html: questionsItems }} />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        );
      });
    }

    return (
      <div>
        <div className="row">
          <div className="ml-auto col-md-6" style={{ textAlign: "right" }}>
            <Button id="sendMessagetBtn" variant="primary" onClick={modalSendMessageOpen}>
              <Trans>trans_33_send_message</Trans>
            </Button>
            &nbsp; &nbsp;
            <Button id="requestQuestionsBtn" variant="primary" onClick={this.navigateToRequestQuestionsPage}>
              <Trans>trans_33_request_questions</Trans>
            </Button>
            &nbsp; &nbsp;
            <Button id="importBtn" variant="primary" onClick={modalImportOpen}>
              <Trans>trans_33_import_questions_from_excel</Trans>
            </Button>
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-3">
            {showVoxAndLocationFilters && (
              <Form.Group controlId="formLocation">
                <Form.Label>
                  <Trans>
                    trans_33_select_location
                  </Trans>
                </Form.Label>
                <LocationSelect onLocationSelected={this.onLocationSelected} />
              </Form.Group>
            )}
          </div>
          <div className="col-md-3">
            {showVoxAndLocationFilters && (
              <Form.Group controlId="formVox">
                <Form.Label>
                  <Trans>trans_33_select_vox</Trans>
                </Form.Label>
                <VoxSelect
                  getNumberOfVoxesFetched={this.getNumberOfVoxesFetched}
                  onVoxSelected={this.onVoxSelected}
                  selectedIdLocation={selectedIdLocation}
                />
              </Form.Group>
            )}
          </div>

          <div className="col-md-2">
            {true && (
              <OverlayTrigger placement="top" overlay={popoverAutoPilot}>
                <span>
                  {isAutoPilotOn && (
                    <div>
                      <Trans>trans_33_info_auto_pilot_title</Trans>
                      &nbsp;
                      <BootstrapSwitchButton
                        checked={true}
                        onlabel={i18n.t("trans_33_ON")}
                        offlabel={i18n.t("trans_33_OFF")}
                        onstyle="success"
                        offstyle="danger"
                        onChange={this.onChangeAutoPilot}
                      />
                    </div>
                  )}

                  {!isAutoPilotOn && (
                    <div>
                      <Trans>trans_33_info_auto_pilot_title</Trans>
                      &nbsp;
                      <BootstrapSwitchButton
                        checked={false}
                        onlabel={i18n.t("trans_33_ON")}
                        offlabel={i18n.t("trans_33_OFF")}
                        onstyle="success"
                        offstyle="danger"
                        onChange={this.onChangeAutoPilot}
                      />
                    </div>
                  )}
                </span>
              </OverlayTrigger>
            )}
          </div>

          <div className="col-md-3 ml-auto">
            <Form.Label></Form.Label>
            <Form.Group controlId="searchEt">
              <Form.Control
                type="search"
                placeholder={i18n.t("trans_33_campaign_agenda_search_existing")}
                className="search"
                onChange={this.handleSearch}
              />
            </Form.Group>
          </div>
          {loading && (
            <div>
              <br />
              <Spinner animation="border" />
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-md-4">
            <DayPicker
              onDayClick={this.handleDayClick}
              onMonthChange={this.onMonthChange}
              selectedDays={this.state.selectedDay}
              modifiers={selectedDaysModifiers}
              firstDayOfWeek={1}
              disabledDays={[
                { before: new Date() },
                { daysOfWeek: disabledDaysOfWeek },
              ]}
            />
            <br />
            <br />
            <div>
              <p className="legend_paragraph">
                <span className="legend_dot1" /> <Trans>trans_33_campaign_agenda_legend1</Trans>
              </p>
              <p className="legend_paragraph">
                <span className="legend_dot2" /> <Trans>trans_33_campaign_agenda_legend2</Trans>
              </p>
              <p className="legend_paragraph">
                <span className="legend_dot3" /> <Trans>trans_33_campaign_agenda_legend3</Trans>
              </p>
            </div>
          </div>
          <div className="col-md-8">
            {!isInCopyState && (
              <Accordion
                defaultActiveKey={activeKeyForWeekview}
                key={activeKeyForWeekview}
              >
                {listItems}
              </Accordion>
            )}

            {isInCopyState && (
              <div id="copyPasteTooltipPanel">
                <div className="row">
                  <div className="col-md-2">
                    <img src="/assets/images/icon_arrow_left64.png" />
                  </div>
                  <div className="col-md-10">
                    <p>
                      <Trans>trans_33_campaign_agenda_pick_a_date</Trans> <br />
                      <img src="/assets/images/icon_paste32.png" /> <Trans>trans_33_campaign_agenda_to_paste_the_question</Trans>
                      <br />
                      <br />
                      <a href="#" onClick={this.cancelInCopyState}>
                        <Trans>trans_33_Cancel</Trans>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <ModalAddNewSchedule 
          selectedIdVox={selectedIdVox}
          selectedIdLocation={selectedIdLocation}
          searchedText={searchedText}
          selectedDay={this.state.selectedDayAddButton}
          show={this.state.modalAddNewScheduleShow}
          onHide={closeAddNewScheduleModal}
          onScheduleAdded={this.onScheduleAdded}
        />

        <ModalConfirmAction
          show={this.state.modalConfirmPasteShow}
          actionTitle={i18n.t("trans_33_campaign_agenda_confim_paste")}
          actionBody={i18n.t("trans_33_campaign_agenda_confim_paste_description")}
          noBtnText={i18n.t("trans_33_campaign_agenda_confim_no_btn")}
          yesBtnText={i18n.t("trans_33_campaign_agenda_confim_yes_btn")}
          onConfirmNo={modalConfirmPasteClose}
          onHide={modalConfirmPasteClose}
          onConfirmYes={this.doPaste}
        />

        <ModalConfirmAction
          show={this.state.modalConfirmDeleteShow}
          actionTitle={i18n.t("trans_33_campaign_agenda_confim_delete")}
          actionBody={i18n.t("trans_33_campaign_agenda_confim_delete_description")}
          noBtnText={i18n.t("trans_33_campaign_agenda_confim_no_btn")}
          yesBtnText={i18n.t("trans_33_campaign_agenda_confim_yes_btn")}
          onConfirmNo={modalConfirmDeleteClose}
          onHide={modalConfirmDeleteClose}
          onConfirmYes={this.doDelete}
        />

        <ModalOnboardingScheduleGiftPack
          show={this.state.modalOnboardingScheduleGiftPack}
          onHide={modalOnboardingScheduleGiftPackClose}
          onConfirmOnboardingScheduleGiftPack={
            modalOnboardingScheduleGiftPackClick
          }
        />

        <ModalImportSchedules
          size="lg"
          show={this.state.modalImportShow}
          onHide={closeImportModal}
          onQuestionsImported={this.onQuestionsImported}
        />


        <ModalSendMessage
            size="lg"
            show={this.state.modalSendMessageShow}
            onHide={closeSendMessageModal}
        />
      </div>
    );
  }

  doFetchCompany() {
    const userStr = localStorage.getItem("user");
    let IdCompany = 0;
    if (userStr) {
      let userObj = JSON.parse(userStr);
      IdCompany = userObj.IdCompany;
    }

    return serverService.getCompany(IdCompany).then(
      (result) => {
        let company = result.data[0];
        if (result.status === "1") {
          this.setState({
            company: company,
            isAutoPilotOn: company.AutoPilot == 1,
          });
        }
      },
      (error) => {
        toast.warn("Did not get company");
      }
    );
  }

  onChangeAutoPilot(checked) {
    this.setState({ isAutoPilotOn: checked });

    let IdCompany = 0;
    let userStr = localStorage.getItem("user");
    if (userStr) {
      let userObj = JSON.parse(userStr);
      IdCompany = userObj.IdCompany;
    }

    let company = {
      IdCompany: IdCompany.toString(),
      AutoPilot: (checked ? 1 : 0).toString(),
    };
    serverService.updateCompany(company).then(
      (result) => {
        if (result.status === "1") {
          if (checked) {
            toast.success(i18n.t("trans_33_campaign_agenda_msg_auto_pilot_on"));
          } else {
            toast.error(i18n.t("trans_33_campaign_agenda_msg_auto_pilot_off"));
          }
        } else {
          toast.warn("Failed to update auto-pilot setting");
        }
      },
      (error) => {
        toast.warn("Did not update auto-pilot");
      }
    );
  }

  onQuestionsImported(item) {
    this.setState({ modalImportShow: false }, function () {
      this.fetchDataForSelectedMonth();
    });
  }

  onVoxSelected(IdVox) {
    this.setState({ selectedIdVox: IdVox }, function () {
      this.fetchDataForSelectedMonth();
    });
  }

  getNumberOfVoxesFetched(numberOfVoxes) {
    this.setState({ showVoxAndLocationFilters: numberOfVoxes > 1 });
  }

  onLocationSelected(IdLocation) {
    this.setState(
      { selectedIdLocation: IdLocation, selectedIdVox: 0 },
      function () {
        this.fetchDataForSelectedMonth();
      }
    );
  }

  handleSearch(e) {
    const { name, value } = e.target;
    this.doDelayedSearch(value);
  }

  onAccordionToggle(e) {
    //console.log(e);
    // toast("accordion toggle");
  }

  doDelayedSearch = (searchedText) => {
    const self = this;

    if (self.searchTimeout) {
      clearTimeout(self.searchTimeout);
    }

    self.setState(
      {
        searchedText: searchedText,
      },
      function () {
        self.searchTimeout = setTimeout(function () {
          self.fetchDataForSearchedText();
        }, 1000);
      }
    );
  };

  handleDayClick(day) {
    const selectedDayMoment = moment(day);
    this.showWeeklyViewPanelBySelectedDay(selectedDayMoment);
  }

  showWeeklyViewPanelBySelectedDay(selectedDayMoment) {
    const selectedDayMM = selectedDayMoment.clone();
    const from_date = selectedDayMoment.startOf("isoWeek").clone();
    const to_date = selectedDayMoment.endOf("isoWeek").clone();

    const selectedDays = this.state.company.SelectedDays;
    let weekDisplayModel = [];
    for (let m = moment(from_date); m.isBefore(to_date); m.add(1, "days")) {
      const dayOfWeek = m.format("dddd");
      const dateFormat = m.format("YYYY-MM-DD");
      if (
        selectedDays !== undefined &&
        selectedDays.toLowerCase().includes(dayOfWeek.toLowerCase())
      ) {
        weekDisplayModel.push({ date: dateFormat });
      }
    }

    const selectedDayDateMysqlFormat = selectedDayMM.format("YYYY-MM-DD");

    this.setState({
      isInCopyState: false,
      weekDisplayModel: weekDisplayModel,
      selectedDay: selectedDayMM.toDate(),
      weekDisplaySelectedDayToggle: true,
      currentSelectedToggleDate: selectedDayDateMysqlFormat,
    });
  }

  showWeeklyViewPanelBySearchedText() {
    let schedulesDataFormattedObjectFromSearch = this.state
      .schedulesDataFormattedObjectFromSearch;

    let weekDisplayModel = [];
    for (let dateKey in schedulesDataFormattedObjectFromSearch) {
      weekDisplayModel.push({ date: dateKey });
    }

    this.setState({
      weekDisplayModel: weekDisplayModel,
      weekDisplaySelectedDayToggle: false,
    });
  }

  onMonthChange(month) {
    this.setState({ selectedMonth: month, selectedDay: month }, function () {
      this.fetchDataForSelectedMonth().then((res) => {
        this.showWeeklyViewPanelBySelectedDay(moment(month));
      });
    });
  }

  fetchDataForSearchedText() {
    let searchedText = this.state.searchedText;

    if (searchedText.length > 0) {
      this.setState({ loading: true });
      serverService.getSchedulesDataBySearchedText(searchedText).then(
        (result) => {
          if (result.status === "1") {
            let schedulesData = result.data;
            let numberOfVoxes = result.numberofvoxes;

            // {'2019-10-23': [{'IdVox':'32', 'IdQuestion': '88'},{'IdVox':'33', 'IdQuestion': '88'}]}
            let schedulesDataFormattedObjectFromSearch = {};

            for (let i = 0; i < schedulesData.length; i++) {
              let date = moment(schedulesData[i].Date).format("YYYY-MM-DD");
              if (schedulesDataFormattedObjectFromSearch[date] === undefined) {
                schedulesDataFormattedObjectFromSearch[date] = [];
              }
              schedulesDataFormattedObjectFromSearch[date].push({
                IdQuestion: schedulesData[i].IdQuestion,
                IdVox: schedulesData[i].IdVox,
                VoxName: schedulesData[i].Name,
                QuestionText: schedulesData[i].QuestionText,
                Type: schedulesData[i].Type,
              });
            }

            this.setState(
              {
                loading: false,
                schedulesDataFormattedObjectFromSearch: schedulesDataFormattedObjectFromSearch,
              },
              function () {
                this.showWeeklyViewPanelBySearchedText();
              }
            );
          } else {
            toast("Something went wrong while fetching data 1");
          }
        },
        (error) => {
          toast.warn("Did not get schedules for search");
          this.setState({ loading: false });
        }
      );
    } else {
      this.showWeeklyViewPanelBySelectedDay(moment());
    }
  }

  fetchDataForSelectedMonth() {
    let day = moment(this.state.selectedDay).format("YYYY-MM-DD");
    if (this.state.selectedDay === undefined) {
      day = moment().format("YYYY-MM-DD");
    }

    let month = moment(this.state.selectedMonth).format("YYYY-MM-DD");
    if (this.state.selectedMonth === undefined) {
      month = moment().format("YYYY-MM-DD");
    }

    this.setState({ loading: true });
    return serverService
      .getSchedulesDataForAgendaByMonth(
        month,
        this.state.selectedIdLocation,
        this.state.selectedIdVox
      )
      .then(
        (result) => {
          if (result.status === "1") {
            let schedulesData = result.data;
            let numberOfVoxes = result.numberofvoxes;

            let userStr = localStorage.getItem("user");
            let IdUser = 0;
            let userObj = {};
            if (userStr) {
              userObj = JSON.parse(userStr);
              IdUser = userObj['IdUser'];
            }

            let FinishedOnboarding = 1;
            if(!userObj['FinishedOnboarding'] || userObj['FinishedOnboarding'] == 0){
              FinishedOnboarding = 0;
            }

            if (
              (FinishedOnboarding == 0 &&
                schedulesData.length === 0 &&
                month === moment().format("YYYY-MM-DD"))) {
              this.setState({ modalOnboardingScheduleGiftPack: true });
            }

            // {'2019-10-23': [{'IdVox':'32', 'IdQuestion': '88'},{'IdVox':'33', 'IdQuestion': '88'}]}
            let schedulesDataFormattedObject = {};

            for (let i = 0; i < schedulesData.length; i++) {
              const date = moment(schedulesData[i].Date).format("YYYY-MM-DD");
              if (schedulesDataFormattedObject[date] === undefined) {
                schedulesDataFormattedObject[date] = [];
              }
              schedulesDataFormattedObject[date].push({
                IdQuestion: schedulesData[i].IdQuestion,
                IdVox: schedulesData[i].IdVox,
                VoxName: schedulesData[i].Name,
                QuestionText: schedulesData[i].QuestionText,
                Type: schedulesData[i].Type,
              });
            }

            let tempSelectedDaysModifiers = {
              partiallyassigned: [],
              assigned: [],
            };

            let weeklyViewColorCodes = {};
            for (let dateKey in schedulesDataFormattedObject) {
              weeklyViewColorCodes[dateKey] = {};
              weeklyViewColorCodes[dateKey].color = "none";
              if (numberOfVoxes === 1) {
                weeklyViewColorCodes[dateKey].color = "colorGreen";
                tempSelectedDaysModifiers.assigned.push(new Date(dateKey));
              } else if (
                numberOfVoxes > 1 &&
                schedulesDataFormattedObject[dateKey].length < numberOfVoxes
              ) {
                weeklyViewColorCodes[dateKey].color = "colorOrange";
                tempSelectedDaysModifiers.partiallyassigned.push(
                  new Date(dateKey)
                );
              } else if (
                numberOfVoxes > 1 &&
                schedulesDataFormattedObject[dateKey].length >= numberOfVoxes
              ) {
                weeklyViewColorCodes[dateKey].color = "colorGreen";
                tempSelectedDaysModifiers.assigned.push(new Date(dateKey));
              }
            }

            this.setState({
              selectedDaysModifiers: tempSelectedDaysModifiers,
              weeklyViewColorCodes: weeklyViewColorCodes,
              loading: false,
              schedulesDataFormattedObject: schedulesDataFormattedObject,
            });
          } else {
            toast("Something went wrong while fetching data 2");
          }
        },
        (error) => {
          toast.warn("Did not get schedules");
          this.setState({ loading: false });
        }
      );
  }

  navigateToRequestQuestionsPage(){
    window.location.href = "/request";
  }

  onScheduleAdded() {
    this.fetchDataForSelectedMonth();
  }

  doPaste() {
    const copyDate = this.state.copyDate;
    const copyIdVox = this.state.copyIdVox;
    const copyIdLocation = this.state.copyIdLocation;

    const pasteDate = this.state.pasteDate;

    serverService
      .copyAllScheduledQuestionsFromDateToAnotherDate(
        copyDate,
        copyIdVox,
        copyIdLocation,
        pasteDate
      )
      .then(
        (result) => {
          if (result.status === "1") {
            this.setState({ modalConfirmPasteShow: false });
            toast.success(
              i18n.t("trans_33_campaign_agenda_msg_schedules_pasted_from") + " " + copyDate + " " + i18n.t("trans_33_to") + " " + pasteDate
            );
            this.fetchDataForSelectedMonth();
          } else {
            toast.warn("Failed to paste");
          }
        },
        (error) => {
          toast.warn("Did not copy questions");
        }
      );
  }

  cancelInCopyState() {
    this.setState({ isInCopyState: false, copyDate: "" });
  }

  doDelete() {
    const deleteDate = this.state.deleteDate;
    const IdVox = this.state.selectedIdVox;
    const IdLocation = this.state.selectedIdLocation;
    serverService.deleteScheduledQuestions(deleteDate, IdVox, IdLocation).then(
      (result) => {
        if (result.status === "1") {
          this.setState({ modalConfirmDeleteShow: false });
          toast.success(i18n.t("trans_33_campaign_agenda_msg_schedules_deleted_from") + " " + deleteDate);
          this.fetchDataForSelectedMonth();
        } else {
          toast.warn("Failed to delete");
        }
      },
      (error) => {
        toast.warn("Did not delete scheduled questions");
      }
    );
  }


  markOnboadringAsFinished(){
    serverService.finishOnboarding()
        .then(
            (response) => {
              if(response.status == 1){
                  let userObj = JSON.parse(localStorage.getItem("user"));
                  userObj['FinishedOnboarding'] = 1;
                  localStorage.setItem('user',JSON.stringify(userObj));
              }
            },
            (error) => {
              console.log(error);
              toast.warn(i18n.t("trans_33_msg_something_went_wrong"));
            }
        );
  }
}

export default CampaignAgendaPage;
