/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import "./ModalOnboardingCancelScheduleGiftPack.css"

import 'react-day-picker/lib/style.css';

import {Trans} from "react-i18next";

interface MyProps {
    onConfirmChangedMyMind(): void,
    onConfirmCancelGiftPack(): void,
    show:boolean,
    onHide(): any,
}

interface MyState {
    formFields:any,
    modalConfirmCancelShow:boolean,
}


class ModalOnboardingCancelScheduleGiftPack extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);
        this.state = {
            formFields:{},
            modalConfirmCancelShow:false,
        };

        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleCancelCancelClick = this.handleCancelCancelClick.bind(this);
        this.handleCancelYesClick = this.handleCancelYesClick.bind(this);
    }


    handleCancelClick() {
        this.setState({modalConfirmCancelShow:true});
    }

    handleCancelCancelClick() {
        this.setState({modalConfirmCancelShow:false});
    }

    handleCancelYesClick() {
        this.props.onHide();
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        <img src="/assets/images/onboarding/onboarding_cancel.png" id="cancel_img"/>
                        <Trans>
                            trans_33_modal_onboarding_cancel_gift_pack_title
                        </Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <br/>
                    <br/>
                    <p>
                        <Trans>
                            trans_33_modal_onboarding_cancel_gift_pack_description1
                        </Trans>
                    </p>
                    <p>
                        <Trans>
                            trans_33_modal_onboarding_cancel_gift_pack_description2
                        </Trans>
                    </p>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.props.onConfirmCancelGiftPack}>
                            <Trans>
                                trans_33_modal_onboarding_cancel_gift_pack_cancel_btn
                            </Trans>
                        </Button>
                        <Button variant="primary" onClick={this.props.onConfirmChangedMyMind}>
                            <Trans>
                                trans_33_modal_onboarding_cancel_gift_pack_ok_btn
                            </Trans>
                        </Button>
                    </Modal.Footer>

                </Modal.Body>

            </Modal>
        );
    }

}

export default ModalOnboardingCancelScheduleGiftPack;