/**
 * Created by alexd on 8/5/2020.
 */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from './res/locales/translation-en.json';
import translationFR from './res/locales/translation-fr.json';
import translationDE from './res/locales/translation-de.json';
import translationNL from './res/locales/translation-nl.json';
import translationES from './res/locales/translation-es.json';
import translationIT from './res/locales/translation-it.json';

const resources = {
    en: {
        translation: translationEN
    }, fr: {
        translation: translationFR
    }, de: {
        translation: translationDE
    }, nl: {
        translation: translationNL
    }, es: {
        translation: translationES
    }, it: {
        translation: translationIT
    }
};

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        resources,

        keySeparator: false,

        interpolation: {
            escapeValue: false
        }
    }, xx => {
    });

export default i18n;